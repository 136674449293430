import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getHistory } from '../../../utils/history.util';

declare global {
  interface Window {
    LO: any;
  }
}

const logTransactionRequestEventInLuckyOrange = (transactionRequest: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Placed ' + transactionRequest.type + ' Request', transactionRequest);
      console.log('[LO Event]: Placed ' + transactionRequest.type + ' Request');
    }
  }
};

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const INSERT_TRANSACTION = `INSERT_TRANSACTION${suffix}`;

export const insertTransactionAction = createAction<any>(INSERT_TRANSACTION);

const INSERT_TRANSACTION_SUCCESS = `INSERT_TRANSACTION_SUCCESS${suffix}`;
const insertTransactionSuccessAction = createAction<any[]>(INSERT_TRANSACTION_SUCCESS);

const INSERT_TRANSACTION_ERROR = `INSERT_TRANSACTION_ERROR${suffix}`;
const insertTransactionErrorAction = createAction<string>(INSERT_TRANSACTION_ERROR);

function* insertTransactionWatcher(): SagaIterator {
  yield takeEvery(INSERT_TRANSACTION, insertTransactionWorker);
}
mergeSaga(insertTransactionWatcher);

function* insertTransactionWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(insertTransactionApi, action.payload);
    yield put(insertTransactionSuccessAction(result));
    getHistory().push('/cashier/payment');
  } catch (e) {
    console.log(e.response);
    yield call(showToast, e.response.data.message, 'error');
    yield put(insertTransactionErrorAction(e));
  }
}

const insertTransactionApi = (data: any): Request => {
  return vipApi(`/pcashier/InsertTransaction`, 'post', {
    site: 1,
    type: data.type,
    method: data.method,
    bonus: data.bonus != null || data.bonus != undefined ? data.bonus : '',
    player_number: data.playerNumber,
    account: data.account != null || data.account != undefined ? data.account : '',
    amount: data.amount,
    name: data.name != null || data.name != undefined ? data.name : '',
    fingerprint: data.fingerprint,
    ip: data.ip,
  });
};

const reduceHandlers = {
  [INSERT_TRANSACTION]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [INSERT_TRANSACTION_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => {
    logTransactionRequestEventInLuckyOrange(action.payload.data);

    return { ...slice, isLoading: false, transactionRequest: action.payload.data };
  },
  [INSERT_TRANSACTION_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
