import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
import { getUserBalanceAction } from '../../user/actions/get-balance.action';

declare global {
  interface Window {
    LO: any;
  }
}

const logPlacedWagerInLuckyOrange = (wagerData: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Placed Wager', wagerData);
      console.log('[LO Event]: Placed Wager');
    }
  }
};

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const PLACE_BET = `PLACE_BET${suffix}`;

export const placeBetAction = createAction<any>(PLACE_BET);

const PLACE_BET_SUCCESS = `PLACE_BET_SUCCESS${suffix}`;
const placeBetSuccessAction = createAction<any[]>(PLACE_BET_SUCCESS);

const PLACE_BET_ERROR = `PLACE_BET_ERROR${suffix}`;
const placeBetErrorAction = createAction<string>(PLACE_BET_ERROR);

function* placeBetWatcher(): SagaIterator {
  yield takeEvery(PLACE_BET, placeBetWorker);
}
mergeSaga(placeBetWatcher);

function* placeBetWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(placeBetApi, action.payload);
    if (result && result.data && result.data.wager) {
      if (!Array.isArray(result.data.wager)) result.data.wager = [result.data.wager];
      for (let index = 0; index < result.data.wager.length; index++) {
        const element = result.data.wager[index];
        if (!Array.isArray(element.detail)) element.detail = [element.detail];
      }
      logPlacedWagerInLuckyOrange(result.data.wager);
    }
    yield put(placeBetSuccessAction(result));
    yield put(getUserBalanceAction());
  } catch (e) {
    yield put(placeBetErrorAction(e));
  }
}

const placeBetApi = (data: any): Request => {
  return vipApi(`/wager/betslip/placeBet`, 'post', data);
};

const reduceHandlers = {
  [PLACE_BET]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [PLACE_BET_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    betResponses: action.payload,
  }),
  [PLACE_BET_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
