import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Button, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Loading } from '../../shared/loading/loading.component';
import { getHistory } from '../../utils/history.util';
import TermsConditionDialoge from './components/terms-condition-dialoge';

const useStyles: any = makeStyles({
  root: {
    padding: 0,
    width: '100%',
  },
  banner: {
    width: '100%',
    height: 'auto',
  },
  boxDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.5rem',
    height: 'auto',
  },
  bonusHead: {
    color: '#c8a964',
    fontSize: '26px',
    marginBottom: '2px',
    lineHeight: '120%',
    fontWeight: 600,
  },
  bonusPara: {
    color: '#a1a1a1',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '7px',
  },
  bonusReadMore: {
    color: '#c8a964',
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '12px',
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '0px 5px 5px 0px',
  },
  actionBtn: {
    marginTop: '2rem',
  },
});

export interface ExternalActionProps {
  getPromotionAction: () => void;
}

export interface ExternalProps {
  promotions: any[];
  isLoading: boolean;
}

export const PromotionsPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getPromotionAction, promotions, isLoading } = props;
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    getPromotionAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <img src={`${process.env.PUBLIC_URL}/images/bonuses-page/banner.png`} className={classes.banner} />

      <Container style={{ marginTop: '1rem' }}>
        <Grid container spacing={5}>
          {promotions &&
            promotions.map((item: any, i: number) => {
              return (
                <Grid item sm={6} xs={12} key={i}>
                  <Paper>
                    <Grid container>
                      <Grid item sm={8} xs={12}>
                        <div className={classes.boxDiv}>
                          <div>
                            <Typography className={classes.bonusHead}>{item.code}</Typography>
                            <Typography className={classes.bonusPara}>{item.description}</Typography>
                            <Typography
                              className={classes.bonusReadMore}
                              onClick={() => {
                                setOpen(true);
                                setSelected(item);
                              }}
                            >
                              Read More
                            </Typography>
                          </div>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => getHistory().push('/cashier')}
                            className={classes.actionBtn}
                          >
                            Deposit Now
                          </Button>
                        </div>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        {item.image && <img src={item.image} className={classes.img} />}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <TermsConditionDialoge
        open={open}
        onClose={() => {
          setOpen(false);
          setSelected(selected);
        }}
        data={selected}
      />
    </div>
  );
};
