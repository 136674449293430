import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_AGENT_STATS = `GET_AGENT_STATS${suffix}`;

export const getAgentStatsAction = createAction(GET_AGENT_STATS);

const GET_AGENT_STATS_SUCCESS = `GET_AGENT_STATS_SUCCESS${suffix}`;
const getAgentStatsSuccessAction = createAction<any[]>(GET_AGENT_STATS_SUCCESS);

const GET_AGENT_STATS_ERROR = `GET_AGENT_STATS_ERROR${suffix}`;
const getAgentStatsErrorAction = createAction<any>(GET_AGENT_STATS_ERROR);

function* getAgentStatsWatcher(): SagaIterator {
  yield takeEvery(GET_AGENT_STATS, getAgentStatsWorker);
}
mergeSaga(getAgentStatsWatcher);

function* getAgentStatsWorker(): SagaIterator {
  try {
    const result: any = yield call(getAgentStatsApi);
    yield put(getAgentStatsSuccessAction(result));
  } catch (e) {
    yield put(getAgentStatsErrorAction(e));
  }
}

const getAgentStatsApi = (): Request => {
  return vipApi(`/wager/GetAgentStats`, 'post', {});
};

const reduceHandlers = {
  [GET_AGENT_STATS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_AGENT_STATS_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    agentData: action.payload.data,
  }),
  [GET_AGENT_STATS_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
