import { connect } from 'react-redux';
import { CashierPage, ExternalProps, ExternalActionProps } from './cashier.component';
import { isLoadingSelector, loggedInUserSelector, userBalanceSelector } from './../../../api/user/user.selector';
import {
  fingerprintSelector,
  freeFreeTimesSelector,
  ipSelector,
  isLoadingSliceSelector,
  rolloverSelector,
} from '../../../api/cashier/cashier.selector';
import { getPlayerRolloverAction } from '../../../api/cashier/actions/get-player-rollover.action';
import { setTransactionIpAction } from '../../../api/cashier/actions/set-transaction-ip.action';
import { setTransactionTypeAction } from '../../../api/cashier/actions/set-transaction-type.action';
import { setTransactionBonusAction } from '../../../api/cashier/actions/set-transaction-bonus.action';
import { setTransactionFingerprintAction } from '../../../api/cashier/actions/set-transaction-fingerprint.action';
import { setNeedCashierFowardAction } from '../../../api/user/actions/set-need-cashier-forward.action';
import { getUserInfoAction } from '../../../api/user/actions/get-user-info.action';
import { setBankReferenceNullAction } from '../../../api/cashier/actions/set-transaction-bank-reference-null.action';
import { getFeeFreeTimesAction } from '../../../api/cashier/actions/get-fee-free-times.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    user: loggedInUserSelector(state),
    isLoading: isLoadingSliceSelector(state),
    userDataLoading: isLoadingSelector(state),
    rollover: rolloverSelector(state),
    fingerprint: fingerprintSelector(state),
    ip: ipSelector(state),
    userBalances: userBalanceSelector(state),
    feeFreeTimes: freeFreeTimesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPlayerRolloverAction,
  setTransactionIpAction,
  setTransactionTypeAction,
  setTransactionBonusAction,
  setTransactionFingerprintAction,
  setNeedCashierFowardAction,
  getUserInfoAction,
  setBankReferenceNullAction,
  getFeeFreeTimesAction,
};

export const CashierPageContainer = connect(mapStateToProps, mapDispatchToProps)(CashierPage);
