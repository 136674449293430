export interface Users {
  id?: number;
  name: string;
  playerNumber: string;
  email: string;
  phoneNumber: string;
  commissionPercentage: string;
  first_name: string;
  last_name: string;
}

export interface ISignup {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
  inviter_code: string;
  country_code: string;
  fingerprint: string | null;
  ip: string;
}

export const emptySignup: ISignup = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  country_code: '1',
  password: '',
  password_confirmation: '',
  inviter_code: '',
  fingerprint: '',
  ip: '',
};
