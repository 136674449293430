import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MarkChatUnreadIcon from '@material-ui/icons/Feedback'; //SpeakerNotes
// import MarkChatUnreadIcon from '@material-ui/icons/MarkChatUnread';
import Joyride, { CallBackProps, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useHistory } from 'react-router';

////// All Reserved Sections

// .tour_playerHistory-table

// .tour_bonusHistory-description
// .tour_bonusHistory-details
// .tour_bonusHistory-wagers
// .tour_bonusHistory-contribution

// .tour_casino-providers
// .tour_casino-categories
// .tour_casino-slider
// .tour_casino-grid

// .tour_profile-cashBalance
// .tour_profile-userName
// .tour_profile-freeplay
// .tour_profile-btns
// .tour_profile-betHistoryTab
// .tour_profile-transactionsTab
// .tour_profile-bonusHistoryTab
// .tour_profile-agentTab
// .tour_profile-rulesTab
// .tour_profile-accountTab
// .tour_profile-personalInfo
// .tour_profile-updateProfile
/////// .tour_profile-updateBtn

// .tour_raf-referred
// .tour_raf-earned
// .tour_raf-claimed
// .tour_raf-rules
// .tour_raf-howWork
// .tour_raf-url
// .tour_raf-shareBtns
// .tour_raf-statusCell
// .tour_raf-claimBonusCell

// .tour_rules-generalTerms
// .tour_rules-sportsRules
// .tour_rules-content

// .tour_header-watchBtn
// .tour_header-username
// .tour_header-cashier
// .tour_header-chat

// .tour_tabBar-sportsbook
// .tour_tabBar-casino
// .tour_tabBar-live
// .tour_tabBar-esports
// .tour_tabBar-horses
// .tour_tabBar-refer-a-friend
// .tour_tabBar-rules
// .tour_tabBar-myaccount

// .tour_sportsbook-leftNav
// .tour_sportsbook-content

// .tour_transactionHistory-filter
// .tour_transactionHistory-table
// .tour_transactionHistory-dateCell
// .tour_transactionHistory-typeCell
// .tour_transactionHistory-statusCell
/////// .tour_transactionHistory-filterDays
/////// .tour_transactionHistory-filterDeposit

/////// .tour_betHistory-filterBy
/////// .tour_betHistory-table

/////// .tour_deposit-customAmount
/////// .tour_deposit-amount
/////// .tour_deposit-secondField
/////// .tour_deposit-continue

//////

// const newTourSections = [
//   {
//     // id: [id],
//     previewImage: '......./tour_profile-updateBtn.png',
//     target: '.tour_profile-updateBtn',
//     targetName: 'profile-updateBtn',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_transactionHistory-filterDays.png',
//     target: '.tour_transactionHistory-filterDays',
//     targetName: 'transactionHistory-filterDays',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_transactionHistory-filterDeposit.png',
//     target: '.tour_transactionHistory-filterDeposit',
//     targetName: 'transactionHistory-filterDeposit',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_betHistory-filterBy.png',
//     target: '.tour_betHistory-filterBy',
//     targetName: 'betHistory-filterBy',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_betHistory-table.png',
//     target: '.tour_betHistory-table',
//     targetName: 'betHistory-table',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_deposit-customAmount.png',
//     target: '.tour_deposit-customAmount',
//     targetName: 'deposit-customAmount',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_deposit-amount.png',
//     target: '.tour_deposit-amount',
//     targetName: 'deposit-amount',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_deposit-secondField.png',
//     target: '.tour_deposit-secondField',
//     targetName: 'deposit-secondField',
//   },
//   {
//     // id: [id],
//     previewImage: '......./tour_deposit-continue.png',
//     target: '.tour_deposit-continue',
//     targetName: 'deposit-continue',
//   },
// ];

const useStyles = makeStyles(() => ({
  runTour: {
    position: 'fixed',
    padding: '20px',
    backgroundColor: '#c9a964',
    right: 0,
    top: '50%',
    borderRadius: '5px 0 0 5px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    transform: 'translate(100px, -50%)',
    transition: 'all .1s cubic-bezier(0.4, 0, 1, 1)',
    '&:hover': {
      transform: 'translate(0, -50%)',
      '& $tourIcon': {
        marginRight: '10px',
      },
    },
  },
  tourIcon: {
    marginRight: '30px',
    transition: 'all .15s cubic-bezier(0.4, 0, 1, 1)',
  },
}));

export interface TourProps {
  getTourStepsAction: () => void;
  tourSteps: any;
}

const routeName: any = {
  '/sportsbook': 'sportsbook',
  '/casino': 'casino',
  '/refer-a-friend': 'raf',
  '/rules': 'rules',
  '/guides': 'guides',
  '/profile': 'profile',
  '/player-history': 'playerHistory',
  '/transaction-history': 'transactionHistory',
  '/bonus-history': 'bonusHistory',
  '/agent': 'agent',
  '/cashier': 'cashier',
  '/cashier/info': 'deposit',
};

const Tour = ({ getTourStepsAction, tourSteps }: TourProps) => {
  const classes = useStyles();
  const [run, setRun] = React.useState<boolean>(false);
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const [allSteps, setAllSteps] = React.useState<any>([]);
  const history = useHistory();

  React.useEffect(() => {
    getTourStepsAction();
  }, []);

  React.useEffect(() => {
    const pathname = history.location.pathname;
    const routeTourData = Array.isArray(tourSteps) && tourSteps.filter((item: any) => item.page === routeName[pathname]);
    if (routeTourData) {
      const _routeAllSteps = routeTourData[0]?.steps.map((item: any) => {
        return {
          target: `.tour_${item?.stepName}`,
          content: item?.stepDescription,
          disableBeacon: true,
        };
      });
      setAllSteps(_routeAllSteps);
    }
  }, [history.location, tourSteps]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const stepInfo: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const { action, index, status, type } = data;
    if (stepInfo.includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if (finishedStatuses.includes(status)) {
      setStepIndex(0);
      setRun(false);
    }
  };

  return (
    <>
      {allSteps?.length > 0 && (
        <div onClick={() => setRun(true)} className={classes.runTour}>
          <MarkChatUnreadIcon className={classes.tourIcon} />
          Start Tour
        </div>
      )}
      {run && (
        <Joyride
          callback={handleJoyrideCallback}
          run={run}
          stepIndex={stepIndex}
          steps={allSteps}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          scrollOffset={250}
          styles={{
            options: {
              primaryColor: '#c8a964',
              zIndex: 10000,
            },
          }}
        />
      )}
    </>
  );
};

export default Tour;
