import { connect } from 'react-redux';
import { getRafCodeAction } from '../../api/user/actions/get-raf-code.action';
import { getRafHistoryAction } from '../../api/user/actions/get-raf-history.action';
import { getUserInfoAction } from '../../api/user/actions/get-user-info.action';
import { redeemRafBonusAction } from '../../api/user/actions/redeem-raf-bonus.action';
import { isLoadingSelector, userRafCodeSelector, userRafSelector, userSliceSelector } from '../../api/user/user.selector';
import { ReferAFriendPage, ExternalProps, ExternalActionProps } from './raf.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    user: userSliceSelector(state),
    isLoading: isLoadingSelector(state),
    rafData: userRafSelector(state),
    rafCode: userRafCodeSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getRafHistoryAction,
  redeemRafBonusAction,
  getRafCodeAction,
  getUserInfoAction,
};

export const ReferAFriendPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReferAFriendPage);
