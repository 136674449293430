import { connect } from 'react-redux';
import { RulesPage, ExternalProps, ExternalActionProps } from './rules.component';
import { isLoadingSliceSelector, rulesSelector, categoriesSelector } from './../../api/rule/rule.selector';
import { getRuleAction } from './../../api/rule/actions/get-rule.action';
import { getCategoryAction } from './../../api/rule/actions/get-category.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    rules: rulesSelector(state),
    isLoading: isLoadingSliceSelector(state),
    categories: categoriesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getRuleAction,
  getCategoryAction,
};

export const RulesPageContainer = connect(mapStateToProps, mapDispatchToProps)(RulesPage);
