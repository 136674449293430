import { connect } from 'react-redux';
import { getUserInfoAction } from '../../api/user/actions/get-user-info.action';
import { sendEmailCodeAction } from '../../api/user/actions/send-email-code.action';
import { sendOtpAction } from '../../api/user/actions/send-otp-action';
import { updateProfileAction } from '../../api/user/actions/update-profile.action';
import { verifyEmailCodeAction } from '../../api/user/actions/verify-email-code.action';
import { verifyOtpAction } from '../../api/user/actions/verify-otp-action';
import { isLoadingSelector, loggedInUserSelector, userBalanceSelector } from '../../api/user/user.selector';
import { ProfilePage, ExternalProps, ExternalActionProps } from './profile-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    userBalance: userBalanceSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getUserInfoAction,
  sendEmailCodeAction,
  verifyEmailCodeAction,
  verifyOtpAction,
  sendOtpAction,
  updateProfileAction,
};

export const ProfilePageContainer = connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
