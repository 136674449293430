import React from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#121212',
  },
});
export interface ExternalProps {
  dayData: any;
}

export const DesktopWagerDetail = (props: ExternalProps) => {
  const classes = useStyles();
  const { dayData } = props;

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={0}>
        {dayData &&
          dayData.map((day: any, index: any) => {
            return (
              <Grid key={index} item xs={12}>
                <Grid container style={{ borderTop: '1px solid #121212', backgroundColor: '#2d2d2d' }}>
                  <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
                    {day.TicketNumber == 0 ? 'TRANS' : 'WAGER'}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
                    {day.TicketNumber != 0 && <Typography variant='body1'>Ticket #{day.TicketNumber}</Typography>}
                    {day.TicketNumber == 0 && day.PlacedDate + ' ' + day.PlacedTime}
                    {day.TicketNumber != 0 &&
                      day.detail.map((detail: any, index: any) => {
                        return (
                          <>
                            <Typography key={index} variant='caption'>
                              {detail.GameDate} {detail.GameTime}
                            </Typography>
                            <br />
                          </>
                        );
                      })}
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'left', padding: '.5rem' }}>
                    <div dangerouslySetInnerHTML={{ __html: day.HeaderDesc }}></div>
                    <div>
                      {day.TicketNumber != 0 &&
                        day.detail.map((detail: any, index: any) => {
                          return (
                            <>
                              <Typography key={index} variant='caption'>
                                [{detail.IdSport}] {detail.DetailDesc}
                              </Typography>
                              <br />
                            </>
                          );
                        })}
                    </div>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
                    {day.Result == '' ? (
                      ''
                    ) : (
                      <>
                        {day.Result == 'WIN' ? (
                          <Typography style={{ color: 'green' }}>{day.Result}</Typography>
                        ) : (
                          <Typography style={{ color: 'red' }}>{day.Result}</Typography>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
                    {day.TicketNumber == 0 ? (
                      ''
                    ) : (
                      <>
                        {'$' + day.RiskAmount}/{'$' + day.WinAmount}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
                    {day.WinLoss > 0 ? (
                      <Typography style={{ color: 'green' }}>{'$' + day.WinLoss}</Typography>
                    ) : (
                      <Typography style={day.WinLoss < 0 ? { color: 'red' } : { color: 'white' }}>{'$' + day.WinLoss}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'center', padding: '.5rem' }}>
                    {'$' + day.Balance}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem', lineBreak: 'anywhere' }}>
                    <Typography variant='caption'>
                      {day.PlacedDate}
                      <br />
                      {day.PlacedTime}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};
