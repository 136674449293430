import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
// import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const TV_ON_OFF = `TV_ON_OFF${suffix}`;

export const tvOnOffAction = createAction<boolean>(TV_ON_OFF);

const TV_ON_OFF_SUCCESS = `TV_ON_OFF_SUCCESS${suffix}`;
const tvOnOffSuccessAction = createAction<boolean>(TV_ON_OFF_SUCCESS);

const TV_ON_OFF_ERROR = `TV_ON_OFF_ERROR${suffix}`;
const tvOnOffErrorAction = createAction<string>(TV_ON_OFF_ERROR);

function* tvOnOffWatcher(): SagaIterator {
  yield takeEvery(TV_ON_OFF, tvOnOffWorker);
}
mergeSaga(tvOnOffWatcher);

function* tvOnOffWorker(action: any): SagaIterator {
  try {
    yield put(tvOnOffSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(tvOnOffErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [TV_ON_OFF]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [TV_ON_OFF_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    tvOnOffResponses: action.payload,
  }),
  [TV_ON_OFF_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
