import { connect } from 'react-redux';
import { PlayerHistoryPage, ExternalProps, ExternalActionProps } from './player-history.component';
import { getUserHistoryAction } from './../../api/user/actions/get-history.action';
import { userHistorySelector, isLoadingSelector, userBalanceSelector, loggedInUserSelector } from './../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    userHistory: userHistorySelector(state),
    isLoading: isLoadingSelector(state),
    userBalance: userBalanceSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getUserHistoryAction,
};

export const PlayerHistoryPageContainer = connect(mapStateToProps, mapDispatchToProps)(PlayerHistoryPage);
