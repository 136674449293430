import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
  selectBetType: {
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#000',
    padding: '0px 15px',
    marginRight: '10px',
    height: '27px',
    '& svg.MuiSelect-icon': {
      color: '#000',
    },
  },
  filterGrid: {
    display: 'flex',
    '& h6': {
      marginRight: '15px',
    },
  },
});

export interface ExternalActionProps {
  handleWeekChange: (weeksAgo: any) => void;
  handleFilterChange: (data: any) => void;
}

export interface ExternalProps {
  userHistory: any;
  weeksAgo: any;
  filter: any;
}

export const DailyBalance = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { userHistory, handleWeekChange, weeksAgo, handleFilterChange, filter } = props;

  const colorNumber = (number: any) => {
    if (number == 0) {
      return '$' + number;
    }
    if (number < 0) {
      return <span style={{ color: 'red' }}>-${number}</span>;
    }
    return <span style={{ color: 'green' }}>${number}</span>;
  };

  return (
    <div className={clsx(classes.root)}>
      <Grid container>
        <Grid item xs={6} style={{ backgroundColor: '#121212', width: '100%', padding: '.5rem' }}>
          <Typography gutterBottom style={{ fontWeight: 'bold' }}>
            {userHistory.StartDate + ' to ' + userHistory.EndDate}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ backgroundColor: '#121212', width: '100%', padding: '.5rem' }}>
          <div className={`${classes.filterGrid} tour_profile-betHistoryTab-filterBy`}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Select
                  className={classes.selectBetType}
                  value={weeksAgo}
                  onChange={e => {
                    handleWeekChange(e);
                  }}
                >
                  <MenuItem value={0}>This Week</MenuItem>
                  <MenuItem value={1}>1 Week Ago</MenuItem>
                  <MenuItem value={2}>2 Weeks Ago</MenuItem>
                  <MenuItem value={3}>3 Weeks Ago</MenuItem>
                  <MenuItem value={4}>4 Weeks Ago</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  className={classes.selectBetType}
                  value={filter}
                  onChange={e => {
                    handleFilterChange(e);
                  }}
                  style={{ marginRight: '.5rem' }}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'Wagers'}>Wagers</MenuItem>
                  <MenuItem value={'Payments'}>Payments</MenuItem>
                  <MenuItem value={'Free Plays'}>Free Plays</MenuItem>
                  <MenuItem value={'Casino'}>Casino</MenuItem>
                  <MenuItem value={'RBL'}>RBL</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box style={{ backgroundColor: '#2d2d2d', padding: '.8rem' }}>
            <Grid container>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                M
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                T
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                W
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                T
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                F
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                S
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                S
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center', fontWeight: 'bold' }}>
                Total
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: '.5rem' }}>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost1)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost2)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost3)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost4)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost5)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost6)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost7)}
              </Grid>
              <Grid item style={{ width: '12%', textAlign: 'center' }}>
                {colorNumber(userHistory.WonLost8)}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
