import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { promotionSelectionReducer, PromotionSelectionSliceType } from './../promotion.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${promotionSelectionReducer.sliceName}/app`;

const GET_PROMOTION = `GET_PROMOTION${suffix}`;

export const getPromotionAction = createAction(GET_PROMOTION);

const GET_PROMOTION_SUCCESS = `GET_PROMOTION_SUCCESS${suffix}`;
const getPromotionSuccessAction = createAction<any[]>(GET_PROMOTION_SUCCESS);

const GET_PROMOTION_ERROR = `GET_PROMOTION_ERROR${suffix}`;
const getPromotionErrorAction = createAction<string>(GET_PROMOTION_ERROR);

function* getPromotionWatcher(): SagaIterator {
  yield takeEvery(GET_PROMOTION, getPromotionWorker);
}
mergeSaga(getPromotionWatcher);

function* getPromotionWorker(): SagaIterator {
  try {
    const result: any = yield call(getPromotionApi);
    console.log(result.data);
    yield put(getPromotionSuccessAction(result.data));
  } catch (e) {
    yield put(getPromotionErrorAction('Some error occure.'));
  }
}

const getPromotionApi = (): Request => {
  return vipApi(`/wager/promotions`, 'get', {});
};

const reduceHandlers = {
  [GET_PROMOTION]: (slice: PromotionSelectionSliceType): PromotionSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PROMOTION_SUCCESS]: (slice: PromotionSelectionSliceType, action: any): PromotionSelectionSliceType => ({
    ...slice,
    isLoading: false,
    promotions: action.payload,
  }),
  [GET_PROMOTION_ERROR]: (slice: PromotionSelectionSliceType): PromotionSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

promotionSelectionReducer.addActionReducerMap(reduceHandlers);
