import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { showToast } from '../../../utils/toast/show-errors';
import { CashierApiResponse } from '../../../models/cashier-response';
import { getUserInfoAction } from './get-user-info.action';

const suffix = `/${userReducer.sliceName}/app`;

const VERIFY_OTP = `VERIFY_OTP${suffix}`;
export const verifyOtpAction = createAction<any>(VERIFY_OTP);

const VERIFY_OTP_ERROR = `VERIFY_OTP_ERROR${suffix}`;
const verifyOtpErrorAction = createAction<string>(VERIFY_OTP_ERROR);
const VERIFY_OTP_SUCCESS = `VERIFY_OTP_SUCCESS${suffix}`;
const verifyOtpSuccessAction = createAction<any>(VERIFY_OTP_SUCCESS);

function* verifyOtpWatcher(): SagaIterator {
  yield takeEvery(VERIFY_OTP, verifyOtpWorker);
}
mergeSaga(verifyOtpWatcher);

function* verifyOtpWorker(action: any): SagaIterator {
  try {
    const response: CashierApiResponse = yield call(getApiPlayer, action.payload);
    yield put(verifyOtpSuccessAction(response.data));
    yield put(getUserInfoAction());
  } catch (e) {
    // console.log('Error: ', e.response);
    yield call(showToast, e.response.data.message, 'error');
    yield put(verifyOtpErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (data: any): Request => {
  return vipApi(`/VerifyPhone`, 'post', data);
};

const reduceHandlers = {
  [VERIFY_OTP]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    verifyOtpSuccess: false,
  }),
  [VERIFY_OTP_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      verifyOtpSuccess: true,
      inviteDetails: action.payload,
    };
  },
  [VERIFY_OTP_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    error: action.payload,
    verifyOtpSuccess: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
