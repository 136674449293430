import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface ExternalProps {
  open: boolean;
  data: any;
}

export interface ExternalActionProps {
  onClose: () => void;
}

export const WageringDialoge = (props: ExternalProps & ExternalActionProps) => {
  const { open, data, onClose } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='md' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogTitle id='max-width-dialog-title'>Wagering History</DialogTitle>
        <DialogContent>{data}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary' variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
