import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LeftNav } from './components/left-nav';
import { GenericLobby } from './components/generic-lobby';
import { TpiCasino } from './components/tpi-casinos';
import { useEffect } from 'react';
import { AdvertisementSlider } from './components/advertisement-slider';
import { PROVIDERS } from '../../enum/providers/providers';
import { IRight } from '../../models/rights';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#121212',
    },
    providerBar: {
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      textAlign: 'center',
      fontSize: '2rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  }),
);

export interface ExternalProps {
  user: any;
  isLoading: any;
  categories: any;
  games: any;
  gameUrl: any;
  userBalances: any;
}

export interface ExternalActionProps {
  getCasinoGamesAction: (x: any) => void;
  changeCasinoGamesAction: (x: any) => void;
  openCasinoGameAction: (x: any) => void;
  getUserBalanceAction: () => void;
}

export const CasinoPage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const [provider, setProvider] = useState<IRight | null | undefined>(null);
  const [useFreePlay, setUseFreePlay] = useState(false);
  const {
    isLoading,
    categories,
    games,
    gameUrl,
    userBalances,
    user,
    getCasinoGamesAction,
    changeCasinoGamesAction,
    openCasinoGameAction,
    getUserBalanceAction,
  } = props;
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;
  // const setProvider = (item: any) => {
  //   setProvider2({ ...(provider ?? {}), ...item });
  // };

  useEffect(() => {
    getUserBalanceAction();
    // getCasinoGamesAction({
    //   deviceId: 1,
    //   category: '',
    //   providerId: '',
    // });
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Grid container>
        {/* Game Section */}
        <Grid item xs={12} sm={3}>
          <LeftNav
            categories={categories}
            user={user}
            provider={provider}
            setProvider={setProvider}
            changeCasinoGamesAction={changeCasinoGamesAction}
            userBalances={userBalances}
            useFreePlay={useFreePlay}
            setUseFreePlay={setUseFreePlay}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          {/* Ad Section */}
          {!isMobile && (
            <Grid item xs={12}>
              <AdvertisementSlider />
            </Grid>
          )}

          {/* <div className={classes.providerBar}>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              {provider == 0 ? 'Please select a provider' : provider == 1 ? 'Betsoft' : 'DGS'}
            </Typography>
          </div> */}

          {provider && [PROVIDERS.DGS_CASINO, PROVIDERS.PLATINUM, PROVIDERS.VIG].indexOf(Number(provider.id)) != -1 && (
            <GenericLobby isLoading={isLoading} provider={provider} gameUrl={gameUrl} openCasinoGameAction={openCasinoGameAction} />
          )}
          {provider && [PROVIDERS.DGS_CASINO, PROVIDERS.PLATINUM, PROVIDERS.VIG].indexOf(Number(provider.id)) == -1 && (
            <TpiCasino
              isLoading={isLoading}
              provider={provider}
              games={games}
              getCasinoGamesAction={getCasinoGamesAction}
              gameUrl={gameUrl}
              openCasinoGameAction={openCasinoGameAction}
              useFreePlay={useFreePlay}
              getUserBalanceAction={getUserBalanceAction}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
