import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const SEND_EMAIL_CODE = `SEND_EMAIL_CODE${suffix}`;

export const sendEmailCodeAction = createAction(SEND_EMAIL_CODE);

const SEND_EMAIL_CODE_SUCCESS = `SEND_EMAIL_CODE_SUCCESS${suffix}`;
const sendEmailCodeSuccessAction = createAction<any[]>(SEND_EMAIL_CODE_SUCCESS);

const SEND_EMAIL_CODE_ERROR = `SEND_EMAIL_CODE_ERROR${suffix}`;
const sendEmailCodeErrorAction = createAction<any>(SEND_EMAIL_CODE_ERROR);

function* sendEmailCodeWatcher(): SagaIterator {
  yield takeEvery(SEND_EMAIL_CODE, sendEmailCodeWorker);
}
mergeSaga(sendEmailCodeWatcher);

function* sendEmailCodeWorker(): SagaIterator {
  try {
    const result: any = yield call(sendEmailCodeApi);
    yield put(sendEmailCodeSuccessAction(result.data));
  } catch (e) {
    yield put(sendEmailCodeErrorAction(e));
  }
}

const sendEmailCodeApi = (): Request => {
  return vipApi(`/wager/sendEmailConfirmation`, 'post', {});
};

const reduceHandlers = {
  [SEND_EMAIL_CODE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [SEND_EMAIL_CODE_SUCCESS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [SEND_EMAIL_CODE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
