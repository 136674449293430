import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';
import { getUserInfoAction } from './get-user-info.action';

const suffix = `/${userReducer.sliceName}/app`;

const VERIFY_EMAIL_CODE = `VERIFY_EMAIL_CODE${suffix}`;

export const verifyEmailCodeAction = createAction(VERIFY_EMAIL_CODE);

const VERIFY_EMAIL_CODE_SUCCESS = `VERIFY_EMAIL_CODE_SUCCESS${suffix}`;
const verifyEmailCodeSuccessAction = createAction<any[]>(VERIFY_EMAIL_CODE_SUCCESS);

const VERIFY_EMAIL_CODE_ERROR = `VERIFY_EMAIL_CODE_ERROR${suffix}`;
const verifyEmailCodeErrorAction = createAction<any>(VERIFY_EMAIL_CODE_ERROR);

function* verifyEmailCodeWatcher(): SagaIterator {
  yield takeEvery(VERIFY_EMAIL_CODE, verifyEmailCodeWorker);
}
mergeSaga(verifyEmailCodeWatcher);

function* verifyEmailCodeWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(verifyEmailCodeApi, action.payload);
    yield put(verifyEmailCodeSuccessAction(result.data));
    yield put(getUserInfoAction());
  } catch (e) {
    yield put(verifyEmailCodeErrorAction(e));
  }
}

const verifyEmailCodeApi = (x: any): Request => {
  return vipApi(`/VerifyEmail`, 'post', { code: x.code, username: x.username });
};

const reduceHandlers = {
  [VERIFY_EMAIL_CODE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [VERIFY_EMAIL_CODE_SUCCESS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [VERIFY_EMAIL_CODE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
