import React from 'react';
import { Grid } from '@material-ui/core';
import { DailyBalance } from './daily-balance.component';
import { DgsStyleWagerHistory } from './dgs-style-wager-history.component';

export interface ExternalActionProps {
  getUserHistoryAction: (data: any) => void;
}
export interface ExternalProps {
  userHistory: any;
  isLoading: boolean;
}

export const BetHistoryPage = (props: ExternalProps & ExternalActionProps) => {
  const { userHistory, getUserHistoryAction, isLoading } = props;
  const [weeksAgo, setWeeksAgo] = React.useState(0);
  const [filter, setFilter] = React.useState('all');

  const handleWeekChange = (event: any) => {
    setWeeksAgo(event.target.value);
    getUserHistoryAction({
      weeks: event.target.value,
      filter: filter,
    });
  };

  const handleFilterChange = (event: any) => {
    setFilter(event.target.value);
    getUserHistoryAction({
      weeks: weeksAgo,
      filter: event.target.value,
    });
  };

  return (
    <React.Fragment>
      {!isLoading && userHistory && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DailyBalance
                userHistory={userHistory}
                weeksAgo={weeksAgo}
                handleWeekChange={handleWeekChange}
                handleFilterChange={handleFilterChange}
                filter={filter}
              />
            </Grid>
            <Grid item xs={12}>
              <DgsStyleWagerHistory userHistory={userHistory} />
              {/* <WagerHistoryDetails wagerHistory={userHistory} isLoading={isLoading} wagerType={wagerType} /> */}
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};
