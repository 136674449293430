import { connect } from 'react-redux';
import { getAgentStatsAction } from '../../api/user/actions/get-agent-stats.action';
import { getUserInfoAction } from '../../api/user/actions/get-user-info.action';
import { agentDataSelector, isLoadingSelector, loggedInUserSelector } from '../../api/user/user.selector';
import { AgentDashboardPage, ExternalActionProps, ExternalProps } from './agent-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    agentData: agentDataSelector(state),
    isLoading: isLoadingSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getAgentStatsAction,
  getUserInfoAction,
};

export const AgentDashboardpageContainer = connect(mapStateToProps, mapDispatchToProps)(AgentDashboardPage);
