import { createSelector } from 'reselect';
import { ruleSelectionReducer, RuleSelectionSliceType } from './rule.reducer';

export const ruleSelectionSliceSelector = (state: any): any => state[ruleSelectionReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  ruleSelectionSliceSelector,
  (ruleSelectionSlice: RuleSelectionSliceType) => ruleSelectionSlice.isLoading,
);

export const rulesSelector = createSelector(
  ruleSelectionSliceSelector,
  (ruleSelectionSlice: RuleSelectionSliceType) => ruleSelectionSlice.rules,
);

export const categoriesSelector = createSelector(
  ruleSelectionSliceSelector,
  (ruleSelectionSlice: RuleSelectionSliceType) => ruleSelectionSlice.categories,
);
