import React from 'react';
import { makeStyles, Typography, Grid, Paper } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getHistory } from '../../utils/history.util';
import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: '.5rem',
    marginBottom: '1rem',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#2d2d2d',
    color: '#fff',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1f4d72',
      color: '#fff',
    },
  },
  active: {
    backgroundColor: '#1f4d72',
    color: '#fff',
  },
  icon: {
    fontSize: '42px',
  },
  linkText: {
    fontSize: '18px',
    fontWeight: 600,
  },
}));

interface Linkprops {
  routeName: string;
}

export const AccountLinks = ({ routeName }: Linkprops) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={6} md={2} className='tour_profile-betHistoryTab'>
          <Paper
            className={clsx(classes.paper, { [classes.active]: routeName == 'bet' })}
            onClick={() => getHistory().push('/player-history')}
          >
            <ReceiptIcon className={classes.icon} />
            <Typography className={classes.linkText}>Player History</Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2} className='tour_profile-transactionsTab'>
          <Paper
            className={clsx(classes.paper, { [classes.active]: routeName == 'transactions' })}
            onClick={() => getHistory().push('/transaction-history')}
          >
            <AccountBalanceWalletIcon className={classes.icon} />
            <Typography className={classes.linkText}>Transactions</Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2} className='tour_profile-bonusHistoryTab'>
          <Paper
            className={clsx(classes.paper, { [classes.active]: routeName == 'bonus' })}
            onClick={() => getHistory().push('/bonus-history')}
          >
            <HistoryIcon className={classes.icon} />
            <Typography className={classes.linkText}>Bonus History</Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2} className='tour_profile-agentTab' zeroMinWidth>
          <Paper className={clsx(classes.paper, { [classes.active]: routeName == '/agent' })} onClick={() => getHistory().push('/agent')}>
            <SupervisorAccountIcon className={classes.icon} />
            <Typography className={classes.linkText}>Agent Dashboard</Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2} className='tour_profile-rulesTab'>
          <Paper className={clsx(classes.paper, { [classes.active]: routeName == 'rules' })} onClick={() => getHistory().push('/rules')}>
            <HelpOutlineIcon className={classes.icon} />
            <Typography className={classes.linkText}>Rules</Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2} className='tour_profile-accountTab'>
          <Paper
            className={clsx(classes.paper, { [classes.active]: routeName == 'profile' })}
            onClick={() => getHistory().push('/profile')}
          >
            <SettingsIcon className={classes.icon} />
            <Typography className={classes.linkText}>Account</Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
