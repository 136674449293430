import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const CHECK_CBIO_AMOUNT = `CHECK_CBIO_AMOUNT${suffix}`;

export const checkCbioAmountAction = createAction<any>(CHECK_CBIO_AMOUNT);

const CHECK_CBIO_AMOUNT_SUCCESS = `CHECK_CBIO_AMOUNT_SUCCESS${suffix}`;
const checkCbioAmountSuccessAction = createAction<any[]>(CHECK_CBIO_AMOUNT_SUCCESS);

const CHECK_CBIO_AMOUNT_ERROR = `CHECK_CBIO_AMOUNT_ERROR${suffix}`;
const checkCbioAmountErrorAction = createAction<string>(CHECK_CBIO_AMOUNT_ERROR);

function* checkCbioAmountWatcher(): SagaIterator {
  yield takeEvery(CHECK_CBIO_AMOUNT, checkCbioAmountWorker);
}
mergeSaga(checkCbioAmountWatcher);

function* checkCbioAmountWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(checkCbioAmountApi, action.payload);
    yield put(checkCbioAmountSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(checkCbioAmountErrorAction(e));
  }
}

const checkCbioAmountApi = (x: any): Request => {
  return vipApi(`/pcashier/checkCBIO`, 'post', {
    amount: x,
  });
};

const reduceHandlers = {
  [CHECK_CBIO_AMOUNT]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CHECK_CBIO_AMOUNT_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    cbioAllowed: action.payload.message,
  }),
  [CHECK_CBIO_AMOUNT_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    cbioAllowed: null,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
