import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid, Container } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Loading } from '../../shared/loading/loading.component';
const useStyles: any = makeStyles({
  root: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
  },
  descriptionDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export interface ExternalActionProps {
  getPendingBetsAction: () => void;
}

export interface ExternalProps {
  pendingBets: any;
  isLoading: boolean;
}

export const PendingBetsPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getPendingBetsAction, isLoading, pendingBets } = props;

  const getSort = (data: any) => {
    const sortedData = data.sort((a: any, b: any) => {
      return parseInt(b.TicketNumber) - parseInt(a.TicketNumber);
    });
    return sortedData;
  };

  useEffect(() => {
    getPendingBetsAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <div style={{ backgroundColor: '#000', paddingTop: '20px', paddingBottom: '40px' }}>
        <Container>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}>
              <Typography variant='h4' color='primary' align='center'>
                Pending Bets
              </Typography>
              <TableContainer component={Paper} style={{ marginTop: '15px' }}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#888' }}>
                      <TableCell align='center'>Ticket#</TableCell>
                      <TableCell>Bet Placed At</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Risk/Win</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingBets.data &&
                      getSort(pendingBets.data).map((item: any, i: number) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align='center'>{item.TicketNumber}</TableCell>
                            <TableCell>
                              {item.PlacedDate}&nbsp;{item.PlacedTime}
                            </TableCell>
                            <TableCell>
                              <strong>{item.HeaderDesc}</strong>
                              {Array.isArray(item.detail) ? (
                                item.detail.map((detail: any, d: number) => {
                                  return (
                                    <React.Fragment key={d}>
                                      <div className={classes.descriptionDiv}>
                                        <span dangerouslySetInnerHTML={{ __html: detail.DetailDesc }}></span>
                                        <strong>
                                          {detail.GameDate}&nbsp;{detail.GameTime}
                                        </strong>
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <>
                                  <div className={classes.descriptionDiv}>
                                    <span dangerouslySetInnerHTML={{ __html: item.detail.DetailDesc }}></span>
                                    <strong>
                                      {item.detail.GameDate}&nbsp;{item.detail.GameTime}
                                    </strong>
                                  </div>
                                </>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.RiskAmount}/{item.WinAmount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
