import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralGuides } from './components/general-guides';
import SportsGuides from './components/sports-guides';
import { getHistory } from '../../utils/history.util';
import { Loading } from '../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    padding: '1rem',
    backgroundColor: '#121212',
  },
  container: {
    maxWidth: '1200px',
    display: 'block',
    margin: '0px auto',
  },
  guidesHeader: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  ul: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  li: {
    margin: '.5rem',
    padding: 0,
    fontSize: '.9rem',
    color: '#707070',
    cursor: 'pointer',
  },
  liSelected: {
    margin: '.5rem',
    padding: 0,
    fontSize: '.9rem',
    color: 'white',
    cursor: 'pointer',
  },
  hAd: {
    width: '100%',
    height: '100px',
  },
  vAd: {
    width: '100%',
    height: '500px',
  },
  img: {
    borderRadius: '5px',
    width: '100%',
  },
});

export interface ExternalActionProps {
  getGuideAction: () => void;
  getCategoryAction: () => void;
}

export interface ExternalProps {
  guides: any;
  isLoading: boolean;
  categories: any;
}

export const GuidesPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getGuideAction, guides, isLoading, categories, getCategoryAction } = props;
  const [selectedGuides, setSelectedGuides] = useState(0);
  const [showGuides, setShowGuides] = useState([]);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isGeneral, setIsGeneral] = useState(false);
  const [showCategory, setShowCategory] = useState<any[]>([]);

  useEffect(() => {
    getCategoryAction();
    getGuideAction();
  }, []);

  useEffect(() => {
    if (Array.isArray(guides) && Array.isArray(categories) && categories.length > 0) {
      const cats: any[] = [];
      categories.map((item: any) => {
        const y = guides.filter((x: any) => x.category == item.id);
        cats.push({ ...item, length: y.length });
      });
      setShowCategory(cats);
      setSelectedGuides(categories[0].id);
    }
  }, [categories, guides]);

  useEffect(() => {
    if (Array.isArray(guides) && guides.length > 0) {
      const newGuides: any = guides.filter((item: any) => {
        return item.category == selectedGuides;
      });
      if (newGuides.length > 1) {
        setIsGeneral(false);
      } else {
        setIsGeneral(true);
      }
      setShowGuides(newGuides);
    }
  }, [selectedGuides, guides]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;
  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <div className={clsx(classes.container)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2}>
            <Typography variant='h6' color='primary' className={clsx(classes.guidesHeader)}>
              Guides
            </Typography>
            <ul className={clsx(classes.ul)}>
              {Array.isArray(showCategory) &&
                showCategory.map((item: any) => {
                  return (
                    item.length > 0 && (
                      <li
                        key={item.id}
                        className={selectedGuides == item.id ? clsx(classes.liSelected) : clsx(classes.li)}
                        onClick={() => setSelectedGuides(item.id)}
                      >
                        <FontAwesomeIcon icon={['fad', 'crown']} /> {item.name}
                      </li>
                    )
                  );
                })}
            </ul>
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              <img
                src={`${process.env.PUBLIC_URL}/images/market/hzadimg.png`}
                className={clsx(classes.img)}
                onClick={() => {
                  getHistory().push('/refer-a-friend');
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={8}>
            <Paper>
              {Array.isArray(categories) &&
                categories.map((item: any) => {
                  return (
                    selectedGuides == item.id &&
                    (isGeneral ? (
                      <GeneralGuides guides={showGuides} category={item} />
                    ) : (
                      <SportsGuides guides={showGuides} category={item} />
                    ))
                  );
                })}
            </Paper>
          </Grid>

          {!isMobile && (
            <Grid item xs={12} sm={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/market/verticalrafad.png`}
                className={clsx(classes.img)}
                onClick={() => {
                  getHistory().push('/refer-a-friend');
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
