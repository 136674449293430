import { toastr } from 'react-redux-toastr';

export function showReason(reason: any): void {
  showError(reason);
}

export function showError(error: any): void {
  let logTitle = 'Error';
  let msgObj = error;
  if (error.response && error.response.data) {
    msgObj = error.response.data;
    logTitle = error.toString();
  }
  const msg = msgObj.message ? msgObj.message : msgObj.toString();
  // eslint-disable-next-line no-console
  console.error(logTitle, '-', msg);
}

export function showErrors(promise: any): Promise<any> {
  return promise.catch((reason: any) => {
    showError(reason);
    // Return new rejected promise
    return Promise.reject(reason);
  });
}

export function showToast(error: any, type: any): any {
  const message = error && error.message ? error.message : error.toString();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore we have a types mismatch between the JS version of react-redux-toastr@7.4.1 and @types/react-redux-toastr@7.4.0
  // this prevents focus from being taken away from inputs when the toast message appears

  if (type == 'error') {
    toastr.error(message, '', { timeOut: 10000 });
  } else if (type == 'success') {
    toastr.success('Success', message, { timeOut: 10000 });
  } else if (type == 'warning') {
    toastr.warning('Warning', message, { timeOut: 10000 });
  } else if (type == 'info') {
    toastr.info('info', message, { timeOut: 1000 });
  }
}
