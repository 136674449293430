import React from 'react';
import { makeStyles, Paper, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    marginTop: '40px',
  },
  paper: {
    padding: '20px 20px 40px 20px',
    backgroundColor: '#121212',
  },
});

export interface ExternalProps {
  bonusHistory: any;
  setWagerData: (a: any) => void;
  setContData: (b: any) => void;
  setWagerTableOpen: (c: boolean) => void;
  setContTableOpen: (d: boolean) => void;
}

export const BonusDetail = (props: ExternalProps) => {
  const classes = useStyles();
  const { bonusHistory, setWagerData, setContData, setWagerTableOpen, setContTableOpen } = props;

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={5} className='tour_bonusHistory-details'>
          <Grid item xs={12} md={6}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Rollover ID :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  #{bonusHistory.id}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Deposit Amount :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.deposit_amount}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Bonus Amount :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.bonus_amount}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Rollover Amount :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.rollover_amount}
                </Typography>
              </Grid>
              {bonusHistory.previous_rollover != null && (
                <>
                  <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.tableTitle}>
                      Previous Rollover :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.tableContent}>
                      ${bonusHistory.previous_rollover}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Starting Rollover :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.rollover_data ? bonusHistory.rollover_data.starting_rollover : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Free Play Winings :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.rollover_data ? bonusHistory.rollover_data.free_play_winnings : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Amount Wagered :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.rollover_data ? bonusHistory.rollover_data.amount_wagered : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Remaining Rollover :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  ${bonusHistory.rollover_data ? bonusHistory.rollover_data.remaining_rollover : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableTitle}>
                  Start Date :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2' className={classes.tableContent}>
                  {bonusHistory.start_date}
                </Typography>
              </Grid>
              {bonusHistory.active == 0 && (
                <>
                  <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.tableTitle}>
                      End :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle2' className={classes.tableContent}>
                      {bonusHistory.end_reason}
                      <br />
                      {bonusHistory.end_date}
                    </Typography>
                  </Grid>
                </>
              )}

              <Button
                onClick={() => {
                  setWagerData(bonusHistory.wager_data);
                  setWagerTableOpen(true);
                }}
                className='tour_bonusHistory-wagers'
                variant='contained'
                color='primary'
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                Wagers
              </Button>

              <Button
                onClick={() => {
                  setContData(bonusHistory.contribution_table);
                  setContTableOpen(true);
                }}
                className='tour_bonusHistory-contribution'
                variant='contained'
                color='default'
              >
                Contribution Table
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
