import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const PREVIEW_BET = `PREVIEW_BET${suffix}`;

export const previewBetAction = createAction<any>(PREVIEW_BET);

const PREVIEW_BET_SUCCESS = `PREVIEW_BET_SUCCESS${suffix}`;
const previewBetSuccessAction = createAction<any[]>(PREVIEW_BET_SUCCESS);

const PREVIEW_BET_ERROR = `PREVIEW_BET_ERROR${suffix}`;
const previewBetErrorAction = createAction<string>(PREVIEW_BET_ERROR);

function* previewBetWatcher(): SagaIterator {
  yield takeEvery(PREVIEW_BET, previewBetWorker);
}
mergeSaga(previewBetWatcher);

function* previewBetWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(previewBetApi, action.payload);
    if (result && result.data && result.data.wager) {
      if (!Array.isArray(result.data.wager)) result.data.wager = [result.data.wager];
      for (let index = 0; index < result.data.wager.length; index++) {
        const element = result.data.wager[index];
        if (!Array.isArray(element.detail)) element.detail = [element.detail];
      }
    }
    yield put(previewBetSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(previewBetErrorAction(e));
  }
}

const previewBetApi = (data: any): Request => {
  return vipApi(`/wager/betslip/previewBet`, 'post', data);
};

const reduceHandlers = {
  [PREVIEW_BET]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [PREVIEW_BET_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    previewBetResponses: action.payload,
  }),
  [PREVIEW_BET_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
