import { connect } from 'react-redux';
import { resetPasswordAction } from '../../../api/user/actions/reset-password-action';
import { ResetPasswordPage, ExternalProps, ExternalActionProps } from './reset-password-page.component';
import { resetPasswordSuccessSelector, isLoadingSelector } from '../../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    resetPasswordSuccess: resetPasswordSuccessSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  resetPasswordAction,
};

export const ResetPasswordPageContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
