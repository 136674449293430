import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { Loading } from '../../shared/loading/loading.component';
import { PROVIDERS } from '../../enum/providers/providers';
import { isAndroid, isIOS, isWinPhone } from 'react-device-detect';
const useStyles: any = makeStyles({
  root: {
    width: '100%',
    border: 'none',
    height: '85%',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute',
  },
});
export interface ExternalProps {
  isLoading: any;
  gameUrl: any;
  path: string;
}

export interface ExternalActionProps {
  openCasinoGameAction: (x: any) => void;
}

export const LobbyProvider = (props: ExternalActionProps & ExternalProps) => {
  const { gameUrl, openCasinoGameAction, isLoading, path } = props;
  const classes = useStyles();
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;
  let device_id = 1;

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        device_id = 3;
      }
      if (isIOS) {
        device_id = 2;
      }
      if (isWinPhone) {
        device_id = 4;
      }
    }

    let provider = 0;
    switch (path) {
      case '/props-builder':
        provider = PROVIDERS.PROPS_BUILDER;
        break;
      case '/racebook':
        provider = PROVIDERS.RACEBOOK;
        break;
      case '/live-dealer':
        device_id = 1;
        provider = PROVIDERS.VIG;
        break;
      default:
        break;
    }
    openCasinoGameAction({
      device_id: device_id,
      site_id: 2,
      provider_id: provider,
      game_id: 0,
      is_freeplay: 0,
    });
  }, [path]);

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      {!isLoading && (!isMobile || device_id == 1) && <iframe className={clsx(classes.root)} src={gameUrl} height='100%'></iframe>}
    </>
  );
};
