import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { showToast } from '../../../utils/toast/show-errors';
// import { CashierApiResponse } from '../../../models/cashier-response';

const suffix = `/${userReducer.sliceName}/app`;

const USER_INFO = `USER_INFO${suffix}`;
export const userInfoAction = createAction(USER_INFO);

const USER_INFO_ERROR = `USER_INFO_ERROR${suffix}`;
const userInfoErrorAction = createAction<string>(USER_INFO_ERROR);
const USER_INFO_SUCCESS = `USER_INFO_SUCCESS${suffix}`;
const userInfoSuccessAction = createAction<any>(USER_INFO_SUCCESS);

function* userInfoWatcher(): SagaIterator {
  yield takeEvery(USER_INFO, userInfoWorker);
}
mergeSaga(userInfoWatcher);

function* userInfoWorker(): SagaIterator {
  try {
    const response: any = yield call(getUserInfo);
    const { data } = response;
    if (data) {
      setUserSession(data);
      yield put(userInfoSuccessAction(response.data));
    } else {
      yield put(userInfoErrorAction('Something went wrong.'));
    }
  } catch (e) {
    yield call(showToast, 'Something went wrong.', 'error');
    yield put(userInfoErrorAction('Something went wrong.'));
  }
}

const getUserInfo = (): Request => {
  return vipApi(`/userInfo`, 'post', {});
};

const setUserSession = (user: any) => {
  try {
    if (user) localStorage.setItem('user', JSON.stringify(user));
    return true;
  } catch (e) {
    return false;
  }
};

const reduceHandlers = {
  [USER_INFO]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
  }),
  [USER_INFO_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      loggedInUser: action.payload,
    };
  },
  [USER_INFO_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
