import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function RAFRules() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='contained' size='large' color='secondary' onClick={handleClickOpen} className='tour_raf-rules'>
        R-A-F Rules
      </Button>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{'RAF Rules'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              <ul>
                <li>The Refer-A-Friend Program is only valid for clients over 18 years of age who have an active VIP Club account.</li>
                <li>The referring party must have a funded VIP Club account.</li>
                <li>
                  Maximum referral bonus awarded is $250 per unique referral. There is no cap limit to the number of unique referrals.
                </li>
                <li>
                  The referring party must be actively playing in the last 14 days in your VIP Club account prior to receiving a Referral
                  Bonus.
                </li>
                <li>The 100% referral bonus has a four times (4x) wagering rollover requirement (Unverified Players).</li>
                <li>The 200% referral bonus has a six times (6x) wagering rollover requirement. (Verified Players)</li>
                <li>
                  The referrer can’t withdraw any bonus or restricted balance until rollover requirements have been completed, the referral
                  bonus has been canceled, or the referral bonus has expired. The referring party can cancel the referral bonus by
                  contacting customer service at customersupport@vipclub.lv. Post referral bonus cancelation, all bonus money and money
                  earned via betting the bonus money will be forfeited.
                </li>
                <li>The referral bonus will expire after 14 days of accepting the bonus offer.</li>
                <li>
                  The referring party will not qualify for the referral bonus if the referral resides in the same household, is a family
                  member, uses the same payment account or uses a shared computer/internet connection.
                </li>
                <li>
                  VIP Club will use best efforts to determine if the referring and referred accounts are eligible. Management decisions to
                  determine eligibility will be final. VIP Club reserves the right to withdraw the availability of the offer at any time and
                  at our sole and absolute discretion. Any attempt to manipulate or circumvent the Refer-A-Friend Program will result in
                  disqualification from the program.
                </li>
                <li>
                  VIP Club Refer-A-Friend Program requirements are in place to ensure there is no wrongful misuse of this bonus promotion.
                </li>
              </ul>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color='primary'>
            Disagree
          </Button> */}
          <Button onClick={handleClose} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
