import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
});

export interface ExternalProps {
  dayData: any;
}

export const MobileWagerDetail = (props: ExternalProps) => {
  const classes = useStyles();
  const { dayData } = props;

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={0}>
        {dayData &&
          dayData.map((day: any, index: any) => {
            return (
              <Box key={index} style={{ marginBottom: '.5rem', marginTop: '.5rem', width: '100%' }}>
                <Box style={{ padding: '.5rem', display: 'inline-flex', width: '100%' }} bgcolor='secondary.main'>
                  <span style={{ fontWeight: 'bold' }}>[{day.TicketNumber == 0 ? 'TRANS' : 'Ticket Number #' + day.TicketNumber}]</span>
                  <div dangerouslySetInnerHTML={{ __html: day.HeaderDesc }} style={{ marginLeft: '.25rem' }}></div>
                </Box>
                <Box style={{ borderTop: '1px solid #121212', backgroundColor: '#2d2d2d', padding: '.5rem' }}>
                  <Grid container spacing={1}>
                    {day.TicketNumber != 0 &&
                      day.detail.map((detail: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <Grid item xs={1}>
                              <Typography variant='body2'>{detail.IdSport}</Typography>
                            </Grid>
                            <Grid item xs={11}>
                              <Typography variant='body2'>{detail.DetailDesc}</Typography>
                              <Typography variant='caption'>
                                {detail.GameDate} @ {detail.GameTime}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                  </Grid>
                  <hr />
                  <Grid container>
                    <Grid item xs={4}>
                      Risk/Win:{' '}
                    </Grid>
                    <Grid item xs={8}>
                      {' '}
                      {day.TicketNumber == 0 ? (
                        '-/-'
                      ) : (
                        <>
                          {'$' + day.RiskAmount}/{'$' + day.WinAmount}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      Result:
                    </Grid>
                    <Grid item xs={8}>
                      {day.Result == '' ? (
                        '-'
                      ) : (
                        <>
                          {day.Result == 'WIN' ? (
                            <Typography style={{ color: 'green' }}>{day.Result}</Typography>
                          ) : (
                            <Typography style={{ color: 'red' }}>{day.Result}</Typography>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      Balance:
                    </Grid>
                    <Grid item xs={8}>
                      {'$' + day.Balance}
                    </Grid>
                  </Grid>
                  <hr />

                  <Grid item xs={12}>
                    Placed: {day.PlacedDate} {day.PlacedTime}
                  </Grid>
                </Box>
              </Box>
            );
          })}
      </Grid>
    </div>
  );
};
