import React from 'react';
import NumberFormat from 'react-number-format';
import { Button, Card, Container, Grid, FormLabel, TextField, Typography, Chip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { getHistory } from '../../../utils/history.util';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 15,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  card: {
    padding: 20,
    textAlign: 'center',
  },
  input: {
    paddingBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
  },
}));

export interface ExternalProps {
  bonus: any;
  type: any;
  method: any;
  account: any;
  amount: any;
  user: any;
}

export interface ExternalActionProps {
  setTransactionAccountAction: (action: any) => void;
  setTransactionAmountAction: (amount: any) => void;
  setTransactionBonusAction: (bonus: any) => void;
}

export const TransactionInfoPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    bonus,
    type,
    method,
    account,
    amount,
    user,
    setTransactionAccountAction,
    setTransactionAmountAction,
    setTransactionBonusAction,
  } = props;
  const classes = useStyles();

  const isCashApp = (methodName: any) => {
    return methodName == 'Cash App';
  };

  const claimedBonus = (bonus: any) => {
    return bonus != null;
  };

  useEffect(() => {
    if (type == null) {
      getHistory().push('/cashier');
    }
  }, []);

  const getMinMax = (transactionType: any, bonus: any, method: any) => {
    if (bonus != null) {
      return {
        min: bonus.min_deposit,
        max: bonus.max_deposit,
      };
    }
    if (transactionType == 1) {
      return {
        min: method.deposit_min,
        max: method.deposit_max,
      };
    }
    return {
      min: method.withdrawal_min,
      max: method.withdrawal_max,
    };
  };

  const checkInfo = (transactionType: any, method: any, amount: any, account: any, min: any, max: any) => {
    min = parseFloat(min);
    amount = parseFloat(amount);
    max = parseFloat(max);

    if ((transactionType == 2 || method.name == 'Cash App' || method.name == 'Apple Pay') && account == '') {
      if (method.name == 'Cash App') {
        return alert('Please enter your cash app tag.');
      } else if (method.name == 'Apple Pay') {
        return alert('Please enter your apple pay phone or email.');
      } else {
        return alert('Please enter a Bitcoin address.');
      }
    } else if (amount == '' || amount == '0') {
      return alert('Please enter an amount. Must be greater than $0.');
    } else if (amount < min || amount > max) {
      return alert('Please enter an amount between $' + min + ' and $' + max + '.');
    } else {
      getHistory().push('/cashier/confirm');
    }
  };

  const getMethodAccountLabel = (method: any) => {
    switch (method.name) {
      case 'Bitcoin':
        return 'Bitcoin address';
        break;
      case 'Cash App':
        return 'Cash Tag';
        break;
      case 'Apple Pay':
        return 'Apple Pay Phone or Email';
        break;
      case 'Zelle':
        return 'Zelle Phone or Email';
        break;
    }
  };

  const getMethodHelpText = (method: any, transactionType: any) => {
    switch (method.name) {
      case 'Bitcoin':
        return (
          <small>
            Please enter <b>your Bitcoin Address</b> you will be receiving from.
          </small>
        );
        break;
      case 'Cash App':
        return (
          <small>
            Please enter <b>your cash app tag</b> you will be {transactionType == 2 ? 'receiving' : 'sending'} from.
          </small>
        );
        break;
      case 'Apple Pay':
        return (
          <small>
            Please enter <b>Apple Pay Phone or Email</b> you will be sending from.
          </small>
        );
        break;
    }
  };

  const getMethodAccountPlaceholder = (method: any) => {
    switch (method.name) {
      case 'Bitcoin':
        return 'Bitcoin Address';
        break;
      case 'Cash App':
        return '$CashTag';
        break;
      case 'Apple Pay':
        return 'Apple Pay Phone or Email';
        break;
      case 'Zelle':
        return 'Zelle Phone or Email';
        break;
    }
  };

  const selectCustomAmount = (amount: any) => {
    setTransactionAmountAction(amount);
  };

  return (
    <React.Fragment>
      {method == null && getHistory().push('/cashier')}

      <Container className={classes.container} maxWidth='sm'>
        <FormLabel component='legend'>
          <Typography variant='h6' classes={{ root: classes.title }}>
            Enter {type == 1 ? 'Deposit' : 'Withdrawal'} Info
          </Typography>
        </FormLabel>
        <hr />
        {type == 1 && method.name == 'Bitcoin' && (
          <Alert severity='warning'>
            <Typography variant='body2'>
              When sending Bitcoin, please makes sure you select the default/standard fee that your wallet selects. <br />
              <br />
              <b>Sending Bitcoin at a lower processing fee will delay your deposit.</b>
            </Typography>
          </Alert>
        )}

        {method.name == '(Instant) Pay' && (
          <Alert severity='warning'>
            <Typography variant='body2'>
              This eCheck will be under your name{' '}
              <b>
                <u>
                  {user.first_name} {user.last_name}
                </u>
              </b>{' '}
              and be sent to{' '}
              <b>
                <u>{user.email}</u>
              </b>
              . <br />
              <br />
              The debit card you use with the Instant Pay check{' '}
              <b>
                <u>MUST</u>
              </b>{' '}
              match the name above.
              <br />
              <br />
              <b>If this information is incorrect, please update your profile..</b>
              <br />
              <Button
                variant='contained'
                onClick={() => {
                  getHistory().push('/profile');
                }}
                style={{ display: 'block', margin: '10px auto' }}
              >
                Update Profile
              </Button>
            </Typography>
          </Alert>
        )}

        <br />
        <Grid item xs={12}>
          <Card classes={{ root: classes.card }}>
            <Alert severity='error' style={{ marginBottom: '1rem' }}>
              <Typography variant='body2'>
                Any transactions with{' '}
                <b>
                  <u>NOTES OR USERNAMES RELATED TO GAMBLING OR BETTING</u>
                </b>{' '}
                will automatically be returned, and you will be blocked from the platform.
              </Typography>
            </Alert>

            {/* Amount Preset Section */}
            <div className='tour_deposit-customAmount'>
              <Typography variant='subtitle1' gutterBottom>
                Select an amount or enter a custom amount below.
              </Typography>
              <Grid container>
                <Grid item xs={4}>
                  <Chip color='secondary' label='$100' onClick={() => selectCustomAmount(100)} clickable />
                </Grid>
                <Grid item xs={4}>
                  <Chip color='secondary' label='$250' onClick={() => selectCustomAmount(250)} clickable />
                </Grid>
                <Grid item xs={4}>
                  <Chip color='secondary' label='$500' onClick={() => selectCustomAmount(500)} clickable />
                </Grid>
              </Grid>
            </div>
            <br />
            <br />

            <div className='tour_deposit-amount'>
              <NumberFormat
                label='Amount ($USD)'
                value={amount == 0 ? null : amount}
                customInput={TextField}
                prefix={'$'}
                thousandSeparator=','
                onValueChange={(values: any) => setTransactionAmountAction(values.value == null ? 0 : values.value)}
                classes={{ root: classes.input }}
                color='primary'
                fullWidth
              />
            </div>
            <small>
              {claimedBonus(bonus) && (
                <b style={{ color: 'green' }}>
                  Claimed [{bonus.code}]{' '}
                  {bonus.description == 'NO rollover, risk free bet.' ? (
                    'Risk-Free Bet'
                  ) : (
                    <>
                      {bonus.match_multiplier * 100}% {bonus.type} Bonus
                    </>
                  )}
                </b>
              )}
              <br />
              Fee: {method.fee !== null && method.fee}% | {type == 1 ? 'Deposit' : 'Withdrawal'} Min: $
              {Intl.NumberFormat().format(getMinMax(type, bonus, method).min)} |{type == 1 ? 'Deposit' : 'Withdrawal'} Max: $
              {Intl.NumberFormat().format(getMinMax(type, bonus, method).max)}
            </small>
            <br />
            <br />

            {(type == 2 || isCashApp(method.name) || method.name == 'Apple Pay' || method.name == 'Zelle') &&
              method.name != '(Instant) Pay' && (
                <div className='tour_deposit-secondField'>
                  <TextField
                    label={getMethodAccountLabel(method.name)}
                    onChange={e => setTransactionAccountAction(e.target.value)}
                    value={account}
                    placeholder={getMethodAccountPlaceholder(method)}
                    classes={{ root: classes.input }}
                    color='primary'
                    fullWidth
                  />
                  {getMethodHelpText(method, type)}
                </div>
              )}
          </Card>
        </Grid>

        <div className={classes.buttons}>
          <Button
            variant='outlined'
            onClick={() => {
              setTransactionBonusAction(null);
              getHistory().goBack();
            }}
          >
            <Typography>Back</Typography>
          </Button>
          <Button
            variant='contained'
            color='primary'
            className='tour_deposit-continue'
            onClick={() => {
              checkInfo(type, method, amount, account, getMinMax(type, bonus, method).min, getMinMax(type, bonus, method).max);
            }}
          >
            <Typography>Continue</Typography>
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};
