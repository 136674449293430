import { connect } from 'react-redux';
import { BonusHistoryPage, ExternalProps, ExternalActionProps } from './bonus-history.component';
import { getBonusHistoryAction } from './../../api/user/actions/get-bonus-history.action';
import { bonusHistorySelector, isLoadingSelector, userBalanceSelector, loggedInUserSelector } from './../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    bonusHistory: bonusHistorySelector(state),
    isLoading: isLoadingSelector(state),
    userBalance: userBalanceSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getBonusHistoryAction,
};

export const BonusHistoryPageContainer = connect(mapStateToProps, mapDispatchToProps)(BonusHistoryPage);
