/**
 * @export
 *  DGS Wager Types
 */
export enum WAGER_TYPE {
  STRAIGHT = 0,
  PARLAY = 1,
  TEASER = 2,
  IFWIN = 3,
  REVERSEWIN = 4,
  ROUNDROBIN = 5,
  ACTIONPOINTS = 6,
  IFWINORTIE = 7,
  ACTIONREVERSE = 8,
  BLOCK = 9,
  IFLOSE = 10,
  IFLOSEORTIE = 11,
  RACINGBET = 12,
  CARDBET = 14,
  COMPACTROUNDROBIN = 15,
  KEYPAYLEY = 16,
  COMPACTIFWIN = 17,
  COMPACTIFWINORTIE = 18,
  COMPACTIFLOSE = 19,
  COMPACTIFLOSEORTIE = 20,
  COMPACTREVERSEWIN = 21,
  COMPACTREVERSEACTION = 22,
}

/**
 * To get wagertype description by the enumerable.
 * Possible future change. Multilanguage.
 *
 * @param {WAGER_TYPE} pWagerType
 * @public
 */
export const GetWagerTypeDescription = (pWagerType: WAGER_TYPE) => {
  switch (pWagerType) {
    case WAGER_TYPE.STRAIGHT:
      return 'STRAIGHT';
    case WAGER_TYPE.PARLAY:
      return 'PARLAY';
    case WAGER_TYPE.TEASER:
      return 'TEASER';
    case WAGER_TYPE.ROUNDROBIN:
      return 'ROUNDROBIN';
    case WAGER_TYPE.IFWIN:
      return 'IFWINONLY';
    default:
      return 'ERROR';
  }
};
