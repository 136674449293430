import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${userReducer.sliceName}/app`;

const RESET_PASSWORD = `RESET_PASSWORD${suffix}`;
export const resetPasswordAction = createAction<string>(RESET_PASSWORD);

const RESET_PASSWORD_ERROR = `RESET_PASSWORD_ERROR${suffix}`;
const resetPasswordErrorAction = createAction<string>(RESET_PASSWORD_ERROR);
const RESET_PASSWORD_SUCCESS = `RESET_PASSWORD_SUCCESS${suffix}`;
const resetPasswordSuccessAction = createAction<string>(RESET_PASSWORD_SUCCESS);

function* resetPasswordWatcher(): SagaIterator {
  yield takeEvery(RESET_PASSWORD, resetPasswordWorker);
}
mergeSaga(resetPasswordWatcher);

function* resetPasswordWorker(action: any): SagaIterator {
  try {
    yield call(getApiPlayer, action.payload);
    yield call(showToast, 'Password reset. You may now login with your new password!', 'success');
    yield put(resetPasswordSuccessAction(action.payload.username));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(resetPasswordErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (data: any): Request => {
  return vipApi(`/ResetPassword`, 'post', data);
};

const reduceHandlers = {
  [RESET_PASSWORD]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    resetPasswordSuccess: false,
  }),
  [RESET_PASSWORD_SUCCESS]: (slice: UserSliceType): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      resetPasswordSuccess: true,
    };
  },
  [RESET_PASSWORD_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    error: action.payload,
    resetPasswordSuccess: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
