import { createMuiTheme } from '@material-ui/core/styles';

// const navigationbuttoncolors = "#999999";
// const navigationbackground = "#222222";
// const green: "#86a645";
// const profilebuttononnavcolor = "#5e5e5e";
// const dropdowns: "#ebebeb";
// const reportingpanel: "#d7d7d7";
// const reportingtablerowone: "#e1e1e1";
// const row2: "#ebebeb";
// const dropdownarrows: "#808080";
// const fontdark: "#222222";
// const fontlight: "#808080";
// const lightershadeofgradient: "#caf881";
// const darkershade: "#819f44";

export const getTheme = (prefersDarkMode: boolean) => {
  return createMuiTheme({
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: '#c8a964', // 212121 - 1a1a1a
      },
      secondary: {
        main: '#1f4d72',
      },
      background: {
        paper: '#2d2d2d',
      },
    },
  });
};

// the blue in cashier button
// #1f4d72

// the darker grey
// #1a1a1a

// the lighter grey
// #2d2d2d

// Icon Colors
// #707070

// TODO:: JACK
// this thing is beautiful
// https://bareynol.github.io/mui-theme-creator/

// export default getTheme();
