import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 24,
      width: '100%',
      '& table': {
        width: '100%',
        marginBottom: '1rem',
        color: '#fff',
        border: '1px solid #dee2e6',
      },
      '& table td, & table th': {
        padding: '.75rem',
        verticalAlign: 'top',
        borderTop: '1px solid #dee2e6',
        border: '1px solid #dee2e6',
      },
      '& table thead th': {
        verticalAlign: 'bottom',
        borderBottom: '2px solid #dee2e6',
        borderBottomWidth: '2px',
      },
      '& table tbody+tbody': {
        borderTop: '2px solid #dee2e6',
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    accordainSummery: {
      backgroundColor: '#1a1a1a',
    },
    accordainDetail: {
      backgroundColor: '#3a3a3a',
    },
    spanText: {
      fontSize: '12px',
      color: '#c8a964',
    },
    expandIcon: {
      backgroundColor: '#1f4d72',
      padding: '6px',
      borderRadius: '50%',
    },
  }),
);

export function SportsRules(props: { rules: any; category: any }) {
  const { rules, category } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {category && (
        <Typography variant='h5' gutterBottom>
          {category.name}
        </Typography>
      )}
      <hr />
      {rules &&
        rules.map((item: any, i: number) => {
          return (
            <Accordion key={item.id} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
              <AccordionSummary
                className={classes.accordainSummery}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
              >
                <Typography className={classes.heading}>
                  <FontAwesomeIcon icon={['fad', 'crown']} style={{ color: '#c8a964' }} />
                  &nbsp;{item.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordainDetail}>
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}
