import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_PLAYER_ROLLOVER = `GET_PLAYER_ROLLOVER${suffix}`;

export const getPlayerRolloverAction = createAction<any>(GET_PLAYER_ROLLOVER);

const GET_PLAYER_ROLLOVER_SUCCESS = `GET_PLAYER_ROLLOVER_SUCCESS${suffix}`;
const getPlayerRolloverSuccessAction = createAction<any[]>(GET_PLAYER_ROLLOVER_SUCCESS);

const GET_PLAYER_ROLLOVER_ERROR = `GET_PLAYER_ROLLOVER_ERROR${suffix}`;
const getPlayerRolloverErrorAction = createAction<string>(GET_PLAYER_ROLLOVER_ERROR);

function* getPlayerRolloverWatcher(): SagaIterator {
  yield takeEvery(GET_PLAYER_ROLLOVER, getPlayerRolloverWorker);
}
mergeSaga(getPlayerRolloverWatcher);

function* getPlayerRolloverWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getPlayerRolloverApi, action.payload);
    yield put(getPlayerRolloverSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPlayerRolloverErrorAction(e));
  }
}

const getPlayerRolloverApi = (playerNumber: any): Request => {
  return vipApi(`/pcashier/rollovers/${playerNumber}`, 'get', {});
};

const reduceHandlers = {
  [GET_PLAYER_ROLLOVER]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PLAYER_ROLLOVER_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    rollover: action.payload.data,
  }),
  [GET_PLAYER_ROLLOVER_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
