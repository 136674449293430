import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_BANK_REFERENCE_NULL = `SET_BANK_REFERENCE_NULL${suffix}`;

export const setBankReferenceNullAction = createAction<any>(SET_BANK_REFERENCE_NULL);

const reduceHandlers = {
  [SET_BANK_REFERENCE_NULL]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    bankReference: action.payload,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
