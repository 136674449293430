import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_USER_HISTORY = `GET_USER_HISTORY${suffix}`;

export const getUserHistoryAction = createAction<any>(GET_USER_HISTORY);

const GET_USER_HISTORY_SUCCESS = `GET_USER_HISTORY_SUCCESS${suffix}`;
const getUserHistorySuccessAction = createAction<any[]>(GET_USER_HISTORY_SUCCESS);

const GET_USER_HISTORY_ERROR = `GET_USER_HISTORY_ERROR${suffix}`;
const getUserHistoryErrorAction = createAction<string>(GET_USER_HISTORY_ERROR);

function* getUserHistoryWatcher(): SagaIterator {
  yield takeEvery(GET_USER_HISTORY, getUserHistoryWorker);
}
mergeSaga(getUserHistoryWatcher);

function* getUserHistoryWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getUserHistoryApi, action.payload);
    yield put(getUserHistorySuccessAction(result.data));
  } catch (e) {
    yield put(getUserHistoryErrorAction(e));
  }
}

const getUserHistoryApi = (x: any): Request => {
  let wagers = 0;
  let payments = 0;
  let freeplay = 0;
  let casino = 0;
  let rbl = 0;
  if (x.filter == 'Wagers') {
    wagers = 1;
  }
  if (x.filter == 'Payments') {
    payments = 1;
  }
  if (x.filter == 'Free Plays') {
    freeplay = 1;
  }
  if (x.filter == 'Casino') {
    casino = 1;
  }
  if (x.filter == 'RBL') {
    rbl = 1;
  }

  return vipApi(`/wager/History`, 'post', {
    weeks: x.weeks,
    Wagers: wagers,
    Payments: payments,
    FreePlay: freeplay,
    Casino: casino,
    RBL: rbl,
  });
};

const reduceHandlers = {
  [GET_USER_HISTORY]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_USER_HISTORY_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    userHistory: action.payload,
  }),
  [GET_USER_HISTORY_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
