import { connect } from 'react-redux';
import { TransactionHistoryPage, ExternalProps, ExternalActionProps } from './transaction-history.component';
import { getTransactionHistoryAction } from './../../api/user/actions/get-transaction-history.action';
import { transactionHistorySelector, isLoadingSelector, userBalanceSelector, loggedInUserSelector } from './../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    transactionHistory: transactionHistorySelector(state),
    isLoading: isLoadingSelector(state),
    userBalance: userBalanceSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getTransactionHistoryAction,
};

export const TransactionHistoryPageContainer = connect(mapStateToProps, mapDispatchToProps)(TransactionHistoryPage);
