import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'tpiSlice';

export interface tpiSliceType {
  isLoading: boolean;
  casinoGames?: any[] | null;
  casinoCategories?: any[] | null;
  gameUrl: string | null;
  liveWageringUrl?: string | null;
  eSportsWageringUrl?: string | null;
  dgsCasinoUrl?: string | null;
  horsesUrl?: string | null;
}

export const initialState: tpiSliceType = {
  isLoading: false,
  casinoGames: [],
  casinoCategories: [],
  gameUrl: '',
  liveWageringUrl: '',
  eSportsWageringUrl: '',
  dgsCasinoUrl: '',
};

export const tpiSliceReducer = createAndMergeSliceReducer(sliceName, initialState);
