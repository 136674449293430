import React, { useState, useEffect } from 'react';
import { Button, Paper, TextField, Typography, Theme, createStyles, Container, Grid, InputAdornment, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
// import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/Lock';
import { ISignup, emptySignup } from '../../../models/users';
import { toastr } from 'react-redux-toastr';
import { Redirect, useParams } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getHistory } from '../../../utils/history.util';
import CountrySelect from './components/country-select.component';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shrinkLogo: {
      maxWidth: 100,
      height: 'auto',
      margin: '0px auto',
      display: 'block',
    },
    container: {
      display: 'table',
      height: '100%',
      position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.png` + ')',
      backgroundSize: '1920px 1080px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'black',
      backgroundPosition: 'top center',
      paddingBottom: '4rem',
    },
    helper: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    content: {
      margin: '0 auto',
      width: '100%',
      backgroundColor: '#1a1a1a',
      paddingBottom: '50px',
    },
    moveForgetPasswordToRight: {
      float: 'right',
      display: 'flex',
      paddingBottom: 20,
      color: 'white',
    },
    moveForgotUsernameToLeft: {
      float: 'left',
      display: 'flex',
      paddingBottom: 20,
      color: 'white',
    },
    tab: {
      border: '1px solid #c8a964',
      padding: '10px',
      textAlign: 'center',
    },
    tabActive: {
      backgroundColor: theme.palette.primary.main,
    },
    topHeadBox: {
      padding: '25px',
      textAlign: 'center',
      backgroundColor: '#050505',
    },
    textPadding: {
      paddingTop: '8px',
      paddingBottom: '20px',
    },
    tabPanel: {
      display: 'flex',
      textAlign: 'center',
      padding: '20px',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none!important',
    },
    bottomBtnContainer: {
      paddingLeft: '20px',
      paddingRight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    successGrid: {
      marginTop: '0px',
      textAlign: 'center',
    },
    buttons: {
      margin: 5,
    },
  }),
);
// interface TextMaskCustomProps {
//   inputRef: (ref: HTMLInputElement | null) => void;
// }

// function TextMaskCustom(props: TextMaskCustomProps) {
//   const { inputRef, ...other } = props;

//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref: any) => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//       placeholderChar={'\u2000'}
//       showMask
//     />
//   );
// }

export interface ExternalProps {
  isLoading: boolean;
  error?: string;
  inviteSuccess: boolean;
  signupSuccess: boolean;
  codeInvalid: boolean;
  loggedIn: boolean;
  signupUsername: string | null;
}
export interface ExternalActionProps {
  signupAction: (signup: ISignup) => void;
  inviteAction: (code: string) => void;
}
export const SignupPage = (props: ExternalActionProps & ExternalProps) => {
  const { isLoading, signupAction, inviteAction, inviteSuccess, signupSuccess, codeInvalid, loggedIn, signupUsername } = props;
  const { code } = useParams();
  const classes = useStyles();
  const [ntab, setNtab] = useState(1);
  const [invite, setInvite] = useState(code);
  const [selected, setSelected] = useState(emptySignup);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (inviteSuccess) {
      setNtab(2);
    } else {
      inviteAction(invite);
    }
  }, [inviteSuccess]);

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => {
        return response.json();
      })
      .then(res => {
        setSelected({
          ...selected,
          ip: res.ip,
        });
      })
      .catch(err => console.error('Problem fetching my IP', err));
  }, []);

  // if (signupSuccess) {
  //   // return <Redirect to='/login' />;
  // }

  if (localStorage.getItem('fpjs') == null) {
    FingerprintJS.load({ token: 'nvoiB2OqFxGoBIg53MC6' })
      .then(fp => fp.get())
      .then(result => {
        localStorage.setItem('fpjs', result.visitorId);
      });
  }

  const validation = () => {
    if (selected.password != selected.password_confirmation) {
      toastr.error('Error', 'Password and Confirmtaion Password did not match');
      setDisabled(false);
      return false;
    }
    if (selected.phone == '') {
      toastr.error('Error', 'Please enter a valid phone number.');
      setDisabled(false);
      return false;
    }
    setDisabled(true);
    return true;
  };

  if (loggedIn) {
    return <Redirect to={'/sportsbook'} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Container>
          <Paper className={classes.content} elevation={3}>
            <div>
              <div className={classes.topHeadBox}>
                <Typography variant='h4' color='primary'>
                  SIGN UP
                </Typography>
              </div>
              <Grid container alignContent='center' className={clsx(classes.successGrid, { [classes.hidden]: signupSuccess })}>
                <Grid item xs={6} className={clsx(classes.tab, { [classes.tabActive]: ntab == 1 })}>
                  <Typography variant='h5'>INVITE CODE</Typography>
                </Grid>
                <Grid item xs={6} className={clsx(classes.tab, { [classes.tabActive]: ntab == 2 })}>
                  <Typography variant='h5'>INFO</Typography>
                </Grid>
                <Grid item xs={12} className={clsx(classes.tabPanel, { [classes.hidden]: ntab != 1 })}>
                  <Grid item xs={12} lg={4}>
                    <AccountCircleOutlinedIcon color='primary' style={{ fontSize: '200px' }} />
                    <Typography variant='subtitle1'>PLEASE ENTER AN INVITE CODE.</Typography>
                    <TextField
                      fullWidth
                      variant='outlined'
                      placeholder='Invite Code'
                      disabled={codeInvalid}
                      className={classes.textPadding}
                      value={invite}
                      onChange={e => setInvite(e.target.value)}
                    />
                    <br />
                    <Button
                      variant='contained'
                      color='secondary'
                      size='large'
                      onClick={() => {
                        getHistory().push('/login');
                      }}
                      style={{ margin: 10 }}
                    >
                      Login
                    </Button>
                    <Button variant='contained' color='primary' size='large' onClick={() => inviteAction(invite)}>
                      Sign Up
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={clsx(classes.tabPanel, { [classes.hidden]: ntab != 2 })}>
                  <Grid item lg={5} md={5} xs={12}>
                    <Typography variant='subtitle1'>
                      INVITE CODE: {invite} <br />
                      <span
                        style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={() => {
                          getHistory().push('/login');
                        }}
                      >
                        Already have an account? Login here.
                      </span>
                    </Typography>
                    <form
                      id='signupForm'
                      onSubmit={e => {
                        e.preventDefault();

                        validation()
                          ? signupAction({ ...selected, phone: selected.country_code + selected.phone, inviter_code: invite })
                          : () => false;
                      }}
                    >
                      <TextField
                        fullWidth
                        variant='outlined'
                        required
                        placeholder='First Name'
                        label='First Name'
                        className={classes.textPadding}
                        value={selected.first_name}
                        onChange={e =>
                          setSelected({
                            ...selected,
                            first_name: e.target.value,
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        fullWidth
                        variant='outlined'
                        required
                        placeholder='Last Name'
                        label='Last Name'
                        className={classes.textPadding}
                        value={selected.last_name}
                        onChange={e =>
                          setSelected({
                            ...selected,
                            last_name: e.target.value,
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <PersonIcon />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        fullWidth
                        variant='outlined'
                        placeholder='Email'
                        required
                        type='email'
                        label='Email'
                        className={classes.textPadding}
                        value={selected.email}
                        onChange={e =>
                          setSelected({
                            ...selected,
                            email: e.target.value,
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        fullWidth
                        variant='outlined'
                        required
                        placeholder='Phone'
                        label='Phone'
                        className={classes.textPadding}
                        value={selected.phone}
                        onChange={e =>
                          setSelected({
                            ...selected,
                            phone: e.target.value,
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <CountrySelect selected={selected} setSelected={setSelected} />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        fullWidth
                        variant='outlined'
                        placeholder='Password'
                        type='password'
                        required
                        label='Password'
                        value={selected.password}
                        onChange={e =>
                          setSelected({
                            ...selected,
                            password: e.target.value,
                          })
                        }
                        className={classes.textPadding}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography variant='subtitle1' gutterBottom>
                        Password must be 6-10 characters with letters and numbers only.
                      </Typography>
                      <TextField
                        fullWidth
                        variant='outlined'
                        placeholder='Confirm Password'
                        label='Confirm Password'
                        className={classes.textPadding}
                        type='password'
                        required
                        value={selected.password_confirmation}
                        onChange={e =>
                          setSelected({
                            ...selected,
                            password_confirmation: e.target.value,
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={clsx(classes.bottomBtnContainer, { [classes.hidden]: ntab != 2 })}>
                  <Button variant='contained' color='primary' onClick={() => setNtab(1)}>
                    Previous
                  </Button>

                  <Button variant='contained' type='submit' form='signupForm' color='primary' disabled={disabled && isLoading}>
                    Finish
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={clsx(classes.successGrid, { [classes.hidden]: !signupSuccess })}>
                <Grid item xs={12}>
                  <CheckCircleIcon style={{ fontSize: '80px', color: 'green' }} />
                  <Typography variant='h5'>
                    You are successfully signed up with us.
                    <br />
                    <br />
                    Now you can login with{' '}
                    <b>
                      {signupUsername != null && `${signupUsername} or `} {selected.email}
                    </b>
                    <br />
                    &nbsp;
                  </Typography>
                  <Chip component='a' href='/#/login' label='Login' clickable color='primary' id='afterSignUpLoginBtn' />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Container>
      </div>
    </div>
  );
};
