import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid, Container, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Loading } from '../../shared/loading/loading.component';
// import { getHistory } from '../../utils/history.util';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getAppSettings } from '../../utils/app-settings.util';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
// import RAFTerms from './components/raf-terms';
import RAFHowTo from './components/raf-how-to';
import RAFRules from './components/raf-rules';
import RAFTerms from './components/raf-terms';
// import { getHistory } from '../../utils/history.util';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { getHistory } from '../../utils/history.util';

const useStyles: any = makeStyles({
  root: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
  },
  dot300: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #c8a964 100%, lightgrey 0%),
                     linear-gradient(120deg, #fff 36%, #fff 30%),
                     linear-gradient(300deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(210deg, #fff 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  dot280: {
    border: '20px solid #404040',
    margin: '10px',
    borderRadius: '50%',
    width: '230px',
    height: '230px',
  },
  colorMediumText: {
    fontSize: '18px',
    color: '#c8a964',
  },
  smallText: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#666',
  },
  innerCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  midGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  moneyText: {
    color: '#c8a964',
    fontSize: '28px',
  },
  upperText: {
    color: '#777',
  },
  depositBtn: {
    color: '#fff',
    backgroundColor: '#011f63',
  },
  btnGrid: {
    display: 'block',
    margin: '30px auto',
    paddingBottom: '50px',
    textAlign: 'center',
  },
  tabPill: {
    padding: '10px auto',
    border: '1px solid #c8a964',
  },
  shareBtns: {
    margin: '.5rem',
  },
});

export interface ExternalProps {
  user: any;
  isLoading: boolean;
  rafData: any;
  rafCode: any;
}

export interface ExternalActionProps {
  getRafHistoryAction: (username: string) => void;
  redeemRafBonusAction: (x: any) => void;
  getRafCodeAction: (username: string) => void;
  getUserInfoAction: () => void;
}

export const ReferAFriendPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { user, isLoading, rafData, rafCode, getRafHistoryAction, redeemRafBonusAction, getRafCodeAction, getUserInfoAction } = props;

  useEffect(() => {
    getUserInfoAction();
    getRafHistoryAction(user.loggedInUser.username);
    getRafCodeAction(user.loggedInUser.username);
  }, []);

  const getReferralStatus = (referral: any) => {
    if (referral.invite_approved == 0) {
      return (
        <>
          <LinearProgress value={100} variant='determinate' color='secondary'></LinearProgress>
          <span style={{ color: 'red' }}>DENIED</span> - Upon admin review, we have chosen to deny this referral.
        </>
      );
    }

    // Complete
    if (referral.invite_redeemed != null) {
      return (
        <>
          <LinearProgress value={100} variant='determinate' color='secondary'></LinearProgress> - 100/100%{' '}
          <span style={{ color: 'green' }}>(Redeemed)</span>
        </>
      );
    }

    // Bonus Available
    if (referral.wagered != null && referral.deposit != null) {
      return (
        <>
          <LinearProgress value={100} variant='determinate' color='primary'></LinearProgress> - 100/100% (Bonus Available)
        </>
      );
    }

    // // Pending Wager
    // if (referral.deposit != null && referral.wagered == null) {
    //   return (
    //     <>
    //       <LinearProgress value={75} variant='determinate' color='secondary'></LinearProgress> - 75/100% (Pending Wager)
    //     </>
    //   );
    // }

    // Pending Deposit
    if (referral.deposit != null && referral.wagered == null) {
      return (
        <>
          <LinearProgress value={60} variant='determinate' color='secondary'></LinearProgress> - 60/100% (Deposited - Need a completed
          wager.)
        </>
      );
    }

    return (
      <>
        <LinearProgress value={25} variant='determinate' color='secondary'></LinearProgress> - 25/100% (Signed Up - Need to Deposit.)
      </>
    );
  };

  const url = `${getAppSettings().baseUrl}#/signup/${rafCode}`;

  console.log(user);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <Container>
        <Grid container alignItems='center'>
          <Grid item xs={12} md={4} className='tour_raf-referred'>
            <Typography align='center' className={classes.upperText}>
              Friends Referred
            </Typography>
            <Typography align='center' className={classes.moneyText}>
              {rafData != null && rafData.invited}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.midGrid}>
            <div className={classes.dot300}>
              <div className={classes.dot280}>
                <div className={classes.innerCircle}>
                  <img src='/logo.png' style={{ width: '70px' }} />
                  <Typography>{user.loggedInUser.first_name + ' ' + user.loggedInUser.last_name}</Typography>
                  <div className='tour_raf-earned'>
                    <Typography className={classes.smallText}>Total Earned</Typography>
                    <Typography className={classes.colorMediumText}>${rafData != null && rafData.earned}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className='tour_raf-claimed'>
            <Typography align='center' className={classes.upperText}>
              Referrals Claimed
            </Typography>
            <Typography align='center' className={classes.moneyText}>
              {rafData != null && rafData.claimed}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.btnGrid}>
            {/* <Button variant='contained' size='large' color='secondary' onClick={() => getHistory().push('/rules')}>
              R-A-F Rules
            </Button> */}
            <RAFRules />
            <RAFHowTo />
            {/* <RAFTerms /> */}
          </Grid>
          {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}></Grid> */}
        </Grid>

        {/* <AccountLinks routeName='refer-a-friend' /> */}

        {
          <>
            <Paper style={{ marginBottom: '1rem', padding: 12, textAlign: 'center' }}>
              {user && user.loggedInUser.cognito_match != 1 && (
                <div style={{ maxWidth: '600px', display: 'block', margin: '1rem auto' }}>
                  <Alert variant='standard' color='warning'>
                    <AlertTitle>Verify your account</AlertTitle>
                    Update your profile with your name and phone to get 200% match on your friends deposit!
                    <br />
                    <Button
                      variant='contained'
                      color='default'
                      onClick={() => {
                        getHistory().push('/profile');
                      }}
                      style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
                    >
                      Update Profile
                    </Button>
                  </Alert>
                </div>
              )}

              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'>Copy the link below and share with your friends!</Typography>
                  <div
                    className='tour_raf-url'
                    style={{
                      backgroundColor: '#121212',
                      maxWidth: '700px',
                      padding: 5,
                      borderRadius: '2px',
                      display: 'block',
                      margin: '0px auto',
                      wordWrap: 'break-word',
                    }}
                  >
                    <Typography variant='h6'>{url}</Typography>
                  </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <div className='tour_raf-shareBtns'>
                    <Button
                      variant='contained'
                      onClick={() => {
                        window.open(`https://t.me/share/url?url=${url}&text=Check out this new VIP, invite only sportsbook!`, '_blank');
                      }}
                      className={classes.shareBtns}
                    >
                      <TelegramIcon />
                      Telegram
                    </Button>
                    <Button
                      className={classes.shareBtns}
                      variant='contained'
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=https%3A//www.vipclub.lv/%23/signup/${rafCode}`,
                          '_blank',
                        );
                      }}
                    >
                      <FacebookIcon />
                      Facebook
                    </Button>
                    <Button
                      className={classes.shareBtns}
                      variant='contained'
                      onClick={() => {
                        window.open(
                          `https://twitter.com/intent/tweet?text=Check%20out%20this%20invite%20only%20sportsbook!%20https%3A//www.vipclub.lv/%23/signup/${rafCode}`,
                          '_blank',
                        );
                      }}
                    >
                      <TwitterIcon />
                      Twitter
                    </Button>
                    <Button
                      className={classes.shareBtns}
                      onClick={() => {
                        navigator.clipboard.writeText(url);
                      }}
                      variant='contained'
                      color='primary'
                    >
                      <FileCopyIcon /> Copy
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Paper>

            <Paper>
              <Grid container>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Name <br /> <small>Status</small>
                        </TableCell>
                        <TableCell size='small'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoading &&
                        rafData !== null &&
                        rafData.referrals.map((entry: any) => (
                          <TableRow key={entry.id}>
                            <TableCell className='tour_raf-statusCell'>
                              {entry.username} - {entry.first_name + ' ' + entry.last_name}
                              {getReferralStatus(entry)}
                            </TableCell>
                            <TableCell className='tour_raf-claimBonusCell'>
                              {entry.wagered != null && entry.invite_redeemed == null && entry.deposit != null && (
                                <>
                                  {entry.need_approval == false ? (
                                    <RAFTerms redeemRafBonusAction={redeemRafBonusAction} user={user} referral={entry} />
                                  ) : (
                                    <>
                                      {entry.invite_approved == 1 && (
                                        <RAFTerms redeemRafBonusAction={redeemRafBonusAction} user={user} referral={entry} />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {entry.wagered != null &&
                                entry.invite_redeemed == null &&
                                entry.deposit != null &&
                                entry.need_approval == true &&
                                (entry.invite_approved == null || entry.invite_approved == 0) &&
                                'Pending Admin Approval'}
                            </TableCell>
                          </TableRow>
                        ))}

                      {!isLoading && rafData != null && rafData.referrals.length == 0 && (
                        <TableRow>
                          <TableCell>
                            <Typography variant='subtitle2' gutterBottom>
                              Please start referring friends to see data.
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
          </>
        }
      </Container>
    </div>
  );
};
