import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const LEAGUE = `LEAGUE${suffix}`;

export const leagueAction = createAction<boolean>(LEAGUE);

const LEAGUE_SUCCESS = `LEAGUE_SUCCESS${suffix}`;
const leagueSuccessAction = createAction<boolean>(LEAGUE_SUCCESS);

const LEAGUE_ERROR = `LEAGUE_ERROR${suffix}`;
const leagueErrorAction = createAction<string>(LEAGUE_ERROR);

function* leagueWatcher(): SagaIterator {
  yield takeEvery(LEAGUE, leagueWorker);
}
mergeSaga(leagueWatcher);

function* leagueWorker(action: any): SagaIterator {
  try {
    yield put(leagueSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(leagueErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [LEAGUE]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [LEAGUE_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    league: action.payload,
  }),
  [LEAGUE_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
