import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { MobileWagerDetail } from './mobile-wager-detail.component';
import { DesktopWagerDetail } from './desktop-wager-detail.component';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
  },
});

// export interface ExternalActionProps {}

export interface ExternalProps {
  userHistory: any;
}

export const DgsStyleWagerHistory = (props: ExternalProps) => {
  const classes = useStyles();
  const { userHistory } = props;
  const [width] = React.useState<number>(window.innerWidth);
  const isMobile: boolean = width <= 768;
  const isIpad: boolean = width <= 1280;

  return (
    <div className={clsx(classes.root)}>
      {isMobile ? (
        ''
      ) : (
        <Box
          bgcolor='secondary.main'
          style={
            isIpad ? { top: 64, left: 'auto', right: 0, position: 'sticky' } : { top: 146, left: 'auto', right: 0, position: 'sticky' }
          }
        >
          <Grid container style={{ marginBottom: '.5rem' }}>
            <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
              Type
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
              Date
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'left', padding: '.5rem' }}>
              Desc.
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
              Result
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
              Risk/Win
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
              Amount
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'center', padding: '.5rem' }}>
              Balance
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center', padding: '.5rem' }}>
              Placed
            </Grid>
          </Grid>
        </Box>
      )}
      <div style={{ marginTop: -8 }}>
        {userHistory.history &&
          userHistory.history.map((day: any, index: any) => {
            return (
              <React.Fragment key={index}>
                {/* <Box bgcolor='secondary.main' style={{ padding: '1rem' }}>
                ({GetWeekDay(index)}) {day.Date}
              </Box> */}
                {isMobile ? <MobileWagerDetail dayData={day.wager} /> : <DesktopWagerDetail dayData={day.wager} />}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};
