import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_TRANSACTION_STATUS = `GET_TRANSACTION_STATUS${suffix}`;

export const getTransactionStatusAction = createAction<any>(GET_TRANSACTION_STATUS);

const GET_TRANSACTION_STATUS_SUCCESS = `GET_TRANSACTION_STATUS_SUCCESS${suffix}`;
const getTransactionStatusSuccessAction = createAction<any[]>(GET_TRANSACTION_STATUS_SUCCESS);

const GET_TRANSACTION_STATUS_ERROR = `GET_TRANSACTION_STATUS_ERROR${suffix}`;
const getTransactionStatusErrorAction = createAction<string>(GET_TRANSACTION_STATUS_ERROR);

function* getTransactionStatusWatcher(): SagaIterator {
  yield takeEvery(GET_TRANSACTION_STATUS, getTransactionStatusWorker);
}
mergeSaga(getTransactionStatusWatcher);

function* getTransactionStatusWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getTransactionStatusApi, action.payload);
    yield put(getTransactionStatusSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getTransactionStatusErrorAction(e));
  }
}

const getTransactionStatusApi = (transactionId: any): Request => {
  return vipApi(`/pcashier/GetTransactionStatus`, 'post', {
    id: transactionId,
  });
};

const reduceHandlers = {
  [GET_TRANSACTION_STATUS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_TRANSACTION_STATUS_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    transactionStatus: action.payload,
  }),
  [GET_TRANSACTION_STATUS_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
