import React, { useEffect } from 'react';
import ReduxToastr from 'react-redux-toastr';
import clsx from 'clsx';
import { Hidden, makeStyles, Fab, Typography } from '@material-ui/core';
import { AppBar } from '../app-bar/app-bar.component';
import { Routes } from '../../routes';
import { NavBarBottom } from '../app-bar/nav-bar-bottom.component';
import { NavBarContainer } from '../app-bar/tab-bar.container';
import ForumIcon from '@material-ui/icons/Forum';

declare global {
  interface Window {
    LO: any;
  }
}

const openLOLiveChat = () => {
  if (window.LO != null) {
    if (window.LO.messenger != undefined && window.LO.messenger != null) {
      window.LO.messenger.open();
      console.log('opened LO Chat');
    }
  }
};

const appBarHeight = '64px';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    marginTop: appBarHeight,
    marginBottom: appBarHeight,
  },
  marginTop72: {
    marginTop: '72px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-7px',
    },
  },
}));

export interface AppRootProps {
  isLoggedIn: boolean;
  user: any;
  userBalance: any;
  tvOnOffResponses: boolean;
  tvUrl: string;
  path: string;
  tourSteps: any;
  rightOpen: boolean;
}
export interface ExternalActionProps {
  getUserBalanceAction: () => void;
  tvOnOffAction: (isOn: boolean) => void;
  setTvUrlAction: (ch: string) => void;
  userInfoAction: () => void;
  getTourStepsAction: () => void;
}
export const AppRoot = ({
  isLoggedIn,
  user,
  userBalance,
  getUserBalanceAction,
  tvOnOffAction,
  tvOnOffResponses,
  setTvUrlAction,
  userInfoAction,
  getTourStepsAction,
  tourSteps,
  tvUrl,
  path,
  rightOpen,
}: ExternalActionProps & AppRootProps) => {
  const classes = useStyles();
  useEffect(() => {
    if (isLoggedIn) {
      userInfo();
    }

    //Here detect all message from iframes
    window.addEventListener(
      'message',
      event => {
        if (event.origin.includes('plive.webavanx.com')) if (event.data == 'bet-accepted') getUserBalanceAction();
      },
      false,
    );
    // if (Object.entries(userBalance).length === 0 && isLoggedIn) {
    //   getUserBalanceAction();
    // }
  }, []);

  const userInfo = async () => {
    userInfoAction();
  };

  useEffect(() => {
    if (isLoggedIn) {
      getUserBalanceAction();
      if (window.LO != null) {
        if (window.LO.visitor) {
          const userData = {
            'Player Number': user.username,
            email: user.email,
            'Player Type': user.player_type,
            Agent: user.agent,
            phone: user.phone,
            'Identity Verified': user.cognito_match == 1 ? 'Yes' : 'No',
            'Sign Up': user.sign_up_date,
            'Last Deposit': user.last_deposit_date ?? 'N/A',
            'Available Balance': userBalance.RealAvailBalance,
            'Free Play Balance': userBalance.FreePlayAmount,
          };
          window.LO.visitor.identify(user.username, userData);
          console.log('[LO]: Sent User Info');
        }
      }
    }
  }, [path]);

  return (
    <div className={classes.root}>
      {isLoggedIn && (
        <AppBar
          user={user}
          userBalance={userBalance}
          tvOnOffResponses={tvOnOffResponses}
          tvOnOffAction={tvOnOffAction}
          tvUrl={tvUrl}
          setTvUrlAction={setTvUrlAction}
          isTvOn={tvOnOffResponses}
          getTourStepsAction={getTourStepsAction}
          tourSteps={tourSteps}
        />
      )}
      <div
        className={clsx({
          [classes.content]: isLoggedIn,
        })}
      >
        <Hidden mdDown>{isLoggedIn && <NavBarContainer />}</Hidden>
        <Hidden lgUp>
          {isLoggedIn && (
            <NavBarBottom
              isTVOn={tvOnOffResponses}
              tvOnOffAction={tvOnOffAction}
              tvUrl={tvUrl}
              setTvUrlAction={setTvUrlAction}
              user={user}
              rightOpen={rightOpen}
            />
          )}
        </Hidden>
        <div
          className={clsx({
            [classes.marginTop72]: isLoggedIn,
          })}
        >
          <Routes />
        </div>
      </div>
      <ReduxToastr timeOut={2000} transitionIn='fadeIn' transitionOut='fadeOut' />

      {(path == '/login' || path.includes('signup')) && (
        <Fab
          variant='extended'
          size='small'
          color='primary'
          aria-label='add'
          onClick={() => {
            openLOLiveChat();
          }}
          style={{
            margin: '0px',
            top: 'auto',
            right: '20px',
            bottom: '20px',
            left: 'auto',
            position: 'fixed',
          }}
        >
          <ForumIcon />{' '}
          <Typography variant='body2' style={{ marginLeft: '.25rem', marginRight: '.25rem' }}>
            Support
          </Typography>
        </Fab>
      )}
    </div>
  );
};
