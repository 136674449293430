import React from 'react';

export const VipLiveTv = (props: any) => {
  const { style } = props;
  return (
    <svg id='Tv_live' style={style} viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <g>
          <path
            d='m484.297 410.555h-456.594c-11.158 0-20.203-9.045-20.203-20.203v-331.334c0-11.158 9.045-20.203 20.203-20.203h456.594c11.158 0 20.203 9.045 20.203 20.203v331.333c0 11.159-9.045 20.204-20.203 20.204z'
            fill='#707dd3'
          />
        </g>
        <g>
          <path
            d='m484.297 38.815h-18.183c11.158 0 20.203 9.045 20.203 20.203v331.333c0 11.158-9.045 20.203-20.203 20.203h18.183c11.158 0 20.203-9.045 20.203-20.203v-331.333c0-11.158-9.045-20.203-20.203-20.203z'
            fill='#5766cb'
          />
        </g>
        <g>
          <path
            d='m39.825 368.128v-286.886c0-5.579 4.523-10.102 10.102-10.102h412.146c5.579 0 10.102 4.523 10.102 10.102v286.886c0 5.579-4.523 10.102-10.102 10.102h-412.146c-5.579 0-10.102-4.523-10.102-10.102z'
            fill='#90d8f9'
          />
        </g>
        <g>
          <path
            d='m462.073 71.14h-8.081v283.856c0 2.789-2.261 5.051-5.051 5.051h-409.116v8.081c0 5.579 4.523 10.102 10.102 10.102h412.146c5.579 0 10.102-4.523 10.102-10.102v-286.886c0-5.579-4.523-10.102-10.102-10.102z'
            fill='#75cef9'
          />
        </g>
        <g>
          <path
            d='m468.227 473.185h23.008c6.053 0 9.957-6.409 7.181-11.788l-20.596-39.905c-3.467-6.717-10.394-10.937-17.953-10.937h-407.734c-7.559 0-14.486 4.22-17.953 10.937l-20.596 39.905c-2.776 5.379 1.128 11.788 7.181 11.788h23.008c3.78 0 7.243-2.11 8.977-5.469l12.819-24.836h380.864l12.819 24.836c1.732 3.359 5.196 5.469 8.975 5.469z'
            fill='#5766cb'
          />
        </g>
        <g>
          <g>
            <path
              d='m128.214 270.142v-90.915c0-11.158 9.045-20.203 20.203-20.203h215.165c11.158 0 20.203 9.045 20.203 20.203v90.915c0 11.158-9.045 20.203-20.203 20.203h-215.164c-11.158.001-20.204-9.045-20.204-20.203z'
              fill='#dd636e'
            />
          </g>
          <g>
            <circle cx='100.435' cy='131.75' fill='#dd636e' r='16.163' />
          </g>
        </g>
        <g>
          <path d='m462.07 370.73h-60.78c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h60.78c9.704 0 17.6-7.896 17.6-17.6v-286.89c0-9.705-7.896-17.6-17.6-17.6h-412.14c-9.705 0-17.6 7.896-17.6 17.6v286.89c0 9.705 7.896 17.6 17.6 17.6h316.36c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-316.36c-1.434 0-2.6-1.167-2.6-2.6v-286.89c0-1.434 1.167-2.6 2.6-2.6h412.14c1.434 0 2.6 1.167 2.6 2.6v286.89c0 1.433-1.166 2.6-2.6 2.6z' />
          <path d='m512 390.35v-331.33c0-15.274-12.426-27.7-27.7-27.7h-362.05c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h362.05c7.003 0 12.7 5.697 12.7 12.7v331.33c0 7.002-5.697 12.7-12.7 12.7h-456.6c-7.003 0-12.7-5.697-12.7-12.7v-331.33c0-7.003 5.697-12.7 12.7-12.7h59.55c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-59.55c-15.274 0-27.7 12.426-27.7 27.7v331.33c0 15.213 12.328 27.596 27.518 27.695-.001.002-.002.003-.003.005l-20.603 39.917c-2.508 4.871-2.303 10.57.548 15.246 2.852 4.676 7.828 7.468 13.31 7.468h23c6.626 0 12.623-3.65 15.645-9.521l10.725-20.78h54.241c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-58.811c-2.806 0-5.378 1.567-6.665 4.061l-12.823 24.847c-.442.859-1.329 1.393-2.312 1.393h-23c-.117 0-.333 0-.503-.278-.169-.277-.071-.467-.022-.562l20.6-39.909c2.191-4.245 6.516-6.881 11.286-6.881h407.74c4.77 0 9.094 2.637 11.285 6.88l20.597 39.903c.053.102.15.292-.019.568-.17.278-.387.278-.503.278h-23c-.983 0-1.87-.534-2.315-1.4l-12.82-24.84c-1.287-2.494-3.858-4.06-6.665-4.06h-287.051c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h282.48l10.722 20.774c3.026 5.876 9.022 9.526 15.648 9.526h23c5.482 0 10.458-2.792 13.31-7.468s3.056-10.375.545-15.252l-20.601-39.911c-.001-.001-.001-.002-.002-.004 15.191-.099 27.518-12.483 27.518-27.695z' />
          <path d='m148.417 297.846h215.165c15.275 0 27.703-12.428 27.703-27.704v-90.915c0-15.275-12.428-27.703-27.703-27.703h-215.165c-15.275 0-27.703 12.428-27.703 27.703v90.915c0 15.276 12.428 27.704 27.703 27.704zm-12.703-118.618c0-7.004 5.699-12.703 12.703-12.703h215.165c7.005 0 12.703 5.699 12.703 12.703v90.915c0 7.005-5.698 12.704-12.703 12.704h-215.165c-7.004 0-12.703-5.699-12.703-12.704z' />
          <path d='m324.186 263.71h26.993c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-19.493v-17.03h17.503c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-17.503v-17.03h19.493c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-26.993c-4.143 0-7.5 3.358-7.5 7.5v64.06c0 4.142 3.357 7.5 7.5 7.5z' />
          <path d='m158.767 263.843c.535.092.968.166 8.134.166 3.453 0 8.47-.017 15.814-.058 4.142-.023 7.481-3.4 7.458-7.542-.024-4.142-3.367-7.511-7.542-7.458-5.22.029-10.822.05-15.096.054v-57.15c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v64.596c0 3.653 2.631 6.774 6.232 7.392z' />
          <path d='m214.078 264.005c4.142 0 7.5-3.358 7.5-7.5v-64.65c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v64.65c0 4.142 3.358 7.5 7.5 7.5z' />
          <path d='m260.928 258.043c1.268 3.572 4.655 5.961 8.442 5.961h.061c3.812-.026 7.195-2.467 8.438-6.125l21.108-63.542c1.307-3.931-.822-8.176-4.753-9.482-3.929-1.306-8.176.822-9.481 4.753l-15.498 46.653-16.269-46.868c-1.358-3.913-5.633-5.982-9.545-4.626-3.913 1.358-5.984 5.632-4.626 9.545z' />
          <path d='m100.435 108.087c-13.047 0-23.663 10.615-23.663 23.663s10.615 23.663 23.663 23.663 23.663-10.615 23.663-23.663-10.616-23.663-23.663-23.663zm0 32.326c-4.776 0-8.663-3.886-8.663-8.663s3.886-8.663 8.663-8.663 8.663 3.886 8.663 8.663-3.887 8.663-8.663 8.663z' />
        </g>
      </g>
    </svg>
  );
};
