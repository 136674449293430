import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_TRANSACTION_HISTORY = `GET_TRANSACTION_HISTORY${suffix}`;

export const getTransactionHistoryAction = createAction(GET_TRANSACTION_HISTORY);

const GET_TRANSACTION_HISTORY_SUCCESS = `GET_TRANSACTION_HISTORY_SUCCESS${suffix}`;
const getTransactionHistorySuccessAction = createAction<any[]>(GET_TRANSACTION_HISTORY_SUCCESS);

const GET_TRANSACTION_HISTORY_ERROR = `GET_TRANSACTION_HISTORY_ERROR${suffix}`;
const getTransactionHistoryErrorAction = createAction<string>(GET_TRANSACTION_HISTORY_ERROR);

function* getTransactionHistoryWatcher(): SagaIterator {
  yield takeEvery(GET_TRANSACTION_HISTORY, getTransactionHistoryWorker);
}
mergeSaga(getTransactionHistoryWatcher);

function* getTransactionHistoryWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getTransactionHistoryApi, action.payload);
    yield put(getTransactionHistorySuccessAction(result));
  } catch (e) {
    yield put(getTransactionHistoryErrorAction(e));
  }
}

const getTransactionHistoryApi = (x: any): Request => {
  return vipApi(`/transactions`, 'post', { days: x.days, type: x.type });
};

const reduceHandlers = {
  [GET_TRANSACTION_HISTORY]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_TRANSACTION_HISTORY_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    transactionHistory: action.payload.data,
  }),
  [GET_TRANSACTION_HISTORY_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
