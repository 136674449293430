import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { Loading } from '../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
    border: 'none',
    height: '85%',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute',
  },
});

export interface ExternalProps {
  isLoading: any;
  eSportsUrl: any;
}
export interface ExternalActionProps {
  getEsportsWageringUrlAction: (x: any) => void;
}

export const EsportsPage = (props: ExternalActionProps & ExternalProps) => {
  const { isLoading, eSportsUrl, getEsportsWageringUrlAction } = props;
  const classes = useStyles();

  useEffect(() => {
    getEsportsWageringUrlAction({ provider_id: 3 });
  }, []);

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      {!isLoading && <iframe className={clsx(classes.root)} src={eSportsUrl} height='100%'></iframe>}
    </>
  );
};
