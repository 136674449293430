import { createSelector } from 'reselect';
import { cashierTransactionReducer, CashierTransactionSliceType } from './cashier.reducer';

export const cashierTransactionSliceSelector = (state: any): any => state[cashierTransactionReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.isLoading,
);

export const isSavingSliceSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.isSaving,
);

export const typeSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.type,
);

export const methodSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.method,
);

export const bonusSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.bonus,
);

export const playerNumberSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.player_number,
);

export const accountSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.account,
);

export const amountSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.amount,
);

export const nameSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.name,
);

export const fingerprintSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.fingerprint,
);

export const ipSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.ip,
);

export const transactionRequestSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.transactionRequest,
);

export const rolloverSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.rollover,
);

export const paymentMethodsSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.paymentMethods,
);

export const onlineHoursSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.onlineHours,
);

export const availableBonusesSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.availableBonuses,
);

export const bankReferenceSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.bankReference,
);

export const transactionStatusSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.transactionStatus,
);

export const cbioAllowedSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.cbioAllowed,
);

export const smsCodeCheckSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.smsCodeCheck,
);

export const freeFreeTimesSelector = createSelector(
  cashierTransactionSliceSelector,
  (cashierTransactionSlice: CashierTransactionSliceType) => cashierTransactionSlice.feeFreeTime,
);
