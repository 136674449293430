import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'ruleSelectionSlice';

export interface RuleSelectionSliceType {
  isLoading: boolean;
  isSaving: boolean;
  rules: any[];
  categories: any[];
}

export const initialState: RuleSelectionSliceType = {
  isLoading: false,
  isSaving: false,
  rules: [],
  categories: [],
};

export const ruleSelectionReducer = createAndMergeSliceReducer(sliceName, initialState);
