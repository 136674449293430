import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_IP = `SET_TRANSACTION_IP${suffix}`;

export const setTransactionIpAction = createAction<any>(SET_TRANSACTION_IP);

const reduceHandlers = {
  [SET_TRANSACTION_IP]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    ip: action.payload,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
