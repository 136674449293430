import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'userSlice';

export interface UserSliceType {
  isLoading: boolean;
  isLoggedIn: boolean;
  isPseudoLoggedIn: boolean;
  error?: string;
  loggedInUser?: any;
  signupSuccess: boolean;
  inviteSuccess: boolean;
  sendOtpSuccess: boolean;
  verifyOtpSuccess: boolean;
  inviteCodeInvalid: boolean;
  inviteDetails: any;
  resetPasswordSuccess: boolean;
  userBalance: any;
  tourSteps: any;
  userHistory: any;
  bonusHistory: any;
  transactionHistory: any;
  userRaf: any;
  userRafCode: any;
  needCashierForward: boolean;
  signupUsername: string | null;
  agentData: any | null;
}

const getSessionUser = () => {
  const user = localStorage.getItem('user');
  if (user !== '' && user !== null) {
    return JSON.parse(user);
  } else {
    return {};
  }
};

const getSessionToken = () => {
  const token = localStorage.getItem('token');
  if (token !== null && token !== '') {
    return true;
  } else {
    return false;
  }
};

export const initialState: UserSliceType = {
  isLoading: false,
  isLoggedIn: getSessionToken(),
  isPseudoLoggedIn: false,
  error: undefined,
  loggedInUser: getSessionUser(),
  signupSuccess: false,
  inviteSuccess: false,
  sendOtpSuccess: false,
  verifyOtpSuccess: false,
  inviteCodeInvalid: true,
  inviteDetails: {},
  resetPasswordSuccess: false,
  userBalance: {},
  tourSteps: {},
  userHistory: {},
  bonusHistory: {},
  transactionHistory: {},
  userRaf: null,
  userRafCode: null,
  needCashierForward: true,
  signupUsername: null,
  agentData: null,
};

export const userReducer = createAndMergeSliceReducer(sliceName, initialState);
