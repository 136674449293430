import { connect } from 'react-redux';
import { loginAction } from '../../../api/user/actions/login-action';
import { LoginPage, ExternalProps, ExternalActionProps } from './login-page.component';
import { isLoadingSelector, isLoggedInSelector, errorSelector } from '../../../api/user/user.selector';
import { querySelector } from '../../../shared/router/router.selector';
import { impersonatePlayerAction } from '../../../api/user/actions/impersonate-user.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    isLoggedIn: isLoggedInSelector(state),
    error: errorSelector(state),
    query: querySelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  loginAction,
  impersonatePlayerAction,
};

export const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
