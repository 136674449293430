import React, { useEffect } from 'react';
import { Button, CardContent, Container, Grid, CircularProgress, FormLabel, Card, Avatar, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import { getHistory } from '../../../utils/history.util';
import { Loading } from '../../../shared/loading/loading.component';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 10,
    marginBottom: '2.5rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  cardContent: {
    textAlign: 'center',
    // backgroundColor: '#121212',
  },
  methodAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    paddingTop: theme.spacing(),
  },
  alertBody: {
    width: '100%',
  },
  alertContainer: {
    marginBottom: '1rem',
  },
}));

export interface ExternalProps {
  user: any;
  paymentMethods: any;
  isLoading: boolean;
  type: any;
  hours: any;
}

export interface ExternalActionProps {
  setTransactionMethodAction: (method: any) => void;
  getOnlineHoursAction: (type: any) => void;
  getPaymentMethodsAction: (x: { playerNumber: any; type: any }) => void;
}

export const TransactionMethodPage = (props: ExternalProps & ExternalActionProps) => {
  const { user, paymentMethods, isLoading, type, hours, setTransactionMethodAction, getPaymentMethodsAction, getOnlineHoursAction } = props;
  const classes = useStyles();

  useEffect(() => {
    if (type == null || type == undefined) {
      getHistory().push('/cashier');
    }
    getPaymentMethodsAction({ playerNumber: user.username, type: type });
    getOnlineHoursAction(type);
  }, []);

  return (
    <React.Fragment>
      <Loading loading={isLoading} />

      <Container className={classes.container} maxWidth='sm'>
        <FormLabel component='legend'>
          <Typography variant='h6' classes={{ root: classes.title }} style={{ marginTop: '.5rem' }}>
            Select a {type == 1 ? 'Deposit' : 'Withdrawal'} Method
          </Typography>
        </FormLabel>
        <hr />

        {/* {hours != null && type == 2 && (
          <Alert
            severity='warning'
            classes={{
              message: classes.alertBody,
              root: classes.alertContainer,
            }}
          >
            <AlertTitle>
              <Typography variant='body1'>Cashier Hours</Typography>
            </AlertTitle>
            <Typography variant='body2'>
              Withdrawal processing hours are from: {hours.open} - {hours.close} {hours.timezone}. <br />
              Any requests outside these hours will be processed the following day.
            </Typography>
          </Alert>
        )} */}

        {!isLoading && paymentMethods.length < 2 && type == 1 && (
          <Alert
            severity='info'
            classes={{
              message: classes.alertBody,
              root: classes.alertContainer,
            }}
          >
            <AlertTitle>
              <Typography variant='body1'>Want More Deposit Methods?</Typography>
            </AlertTitle>
            <Typography variant='body2'>
              Verify your identity via Live Chat today to receive access to additional deposit methods like Debit/Credit Card, Zelle, Paypal
              and much more!
            </Typography>
          </Alert>
        )}

        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color='secondary' style={{ marginTop: '1rem' }} />
            <Typography variant='subtitle2' style={{ color: 'white', fontWeight: 'bold' }}>
              Loading Methods...
            </Typography>
          </div>
        ) : (
          <Grid container spacing={3}>
            {!isLoading &&
              paymentMethods.map((method: any) => (
                <Grid item xs={6} key={method.id} className={`tour_cashier-${method.name.replace(/\s/g, '').toLowerCase()}`}>
                  <Card
                    onClick={() => {
                      setTransactionMethodAction(method);
                      if (type == 1) {
                        getHistory().push('/cashier/bonus');
                      } else {
                        getHistory().push('/cashier/info');
                      }
                    }}
                  >
                    <CardContent classes={{ root: classes.cardContent }}>
                      <Avatar className={classes.methodAvatar} src={method.image} />
                      <Typography variant='h5' gutterBottom>
                        {method.name}
                      </Typography>

                      <div style={{ textAlign: 'left' }}>
                        <Typography variant='body2'>Fee: {method.fee}%</Typography>
                        <Typography variant='body2'>
                          Min: ${Intl.NumberFormat().format(type == 1 ? method.deposit_min : method.withdrawal_min)}
                        </Typography>
                        <Typography variant='body2'>
                          Max: ${Intl.NumberFormat().format(type == 1 ? method.deposit_max : method.withdrawal_max)}
                        </Typography>
                      </div>

                      {hours != null && (method.name == 'Zelle' || method.name == 'Apple Pay') && type == 1 && (
                        <Alert
                          severity='warning'
                          classes={{
                            message: classes.alertBody,
                            root: classes.alertContainer,
                          }}
                          style={{ marginTop: '1rem' }}
                        >
                          <AlertTitle>
                            <Typography variant='body1'>Manual Method</Typography>
                          </AlertTitle>
                          <Typography variant='body2'>
                            {method.name} processing hours are from: {hours.open} - {hours.close} {hours.timezone}. <br />
                            Any requests outside these hours will be processed the following day.
                          </Typography>
                        </Alert>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}

        <div className={classes.button}>
          <Button
            variant='outlined'
            onClick={() => {
              getHistory().goBack();
            }}
          >
            <Typography>Back</Typography>
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};
