import React from 'react';
import { Button, Paper, TextField, Typography, makeStyles, Grid, useMediaQuery } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  shrinkLogo: {
    maxWidth: 100,
    height: 'auto',
    margin: '0px auto',
    display: 'block',
  },
  moveForgetPasswordToRight: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: 20,
  },
  adjustEmailMargin: {
    marginTop: 30,
    marginBottom: 30,
  },
  centerForgetPassword: {
    textAlign: 'center',
    marginBottom: 20,
  },
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.png` + ')',
  },
  styleForgetPasswordParagraph: {
    textAlign: 'center',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: 450,
    backgroundColor: '#101010',
    borderRadius: '25px',
  },
  form: {
    padding: 24,
  },
});
export interface ExternalProps {
  isLoading: boolean;
  error?: string;
  loggedUser: any;
  isVerified: boolean;
  inviteDetails: any;
}
export interface ExternalActionProps {
  sendOtpAction: (username: string) => void;
  verifyOtpAction: (data: any) => void;
}

export function VerifyMobilePage(props: ExternalActionProps & ExternalProps) {
  const { isLoading, sendOtpAction, loggedUser, verifyOtpAction, isVerified, inviteDetails } = props;
  const [otp, setOtp] = React.useState('');
  const [progress, setProgress] = React.useState(60);
  const classes = useStyles();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress <= 0 ? 0 : prevProgress - 1));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    sendOtpAction(loggedUser.username);
  }, []);

  const resendOTP = () => {
    sendOtpAction(loggedUser.username);
    setProgress(60);
  };
  React.useEffect(() => {
    if (isVerified) {
      setTimeout(() => {
        const loginForm: HTMLFormElement = document.forms[0];
        loginForm.submit();
      }, 500);
    }
  }, [isVerified]);

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={3}>
          <div className={classes.form}>
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
            <Typography className={classes.centerForgetPassword} variant='h5'>
              Verify One Time Password
            </Typography>
            <Typography className={classes.styleForgetPasswordParagraph} variant='subtitle2'>
              An One Time Password has been sent to registered mobile number. Please verify youself.
            </Typography>
            <form
              style={{ display: 'none' }}
              noValidate
              name='loginForm'
              method='post'
              action={
                useMediaQuery('(max-width: 720px)')
                  ? 'https://mobile.vipclub.lv/clients/AddOns/LoginVerify.aspx'
                  : 'https://hz.vipclub.lv/Login.aspx'
              }
            >
              <TextField name='Account' value={inviteDetails.AccountName} />
              <TextField name='password' value={loggedUser.text_password} />
            </form>
            <form
              noValidate
              onSubmit={e => {
                e.preventDefault();
                verifyOtpAction({
                  username: loggedUser.username,
                  code: otp,
                });
              }}
            >
              <TextField
                id='otp'
                name='otp'
                className={classes.adjustEmailMargin}
                variant='outlined'
                required
                fullWidth
                label='OTP'
                autoComplete='otp'
                value={otp}
                onChange={event => {
                  setOtp(event.target.value);
                }}
                autoFocus
              />
              <Grid container justify='space-between' alignItems='center'>
                <Button size='large' type='submit' variant='contained' color='primary' disabled={isLoading}>
                  Validate
                </Button>
                <div>
                  <Chip label='Resend OTP' disabled={progress > 0 || isLoading} clickable color='primary' onClick={() => resendOTP()} />
                  &nbsp;&nbsp;00:{progress < 10 ? '0' + progress : progress}
                </div>
              </Grid>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}
