import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import StarsIcon from '@material-ui/icons/Stars';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ListItemAvatar } from '@material-ui/core';
import clsx from 'clsx';

const drawerWidth = 240;
const drawerRightWidth = 500;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  leftNavarrow: {
    padding: '5px',
    backgroundColor: '#fff',
    color: '#000',
    position: 'absolute',
    zIndex: 1024,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  leftNavarrowShift: {
    left: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 'auto',
    borderRight: 0,
    backgroundColor: '#000',
    marginBottom: '75px',
    zIndex: 1099,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: '#000',
    [theme.breakpoints.up('md')]: {
      minHeight: '48px',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  serachTextField: {
    paddingLeft: '10px',
    '&>.MuiInput-underline': {
      '&::before, &::after': {
        borderBottom: 0,
      },
    },
  },
  betLiveNow: {
    display: 'flex',
    backgroundImage: 'linear-gradient(to right, #c8a964, #bc8818, #c8a964)',
    borderRadius: '7px',
    padding: '10px 5px 10px 10px',
    justifyContent: 'space-between',
  },
  nestedOpenList: {
    paddingLeft: theme.spacing(4),
  },
  disabledListItem: {
    color: '#999',
  },
  todayGame: {
    width: '100%',
    backgroundColor: '#000',
    marginBottom: '170px',
    '& span': {
      fontSize: '12px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '14px',
    },
  },
  middleSection: {
    width: 'calc(100% - 740px)',
  },
  middleHeader: {
    height: '48px',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px',
  },
  gameBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    height: '3rem',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '2.5rem',
      marginRight: '1.2rem',
    },
    '& p': {
      fontSize: '25px',
      fontWeight: 800,
    },
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      padding: '.9rem 5rem',
      marginRight: '1rem',
      backgroundColor: '#fff',
      color: '#000',
      clipPath: 'polygon(50px 0%, 0% 100%, 100% 50px, calc(100% - 50px) 100%, 100% 0%)',
    },
  },
  tab: {
    minWidth: 180,
    color: '#707070',
  },
  appBg: {
    backgroundColor: '#2d2d2d',
  },
  betTable: {
    '& th, td': {
      padding: '5px 16px',
    },
    '& th.bg, td.bg': {
      backgroundColor: '#2d2d2d',
    },
  },
  drawerRight: {
    width: drawerRightWidth,
    flexShrink: 0,
  },
  drawerRightPaper: {
    width: drawerRightWidth,
    top: 'auto',
  },
  drawerRightHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  rightTopTab: {
    backgroundColor: '#000',
    '& button': {
      width: '50%',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: '10px',
      },
    },
  },
  innerRightTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
    paddingRight: '10px',
    paddingLeft: '10px',
    backgroundColor: theme.palette.primary.main,
    '& span': {
      padding: '3px 15px',
      borderRadius: '5px',
      backgroundColor: '#fff',
      color: '#000',
      '&.selected': {
        backgroundColor: '#000',
        color: theme.palette.primary.main,
      },
    },
  },
  rightRiskSelectionGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#000',
    '& .MuiFilledInput-underline': {
      borderRadius: '5px',
      '&::after, &::before': {
        borderBottom: 0,
      },
      '& input': {
        padding: '5px 10px',
      },
    },
  },
  boxNugget: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#000',
    margin: '10px 20px',
    padding: '5px 20px',
    borderRadius: '10px',
  },
  nuggetText: {
    fontSize: '10px',
  },
  nuggetCloseIcon: {
    fontSize: '14px',
    marginRight: '5px',
  },
  spreadText: {
    fontSize: '14px',
    color: '#666',
  },
  vsText: {
    fontSize: '16px',
    marginBottom: '5px',
    fontWeight: 600,
  },
  nuggetsTextInput: {
    marginRight: '10px',
  },
  nuggetHeadings: {
    marginTop: '10px',
  },
  underlineNuggetText: {
    fontSize: '14px',
    fontWeight: 600,
    textDecoration: 'underline',
  },
  freePlayText: {
    fontSize: '14px',
    fontWeight: 600,
  },
  imageAvatar: {
    minWidth: '20px',
    width: '20px',
    marginRight: '10px',
  },
  gameIcon: {
    width: '14px',
    height: '14px',
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    opacity: '1!important',
  },
}));

interface LeftNavProps {
  open: boolean;
  games: any[];
  getGamesByLeague: (data: any, index: number) => void;
  setOpen: (a: boolean) => void;
  drawarOpenDefault: boolean;
  search: string;
  setSearch: (x: string) => void;
  searchGame: (x: string) => void;
  selectedGame: any;
}

export const LeftNav = ({
  open,
  games,
  getGamesByLeague,
  setOpen,
  drawarOpenDefault,
  search,
  setSearch,
  searchGame,
  selectedGame,
}: LeftNavProps) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState<number[]>([]);

  const handleClick = (listNumber: number) => {
    if (menuOpen.indexOf(listNumber) !== -1) {
      const newOpenedMenu = menuOpen.filter((x: number) => x != listNumber);
      setMenuOpen(newOpenedMenu);
      return;
    }
    setMenuOpen(menuOpen.concat([listNumber]));
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <TextField
            placeholder='Search'
            className={classes.serachTextField}
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              searchGame(e.target.value);
            }}
          />
          <SearchIcon onClick={() => searchGame(search)} />
        </div>
        {/* <div className={classes.betLiveNow}>
          <SearchIcon />
          <Typography>BET LIVE NOW</Typography>
          <ChevronRightIcon />
        </div> */}
        {/* <List>
          <ListItem className={classes.disabledListItem}>
            <ListItemIcon>
              <StarsIcon className={classes.disabledListItem} />
            </ListItemIcon>
            <ListItemText primary='FAVORITES' />
          </ListItem>
          <ListItem className={classes.nestedOpenList}>
            <ListItemIcon>
              <SportsEsportsIcon />
            </ListItemIcon>
            <ListItemText primary='MAIN SPORTS' />
          </ListItem>
          <ListItem className={classes.disabledListItem}>
            <ListItemIcon>
              <StarsIcon className={classes.disabledListItem} />
            </ListItemIcon>
            <ListItemText primary='BASE BALL' />
          </ListItem>
          <ListItem className={classes.nestedOpenList}>
            <ListItemIcon>
              <SportsBasketballIcon />
            </ListItemIcon>
            <ListItemText primary='BASKET BALL' />
          </ListItem>
        </List> */}
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          subheader={
            <ListSubheader component='div' id='nested-list-subheader'>
              TODAYS GAME
            </ListSubheader>
          }
          className={`${classes.todayGame} tour_sportsbook-leftNav`}
        >
          {games.map((item, i) => {
            return (
              <React.Fragment key={'g' + i + item.idgm}>
                <ListItem button onClick={() => handleClick(i)}>
                  <ListItemAvatar className={classes.imageAvatar}>
                    <img src={item.thumb} className={classes.gameIcon} />
                  </ListItemAvatar>
                  <ListItemText primary={item.value} />
                  {menuOpen && menuOpen.indexOf(i) >= 0 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={menuOpen && menuOpen.indexOf(i) >= 0} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {Array.isArray(item.league) &&
                      item.league.map((league: any, index: number) => {
                        return (
                          <ListItem
                            button
                            className={clsx(classes.nestedOpenList, {
                              [classes.tabActive]: Array.isArray(selectedGame) && selectedGame.indexOf(league.id) >= 0,
                            })}
                            key={'l' + i + league.id}
                            onClick={() => {
                              getGamesByLeague(league, index);
                              drawarOpenDefault ? setOpen(false) : setOpen(true);
                            }}
                            disabled={Array.isArray(selectedGame) && selectedGame.indexOf(league.id) >= 0}
                          >
                            <ListItemText primary={league.desc} />
                            <StarsIcon />
                          </ListItem>
                        );
                      })}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
        </List>
        {/* <List>
          <ListItem>
            <ListItemIcon>
              <SportsEsportsIcon />
            </ListItemIcon>
            <ListItemText primary='MAIN SPORTS' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SportsBasketballIcon />
            </ListItemIcon>
            <ListItemText primary='BASKET BALL' />
          </ListItem>
        </List> */}
      </Drawer>
    </div>
  );
};
