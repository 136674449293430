import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';
import { getUserBalanceAction } from '../../user/actions/get-balance.action';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const OPEN_CASINO_GAME = `OPEN_CASINO_GAME${suffix}`;

export const openCasinoGameAction = createAction<any>(OPEN_CASINO_GAME);

const OPEN_CASINO_GAME_SUCCESS = `OPEN_CASINO_GAME_SUCCESS${suffix}`;
const openCasinoGameSuccessAction = createAction<any[]>(OPEN_CASINO_GAME_SUCCESS);

const OPEN_CASINO_GAME_ERROR = `OPEN_CASINO_GAME_ERROR${suffix}`;
const openCasinoGameErrorAction = createAction<string>(OPEN_CASINO_GAME_ERROR);

function* openCasinoGameWatcher(): SagaIterator {
  yield takeEvery(OPEN_CASINO_GAME, openCasinoGameWorker);
}
mergeSaga(openCasinoGameWatcher);

function* openCasinoGameWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(openCasinoGameApi, action.payload);
    if (result.error_code == 0) {
      yield put(openCasinoGameSuccessAction(result));
      if (action.payload.device_id != 1) {
        const newWindow = window.open(result.lauch_url);
        if (newWindow == null || typeof newWindow == 'undefined') {
          alert('Please disable your pop-up blocker and click the play button again.');
        } else {
          newWindow.focus();
        }

        const timer = setInterval(function () {
          if (newWindow != null && newWindow.closed) {
            clearInterval(timer);
            getUserBalanceAction();
            console.log('Pop up closed');
          }
        }, 1000);
      }
    } else {
      console.log('failed launching game.');
    }
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(openCasinoGameErrorAction(e));
  }
}

const openCasinoGameApi = (x: any): Request => {
  return vipApi(`/ptpi/initProvider`, 'post', {
    device_id: x.device_id,
    site_id: x.site_id ?? 1,
    provider_id: x.provider_id,
    game_id: x.game_id,
    is_freeplay: x.is_freeplay,
  });
};

const reduceHandlers = {
  [OPEN_CASINO_GAME]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [OPEN_CASINO_GAME_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    gameUrl: action.payload.lauch_url,
  }),
  [OPEN_CASINO_GAME_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
