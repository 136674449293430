import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, AppBar } from '@material-ui/core';
import { LeftNav } from '../../shared/left-nav/left-nav.component';
import { WagerLine } from '../../shared/wager-line/wager-line.component';
import { RightNav } from '../../shared/right-nav/right-nav.component';
import { Loading } from '../../shared/loading/loading.component';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { BET_STEP, PLAY_LINE, WAGER_TYPE, WAGER_TYPE as WT } from '../../enum';
import { DGS_BET_TYPE_LOGIC as DBTL } from '../../enum/betslip/dgs-bet-type-logic';
import { showToast } from '../../utils/toast/show-errors';
import { getHistory } from '../../utils/history.util';
import { VipTicketIcon } from '../../shared/icons/ticket-icon';
import { WagerServiceClient } from '../../shared/services/wager-service';
import { IWagerLine } from '../../models/wager-lines';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  rightWingArrow: {
    position: 'absolute',
    right: 0,
    padding: '5px',
    backgroundColor: '#fff',
    color: '#000',
    zIndex: 1024,
  },
  rightWingArrowShift: {
    right: '90%',
    zIndex: 1201,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  mobileAppBar: {
    marginTop: '56px',
    '& .MuiToolbar-regular': {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: 'auto',
      padding: 0,
    },
  },
  noBorderRadius: {
    borderRadius: 0,
  },
  playerLines: {
    position: 'absolute',
    // marginRight: '50.5%',
    color: '#1f4d72',
    fontWeight: 900,
    top: 7,
    left: 21,
  },
}));

export interface ExternalActionProps {
  getLeftSidebarAction: (username: string) => void;
  getMiddleSectionAction: (data: any) => void;
  placeBetAction: (data: any) => void;
  previewBetAction: (data: any) => void;
  createBetAction: (data: any) => void;
  getUserBalanceAction: () => void;
  getTeasersAction: () => void;
  setNeedCashierFowardAction: (status: any) => void;
  playerLinesAction: (status: any) => void;
  betResultsAction: (status: any) => void;
  leagueAction: (status: any) => void;
  wagerTypeAction: (status: any) => void;
  stepWagerAction: (status: any) => void;
  teaserAction: (status: any) => void;
  getPendingBetsAction: () => void;
  removeMiddleSectionAction: (x: any) => void;
  wagerLinesAction: (data: any) => void;
  rightOpenAction: (data: boolean) => void;
}

export interface ExternalProps {
  games: any;
  lines: any;
  user: any;
  isLoading: boolean;
  betPlaced: boolean;
  betResponses: any;
  previewBetResponses: any;
  createBetResponses: any;
  userBalance: any;
  teasers: any;
  isTvOn: boolean;
  needCashierForward: boolean;
  tvUrl: string;
  isTvSetUp: boolean;
  playerLines: any[];
  betResults: any;
  league: any;
  wagerType: WAGER_TYPE;
  stepWager: BET_STEP;
  teaser: any;
  pendingBets: any;
  pendingBetsLoading: boolean;
  rightOpen: boolean;
}

function drawarOpenDefault(): boolean {
  return window.innerWidth >= 1280;
}

export const SportsBookPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { rightOpen, rightOpenAction } = props;

  const [leftOpen, setLeftOpen] = useState<boolean>(drawarOpenDefault());
  const [search, setSearch] = useState('');
  const [filteredGames, setFilteredGames] = useState([]);
  // const [wagerType, wagerTypeAction] = React.useState(WT.STRAIGHT);
  // const [playerLines, playerLinesAction] = React.useState<any[]>([]);
  // const [stepWager, stepWagerAction] = React.useState<BET_STEP>(BET_STEP.INIT);
  // const [betResults, betResultsAction] = React.useState<any>({});
  // const [league, leagueAction] = React.useState<any>(null);
  // const [teaser, teaserAction] = React.useState<any>(null);
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
  const [riskall, riskAllSelection] = React.useState<any>({ amount: null });
  const [game, setGame] = React.useState<any>([]);

  /* Code Refactor */
  const [collapsed, setCollapsed] = React.useState<string[]>([]);
  const [innerCollapse, setInnerCollapse] = React.useState<string[]>([]);
  const [optionsWager, setOptionsWager] = React.useState<any>({ ignoreStepWager: false });
  const [tempPlayerLines, setTempPlayerLines] = React.useState<any>([]);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    rightOpenAction(drawarOpenDefault());

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;

  const manageInnerCollapse = (x: string) => {
    const newCollapsed = innerCollapse.concat([x]);
    setInnerCollapse(newCollapsed);
  };

  const manageReverseInnerCollapse = (x: string) => {
    const newCollapsed = innerCollapse.filter((y: string) => y != x);
    setInnerCollapse(newCollapsed);
  };

  const collapseAction = (x: string) => {
    const newCollapsed = collapsed.concat([x]);
    setCollapsed(newCollapsed);
  };
  const collapseReverseAction = (x: string) => {
    const newCollapsed = collapsed.filter((y: string) => y != x);
    setCollapsed(newCollapsed);
  };

  const {
    removeMiddleSectionAction,
    getLeftSidebarAction,
    games,
    lines,
    isLoading,
    previewBetAction,
    placeBetAction,
    user,
    previewBetResponses,
    createBetAction,
    createBetResponses,
    betResponses,
    // getUserBalanceAction,
    userBalance,
    getTeasersAction,
    teasers,
    isTvOn,
    needCashierForward,
    tvUrl,
    isTvSetUp,
    setNeedCashierFowardAction,
    // wagerType,
    playerLinesAction,
    playerLines,
    betResults,
    betResultsAction,
    league,
    leagueAction,
    wagerType,
    wagerTypeAction,
    stepWager,
    stepWagerAction,
    teaser,
    teaserAction,
    getPendingBetsAction,
    pendingBets,
    pendingBetsLoading,
    wagerLinesAction,
    // step,
    // betsResult,
    // teaser,
    // riskall,
  } = props;
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const _wagerService = WagerServiceClient;

  const searchGame = (x: string) => {
    const allGames = games.map((item: any) => {
      let newDesc = '';
      item.league.map((i: any) => {
        newDesc += `${i.desc} `;
      });
      return { ...item, desc: newDesc };
    });
    const conf = allGames.filter((it: any) => {
      if (it.value.toLowerCase().indexOf(x.toLowerCase()) >= 0 || it.desc.toLowerCase().indexOf(x.toLowerCase()) >= 0) {
        return it;
      }
    });
    setFilteredGames(conf);
  };
  //#region Calc Section
  const calculateRisk = (data: any, pValue: number) => {
    try {
      stepWagerAction(BET_STEP.COMPILED);
      let value = Number(pValue ?? 0);
      value = value >= 0 ? value : 0;
      switch (wagerType) {
        case WT.STRAIGHT:
        case WT.IFWIN: {
          const odds = Number(data.detail.NewOdds ?? data.detail.Odds);
          let risk = 0;
          if (odds > 0) {
            risk = Math.ceil((100 / odds) * value);
          } else if (odds < 0) {
            risk = Math.ceil(((odds * -1) / 100) * value);
          }
          data.wager.Risk = risk;
          break;
        }

        case WT.PARLAY:
        case WT.ROUNDROBIN:
        case WT.TEASER: {
          break;
        }

        default:
          throw new Error('Invalid WagerType');
      }
      data.wager.Win = value;
      const result = { ...betResults };
      betResultsAction(result);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateWin = (data: any, pValue: number) => {
    try {
      stepWagerAction(BET_STEP.COMPILED);
      let value = Number(pValue ?? 0);
      value = value >= 0 ? value : 0;
      console.log(data.detail);

      switch (wagerType) {
        // case WT.IFWIN:
        // case WT.IFWINORTIE:
        // case WT.IFLOSE:
        // case WT.IFLOSEORTIE:
        case WT.STRAIGHT:
        case WT.IFWIN: {
          const odds = Number(data.detail.NewOdds ?? data.detail.Odds);
          let win = 0;
          if (odds < 0) {
            win = Math.floor((100 / (odds * -1)) * value);
          } else if (odds > 0) {
            win = Math.floor((odds / 100) * value);
          }
          data.wager.Win = win;
          break;
        }

        case WT.PARLAY:
        case WT.ROUNDROBIN:
        case WT.TEASER: {
          break;
        }

        default:
          throw new Error('Invalid WagerType');
      }

      data.wager.Risk = value;
      const result = { ...betResults };
      betResultsAction(result);
    } catch (error) {
      console.error(error);
    }
  };
  //
  //Not delete this. Use for test other way to make logic dgs
  const DgsBetTypeLogic = (pWagerType: WT) => {
    switch (pWagerType) {
      case WT.IFWIN:
      case WT.IFWINORTIE:
      case WT.IFLOSE:
      case WT.IFLOSEORTIE:
      case WT.STRAIGHT: {
        return DBTL.MULTIPLE_BET;
      }

      case WT.PARLAY:
      case WT.ROUNDROBIN:
      case WT.TEASER: {
        return DBTL.UNIQUE_BET;
      }

      default: {
        return DBTL.ERROR;
      }
    }
  };

  //#region  Manage Lines Section
  const getGamesByLeague = async (item: any, pIndex: number) => {
    if (item) {
      if (pIndex != -1) {
        const line: IWagerLine = {
          Description: item.desc,
          IdLeague: item.id,
          IdSport: item.idsport,
          Loading: true,
          banner: null,
          game: null,
          Details: [],
        };
        wagerLinesAction([line]);
        const lineApi = await _wagerService.getSchedulesByUsernameAndLeagueIds({
          username: user.username,
          leagueIds: item.id,
          Utc: 0 - new Date().getTimezoneOffset() / 60,
        });
        // debugger; // eslint-disable-line no-debugger
        if (lineApi && lineApi?.data && !Array.isArray(lineApi?.data)) lineApi.data = [...[], lineApi.data];
        wagerLinesAction(lineApi?.data ?? []);
      }
      setCollapsed(game.filter((em: string) => item.id != em));
      game.push(item.id);
      setGame(game);
      leagueAction(item);
    }
  };

  // useEffect();

  const removeBetSlip = async (item: any, index: number) => {
    //item if you need.
    const line = playerLines[index];
    selectLine(line.League, line.Game, line.Line, line.OriginalPlay);
  };

  const ShowProps = async (pGame: any) => {
    //item if you need.
    if (!pGame.ShowProp) {
      if (isMobile) pGame.ShowAlternatives = false;
      pGame.ShowProp = true;
    } else pGame.ShowProp = false;
    wagerLinesAction([]);
  };

  const ShowAlternatives = async (pGame: any) => {
    //item if you need.
    if (!pGame.ShowAlternatives) {
      if (isMobile) pGame.ShowProp = false;
      pGame.ShowAlternatives = true;
    } else pGame.ShowAlternatives = false;
    wagerLinesAction([]);
  };

  const selectLine = async (pLeague: any, pGame: any, pLine: any, pPlayLine: PLAY_LINE) => {
    try {
      pGame = {
        ...pGame,
        line: null,
      };
      // const lineSelected = {
      //   league: pLeague,
      //   game: pGame,
      //   line: pLine,
      //   play: pPlayLine,
      // };
      let _tmpPoints: number;
      let _currentOdds: number;
      let _actionSelected: boolean; // Line Selected?.
      let _tmpDerivatePoints = 0;

      let _rotation: number;
      let _team: string;
      let _selection: string;

      //Default this selection
      let _tmpPlay: number = pPlayLine;

      switch (pPlayLine) {
        /*
         *   VISITOR
         */
        case PLAY_LINE.VISITORSPREAD:
          _actionSelected = pLine.VisitorSpreadSelected = !pLine.VisitorSpreadSelected;
          _currentOdds = Number(pLine.vsprdoddst);
          _tmpPoints = Number(pLine.vsprdt);
          _rotation = pGame.vnum;
          _team = pGame.vtm;
          _selection = pLine.vsprdh;
          _tmpDerivatePoints = pLine.vsop;
          break;

        case PLAY_LINE.VISITORTOTAL:
          _actionSelected = pLine.VisitorTotalSelected = !pLine.VisitorTotalSelected;
          _currentOdds = Number(pLine.ovoddst);
          _tmpPoints = Number(pLine.ovt);
          _rotation = pGame.vnum;
          _team = pGame.vtm;
          _selection = pLine.ovh;
          _tmpDerivatePoints = pLine.top;
          break;

        case PLAY_LINE.VISITORMONEYLINE:
          _actionSelected = pLine.VisitorMlSelected = !pLine.VisitorMlSelected;
          _currentOdds = Number(pLine.voddst);
          _tmpPoints = 0;
          _rotation = pGame.vnum;
          _team = pGame.vtm;
          _selection = pLine.voddsh;
          _tmpDerivatePoints = 0;
          break;
        //..........

        /*
         *   HOME
         */
        case PLAY_LINE.HOMESPREAD:
          _actionSelected = pLine.HomeSpreadSelected = !pLine.HomeSpreadSelected;
          _currentOdds = Number(pLine.hsprdoddst);
          _tmpPoints = Number(pLine.hsprdt);
          _rotation = pGame.hnum;
          _team = pGame.htm;
          _selection = pLine.hsprdh;
          _tmpDerivatePoints = pLine.hsop;
          break;

        case PLAY_LINE.HOMETOTAL:
          _actionSelected = pLine.HomeTotalSelected = !pLine.HomeTotalSelected;
          _currentOdds = Number(pLine.unoddst);
          _tmpPoints = Number(pLine.unt);
          _rotation = pGame.hnum;
          _team = pGame.htm;
          _selection = pLine.unh;
          _tmpDerivatePoints = pLine.top;
          break;

        case PLAY_LINE.HOMEMONEYLINE:
          _actionSelected = pLine.HomeMlSelected = !pLine.HomeMlSelected;
          _currentOdds = Number(pLine.hoddst);
          _tmpPoints = 0;
          _rotation = pGame.hnum;
          _team = pGame.htm;
          _selection = pLine.hoddsh;
          _tmpDerivatePoints = 0;
          break;
        //..........

        /*
         *   DRAW
         */
        case PLAY_LINE.DRAW:
          _actionSelected = pLine.DrawSelected = !pLine.DrawSelected;
          _currentOdds = Number(pLine.vspoddst);
          _tmpPoints = 0;
          _rotation = pGame.hnum + 1;
          _team = 'Draw';
          _selection = pLine.vspoddst;
          _tmpDerivatePoints = 0;
          break;
        //..........

        /*
         *   TNT
         */
        case PLAY_LINE.TNT:
          _actionSelected = pLine.TntSelected = !pLine.TntSelected;
          _currentOdds = Number(pLine.odds);
          _tmpPlay = pGame.idspt === 'PROP' ? pGame.vnum : pLine.tmnum;
          _tmpPoints = 0;
          _rotation = pLine.tmnum;
          _team = pLine.tmname;
          _selection = pLine.oddsh;
          break;
        //..........
      }
      if (_selection == '') {
        removeSelectLine({ Line: pLine });
        return;
      }

      //Exist Line?
      //NO remove this
      // prettier-ignore
      const validateLine = playerLines.find(
        (x: any) =>
          x.League.id === pLeague.id &&
          x.Game.idgm === pGame.idgm &&
          x.Play === _tmpPlay &&
          //If is TNT, validate this.
          ((x.Game.idspt == 'TNT' && x.Tnum == pLine.tmnum) ||
            //If not is TNT, validate this.
            (x.Game.idspt != 'TNT' && x.Ovt == pLine.ovt))
      );
      //....

      //Delete Line
      let newLines: any[] = [];
      if (validateLine) {
        if (_actionSelected) _actionSelected = false;
        newLines = playerLines.filter((x: any) => x != validateLine);
        playerLinesAction(newLines);
      } else {
        //Similar Object DGS
        // prettier-ignore
        newLines = [
          ...playerLines,
          ...[
            {
              League: pLeague,
              Game: pGame,
              Line: pLine,
              Play: _tmpPlay,
              Points: _tmpPoints,
              Odds: _currentOdds,
              Pitcher: 0,
              IsOnOpenBets: false,
              PointsPurchased: 0,
              Rotation: _rotation,
              Selection: _selection,
              Team: _team,
              IsTnt: pLine.isTnt,
              LineValue: _selection,
              LineOdds: _currentOdds.toString(),
              LinePoints: _tmpPoints.toString(),
              IdSport: pGame.idspt,
              ODPoints: _tmpDerivatePoints,
              //Tnum exist only in TNT,For DGS logic if not is TNT this number is 0.
              Tnum: pGame.idspt == 'TNT' ? pLine.tmnum : (pLine.vnum ?? 0),
              Ovt: pLine.ovt,
              Amount: 0,
              IsSpread: _tmpPlay == PLAY_LINE.VISITORSPREAD || _tmpPlay == PLAY_LINE.HOMESPREAD ? true : false,
              IsTotal: _tmpPlay == PLAY_LINE.VISITORTOTAL || _tmpPlay == PLAY_LINE.HOMETOTAL ? true : false,
              WinAmount: 0,
              // We need this. In directives
              IsDerivate: pLine.isDerivative,
              DerivateType: pLine.derivativeType,
              Derivatelvl: pLine.derivativeLvl,
              OriginalPlay: pPlayLine,
            },
          ],
        ];
        playerLinesAction(newLines);
      }
    } catch (error) {
      console.error(error);
    }
    //....
  };

  //unselect all selection. => delete line
  const removeSelectLine = (pPlayerLine: any) => {
    if (pPlayerLine && pPlayerLine.Line) {
      pPlayerLine.Line.VisitorSpreadSelected = false;
      pPlayerLine.Line.VisitorTotalSelected = false;
      pPlayerLine.Line.VisitorMlSelected = false;
      pPlayerLine.Line.HomeSpreadSelected = false;
      pPlayerLine.Line.HomeTotalSelected = false;
      pPlayerLine.Line.HomeMlSelected = false;
      pPlayerLine.Line.DrawSelected = false;
      pPlayerLine.Line.TntSelected = false;
    }
  };
  //....

  //#region  Manage Bets Section

  /* Wager Compile */
  const previewBets = () => {
    let request = {
      wagerType,
      openSpots: 0,
      //parse this data to array in php
      details: JSON.stringify(playerLines),
    };

    //parse this data to array in php
    if (teaser) request = { ...request, ...{ teaser: JSON.stringify(teaser) } };
    previewBetAction(request);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    let betResultOW = {};
    if (previewBetResponses && previewBetResponses.data) {
      if (previewBetResponses.data.ErrorCode == '0') {
        if (WT.TEASER == wagerType && previewBetResponses.data.wager && previewBetResponses.data.wager.length > 0)
          previewBetResponses.data.wager[0].RiskWin = '2';
        betResultsAction({
          ...betResultOW,
          compile: previewBetResponses.data,
          roundRobinCombinations: previewBetResponses.roundRobinCombinations ?? [],
          compileXml: previewBetResponses.xml,
          errors: [],
        });
        stepWagerAction(BET_STEP.COMPILED);
      } else {
        betResultsAction(
          (betResultOW = {
            ...betResultOW,
            errors: [
              {
                ErrorMsgKey: previewBetResponses.data.ErrorMsgKey,
                ErrorCode: previewBetResponses.data.ErrorCode,
                Msg: previewBetResponses.data.ErrorMsg,
              },
            ],
          }),
        );
        stepWagerAction(BET_STEP.INIT);
      }
    } else {
      betResultsAction({ ...betResultOW, errors: [{ ErrorCode: '', Msg: 'Error-0001' }] });
      stepWagerAction(BET_STEP.INIT);
    }
  }, [previewBetResponses]);

  /* Wager Confirm */
  const confirmBet = () => {
    const request = {
      wagerType,
      openSpots: 0,
      compile: JSON.stringify(betResults.compile),
      xml: betResults.compileXml,
    };
    createBetAction(request);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    if (createBetResponses && createBetResponses.data) {
      if (createBetResponses.data.ErrorCode == '0') {
        betResultsAction({ ...betResults, confirm: createBetResponses.data, confirmXml: createBetResponses.xml, errors: [] });
        stepWagerAction(BET_STEP.CONFIRM);
      } else {
        betResultsAction({
          ...betResults,
          confirm: createBetResponses.data,
          errors: [
            {
              ErrorMsgKey: createBetResponses.data.ErrorMsgKey,
              ErrorCode: createBetResponses.data.ErrorCode,
              Msg: createBetResponses.data.ErrorMsg,
            },
          ],
        });
        stepWagerAction(BET_STEP.COMPILED);
      }
    } else {
      betResultsAction({ ...betResults, errors: [{ ErrorMsgKey: '', ErrorCode: '', Msg: 'Error-0002' }] });
      stepWagerAction(BET_STEP.CONFIRM);
    }
  }, [createBetResponses]);

  /* Wager Post */
  const placeBet = () => {
    if (isLoading) return;
    const request = {
      wagerType,
      openSpots: 0,
      confirm: JSON.stringify(betResults.confirm),
      xml: betResults.confirmXml,
    };
    placeBetAction(request);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    if (betResponses && betResponses.data) {
      if (betResponses.data.ErrorCode == '0') {
        //Here change logic to show new changes, and validate if players accept's
        if (betResponses.data.LinesChanged == 1) {
          betResultsAction({ ...betResults, confirm: betResponses.data, confirmXml: betResponses.xml, errors: [] });
          stepWagerAction(BET_STEP.ACEPT_CHANGES);
        } else {
          betResultsAction({ ...betResults, post: betResponses.data, errors: [] });
          stepWagerAction(BET_STEP.POSTED);
          SaveAndRemoveAllLines();
        }
      } else {
        betResultsAction({
          ...betResults,
          post: betResponses.data,
          errors: [
            {
              ErrorMsgKey: betResponses.data.ErrorMsgKey,
              ErrorCode: betResponses.data.ErrorCode,
              Msg: betResponses.data.ErrorMsg,
            },
          ],
        });
        stepWagerAction(BET_STEP.COMPILED);
      }
    } else {
      betResultsAction({ ...betResults, errors: [{ ErrorMsgKey: '', ErrorCode: '', Msg: 'Error-0003' }] });
      stepWagerAction(BET_STEP.COMPILED);
    }
  }, [betResponses]);
  //....

  useEffect(() => {
    const value = riskall?.amount ?? 0;
    value >= 0 ? value : riskAllSelection({ ...riskall, ...{ amount: 0 } });
    try {
      if (wagerType == WT.STRAIGHT && stepWager == BET_STEP.COMPILED && betResults?.compile?.ErrorCode == '0') {
        (betResults.compile.wager || []).forEach((wager: any) => {
          if (Number(wager.detail[0].Odds ?? 0) > 0) {
            wager.Risk = value;
            calculateWin({ wager, detail: wager.detail[0] }, value);
          } else if (Number(wager.detail[0].Odds ?? 0) < 0) {
            wager.Win = value;
            calculateRisk({ wager, detail: wager.detail[0] }, value);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [riskall]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }
    let preview = true;
    if (wagerType == WT.TEASER && !teaser) {
      if ((teasers?.data?.length ?? 0) == 0) {
        showToast('Error Need Teasers', 'info');
        return;
      }
      selectTeaser(teasers.data[0]);
      return;
      // showToast('Select a Teaser Type', 'info');
      // preview = false;
    }

    if (playerLines.length > 0) {
      if ([WT.PARLAY, WT.TEASER].indexOf(wagerType) != -1 && playerLines.length < 2) {
        showToast(`Select ${2 - playerLines.length} more lines`, 'info');
        preview = false;
      }

      if (wagerType == WT.ROUNDROBIN && playerLines.length < 3) {
        showToast(`Select ${3 - playerLines.length} more lines`, 'info');
        preview = false;
      }
    } else preview = false;

    if (preview) previewBets();

    if (!optionsWager.ignoreStepWager) {
      stepWagerAction(BET_STEP.INIT);
      betResultsAction({});
    }
    setOptionsWager({ ...optionsWager, ignoreStepWager: false });
  }, [playerLines, wagerType, teaser]);

  useEffect(() => {
    getLeftSidebarAction(user.username);
    getTeasersAction();
  }, []);

  useEffect(() => {
    setFilteredGames(games);
    if (Array.isArray(games) && games.length > 0 && lines.length == 0) {
      getGamesByLeague({ id: games[0].league[0].id, desc: games[0].league[0].desc, idsport: games[0].league[0].idsport }, 1);
    }
  }, [games]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     // getGamesByLeague({ id: '1385', desc: 'UEFA - CHAMPIONS LEAGUE', idsport: 'SOC' }, 1);
  //     getGamesByLeague({ id: '1', desc: 'NFL', idsport: 'NFL' }, 1);
  //   }, 1000);
  // }, []);

  const removePlayerLines = () => {
    for (let index = 0; index < playerLines.length; index++) {
      const element = playerLines[index];
      removeSelectLine(element);
    }
    playerLinesAction([]);
  };

  const setBuyPoints = (data: any, value: any) => {
    if (data.detail) {
      data.detail.NewOdds = value ? value.LineDesc.substring(value.LineDesc.length - 4) : undefined;
      //Selection in select
      data.detail.BuyPoints = value;
      data.detail.BuyPoint = value ? value.BuyPoints : 0;
      calculateWin({ wager: data.wager, detail: data.detail }, data?.wager?.Risk);
      return;
    }

    const result = { ...betResults };
    betResultsAction(result);
  };

  const selectTeaser = (pData: any) => {
    teaserAction(pData);
  };

  const selectRoundRobin = (pData: any, pRr: any) => {
    //Selection in select
    pData.wager.RoundRobinCombination = pRr;
    pData.wager.RoundRobinCombinations = pRr.Teams ?? 0;
    betResultsAction({ ...betResults, ...{} });
  };

  const selectBetTypeAmount = (pvalue: string) => {
    if (wagerType == WT.TEASER && betResults.compile.wager) {
      betResults.compile.wager[0].RiskWin = pvalue;
      betResultsAction({ ...betResults, ...{} });
    }
  };
  const setIsBonus = (pStatus: string) => {
    if (betResults.compile && stepWager == BET_STEP.COMPILED) {
      betResults.compile.UseFreePlayAvailable = pStatus;
    }
    betResultsAction({ ...betResults });
  };
  const selectBuyPointsTeaser = (pData: any, pPoints: any) => {
    //Selection in select
    if (pData.wager) pData.wager.TeaserPointsPurchased = pPoints;
    betResultsAction({ ...betResults });
  };

  const removeGameLine = (x: any) => {
    const remain = game.filter((item: any) => item != x);
    setGame(remain);
    removeMiddleSectionAction(x);
  };

  const SaveAndRemoveAllLines = () => {
    setOptionsWager({ ...optionsWager, ignoreStepWager: true });
    setTempPlayerLines(playerLines);
    removePlayerLines();
  };
  const UseLinesOtherBet = () => {
    playerLinesAction(tempPlayerLines);
    setTempPlayerLines([]);
  };

  const closeAll = () => {
    if (isMobile) {
      setLeftOpen(false);
      rightOpenAction(false);
    }
  };

  let availBalance: any;
  if (userBalance.RealAvailBalance != undefined) {
    availBalance = userBalance.RealAvailBalance.replace(/,/g, '');
  }

  useEffect(() => {
    if (!isLoading && availBalance != undefined && availBalance < 10 && needCashierForward && !isLoading) {
      setNeedCashierFowardAction(false);
      getHistory().push('/cashier');
    }
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading && stepWager == BET_STEP.INIT} />

      {!isLoading && availBalance != undefined && availBalance < 10 && needCashierForward && getHistory().push('/cashier')}

      {!drawarOpenDefault() && (
        <React.Fragment>
          <AppBar position='fixed' className={classes.mobileAppBar}>
            <Toolbar>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  rightOpenAction(false);
                  setLeftOpen(!leftOpen);
                }}
                className={classes.noBorderRadius}
              >
                {!leftOpen ? <MenuIcon /> : <CloseIcon />} SPORTS MENU
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  setLeftOpen(false);
                  rightOpenAction(!rightOpen);
                }}
                className={classes.noBorderRadius}
              >
                <VipTicketIcon rgb={'rgb(255,255,255)'} />
                <div className={classes.playerLines}>({playerLines.length ?? 0})</div>
                &nbsp;BET SLIP
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ marginTop: '36px' }}></div>
        </React.Fragment>
      )}
      <LeftNav
        open={leftOpen}
        games={filteredGames}
        search={search}
        setSearch={setSearch}
        searchGame={searchGame}
        getGamesByLeague={getGamesByLeague}
        setOpen={setLeftOpen}
        drawarOpenDefault={!drawarOpenDefault()}
        selectedGame={game}
      />
      <WagerLine
        isTvSetUp={isTvSetUp}
        isTvOn={isTvOn}
        url={tvUrl}
        lines={lines}
        selectLine={selectLine}
        league={league}
        getGamesByLeague={getGamesByLeague}
        removeMiddleSectionAction={removeGameLine}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        innerCollapse={innerCollapse}
        setInnerCollapse={setInnerCollapse}
        manageInnerCollapse={manageInnerCollapse}
        manageReverseInnerCollapse={manageReverseInnerCollapse}
        collapseAction={collapseAction}
        collapseReverseAction={collapseReverseAction}
        ShowProps={ShowProps}
        ShowAlternatives={ShowAlternatives}
      />
      <RightNav
        wagerType={wagerType}
        setWagerType={wagerTypeAction}
        removeBetSlip={removeBetSlip}
        calculateWin={calculateWin}
        calculateRisk={calculateRisk}
        riskAllSelection={riskAllSelection}
        placeBet={placeBet}
        removePlayerLines={removePlayerLines}
        setBuyPoints={setBuyPoints}
        setIsBonus={setIsBonus}
        userBalance={userBalance}
        open={rightOpen}
        setOpen={rightOpenAction}
        teaser={teaser}
        teasers={teasers}
        selectTeaser={selectTeaser}
        step={stepWager}
        playerLines={playerLines}
        betsResult={betResults}
        confirmBet={confirmBet}
        isLoading={isLoading}
        DgsBetTypeLogic={DgsBetTypeLogic}
        selectRoundRobin={selectRoundRobin}
        isTvOn={isTvOn}
        url={tvUrl}
        isTvSetUp={isTvSetUp}
        selectBuyPointsTeaser={selectBuyPointsTeaser}
        riskall={riskall}
        getPendingBetsAction={getPendingBetsAction}
        pendingBets={pendingBets}
        pendingBetsLoading={pendingBetsLoading}
        selectBetTypeAmount={selectBetTypeAmount}
        UseLinesOtherBet={UseLinesOtherBet}
        closeAll={closeAll}
      />
    </div>
  );
};
