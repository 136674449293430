import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFoundPage } from './area/not-found/not-found-page.component';
// import { IndexPage } from './area/index-page/index-page.component';
import { LoginPageContainer } from './area/auth/login-page/login-page.container';
import { SignupPageContainer } from './area/auth/signup-page/signup-page.container';
import { AdminPrivateRouteContainer } from './shared/private-route/admin-private-route.container';
import { ForgetPasswordPageContainer } from './area/auth/forget-password-page/forgot-password-page.container';
import { SportsBookPageContainer } from './area/sportsbook/sportsbook.container';
import { LiveSportsPageContainer } from './area/live-sports/live-sports.container';
import { CasinoPageContainer } from './area/casino/casino.container';
import { HorsesPageContainer } from './area/horses/horses.container';
import { PromotionsPageContainer } from './area/promotions/promotions.container';
import { ReferAFriendPageContainer } from './area/raf/raf.container';
import { RulesPageContainer } from './area/rules/rules.container';
import { GuidesPageContainer } from './area/guides/guides.container';
import { CashierPageContainer } from './area/cashier/cashier-home-page/cashier.container';
import { VerifyMobilePageContainer } from './area/auth/verify-mobile-page/verify-mobile-page.container';
// import { LoginHorizonPageContainer } from './area/auth/login-horizon-page/login-horizon-page.container';
import { ResetPasswordPageContainer } from './area/auth/reset-password-page/reset-password-page.container';
import { PlayerHistoryPageContainer } from './area/player-history/player-history.container';
import { PendingBetsPageContainer } from './area/pending-bets/pending-bets.container';
import { TransactionMethodPageContainer } from './area/cashier/transaction-method-page/transaction-method-page.container';
import { BonusSelectPageContainer } from './area/cashier/bonus-select-page/bonus-select-page.container';
import { TransactionInfoPageContainer } from './area/cashier/transaction-info-page/transaction-info-page.container';
import { ConfirmTransactionPageContainer } from './area/cashier/confirm-transaction-page/confirm-transaction-page.container';
import { RequestReceivedPageContainer } from './area/cashier/request-received-page/request-received-page.container';
import { ESportsPageContainer } from './area/esports/esports.container';
import { CompletePagePageContainer } from './area/cashier/complete-page/complete-page.container';
import { BonusHistoryPageContainer } from './area/bonus-history/bonus-history.container';
import { TransactionHistoryPageContainer } from './area/transaction-history/transaction-history.container';
// import { LiveChatPageContainer } from './area/live-chat/live-chat.container';
import { ProfilePageContainer } from './area/profile-settings/profile-page.container';
import { AgentDashboardpageContainer } from './area/agent-dashboard/agent-page.container';
import { LobbyProviderContainer } from './area/lobby-provider/lobby-provider.container';

export const Routes = (): React.ReactElement<void> => (
  <Switch>
    <Route exact path='/signup/:code' component={SignupPageContainer} />
    <Route exact path='/login' component={LoginPageContainer} />
    {/* <Route exact path='/login-2' component={LoginHorizonPageContainer} /> */}
    <Route exact path='/forget-password' component={ForgetPasswordPageContainer} />
    <Route exact path='/reset-password/:code' component={ResetPasswordPageContainer} />
    <Route exact path='/validate-mobile' component={VerifyMobilePageContainer} />

    <AdminPrivateRouteContainer path='/racebook' component={LobbyProviderContainer} />
    <AdminPrivateRouteContainer path='/live-dealer' component={LobbyProviderContainer} />
    <AdminPrivateRouteContainer path='/props-builder' component={LobbyProviderContainer} />
    <AdminPrivateRouteContainer path='/sportsbook' component={SportsBookPageContainer} />
    <AdminPrivateRouteContainer path='/live-sports' component={LiveSportsPageContainer} />
    <AdminPrivateRouteContainer path='/esports' component={ESportsPageContainer} />
    <AdminPrivateRouteContainer path='/casino' component={CasinoPageContainer} />
    <AdminPrivateRouteContainer path='/horses' component={HorsesPageContainer} />
    <AdminPrivateRouteContainer path='/promotions' component={PromotionsPageContainer} />
    <AdminPrivateRouteContainer path='/refer-a-friend' component={ReferAFriendPageContainer} />
    <AdminPrivateRouteContainer path='/rules' component={RulesPageContainer} />
    <AdminPrivateRouteContainer path='/guides' component={GuidesPageContainer} />
    <AdminPrivateRouteContainer path='/player-history' component={PlayerHistoryPageContainer} />
    <AdminPrivateRouteContainer path='/pending-bets' component={PendingBetsPageContainer} />
    <AdminPrivateRouteContainer path='/bonus-history' component={BonusHistoryPageContainer} />
    <AdminPrivateRouteContainer path='/transaction-history' component={TransactionHistoryPageContainer} />
    {/* <AdminPrivateRouteContainer path='/live-chat' component={LiveChatPageContainer} /> */}
    <AdminPrivateRouteContainer path='/profile' component={ProfilePageContainer} />
    <AdminPrivateRouteContainer path='/agent' component={AgentDashboardpageContainer} />

    {/* Cashier Routes */}
    <AdminPrivateRouteContainer path='/cashier/complete' component={CompletePagePageContainer} />
    <AdminPrivateRouteContainer path='/cashier/payment' component={RequestReceivedPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/confirm' component={ConfirmTransactionPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/info' component={TransactionInfoPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/bonus' component={BonusSelectPageContainer} />
    <AdminPrivateRouteContainer path='/cashier/method' component={TransactionMethodPageContainer} />
    <AdminPrivateRouteContainer path='/cashier' component={CashierPageContainer} />

    <AdminPrivateRouteContainer path='/' component={SportsBookPageContainer} />
    <Route path='' component={NotFoundPage} />
  </Switch>
);
