import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_RAF_CODE = `GET_RAF_CODE${suffix}`;

export const getRafCodeAction = createAction(GET_RAF_CODE);

const GET_RAF_CODE_SUCCESS = `GET_RAF_CODE_SUCCESS${suffix}`;
const getRafCodeSuccessAction = createAction<any[]>(GET_RAF_CODE_SUCCESS);

const GET_RAF_CODE_ERROR = `GET_RAF_CODE_ERROR${suffix}`;
const getRafCodeErrorAction = createAction<string>(GET_RAF_CODE_ERROR);

function* getRafCodeWatcher(): SagaIterator {
  yield takeEvery(GET_RAF_CODE, getRafCodeWorker);
}
mergeSaga(getRafCodeWatcher);

function* getRafCodeWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getRafCodeApi, action.payload);
    yield put(getRafCodeSuccessAction(result.data));
  } catch (e) {
    yield put(getRafCodeErrorAction(e));
  }
}

const getRafCodeApi = (username: any): Request => {
  return vipApi(`/CreateInvitationCode`, 'post', {
    username: username,
  });
};

const reduceHandlers = {
  [GET_RAF_CODE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_RAF_CODE_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    userRafCode: action.payload.invitation_code,
  }),
  [GET_RAF_CODE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
