import React from 'react';
import { Button, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import { RoutesDictionary, RoutesEnum } from '../../../models/routes';
import { getHistory } from '../../../utils/history.util';

export interface ExternalActionProps {
  forgotPasswordAction: (email: string) => void;
}

const useStyles = makeStyles({
  shrinkLogo: {
    maxWidth: 100,
    height: 'auto',
    margin: '0px auto',
    display: 'block',
  },
  moveForgetPasswordToRight: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: 20,
  },
  adjustEmailMargin: {
    marginTop: 30,
    marginBottom: 30,
  },
  centerForgetPassword: {
    textAlign: 'center',
    marginBottom: 20,
  },
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.png` + ')',
  },
  styleForgetPasswordParagraph: {
    textAlign: 'center',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: 450,
    backgroundColor: '#050505',
  },
  form: {
    padding: 24,
  },
});

const { path } = RoutesDictionary[RoutesEnum.LoginPage];

export function ForgetPasswordPage(props: ExternalActionProps): React.ReactElement<ExternalActionProps> {
  const { forgotPasswordAction } = props;
  const [email, setEmail] = React.useState('');
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={3}>
          <div className={classes.form}>
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
            <Typography className={classes.centerForgetPassword} variant='h5'>
              Find Your Account
            </Typography>
            <Typography className={classes.styleForgetPasswordParagraph} variant='subtitle2'>
              Enter your email address and we&apos;ll send you a link to reset your password.
            </Typography>
            <form noValidate>
              <TextField
                id='email'
                name='email'
                className={classes.adjustEmailMargin}
                variant='outlined'
                required
                fullWidth
                label='Email'
                autoComplete='email'
                value={email}
                onChange={event => {
                  setEmail(event.target.value);
                }}
                autoFocus
              />
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    forgotPasswordAction(email);
                  }}
                >
                  Reset Password
                </Button>
              </div>
              <Button
                fullWidth
                color='primary'
                onClick={() => {
                  getHistory().push(path);
                }}
              >
                Cancel
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}
