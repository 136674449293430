import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const GET_CASINO_GAMES = `GET_CASINO_GAMES${suffix}`;

export const getCasinoGamesAction = createAction<any>(GET_CASINO_GAMES);

const GET_CASINO_GAMES_SUCCESS = `GET_CASINO_GAMES_SUCCESS${suffix}`;
const getCasinoGamesSuccessAction = createAction<any[]>(GET_CASINO_GAMES_SUCCESS);

const GET_CASINO_GAMES_ERROR = `GET_CASINO_GAMES_ERROR${suffix}`;
const getCasinoGamesErrorAction = createAction<string>(GET_CASINO_GAMES_ERROR);

function* getCasinoGamesWatcher(): SagaIterator {
  yield takeEvery(GET_CASINO_GAMES, getCasinoGamesWorker);
}
mergeSaga(getCasinoGamesWatcher);

function* getCasinoGamesWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getCasinoGamesApi, action.payload);
    yield put(getCasinoGamesSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getCasinoGamesErrorAction(e));
  }
}

const getCasinoGamesApi = (x: any): Request => {
  return vipApi(`/ptpi/categoriesWithGames`, 'post', {
    device_id: x.deviceId,
    categories: x.category,
    providers: x.providerId,
  });
};

const reduceHandlers = {
  [GET_CASINO_GAMES]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_CASINO_GAMES_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoCategories: action.payload?.data,
    casinoGames: action?.payload?.data ? action.payload.data[0].games : [],
  }),
  [GET_CASINO_GAMES_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
