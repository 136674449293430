import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
    '& table': {
      width: '100%',
      marginBottom: '1rem',
      color: '#fff',
      border: '1px solid #dee2e6',
    },
    '& table td, & table th': {
      padding: '.75rem',
      verticalAlign: 'top',
      borderTop: '1px solid #dee2e6',
      border: '1px solid #dee2e6',
    },
    '& table thead th': {
      verticalAlign: 'bottom',
      borderBottom: '2px solid #dee2e6',
      borderBottomWidth: '2px',
    },
    '& table tbody+tbody': {
      borderTop: '2px solid #dee2e6',
    },
  },
});

// export interface ExternalActionProps {}

// export interface ExternalProps {}

export const GeneralRules = (props: { rules: any; category: any }) => {
  const classes = useStyles();
  const { rules } = props;

  return (
    <div className={clsx(classes.root)}>
      {rules && rules.length > 0 && (
        <>
          <Typography variant='h5'>{rules[0].name}</Typography>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: rules[0].content }}></div>
        </>
      )}
    </div>
  );
};
