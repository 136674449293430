import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid, Container, Button, Paper, TextField } from '@material-ui/core';
import { Loading } from '../../shared/loading/loading.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useEffect } from 'react';
import { BalanceDetail } from '../../shared/account/balance-detail.component';
import { AccountLinks } from '../../shared/account/account-links.component';

const useStyles: any = makeStyles({
  root: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
  },
  dot300: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #c8a964 100%, lightgrey 0%),
                     linear-gradient(120deg, #fff 36%, #fff 30%),
                     linear-gradient(300deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(210deg, #fff 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  dot280: {
    border: '20px solid #404040',
    margin: '10px',
    borderRadius: '50%',
    width: '230px',
    height: '230px',
  },
  colorMediumText: {
    fontSize: '18px',
    color: '#c8a964',
  },
  smallText: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#666',
  },
  innerCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  midGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  moneyText: {
    color: '#c8a964',
    fontSize: '28px',
  },
  upperText: {
    color: '#777',
  },
  depositBtn: {
    color: '#fff',
    backgroundColor: '#011f63',
  },
  btnGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    paddingBottom: '50px',
  },
  tabPill: {
    padding: '10px auto',
    border: '1px solid #c8a964',
  },
  btn: {
    margin: 5,
  },
  bottomDiv: {
    backgroundColor: '#121212',
    padding: 10,
  },
  topHeader: {
    padding: 10,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  profileGrids: {
    padding: 10,
  },
});

export interface ExternalActionProps {
  getUserInfoAction: () => void;
  sendEmailCodeAction: () => void;
  verifyEmailCodeAction: (x: any) => void;
  sendOtpAction: (username: any) => void;
  verifyOtpAction: (data: any) => void;
  updateProfileAction: (data: any) => void;
}

export interface ExternalProps {
  isLoading: boolean;
  userBalance: any;
  user: any;
}

export const ProfilePage = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const {
    isLoading,
    userBalance,
    user,
    getUserInfoAction,
    sendEmailCodeAction,
    verifyEmailCodeAction,
    sendOtpAction,
    verifyOtpAction,
    updateProfileAction,
  } = props;
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [dob, setDob] = React.useState('2017-05-24');
  const [zip, setZip] = React.useState('');

  const [viewEmailCode, setViewEmailCode] = React.useState(false);
  const [emailCode, setEmailCode] = React.useState('');
  const [viewSmsCode, setViewSmsCode] = React.useState(false);
  const [smsCode, setSmsCode] = React.useState('');

  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    getUserInfoAction();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setPhone(user.phone);
      setEmail(user.email);
      if (user.dob != null) {
        setDob(user.dob);
      }
      if (user.zip != null) {
        setZip(user.zip);
      }
    }
  }, []);

  const calculate_age = (dob1: any) => {
    const today = new Date();
    const birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    let age_now = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />

      <BalanceDetail userBalance={userBalance} user={user} routeName='profile' />
      <div style={{ backgroundColor: '#000', paddingTop: '30px', paddingBottom: '40px' }}>
        <Container>
          <div style={{ margin: '0x auto', display: 'block', textAlign: 'center' }}>
            <AccountLinks routeName='profile' />
          </div>

          <Grid container direction='row' justify='center' spacing={2}>
            {/* Personal Info Section */}
            <Grid item xs={12} sm={6}>
              <Paper className='tour_profile-personalInfo'>
                <Typography variant='h6' className={classes.topHeader}>
                  <FontAwesomeIcon style={{ marginRight: '.5rem' }} icon={['fad', 'user']} /> Personal Info
                </Typography>
                <div className={classes.bottomDiv}>
                  {user.cognito_match == 1 && user.email_verified_at != null && user.phone_verified_at != null ? (
                    <>
                      {' '}
                      <Alert>
                        <AlertTitle>Account Status</AlertTitle>
                        Your account is fully verified. You have access to all payment methods and bonuses!
                      </Alert>
                      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
                    </>
                  ) : (
                    <>
                      {' '}
                      <Alert color={user.cognito_match == 1 ? 'warning' : 'info'}>
                        <AlertTitle>Account Status</AlertTitle>
                        {user.cognito_match == 1
                          ? 'Please confirm your phone and email to unlock withdrawals and fully secure your account.'
                          : 'Please verify your personal information to gain access to additional payment methods and perks!'}
                      </Alert>
                      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
                    </>
                  )}
                  <Grid container>
                    <Grid item xs={6} className={classes.profileGrids}>
                      Name:
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids}>
                      {user.first_name} {user.last_name}
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids}>
                      Account Status:
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids}>
                      {user.cognito_match == 1 ? (
                        <span style={{ color: 'green' }}>Verified</span>
                      ) : (
                        <span style={{ color: 'red' }}>Unverified</span>
                      )}
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids}>
                      Phone:
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids}>
                      {user.phone_verified_at != null ? (
                        <>
                          <FontAwesomeIcon style={{ color: 'green' }} icon={['fad', 'check-circle']} /> Confirmed
                          <br />
                          <small>
                            Confirmed at: {user.phone_verified_at_formatted ? user.phone_verified_at_formatted : user.phone_verified_at}
                          </small>
                          <br />
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon style={{ color: 'red' }} icon={['fad', 'times-octagon']} /> Unconfirmed
                        </>
                      )}
                      <br />
                      {user.phone != null ? user.phone : 'N/A'}

                      <br />
                      <br />
                      {!viewSmsCode && user.phone_verified_at == null && (
                        <span
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => {
                            sendOtpAction(user.username);
                            setViewSmsCode(true);
                          }}
                        >
                          Click here to confirm your Phone.
                        </span>
                      )}
                      <br />
                      {viewSmsCode && user.phone_verified_at == null && (
                        <>
                          <Typography variant='body1' gutterBottom>
                            Verification code has been sent to your phone.
                          </Typography>
                          <br />
                          <TextField
                            label='Verification Code'
                            variant='filled'
                            size='small'
                            fullWidth
                            onChange={(event: any) => {
                              setSmsCode(event.target.value);
                            }}
                          />
                          <div style={{ padding: 10 }}>
                            <Button
                              style={{ margin: 4 }}
                              onClick={() => {
                                sendOtpAction(user.username);
                              }}
                            >
                              Resend Code
                            </Button>
                            <Button
                              variant='contained'
                              style={{ margin: 4 }}
                              onClick={() => {
                                verifyOtpAction({
                                  code: smsCode,
                                  username: user.username,
                                });
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids}>
                      Email:
                    </Grid>
                    <Grid item xs={6} className={classes.profileGrids} zeroMinWidth>
                      {user.email_verified_at != null ? (
                        <>
                          <FontAwesomeIcon style={{ color: 'green' }} icon={['fad', 'check-circle']} /> Confirmed
                          <br />
                          <small>
                            Confirmed at: {user.email_verified_at_formatted ? user.email_verified_at_formatted : user.email_verified_at}
                          </small>
                          <br />
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon style={{ color: 'red' }} icon={['fad', 'times-octagon']} /> Unconfirmed
                        </>
                      )}
                      <br />
                      <Typography style={{ overflowWrap: 'break-word' }}>{user.email != null ? user.email : 'N/A'}</Typography>

                      <br />
                      {!viewEmailCode && user.email_verified_at == null && (
                        <span
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => {
                            sendEmailCodeAction();
                            setViewEmailCode(true);
                          }}
                        >
                          Click here to confirm your email.
                        </span>
                      )}
                      <br />
                      {viewEmailCode && user.email_verified_at == null && (
                        <>
                          <Typography variant='body1' gutterBottom>
                            Verification code has been sent to your email.
                          </Typography>
                          <br />
                          <TextField
                            label='Verification Code'
                            variant='filled'
                            size='small'
                            fullWidth
                            onChange={(event: any) => {
                              setEmailCode(event.target.value);
                            }}
                          />
                          <div style={{ padding: 10 }}>
                            <Button
                              style={{ margin: 4 }}
                              onClick={() => {
                                sendEmailCodeAction();
                              }}
                            >
                              Resend Code
                            </Button>
                            <Button
                              variant='contained'
                              style={{ margin: 4 }}
                              onClick={() => {
                                verifyEmailCodeAction({
                                  code: emailCode,
                                  username: user.username,
                                });
                              }}
                            >
                              Verify
                            </Button>
                          </div>
                        </>
                      )}
                      <br />
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper className='tour_profile-updateProfile'>
                <Typography variant='h6' className={classes.topHeader}>
                  <FontAwesomeIcon style={{ marginRight: '.5rem' }} icon={['fad', 'user-edit']} />
                  Update Profile
                </Typography>
                <div className={classes.bottomDiv}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Alert color='warning'>
                        <AlertTitle>Attention</AlertTitle>
                        You have {3 - user.is_edited} remaining profile updates.
                      </Alert>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.profileGrids}>
                      <TextField
                        id='firstName'
                        name='firstName'
                        variant='outlined'
                        required
                        fullWidth
                        label='First Name'
                        autoComplete='First Name'
                        value={firstName}
                        onChange={event => {
                          setFirstName(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.profileGrids}>
                      <TextField
                        id='lastName'
                        name='lastName'
                        variant='outlined'
                        required
                        fullWidth
                        label='Last Name'
                        autoComplete='Last Name'
                        value={lastName}
                        onChange={event => {
                          setLastName(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.profileGrids}>
                      <TextField
                        id='phone'
                        name='phone'
                        variant='outlined'
                        required
                        fullWidth
                        label='Phone'
                        autoComplete='phone'
                        value={phone}
                        onChange={event => {
                          setPhone(event.target.value);
                        }}
                      />
                      <small>Phone numbers MUST include country code. +1 for US. Ex: +1 (222) 333-4444</small>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.profileGrids}>
                      <TextField
                        id='email'
                        name='email'
                        variant='outlined'
                        required
                        fullWidth
                        label='Email'
                        autoComplete='email'
                        value={email}
                        onChange={event => {
                          setEmail(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.profileGrids}>
                      {/* <DatePicker selected={dob} onChange={date => setDob(date)} /> */}
                      <TextField
                        id='date'
                        label='Birthday'
                        type='date'
                        defaultValue='2017-05-24'
                        value={dob}
                        required
                        className={classes.textField}
                        onChange={(event: any) => {
                          setDob(event.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.profileGrids}>
                      <TextField
                        id='zip'
                        name='zip'
                        variant='outlined'
                        required
                        fullWidth
                        label='Zipcode'
                        autoComplete='zip'
                        value={zip}
                        onChange={event => {
                          setZip(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.profileGrids} style={{ textAlign: 'center' }}>
                      <Button
                        variant='contained'
                        disabled={disabled && isLoading}
                        className='tour_profile-updateBtn'
                        color='primary'
                        onClick={() => {
                          if (calculate_age(dob) < 18) {
                            alert('You must be 18 years or older to be wagering (Depending on your jurisdiction).');
                          } else {
                            setDisabled(!disabled);
                            updateProfileAction({
                              first_name: firstName,
                              last_name: lastName,
                              phone: phone,
                              email: email,
                              dob: dob,
                              zip: zip,
                            });
                          }
                        }}
                      >
                        Update Profile
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
