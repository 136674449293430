import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';
import { IWagerLine } from '../../../models/wager-lines';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const WAGER_LINES = `WAGER_LINES${suffix}`;

export const wagerLinesAction = createAction<boolean>(WAGER_LINES);

const WAGER_LINES_SUCCESS = `WAGER_LINES_SUCCESS${suffix}`;
const wagerLinesSuccessAction = createAction<boolean>(WAGER_LINES_SUCCESS);

const WAGER_LINES_ERROR = `WAGER_LINES_ERROR${suffix}`;
const wagerLinesErrorAction = createAction<string>(WAGER_LINES_ERROR);

function* wagerLinesWatcher(): SagaIterator {
  yield takeEvery(WAGER_LINES, wagerLinesWorker);
}
mergeSaga(wagerLinesWatcher);

function* wagerLinesWorker(action: any): SagaIterator {
  try {
    yield put(wagerLinesSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(wagerLinesErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [WAGER_LINES]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [WAGER_LINES_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => {
    const alllines = [...slice.lines];
    for (let index = 0; index < (action.payload ?? []).length; index++) {
      const element = action.payload[index];
      const findIndex = alllines.findIndex((x: IWagerLine) => x.IdLeague == element.IdLeague);
      if (findIndex != -1) alllines[findIndex] = element;
      else alllines.unshift(element);
    }
    // let line = slice.lines.find((x: IWagerLine) => x.IdLeague == action.payload.IdLeague);
    // if (line) line = action.payload;
    // else slice.lines.unshift(action.payload);
    return {
      ...slice,
      lines: alllines,
    };
  },
  [WAGER_LINES_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
