import { connect } from 'react-redux';
import { sendOtpAction } from '../../../api/user/actions/send-otp-action';
import { verifyOtpAction } from '../../../api/user/actions/verify-otp-action';
import { VerifyMobilePage, ExternalProps, ExternalActionProps } from './verify-mobile-page.component';
import {
  isLoadingSelector,
  errorSelector,
  loggedInUserSelector,
  verifyOtpSelector,
  inviteDetailsSelector,
} from '../../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    error: errorSelector(state),
    loggedUser: loggedInUserSelector(state),
    isVerified: verifyOtpSelector(state),
    inviteDetails: inviteDetailsSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  sendOtpAction,
  verifyOtpAction,
};

export const VerifyMobilePageContainer = connect(mapStateToProps, mapDispatchToProps)(VerifyMobilePage);
