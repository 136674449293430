import React from 'react';

export const VipSportsIcon = (props: any) => {
  const { style } = props;
  return (
    <svg version='1.1' style={style} id='sports' width='30' height='30' viewBox='0 0 512 512'>
      <path
        style={{ fill: '#F7B239' }}
        d='M232.404,395.957c-19.646,10.425-41.76,16.825-65.221,18.112c-2.928,0.165-5.88,0.248-8.855,0.248
	c-2.975,0-5.927-0.083-8.855-0.248c-36.979-2.031-70.569-16.754-96.497-39.907c-2.208-1.948-4.368-3.979-6.458-6.069
	c-2.09-2.09-4.132-4.239-6.092-6.435C17.12,335.684,2.291,301.975,0.248,264.855C0.083,261.927,0,258.975,0,256
	c0-2.975,0.083-5.927,0.248-8.855c2.043-37.12,16.872-70.829,40.178-96.804c1.96-2.196,4.002-4.345,6.092-6.435
	c2.09-2.09,4.25-4.121,6.458-6.069c25.928-23.153,59.518-37.876,96.497-39.907c2.928-0.165,5.88-0.248,8.855-0.248
	c2.975,0,5.927,0.083,8.855,0.248c36.967,2.031,70.557,16.754,96.485,39.907c2.208,1.948,4.368,3.979,6.458,6.069
	c2.09,2.078,4.121,4.227,6.104,6.423c13.637,15.219,24.381,33.083,31.359,52.741C278.923,204.806,213.679,387.858,232.404,395.957z'
      />
      <path
        style={{ fill: '#E09B2D' }}
        d='M116.626,256c0-66.843,41.426-124.008,100.004-147.228c-18.047-7.154-37.72-11.089-58.312-11.089
	C70.881,97.683,0,168.564,0,256s70.881,158.317,158.317,158.317c20.593,0,40.266-3.935,58.312-11.089
	C158.052,380.008,116.626,322.843,116.626,256z'
      />
      <g>
        <path
          style={{ fill: '#B27214' }}
          d='M276.231,150.33c-20.615,20.626-34.653,46.53-40.603,74.926l-1.818,8.666l-17.332-3.636l1.818-8.666
		c6.647-31.748,22.338-60.722,45.373-83.781c2.208,1.948,4.368,3.979,6.458,6.069C272.217,145.985,274.247,148.133,276.231,150.33z'
        />
        <path
          style={{ fill: '#B27214' }}
          d='M245.38,247.145v17.71h-78.196v149.214c-2.928,0.165-5.88,0.248-8.855,0.248
		c-2.975,0-5.927-0.083-8.855-0.248V264.855h-47.817c-2.149,41.3-19.221,79.825-48.679,109.307
		c-2.208-1.948-4.368-3.979-6.458-6.069c-2.09-2.09-4.132-4.239-6.092-6.435c26.117-26.128,41.359-60.214,43.484-96.804H0.248
		C0.083,261.927,0,258.975,0,256c0-2.975,0.083-5.927,0.248-8.855h83.663c-2.125-36.589-17.368-70.675-43.484-96.804
		c1.96-2.196,4.002-4.345,6.092-6.435c2.09-2.09,4.25-4.121,6.458-6.069c29.458,29.481,46.53,68.007,48.679,109.307h47.817V97.931
		c2.928-0.165,5.88-0.248,8.855-0.248c2.975,0,5.927,0.083,8.855,0.248v149.214H245.38z'
        />
      </g>
      <g>
        <path
          style={{ fill: '#995C0D' }}
          d='M512,308.481c-11.287,18.088-25.078,34.464-40.887,48.608c-2.881,2.598-5.833,5.101-8.855,7.533
		V252.34c3.023,2.432,5.974,4.935,8.855,7.533C486.922,274.017,500.713,290.393,512,308.481z'
        />
        <path
          style={{ fill: '#995C0D' }}
          d='M180.608,252.34v112.282c-3.023-2.432-5.974-4.935-8.855-7.533
		c-15.809-14.145-29.6-30.52-40.887-48.608c11.287-18.088,25.078-34.464,40.887-48.608
		C174.634,257.275,177.585,254.772,180.608,252.34z'
        />
      </g>
      <path
        style={{ fill: '#B27214' }}
        d='M462.258,252.328v112.306c-2.893,2.337-5.844,4.605-8.855,6.777
	c-37.038,26.99-82.635,42.906-131.964,42.906c-31.642,0-61.738-6.553-89.035-18.359c-15.266-6.6-29.659-14.853-42.941-24.534
	c-3.011-2.184-5.962-4.452-8.855-6.789V252.328c2.893-2.338,5.844-4.605,8.855-6.789c11.701-8.524,24.251-15.939,37.498-22.102
	c24.759-11.5,51.962-18.631,80.628-20.367c4.581-0.283,9.197-0.425,13.849-0.425c49.329,0,94.926,15.915,131.964,42.906
	C456.414,247.723,459.365,249.99,462.258,252.328z'
      />
      <g>
        <path
          style={{ fill: '#844908' }}
          d='M432.989,299.626h-17.096v-12.314c0-4.89-3.965-8.855-8.855-8.855s-8.855,3.965-8.855,8.855v12.314
		h-25.089v-12.314c0-4.89-3.965-8.855-8.855-8.855c-4.89,0-8.855,3.965-8.855,8.855v12.314h-25.089v-12.314
		c0-4.89-3.965-8.855-8.855-8.855c-4.89,0-8.855,3.965-8.855,8.855v12.314h-25.101v-12.314c0-4.89-3.965-8.855-8.855-8.855
		s-8.855,3.965-8.855,8.855v12.314h-25.089v-12.314c0-4.89-3.965-8.855-8.855-8.855s-8.855,3.965-8.855,8.855v12.314h-17.096
		c-4.89,0-8.855,3.965-8.855,8.855c0,4.89,3.965,8.855,8.855,8.855h17.096v12.314c0,4.89,3.965,8.855,8.855,8.855
		s8.855-3.965,8.855-8.855v-12.314h25.089v12.314c0,4.89,3.965,8.855,8.855,8.855s8.855-3.965,8.855-8.855v-12.314h25.101v12.314
		c0,4.89,3.965,8.855,8.855,8.855c4.89,0,8.855-3.965,8.855-8.855v-12.314h25.089v12.314c0,4.89,3.965,8.855,8.855,8.855
		c4.89,0,8.855-3.965,8.855-8.855v-12.314h25.089v12.314c0,4.89,3.965,8.855,8.855,8.855s8.855-3.965,8.855-8.855v-12.314h17.096
		c4.89,0,8.855-3.965,8.855-8.855C441.844,303.591,437.88,299.626,432.989,299.626z'
        />
        <path
          style={{ fill: '#844908' }}
          d='M189.463,245.539v125.884c-3.011-2.184-5.962-4.452-8.855-6.789v-0.012
		c-3.023-2.432-5.974-4.935-8.855-7.533v-97.217c2.881-2.598,5.833-5.101,8.855-7.533v-0.012
		C183.501,249.99,186.452,247.723,189.463,245.539z'
        />
        <path
          style={{ fill: '#844908' }}
          d='M471.113,259.873v97.217c-2.881,2.598-5.833,5.101-8.855,7.533v0.012
		c-2.893,2.337-5.844,4.605-8.855,6.777v-125.86c3.011,2.172,5.962,4.439,8.855,6.777v0.012
		C465.281,254.772,468.232,257.275,471.113,259.873z'
        />
      </g>
    </svg>
  );
};
