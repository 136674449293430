import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'cashierTransactionSlice';

export interface CashierTransactionSliceType {
  isLoading: boolean;
  isSaving: boolean;
  rollover?: any[];
  type: number | null;
  method: any | null;
  bonus?: any[] | null;
  player_number: string | null;
  account?: string | null;
  amount: number | null;
  name?: string | null;
  fingerprint?: string | null;
  ip?: string | null;
  paymentMethods: any[] | null;
  onlineHours: any[] | null;
  availableBonuses: any[] | null;
  transactionRequest: any[] | null;
  bankReference: any | null;
  transactionStatus: any | null;
  cbioAllowed: any | null;
  smsCodeCheck: any | null;
  feeFreeTime: any | null;
}

export const initialState: CashierTransactionSliceType = {
  isLoading: false,
  isSaving: false,
  rollover: [],
  type: null,
  method: null,
  bonus: [],
  player_number: null,
  account: null,
  amount: null,
  name: '',
  fingerprint: null,
  ip: null,
  paymentMethods: [],
  onlineHours: [],
  availableBonuses: [],
  transactionRequest: null,
  bankReference: null,
  transactionStatus: '',
  cbioAllowed: null,
  smsCodeCheck: null,
  feeFreeTime: null,
};

export const cashierTransactionReducer = createAndMergeSliceReducer(sliceName, initialState);
