import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { showToast } from '../../../utils/toast/show-errors';
// import { CashierApiResponse } from '../../../models/cashier-response';

const suffix = `/${userReducer.sliceName}/app`;

const INVITE = `INVITE${suffix}`;
export const inviteAction = createAction<string>(INVITE);

const INVITE_ERROR = `INVITE_ERROR${suffix}`;
const inviteErrorAction = createAction<string>(INVITE_ERROR);
const INVITE_SUCCESS = `INVITE_SUCCESS${suffix}`;
const inviteSuccessAction = createAction<string>(INVITE_SUCCESS);

function* inviteWatcher(): SagaIterator {
  yield takeEvery(INVITE, inviteWorker);
}
mergeSaga(inviteWatcher);

function* inviteWorker(action: any): SagaIterator {
  try {
    yield call(getApiPlayer, action.payload);
    // if (response.message === 'Inviter is found') {
    yield put(inviteSuccessAction(action.payload.username));
    // } else {
    //   yield call(showToast, response);
    //   yield put(inviteErrorAction(action.payload.error));
    // }
  } catch (e) {
    yield call(showToast, 'Invite code is not valid. Please make sure your invite information is correct.', 'error');
    yield put(inviteErrorAction('Invite code is not valid. Please make sure your invite information is correct.'));
  }
}

const getApiPlayer = (invite: any): Request => {
  return vipApi(`/signup/${invite}`, 'get', {});
};

const reduceHandlers = {
  [INVITE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    inviteSuccess: false,
  }),
  [INVITE_SUCCESS]: (slice: UserSliceType): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      inviteSuccess: true,
    };
  },
  [INVITE_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    error: action.payload,
    inviteSuccess: false,
    inviteCodeInvalid: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
