import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const GET_LEFT_SIDEBAR = `GET_LEFT_SIDEBAR${suffix}`;

export const getLeftSidebarAction = createAction<string>(GET_LEFT_SIDEBAR);

const GET_LEFT_SIDEBAR_SUCCESS = `GET_LEFT_SIDEBAR_SUCCESS${suffix}`;
const getLeftSidebarSuccessAction = createAction<any[]>(GET_LEFT_SIDEBAR_SUCCESS);

const GET_LEFT_SIDEBAR_ERROR = `GET_LEFT_SIDEBAR_ERROR${suffix}`;
const getLeftSidebarErrorAction = createAction<string>(GET_LEFT_SIDEBAR_ERROR);

function* getLeftSidebarWatcher(): SagaIterator {
  yield takeEvery(GET_LEFT_SIDEBAR, getLeftSidebarWorker);
}
mergeSaga(getLeftSidebarWatcher);

function* getLeftSidebarWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getLeftSidebarApi, action.payload);
    yield put(getLeftSidebarSuccessAction(result));
  } catch (e) {
    yield put(getLeftSidebarErrorAction(e));
  }
}

const getLeftSidebarApi = (username: string): Request => {
  return vipApi(`/wager/getActiveLeaguesByUsername`, 'post', { username });
};

const reduceHandlers = {
  [GET_LEFT_SIDEBAR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_LEFT_SIDEBAR_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    games: action.payload,
  }),
  [GET_LEFT_SIDEBAR_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
