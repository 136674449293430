import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_RAF_HISTORY = `GET_RAF_HISTORY${suffix}`;

export const getRafHistoryAction = createAction(GET_RAF_HISTORY);

const GET_RAF_HISTORY_SUCCESS = `GET_RAF_HISTORY_SUCCESS${suffix}`;
const getRafHistorySuccessAction = createAction<any[]>(GET_RAF_HISTORY_SUCCESS);

const GET_RAF_HISTORY_ERROR = `GET_RAF_HISTORY_ERROR${suffix}`;
const getRafHistoryErrorAction = createAction<string>(GET_RAF_HISTORY_ERROR);

function* getRafHistoryWatcher(): SagaIterator {
  yield takeEvery(GET_RAF_HISTORY, getRafHistoryWorker);
}
mergeSaga(getRafHistoryWatcher);

function* getRafHistoryWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getRafHistoryApi, action.payload);
    yield put(getRafHistorySuccessAction(result.data));
  } catch (e) {
    yield put(getRafHistoryErrorAction(e));
  }
}

const getRafHistoryApi = (username: any): Request => {
  return vipApi(`/raf/${username}`, 'get', {});
};

const reduceHandlers = {
  [GET_RAF_HISTORY]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_RAF_HISTORY_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    userRaf: action.payload,
  }),
  [GET_RAF_HISTORY_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
