import { connect } from 'react-redux';
import { getHorsesUrlAction } from '../../api/tpi/actions/get-horses-url.action';
import { isLoadingSliceSelector, horsesUrlSliceSelector } from '../../api/tpi/tpi.selector';
import { HorsesPage, ExternalProps, ExternalActionProps } from './horses.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    horsesUrl: horsesUrlSliceSelector(state),
  };
}
const mapDispatchToProps: ExternalActionProps = {
  getHorsesUrlAction,
};

export const HorsesPageContainer = connect(mapStateToProps, mapDispatchToProps)(HorsesPage);
