import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_BONUS = `SET_TRANSACTION_BONUS${suffix}`;

export const setTransactionBonusAction = createAction<number>(SET_TRANSACTION_BONUS);

const reduceHandlers = {
  [SET_TRANSACTION_BONUS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    bonus: action.payload,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
