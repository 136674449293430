import { connect } from 'react-redux';
import { GuidesPage, ExternalProps, ExternalActionProps } from './guides.component';
import { isLoadingSliceSelector, guidesSelector, categoriesSelector } from './../../api/guide/guide.selector';
import { getGuideAction } from './../../api/guide/actions/get-guide.action';
import { getCategoryAction } from './../../api/guide/actions/get-category.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    guides: guidesSelector(state),
    isLoading: isLoadingSliceSelector(state),
    categories: categoriesSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getGuideAction,
  getCategoryAction,
};

export const GuidesPageContainer = connect(mapStateToProps, mapDispatchToProps)(GuidesPage);
