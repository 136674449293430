import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid, Container } from '@material-ui/core';
import { Loading } from '../../shared/loading/loading.component';
import { TransactionHistory } from './component/transaction-history.component';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { BalanceDetail } from '../../shared/account/balance-detail.component';
import { AccountLinks } from '../../shared/account/account-links.component';

const useStyles: any = makeStyles({
  root: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
  },
  dot300: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #c8a964 100%, lightgrey 0%),
                     linear-gradient(120deg, #fff 36%, #fff 30%),
                     linear-gradient(300deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(210deg, #fff 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  dot280: {
    border: '20px solid #404040',
    margin: '10px',
    borderRadius: '50%',
    width: '230px',
    height: '230px',
  },
  colorMediumText: {
    fontSize: '18px',
    color: '#c8a964',
  },
  smallText: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#666',
  },
  innerCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  midGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  moneyText: {
    color: '#c8a964',
    fontSize: '28px',
  },
  upperText: {
    color: '#777',
  },
  depositBtn: {
    color: '#fff',
    backgroundColor: '#011f63',
  },
  btnGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    paddingBottom: '50px',
  },
  tabPill: {
    padding: '10px auto',
    border: '1px solid #c8a964',
  },
  selectBetType: {
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#000',
    padding: '0px 15px',
    marginRight: '10px',
    height: '27px',
    '& svg.MuiSelect-icon': {
      color: '#000',
    },
  },
  filterGrid: {
    display: 'flex',
    marginTop: '70px',
  },
});

export interface ExternalActionProps {
  getTransactionHistoryAction: (x: any) => void;
}

export interface ExternalProps {
  transactionHistory: any;
  isLoading: boolean;
  userBalance: any;
  user: any;
}

export const TransactionHistoryPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getTransactionHistoryAction, transactionHistory, isLoading, userBalance, user } = props;
  const [days, setDays] = React.useState(7);
  const [type, setType] = React.useState(1);

  useEffect(() => {
    getTransactionHistoryAction({ days: 7, type: 1 });
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <BalanceDetail userBalance={userBalance} user={user} routeName='transactions' />
      <div style={{ backgroundColor: '#000', paddingTop: '20px', paddingBottom: '40px' }}>
        <Container>
          <AccountLinks routeName='transactions' />

          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}>
              <div className={`${classes.filterGrid} tour_transactionHistory-filter`}>
                <Typography variant='h6' style={{ marginRight: '20px' }}>
                  Filter By:
                </Typography>
                <Select
                  className={`${classes.selectBetType} tour_transactionHistory-filterDays`}
                  value={days}
                  onChange={e => {
                    setDays((e.target.value as unknown) as number);
                    // filterTransaction({ days: (e.target.value as unknown) as number, type: type, status });
                    getTransactionHistoryAction({ days: (e.target.value as unknown) as number, type: type });
                  }}
                >
                  <MenuItem value={7}>7 Days</MenuItem>
                  <MenuItem value={15}>15 Days</MenuItem>
                  <MenuItem value={30}>30 Days</MenuItem>
                  <MenuItem value={60}>60 Days</MenuItem>
                  <MenuItem value={120}>120 Days</MenuItem>
                </Select>
                <Select
                  className={`${classes.selectBetType} tour_transactionHistory-filterDeposit`}
                  value={type}
                  onChange={e => {
                    setType(e.target.value as number);
                    getTransactionHistoryAction({ days: days, type: e.target.value as number });
                  }}
                >
                  <MenuItem value={1}>Deposit</MenuItem>
                  <MenuItem value={2}>Withdrawal</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={12}>
              {!isLoading && <TransactionHistory transactionHistory={transactionHistory} />}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
