import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { Loading } from '../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
    border: 'none',
    height: '85%',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute',
  },
});
export interface ExternalProps {
  isLoading: any;
  horsesUrl: any;
}

export interface ExternalActionProps {
  getHorsesUrlAction: (x: any) => void;
}

export const HorsesPage = (props: ExternalActionProps & ExternalProps) => {
  const { horsesUrl, getHorsesUrlAction, isLoading } = props;
  const classes = useStyles();

  useEffect(() => {
    getHorsesUrlAction({ provider_id: 5 });
  }, []);

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      {!isLoading && <iframe className={clsx(classes.root)} src={horsesUrl} height='100%'></iframe>}
    </>
  );
};
