import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles: any = makeStyles({
  root: {
    marginTop: '40px',
  },
});

export interface ExternalProps {
  transactionHistory: any;
}

const dateFormat = (dateToFormat: any) => {
  const d = new Date(dateToFormat);
  const monthFormat = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`;
  const dateFormat = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`;
  const formatedDate = `${dateFormat}/${monthFormat}/${d.getFullYear()}`;
  const hourFormat = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`;
  const minuteFormat = d.getMinutes() < 10 ? `0${d.getMinutes()}` : `${d.getMinutes()}`;
  const formatedTime = `${hourFormat}:${minuteFormat}:00`;
  return formatedDate + ' ' + formatedTime;
};

const getStatusText = (status: any) => {
  if (status == 1) {
    return <Chip label='Pending' color='secondary' />;
  }
  if (status == 4) {
    return <Chip label='Action Required' style={{ backgroundColor: 'orange' }} />;
  }
  if (status == 5) {
    return <Chip label='Unverified' style={{ backgroundColor: 'grey' }} />;
  }
  if (status == 6) {
    return <Chip label='Completed' color='primary' />;
  }
};

export const TransactionHistory = (props: ExternalProps) => {
  const classes = useStyles();
  const { transactionHistory } = props;

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className='tour_transactionHistory-table'>
        <TableHead>
          <TableRow style={{ backgroundColor: '#888' }}>
            <TableCell>
              ID
              <br />
              Date
            </TableCell>
            <TableCell align='center'>Type / Method / Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(transactionHistory) &&
            transactionHistory &&
            transactionHistory.map((item: any, i: number) => {
              return (
                <TableRow key={i}>
                  <TableCell align='center' className='tour_transactionHistory-dateCell'>
                    #{item.id} <br /> {dateFormat(item.created_at)}
                  </TableCell>
                  <TableCell align='center' className='tour_transactionHistory-typeCell'>
                    {item.type}
                    <br /> {item.method} <br />${item.amount}
                  </TableCell>
                  <TableCell className='tour_transactionHistory-statusCell'>{getStatusText(item.status)}</TableCell>
                </TableRow>
              );
            })}
          {transactionHistory.length == 0 && (
            <TableRow>
              <TableCell align='center' colSpan={5}>
                No Transaction Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
