import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { PROVIDERS } from '../../../enum/providers/providers';
import { getAppSettings } from '../../../utils';

const useStyles: any = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#121212',
      padding: 0,
    },
    title: {
      fontSize: '.8rem',
      color: '#707070',
      padding: 12,
      textTransform: 'uppercase',
    },
    bar: {
      borderColor: 'black',
      margin: 0,
      padding: 0,
    },
    providerImage: {
      maxWidth: '150px',
      height: 'auto',
      maxHeight: '50px',
    },
    columns: {
      padding: 12,
    },
    categories: {
      padding: 12,
      minHeight: '100%',
    },
  }),
);

export interface ExternalProps {
  user: any;
  categories: any;
  provider: any;
  userBalances: any;
  useFreePlay: any;
}

export interface ExternalActionProps {
  setProvider: (providerId: any) => void;
  changeCasinoGamesAction: (x: any) => void;
  setUseFreePlay: (x: any) => void;
}

export const LeftNav = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const { provider, setProvider, categories, user, changeCasinoGamesAction } = props;
  // , userBalances, useFreePlay, setUseFreePlay

  // const handleChange = () => {
  //   setUseFreePlay(!useFreePlay);
  // };
  let url = getAppSettings().apiUrl;
  url = url.slice(0, -4);

  return (
    <div className={clsx(classes.root)}>
      <Typography variant='h6' className={classes.title}>
        Providers:
      </Typography>
      <Grid container>
        {user &&
          user.rights &&
          user.rights
            .filter(
              (x: any) =>
                [PROVIDERS.BETSOFT, PROVIDERS.CONCEPT, PROVIDERS.DGS_CASINO, PROVIDERS.PLATINUM, PROVIDERS.VIG].indexOf(x.id) != -1,
            )
            .map((pRight: any, index: number) => {
              {
                index == 0 && !provider && setProvider({ ...{}, ...pRight });
              }
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  className={classes.columns}
                  key={'provKey' + pRight.id}
                  onClick={() => setProvider({ ...(provider ?? {}), ...pRight })}
                >
                  <img src={`${url}${pRight.image}`} className={classes.providerImage} />
                  {pRight.id == 10 && <Typography className={`liveDealerGradient`}>Live Dealer</Typography>}
                </Grid>
              );
            })}
      </Grid>

      <hr className={classes.bar} />

      {!provider && <Typography variant='subtitle1'>Please select a provider.</Typography>}

      {provider && [PROVIDERS.DGS_CASINO, PROVIDERS.PLATINUM, PROVIDERS.VIG].indexOf(Number(provider.id)) == -1 && categories.length > 0 && (
        <>
          <Typography variant='h6' className={classes.title}>
            Categories:
          </Typography>

          <div className={classes.categories}>
            {categories.map((item: any) => (
              <Button
                onClick={() => {
                  changeCasinoGamesAction({
                    deviceId: 1,
                    category: item.category_id,
                    providerId: '' + provider.id,
                  });
                }}
                key={item.category_id}
              >
                {item.category_description}
              </Button>
            ))}
          </div>

          <hr className={classes.bar} />

          {/* {provider != -1 && provider != 0 && (
            <div className={classes.columns}>
              <FormControl component='fieldset'>
                <FormGroup aria-label='position' row>
                  <FormControlLabel
                    value='end'
                    control={
                      <Checkbox
                        color='primary'
                        value={useFreePlay}
                        onChange={() => {
                          handleChange();
                        }}
                      />
                    }
                    label={`Use Bonus Balance (${userBalances.FreePlayAmount})`}
                    labelPlacement='end'
                  />
                </FormGroup>
              </FormControl>
            </div>
          )} */}

          <hr className={classes.bar} />
        </>
      )}
    </div>
  );
};
