import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    backgroundColor: 'black',
    textAlign: 'center',
  },
  img: {
    width: '100%',
    height: 'auto',
    maxHeight: '160px',
    borderRadius: '5px',
  },
  slider: {
    maxWidth: '1200px',
    margin: '0px auto',
    display: 'block',
  },
});

export const AdvertisementSlider = () => {
  const classes = useStyles();
  return (
    <div className={`${clsx(classes.root)} tour_casino-slider`}>
      <div className={clsx(classes.slider)}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <img src={`${process.env.PUBLIC_URL}/images/casino-slider/piece1.png`} className={clsx(classes.img)} />
          </Grid>
          <Grid item xs={4}>
            <img src={`${process.env.PUBLIC_URL}/images/casino-slider/piece5.jpeg`} className={clsx(classes.img)} />
          </Grid>
          <Grid item xs={4}>
            <img src={`${process.env.PUBLIC_URL}/images/casino-slider/piece4.jpeg`} className={clsx(classes.img)} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
