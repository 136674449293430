import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useEffect } from 'react';
import { Loading } from '../../../shared/loading/loading.component';
import { isAndroid, isIOS, isWinPhone } from 'react-device-detect';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        minWidth: '75%',
      },
      minWidth: '100%',
      border: 'none',
      height: '100%',
      overflow: 'hidden',
      // overflowX: 'hidden',
      overflowY: 'hidden',
      position: 'absolute',
    },
  }),
);

export interface ExternalProps {
  isLoading: any;
  provider: any;
  gameUrl: any;
}

export interface ExternalActionProps {
  openCasinoGameAction: (x: any) => void;
}

export const GenericLobby = (props: ExternalActionProps & ExternalProps) => {
  const { isLoading, provider, gameUrl, openCasinoGameAction } = props;
  const classes = useStyles();
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;

  useEffect(() => {
    let device_id = 1;
    if (isMobile) {
      if (isAndroid) {
        device_id = 3;
      }
      if (isIOS) {
        device_id = 2;
      }
      if (isWinPhone) {
        device_id = 4;
      }
    }
    openCasinoGameAction({
      device_id: device_id,
      site_id: 2,
      provider_id: provider.id,
      game_id: 0,
      is_freeplay: 0,
    });
  }, [provider]);

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      <div></div>
      {!isLoading && !isMobile && <iframe className={clsx(classes.root)} src={gameUrl} height='100%'></iframe>}
    </>
  );
};
