import React from 'react';

export const VipTicketIcon = (props: any) => {
  const { style, rgb } = props;
  return (
    <svg viewBox='103.586 60.051 274.125 354.96' style={style} width='30' height='30' xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke='rgb(0,0,0)'
        strokeWidth='0'
        strokeDasharray='none'
        strokeDashoffset='0'
        strokeLinejoin='miter'
        strokeMiterlimit='4'
        fill={rgb ?? 'rgb(185,30,30)'}
        fillRule='nonzero'
        opacity='1'
        vectorEffect='non-scaling-stroke'
        d='M 103.586 409.051 L 121.598 392.442 C 132.93 381.922 150.41 381.782 161.91 392.114 L 184.719 412.465 C 188.523 415.871 194.289 415.86 198.074 412.43 L 220.551 392.153 C 231.957 381.817 249.34 381.817 260.746 392.153 L 283.223 412.43 C 287.008 415.856 292.773 415.871 296.578 412.465 L 319.387 392.11 C 330.887 381.778 348.371 381.922 359.699 392.442 L 377.711 409.051 L 377.711 60.051 L 103.586 60.051 Z M 173.801 175.829 L 302.148 175.829 C 307.672 175.829 312.148 180.309 312.148 185.829 C 312.148 191.352 307.672 195.829 302.148 195.829 L 173.801 195.829 C 168.277 195.829 163.801 191.352 163.801 185.829 C 163.801 180.309 168.277 175.829 173.801 175.829 Z M 173.801 245.352 L 302.148 245.352 C 307.672 245.352 312.148 249.829 312.148 255.352 C 312.148 260.875 307.672 265.352 302.148 265.352 L 173.801 265.352 C 168.277 265.352 163.801 260.875 163.801 255.352 C 163.801 249.829 168.277 245.352 173.801 245.352 Z M 173.801 245.352'
        strokeLinecap='round'
      ></path>
      <rect x='161.471' y='173.317' width='154.613' height='30.549' fill={rgb ?? 'rgb(185,30,30)'}></rect>
      <rect x='160.224' y='242.519' width='156.484' height='27.431' fill={rgb ?? 'rgb(185,30,30)'}></rect>
    </svg>
  );
};
