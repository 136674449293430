import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_USER_BALANCE = `GET_USER_BALANCE${suffix}`;

export const getUserBalanceAction = createAction(GET_USER_BALANCE);

const GET_USER_BALANCE_SUCCESS = `GET_USER_BALANCE_SUCCESS${suffix}`;
const getUserBalanceSuccessAction = createAction<any[]>(GET_USER_BALANCE_SUCCESS);

const GET_USER_BALANCE_ERROR = `GET_USER_BALANCE_ERROR${suffix}`;
const getUserBalanceErrorAction = createAction<string>(GET_USER_BALANCE_ERROR);

function* getUserBalanceWatcher(): SagaIterator {
  yield takeLatest(GET_USER_BALANCE, getUserBalanceWorker);
}
mergeSaga(getUserBalanceWatcher);

function* getUserBalanceWorker(): SagaIterator {
  try {
    const result: any = yield call(getUserBalanceApi);
    yield put(getUserBalanceSuccessAction(result.data));
  } catch (e) {
    yield put(getUserBalanceErrorAction(e));
  }
}

const getUserBalanceApi = (): Request => {
  return vipApi(`/wager/Balance`, 'post', {});
};

const reduceHandlers = {
  [GET_USER_BALANCE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_USER_BALANCE_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    userBalance: action.payload,
  }),
  [GET_USER_BALANCE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
