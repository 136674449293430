import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { ILogin } from '../../../models/login';
import { showToast } from '../../../utils/toast/show-errors';
// import { CashierApiResponse } from '../../../models/cashier-response';

declare global {
  interface Window {
    LO: any;
  }
}

const logLoginEventInLuckyOrange = (username: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Logged In', { 'Player Number': username });
      console.log('logged Login Event');
    }
  }
};

const suffix = `/${userReducer.sliceName}/app`;

const LOGIN = `LOGIN${suffix}`;
export const loginAction = createAction<ILogin>(LOGIN);

const LOGIN_ERROR = `LOGIN_ERROR${suffix}`;
const loginErrorAction = createAction<string>(LOGIN_ERROR);
const LOGIN_SUCCESS = `LOGIN_SUCCESS${suffix}`;
const loginSuccessAction = createAction<any>(LOGIN_SUCCESS);

function* loginWatcher(): SagaIterator {
  yield takeEvery(LOGIN, loginWorker);
}
mergeSaga(loginWatcher);

function* loginWorker(action: any): SagaIterator {
  try {
    const response: any = yield call(getApiPlayer, action.payload);
    const { token, data, message } = response;
    if (token) {
      setLoginSession(data, token);
      yield put(loginSuccessAction(response.data));
      logLoginEventInLuckyOrange(response.data.username);
      // window.location.reload();
    } else {
      yield put(loginErrorAction(message || 'Something went wrong.'));
    }
  } catch (e) {
    yield call(showToast, 'Login failed. Please make sure your login information is correct.', 'error');
    yield put(loginErrorAction('Login failed. Please make sure your login information is correct.'));
  }
}

const getApiPlayer = (login: ILogin): Request => {
  return vipApi(`/login`, 'post', {
    username: login.username,
    password: login.password,
    ip: login.ip,
    fingerprint: login.fingerprint,
  });
};

const setLoginSession = (user: any, token: any) => {
  try {
    if (token) localStorage.setItem('token', token);
    if (user) localStorage.setItem('user', JSON.stringify(user));
    return true;
  } catch (e) {
    return false;
  }
};

const reduceHandlers = {
  [LOGIN]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    isLoggedIn: false,
  }),
  [LOGIN_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      loggedInUser: action.payload,
      isLoggedIn: true,
      isLoading: false,
    };
  },
  [LOGIN_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
