import React, { useRef } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      '@media print': {
        '&': {
          color: '#000',
        },
      },
    },
    printBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export interface ExternalProps {
  signups: any;
}

export default function SignUpsTable(props: ExternalProps) {
  const classes = useStyles();
  const { signups } = props;
  const componentRef = useRef<HTMLDivElement>(null);
  return (
    <div className={classes.root} ref={componentRef}>
      <div className={classes.printBlock}>
        <Typography variant='h6' gutterBottom>
          Your Sign Ups
        </Typography>
        <ReactToPrint trigger={() => <PrintIcon />} content={() => componentRef.current} />
      </div>
      {signups ? (
        <>
          {signups.map((signup: any) => {
            return (
              <Accordion key={signup.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography className={classes.heading}>
                    {signup.username} | {signup.first_name} {signup.last_name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#000' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant='body1'>Sign Up Date: {signup.signup_date}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1'>Last Deposit: {signup.last_deposit ?? 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1'>Phone: {signup.phone}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1'>Email: {signup.email}</Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h6'>You currently do not have any sign ups. Share your link to get started!</Typography>
        </div>
      )}
    </div>
  );
}
