import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const STEP_WAGER = `STEP_WAGER${suffix}`;

export const stepWagerAction = createAction<boolean>(STEP_WAGER);

const STEP_WAGER_SUCCESS = `STEP_WAGER_SUCCESS${suffix}`;
const stepWagerSuccessAction = createAction<boolean>(STEP_WAGER_SUCCESS);

const STEP_WAGER_ERROR = `STEP_WAGER_ERROR${suffix}`;
const stepWagerErrorAction = createAction<string>(STEP_WAGER_ERROR);

function* stepWagerWatcher(): SagaIterator {
  yield takeEvery(STEP_WAGER, stepWagerWorker);
}
mergeSaga(stepWagerWatcher);

function* stepWagerWorker(action: any): SagaIterator {
  try {
    yield put(stepWagerSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(stepWagerErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [STEP_WAGER]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [STEP_WAGER_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    stepWager: action.payload,
  }),
  [STEP_WAGER_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
