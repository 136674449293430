import { connect } from 'react-redux';
import { PendingBetsPage, ExternalProps, ExternalActionProps } from './pending-bets.component';
import { getPendingBetsAction } from './../../api/game-selection/actions/get-pending-bets.action';
import { isLoadingSliceSelector, pendingBetsSelector } from './../../api/game-selection/game-selection.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    pendingBets: pendingBetsSelector(state),
    isLoading: isLoadingSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPendingBetsAction,
};

export const PendingBetsPageContainer = connect(mapStateToProps, mapDispatchToProps)(PendingBetsPage);
