import { connect } from 'react-redux';
import { getLiveWageringUrlAction } from '../../api/tpi/actions/get-live-wagering-url.action';
import { isLoadingSliceSelector, liveWageringUrlSliceSelector } from '../../api/tpi/tpi.selector';
import { LiveSportsPage, ExternalProps, ExternalActionProps } from './live-sports.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    liveWageringUrl: liveWageringUrlSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getLiveWageringUrlAction,
};

export const LiveSportsPageContainer = connect(mapStateToProps, mapDispatchToProps)(LiveSportsPage);
