import { connect } from 'react-redux';
import { SportsBookPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { getLeftSidebarAction } from './../../api/game-selection/actions/get-left-sidebar.action';
import { getMiddleSectionAction } from './../../api/game-selection/actions/get-middle-section.action';
import { removeMiddleSectionAction } from './../../api/game-selection/actions/remove-middle-section.action';
import { previewBetAction } from './../../api/game-selection/actions/preview-bet.action';
import { createBetAction } from './../../api/game-selection/actions/create-bet.action';
import { placeBetAction } from './../../api/game-selection/actions/place-bet.action';
import { getUserBalanceAction } from './../../api/user/actions/get-balance.action';
import { getTeasersAction } from './../../api/game-selection/actions/get-teasers.action';
import { playerLinesAction } from '../../api/game-selection/actions/player-lines.action';
import { betResultsAction } from '../../api/game-selection/actions/bet-results.action';
import { leagueAction } from '../../api/game-selection/actions/league.action';
import { wagerTypeAction } from '../../api/game-selection/actions/wagertype.action';
import { stepWagerAction } from '../../api/game-selection/actions/step-wager.action';
import { teaserAction } from '../../api/game-selection/actions/teaser.action';
import { wagerLinesAction } from '../../api/game-selection/actions/wager-lines.action';
import { rightOpenAction } from '../../api/game-selection/actions/right-open.action';

import {
  gamesSelector,
  linesSelector,
  isLoadingSliceSelector,
  betResponsesSelector,
  previewBetResponsesSelector,
  createBetResponsesSelector,
  betPlacedSelector,
  teasersSelector,
  tvOnOffResponsesSelector,
  tvUrlSelector,
  isSetUpSelector,
  playerLinesSelector,
  betResultsSelector,
  leagueSelector,
  wagerTypeSelector,
  stepWagerSelector,
  teaserSelector,
  rightOpenSelector,
} from './../../api/game-selection/game-selection.selector';
import { loggedInUserSelector, needCashierForwardSelector, userBalanceSelector } from './../../api/user/user.selector';
import { setNeedCashierFowardAction } from '../../api/user/actions/set-need-cashier-forward.action';
import { getPendingBetsAction } from './../../api/game-selection/actions/get-pending-bets.action';
import { isLoadingSliceSelector as pendingBetsIsLoading, pendingBetsSelector } from './../../api/game-selection/game-selection.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    games: gamesSelector(state),
    lines: linesSelector(state),
    user: loggedInUserSelector(state),
    isLoading: isLoadingSliceSelector(state),
    pendingBetsLoading: pendingBetsIsLoading(state),
    pendingBets: pendingBetsSelector(state),
    betResponses: betResponsesSelector(state),
    previewBetResponses: previewBetResponsesSelector(state),
    createBetResponses: createBetResponsesSelector(state),
    betPlaced: betPlacedSelector(state),
    userBalance: userBalanceSelector(state),
    teasers: teasersSelector(state),
    isTvOn: tvOnOffResponsesSelector(state),
    needCashierForward: needCashierForwardSelector(state),
    tvUrl: tvUrlSelector(state),
    isTvSetUp: isSetUpSelector(state),
    playerLines: playerLinesSelector(state),
    betResults: betResultsSelector(state),
    league: leagueSelector(state),
    wagerType: wagerTypeSelector(state),
    stepWager: stepWagerSelector(state),
    teaser: teaserSelector(state),
    rightOpen: rightOpenSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getLeftSidebarAction,
  getMiddleSectionAction,
  placeBetAction,
  previewBetAction,
  createBetAction,
  getUserBalanceAction,
  getTeasersAction,
  setNeedCashierFowardAction,
  playerLinesAction,
  betResultsAction,
  leagueAction,
  wagerTypeAction,
  stepWagerAction,
  teaserAction,
  getPendingBetsAction,
  removeMiddleSectionAction,
  wagerLinesAction,
  rightOpenAction,
};

export const SportsBookPageContainer = connect(mapStateToProps, mapDispatchToProps)(SportsBookPage);
