export interface Options {
  path?: string;
  port?: number;
  headers?: any;
}

export interface GenericResponseDto<T> {
  status?: number;
  error?: boolean;
  data?: T;
  message?: string;
}

export const GenericResponsePost = async <T>(pOptions: Options, pBody: any): Promise<GenericResponseDto<T>> => {
  const axios = require('axios');
  const data = pBody ? JSON.stringify(pBody) : '';
  pOptions.headers = {
    ...pOptions.headers,
  };
  return await axios
    .post(pOptions.path, data, {
      headers: pOptions.headers,
    })
    .then((res: any) => {
      return <GenericResponseDto<T>>{
        error: false,
        data: res.data,
      };
    })
    .catch((error: any) => {
      return <GenericResponseDto<T>>{
        error: true,
        message: error.message,
      };
    });
};

export const GenericResponseGet = async <T>(pOptions: Options): Promise<GenericResponseDto<T>> => {
  const axios = require('axios');
  pOptions.headers = {
    ...pOptions.headers,
  };
  return await axios
    .get(pOptions.path, {
      headers: pOptions.headers,
    })
    .then((res: any) => {
      return <GenericResponseDto<T>>{
        error: false,
        data: res.data,
      };
    })
    .catch((error: any) => {
      return <GenericResponseDto<T>>{
        error: true,
        data: error?.data,
        message: error.message,
      };
    });
};
