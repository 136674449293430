import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const TEASER = `TEASER${suffix}`;

export const teaserAction = createAction<boolean>(TEASER);

const TEASER_SUCCESS = `TEASER_SUCCESS${suffix}`;
const teaserSuccessAction = createAction<boolean>(TEASER_SUCCESS);

const TEASER_ERROR = `TEASER_ERROR${suffix}`;
const teaserErrorAction = createAction<string>(TEASER_ERROR);

function* teaserWatcher(): SagaIterator {
  yield takeEvery(TEASER, teaserWorker);
}
mergeSaga(teaserWatcher);

function* teaserWorker(action: any): SagaIterator {
  try {
    yield put(teaserSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(teaserErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [TEASER]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [TEASER_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    teaser: action.payload,
  }),
  [TEASER_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
