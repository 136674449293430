import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';
import { PLAY_LINE } from '../../enum';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import CancelIcon from '@material-ui/icons/Cancel';
import { IWagerLine } from '../../models/wager-lines';
import LinearProgress from '@material-ui/core/LinearProgress';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VipSportsIcon } from '../icons/sports-icon';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  middleSection: {
    width: 'calc(100% - 741px)',
    position: 'absolute',
    left: '241px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      left: 0,
    },
  },
  middleHeader: {
    height: '48px',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px',
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.up('lg')]: {
      top: '141px',
      width: 'calc(100% - 741px)',
      left: '241px',
    },
    [theme.breakpoints.down('md')]: {
      top: '92px',
    },
    '@media print': {
      '&': {
        display: 'none !important',
      },
    },
  },
  gameBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    height: '3rem',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    position: 'relative',
    top: '46px',
    // position: 'fixed',
    // width: '100%',
    zIndex: 1,
    // [theme.breakpoints.up('lg')]: {
    //   top: '189px',
    //   width: 'calc(100% - 741px)',
    //   left: '241px',
    // },
    // [theme.breakpoints.down('md')]: {
    //   top: '141px',
    // },
    // '@media print': {
    //   '&': {
    //     display: 'none !important',
    //   },
    // },
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '2.5rem',
      marginRight: '1.2rem',
    },
    '& p': {
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 800,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      WebkitLineClamp: 1,
      maxHeight: '33px',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
    },
    [theme.breakpoints.down('md')]: {
      '& p': {
        fontSize: '18px',
        fontWeight: 800,
      },
    },
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      padding: '.9rem 5rem',
      marginRight: '1rem',
      backgroundColor: '#fff',
      color: '#000',
      clipPath: 'polygon(50px 0%, 0% 100%, 100% 50px, calc(100% - 50px) 100%, 100% 0%)',
    },
  },
  tab: {
    minWidth: 180,
    color: '#707070',
  },
  appBg: {
    backgroundColor: '#2d2d2d',
  },
  betTable: {
    position: 'relative',
    '& div': {
      backgroundColor: '#4c4c4c3a',
      margin: '3px 4px',
      padding: '7px',
      cursor: 'pointer',
    },
    '& th': {
      position: 'sticky',
      top: 0,
    },
    '& th, td': {
      padding: '5px 16px',
      color: '#fff',
      fontSize: '14px',
    },
    '& th.frColor, td.frColor': {
      color: '#c8a964',
      fontSize: '12px',
    },
    '& td.betActive': {
      backgroundColor: '#000',
    },
    // '& td.spreadRow': {
    //   borderBottom: '1px',
    // },
    '& td.spreadRow span.spreadText': {
      fontSize: '14px',
    },
    '& span.spreadColor': {
      color: '#fff',
      cursor: 'pointer',
    },
  },
  bb0: {
    '& td': {
      borderBottom: 0,
    },
  },
  mobTeam: {
    fontSize: '14px',
  },
  marginBottom100: {
    marginBottom: '100px',
  },
  videoPlayer: {
    maxWidth: '750px',
    margin: 'auto',
    position: 'relative',
    zIndex: 1250,
  },
  fixedVideoPlayer: {
    position: 'fixed',
    width: '200px',
    right: '20px',
    bottom: '70px',
    zIndex: 1250,
    [theme.breakpoints.up('sm')]: {
      width: '320px',
    },
  },
  channelSelect: {
    backgroundColor: '#000',
    '& .MuiSelect-select.MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
    },
  },
  midTable: {
    marginTop: '46px',
    backgroundColor: '#212529',
  },
  cancelIcon: {
    cursor: 'pointer',
    marginLeft: '20px',
  },
  bannerProps: {
    background: '#c8a964',
    color: '#fff',
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
  btnSelected: {
    backgroundColor: '#000 !important',
  },
}));

interface WagerProps {
  lines: IWagerLine[];
  selectLine: (pLeague: any, pGame: any, pLine: any, pPlay: PLAY_LINE) => void;
  league: any;
  getGamesByLeague: (data: any, index: number) => void;
  url: string;
  isTvOn: boolean;
  isTvSetUp: boolean;
  collapsed: string[];
  setCollapsed: (x: string[]) => void;
  innerCollapse: string[];
  setInnerCollapse: (x: string[]) => void;
  removeMiddleSectionAction: (x: any) => void;
  manageInnerCollapse: (x: string) => void;
  manageReverseInnerCollapse: (x: string) => void;
  collapseAction: (x: string) => void;
  collapseReverseAction: (x: string) => void;
  ShowProps: (x: any) => void;
  ShowAlternatives: (x: any) => void;
}

function getPreetyDate(t: string) {
  // const r = `${t.slice(0, 4)}-${t.slice(4, 6)}-${t.slice(6, 11)}`;
  const newD = new Date(Number(t.slice(0, 4) ?? 0), Number(t.slice(4, 6) ?? 0) - 1, Number(t.slice(6, 8)) ?? 0);
  const f = newD.toString().slice(0, 15);
  return f;
}
function isDesktop(): boolean {
  return window.innerWidth >= 1280;
}

function militaryToHour(time: any) {
  // Check correct time format and split into components
  if (time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      if (time[3] == ':00') time[3] = ' '; // remove Sec
    }

    return time.join(''); // return adjusted time or original string
  }
  return '';
}

export const WagerLine = ({
  lines,
  selectLine,
  league,
  getGamesByLeague,
  removeMiddleSectionAction,
  collapsed,
  innerCollapse,
  manageInnerCollapse,
  manageReverseInnerCollapse,
  collapseAction,
  collapseReverseAction,
  ShowProps,
  ShowAlternatives,
}: WagerProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={`${classes.middleSection} tour_sportsbook-content`} ref={componentRef}>
        {lines.length > 0 ? (
          <>
            <div className={classes.middleHeader}>
              <Typography style={{ fontSize: '0.8rem' }}>{new Date().toString().slice(0, 24)}</Typography>
              <Typography>American</Typography>
              <CachedIcon
                onClick={() => {
                  getGamesByLeague(league, 1);
                }}
              />
              <div>
                <ReactToPrint trigger={() => <PrintIcon />} content={() => componentRef.current} />
                <br />
              </div>
            </div>
            {lines.map(item => {
              return (
                <div key={item.IdLeague} className={isDesktop() ? '' : classes.marginBottom100}>
                  <div className={classes.gameBar}>
                    <div className={classes.leftSide}>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        onClose={() => setOpen(false)}
                        open={open}
                        title={item.Description}
                        arrow
                      >
                        <Typography onClick={() => setOpen(true)}>{item.Description}</Typography>
                      </Tooltip>
                    </div>
                    <div className={classes.rightSide}>
                      {isDesktop() && <div className='rightFirst'>GAME</div>}
                      <CancelIcon className={classes.cancelIcon} onClick={() => removeMiddleSectionAction(item.IdLeague)} />
                      {collapsed.indexOf(item.IdLeague) == -1 ? (
                        <IndeterminateCheckBoxIcon className={classes.cancelIcon} onClick={() => collapseAction(item.IdLeague)} />
                      ) : (
                        <AddCircleIcon className={classes.cancelIcon} onClick={() => collapseReverseAction(item.IdLeague)} />
                      )}
                    </div>
                  </div>
                  {item.Loading && (
                    <div>
                      <LinearProgress color='primary' style={{ height: '7px', marginTop: '45px' }} />
                    </div>
                  )}

                  {!item.Loading &&
                    collapsed.indexOf(item.IdLeague) === -1 &&
                    item.game &&
                    Array.isArray(item.game) &&
                    item.game.length > 0 && (
                      <TableContainer component={Paper} className={`${classes.midTable} vip-fadeIn`}>
                        <Table className={classes.betTable}>
                          <TableHead>
                            {['PROP', 'TNT'].indexOf(item.IdSport) == -1 &&
                            Array.isArray(item.game[0].line) &&
                            item.game[0].line[0].ovh !== undefined ? (
                              <TableRow>
                                {isDesktop() && <TableCell colSpan={3}></TableCell>}
                                <TableCell align='center' className='frColor'>
                                  {item.IdSport == 'MLB' ? 'RUN LINE' : 'SPREAD'}
                                </TableCell>
                                <TableCell align='center' className='frColor'>
                                  MONEYLINE
                                </TableCell>
                                <TableCell align='center' className='frColor'>
                                  TOTAL
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow>
                                {isDesktop() && <TableCell></TableCell>}
                                <TableCell colSpan={isDesktop() ? 3 : 2} className='frColor'>
                                  #Description
                                </TableCell>
                                <TableCell align='center' className='frColor'>
                                  ODDS
                                </TableCell>
                              </TableRow>
                            )}
                          </TableHead>
                          {item.game.map((game: any, j: number) => {
                            return (
                              <>
                                <TableBody key={j}>
                                  {(['PROP'].indexOf(item.IdSport) == -1 || (['PROP'].indexOf(item.IdSport) != -1 && game.gdesc != '')) && (
                                    <>
                                      <TableRow className={classes.appBg}>
                                        <TableCell colSpan={isDesktop() ? 4 : 1} className='frColor'>
                                          {getPreetyDate(game.gmdt)}
                                        </TableCell>
                                        <TableCell colSpan={2} align='right'>
                                          {innerCollapse.indexOf(game.idgm) !== -1 ? (
                                            <IconButton
                                              aria-label='expand row'
                                              size='small'
                                              onClick={() => manageReverseInnerCollapse(game.idgm)}
                                            >
                                              <KeyboardArrowUpIcon />
                                            </IconButton>
                                          ) : (
                                            <IconButton aria-label='expand row' size='small' onClick={() => manageInnerCollapse(game.idgm)}>
                                              <KeyboardArrowDownIcon />
                                            </IconButton>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                      {['PROP'].indexOf(item.IdSport) != -1 && (
                                        <TableRow>
                                          <TableCell colSpan={42} className={classes.bannerProps}>
                                            {game.gdesc}
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </>
                                  )}

                                  {/* {typeof game.line === 'object' && !Array.isArray(game.line) && game.line.ovh !== undefined */}
                                  {['PROP', 'TNT'].indexOf(item.game[0].idspt) == -1 &&
                                  Array.isArray(item.game[0].line) &&
                                  item.game[0].line[0].ovh !== undefined
                                    ? innerCollapse.indexOf(game.idgm) == -1 &&
                                      game.line.map((line: any, o: number) => {
                                        return (
                                          <React.Fragment key={'line' + o}>
                                            {!isDesktop() && (
                                              <TableRow className={classes.bb0}>
                                                <TableCell colSpan={3}>
                                                  <span className={classes.mobTeam}>
                                                    <span style={{ color: '#fff', fontWeight: 'bold' }}>{game.vtm}</span> &nbsp;&nbsp;
                                                    <span style={{ color: '#c8a964' }}>{militaryToHour(game.gmtm)}</span>
                                                  </span>
                                                </TableCell>
                                              </TableRow>
                                            )}
                                            <TableRow className={isDesktop() ? '' : classes.bb0}>
                                              {isDesktop() && (
                                                <TableCell
                                                  rowSpan={game.idspt != 'NHL' && line.vspoddst != '' ? 3 : 2}
                                                  style={{ borderBottom: '2px solid #515151' }}
                                                >
                                                  <span style={{ color: '#c8a964' }}>{militaryToHour(game.gmtm)}</span>
                                                </TableCell>
                                              )}
                                              {isDesktop() && (
                                                <TableCell align='center' className='bg'>
                                                  {game.vnum}
                                                </TableCell>
                                              )}
                                              {isDesktop() && <TableCell align='center'>{game.vtm}</TableCell>}
                                              <TableCell
                                                align='center'
                                                className={!line.VisitorSpreadSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                              >
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.VISITORSPREAD);
                                                  }}
                                                  className='spreadText spreadColor'
                                                  dangerouslySetInnerHTML={{ __html: line.vsprdh }}
                                                ></div>
                                              </TableCell>
                                              <TableCell
                                                align='center'
                                                className={!line.VisitorMlSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                              >
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.VISITORMONEYLINE);
                                                  }}
                                                  className='spreadText spreadColor'
                                                  dangerouslySetInnerHTML={{ __html: line.voddsh }}
                                                ></div>
                                              </TableCell>
                                              <TableCell
                                                align='center'
                                                className={!line.VisitorTotalSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                              >
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.VISITORTOTAL);
                                                  }}
                                                  className='spreadText spreadColor'
                                                  dangerouslySetInnerHTML={{ __html: line.ovh }}
                                                ></div>
                                              </TableCell>
                                            </TableRow>
                                            {!isDesktop() && (
                                              <TableRow className={classes.bb0}>
                                                <TableCell colSpan={3}>
                                                  <span className={classes.mobTeam} style={{ color: '#fff', fontWeight: 'bold' }}>
                                                    {game.htm}
                                                  </span>
                                                </TableCell>
                                              </TableRow>
                                            )}
                                            {/* style={{ borderBottom: '2px solid #515151' }} */}
                                            <TableRow className={classes.bb0}>
                                              {isDesktop() && (
                                                <TableCell align='center' className='bg'>
                                                  {game.hnum}
                                                </TableCell>
                                              )}
                                              {isDesktop() && <TableCell align='center'>{game.htm}</TableCell>}
                                              <TableCell
                                                align='center'
                                                className={!line.HomeSpreadSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                              >
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.HOMESPREAD);
                                                  }}
                                                  className='spreadText spreadColor'
                                                  dangerouslySetInnerHTML={{ __html: line.hsprdh }}
                                                ></div>
                                              </TableCell>
                                              <TableCell
                                                align='center'
                                                className={!line.HomeMlSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                              >
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.HOMEMONEYLINE);
                                                  }}
                                                  className='spreadText spreadColor'
                                                  dangerouslySetInnerHTML={{ __html: line.hoddsh }}
                                                ></div>
                                              </TableCell>
                                              <TableCell
                                                className={!line.HomeTotalSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                align='center'
                                              >
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.HOMETOTAL);
                                                  }}
                                                  className='spreadText spreadColor'
                                                  dangerouslySetInnerHTML={{ __html: line.unh }}
                                                ></div>
                                              </TableCell>
                                            </TableRow>

                                            {game.idspt != 'NHL' && line.vspoddst != '' && !isDesktop() && (
                                              <TableRow className={classes.bb0}>
                                                <TableCell colSpan={3}>
                                                  <span className={classes.mobTeam} style={{ color: '#fff', fontWeight: 'bold' }}>
                                                    DRAW
                                                  </span>
                                                </TableCell>
                                              </TableRow>
                                            )}

                                            {game.idspt != 'NHL' && line.vspoddst != '' && (
                                              <TableRow className={isDesktop() ? '' : classes.bb0}>
                                                {isDesktop() && (
                                                  <TableCell align='center' className='bg'>
                                                    {Number(game.hnum) + 1}
                                                  </TableCell>
                                                )}
                                                {isDesktop() && <TableCell align='center'>DRAW</TableCell>}
                                                <TableCell className={'bg spreadRow'} align='center'>
                                                  <div className='spreadText spreadColor'></div>
                                                </TableCell>
                                                <TableCell
                                                  align='center'
                                                  className={!line.DrawSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                >
                                                  <div
                                                    className='spreadText spreadColor'
                                                    onClick={() => {
                                                      selectLine(league, game, line, PLAY_LINE.DRAW);
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: line.vsph }}
                                                  ></div>
                                                </TableCell>
                                                <TableCell align='center' className={'bg spreadRow'}>
                                                  <div className='spreadText spreadColor'></div>
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </React.Fragment>
                                        );
                                      })
                                    : game.idspt != 'PROP'
                                    ? game.line.map((line: any, o: number) => {
                                        return (
                                          innerCollapse.indexOf(game.idgm) == -1 && (
                                            <TableRow key={'TNT' + o} style={{ borderBottom: '2px solid #515151' }}>
                                              {isDesktop() && <TableCell className='frColor'>{militaryToHour(item.game.gmtm)}</TableCell>}
                                              <TableCell className='bg' colSpan={isDesktop() ? 3 : 2}>
                                                {line.tmnum}&nbsp;&nbsp;{line.tmname}
                                              </TableCell>
                                              <TableCell className={!line.TntSelected ? '' : 'betActive'} align='center'>
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.TNT);
                                                  }}
                                                  className='spreadColor'
                                                >
                                                  {line.oddsh}
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        );
                                      })
                                    : game.line.map((line: any, y: number) => {
                                        return (
                                          innerCollapse.indexOf(game.idgm) == -1 && (
                                            <TableRow key={'PROP' + y} style={{ borderBottom: '2px solid #515151' }}>
                                              {isDesktop() && <TableCell className='frColor'>{militaryToHour(game.gmtm)}</TableCell>}
                                              <TableCell className='bg' colSpan={isDesktop() ? 3 : 2}>
                                                {/* {line.tmnum == 0 ? game.vtm : line.tmnum}&nbsp;&nbsp; */}
                                                {!line.tmname ? game.htm : line.tmname}
                                              </TableCell>
                                              <TableCell className={!line.TntSelected ? '' : 'betActive'} align='center'>
                                                <div
                                                  onClick={() => {
                                                    selectLine(league, game, line, PLAY_LINE.TNT);
                                                  }}
                                                  className='spreadColor'
                                                >
                                                  {line.oddsh}
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        );
                                      })}

                                  {(game.Props || (game.Alternatives && game.Alternatives.length > 0)) && (
                                    <TableRow>
                                      {game.Props && (
                                        // <TableCell align='center' className={game.ShowProp ? 'btnSelected' : ''}>
                                        <TableCell align='center' style={game.ShowProp ? { background: '#000' } : {}}>
                                          <div onClick={() => ShowProps(game)} className='frColor'>
                                            <VipSportsIcon />
                                            <span style={{ top: '-7px', position: 'relative' }}>Props</span>
                                          </div>
                                        </TableCell>
                                      )}
                                      {game.Alternatives && game.Alternatives.length > 0 && (
                                        // className={game.ShowAlternatives ? 'btnSelected' : ''}
                                        <TableCell colSpan={2} align='center' style={game.ShowAlternatives ? { background: '#000' } : {}}>
                                          <div onClick={() => ShowAlternatives(game)} className='frColor'>
                                            <FontAwesomeIcon icon={['fad', 'layer-plus']} style={{ fontSize: '1.5rem' }} /> Alternatives
                                          </div>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  )}
                                  {game.ShowAlternatives && (
                                    <>
                                      {game.Alternatives.map((alGame: any, z: number) => {
                                        return (
                                          <React.Fragment key={z}>
                                            {alGame.line.map((alLine: any, o: number) => {
                                              return (
                                                <>
                                                  <React.Fragment key={'alternative' + o}>
                                                    {!isDesktop() && (
                                                      <TableRow className={`${classes.bb0} vip-fadeIn`}>
                                                        <TableCell colSpan={3}>
                                                          <span className={classes.mobTeam}>
                                                            <span style={{ color: '#fff', fontWeight: 'bold' }}>{alGame.vtm}</span>{' '}
                                                            &nbsp;&nbsp;
                                                            <span style={{ color: '#c8a964' }}>{militaryToHour(alGame.gmtm)}</span>
                                                          </span>
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                    <TableRow className={'vip-fadeIn'}>
                                                      {isDesktop() && (
                                                        <TableCell
                                                          rowSpan={alGame.idspt != 'NHL' && alLine.vspoddst != '' ? 3 : 2}
                                                          style={{ borderBottom: '0' }}
                                                        >
                                                          <span style={{ color: '#c8a964' }}>{militaryToHour(alGame.gmtm)}</span>
                                                        </TableCell>
                                                      )}
                                                      {isDesktop() && (
                                                        <TableCell align='center' className='bg' style={{ borderBottom: '0' }}>
                                                          {alGame.vnum}
                                                        </TableCell>
                                                      )}
                                                      {isDesktop() && (
                                                        <TableCell align='center' style={{ borderBottom: '0' }}>
                                                          {alGame.vtm}
                                                        </TableCell>
                                                      )}
                                                      <TableCell
                                                        align='center'
                                                        style={{ borderBottom: '0' }}
                                                        className={
                                                          !alLine.VisitorSpreadSelected ? 'bg spreadRow' : 'bg spreadRow betActive'
                                                        }
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            selectLine(league, alGame, alLine, PLAY_LINE.VISITORSPREAD);
                                                          }}
                                                          className='spreadText spreadColor'
                                                          dangerouslySetInnerHTML={{ __html: alLine.vsprdh }}
                                                        ></div>
                                                      </TableCell>
                                                      <TableCell
                                                        align='center'
                                                        className={!alLine.VisitorMlSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            selectLine(league, alGame, alLine, PLAY_LINE.VISITORMONEYLINE);
                                                          }}
                                                          className='spreadText spreadColor'
                                                          dangerouslySetInnerHTML={{ __html: alLine.voddsh }}
                                                        ></div>
                                                      </TableCell>
                                                      <TableCell
                                                        align='center'
                                                        className={!alLine.VisitorTotalSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            selectLine(league, alGame, alLine, PLAY_LINE.VISITORTOTAL);
                                                          }}
                                                          className='spreadText spreadColor'
                                                          dangerouslySetInnerHTML={{ __html: alLine.ovh }}
                                                        ></div>
                                                      </TableCell>
                                                    </TableRow>
                                                    {!isDesktop() && (
                                                      <TableRow className={classes.bb0}>
                                                        <TableCell colSpan={3}>
                                                          <span className={classes.mobTeam} style={{ color: '#fff', fontWeight: 'bold' }}>
                                                            {alGame.htm}
                                                          </span>
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                    {/* style={{ borderBottom: '2px solid #515151' }} */}
                                                    {/* <TableRow className={classes.bb0}> */}
                                                    <TableRow>
                                                      {isDesktop() && (
                                                        <TableCell align='center' className='bg'>
                                                          {alGame.hnum}
                                                        </TableCell>
                                                      )}
                                                      {isDesktop() && <TableCell align='center'>{alGame.htm}</TableCell>}
                                                      <TableCell
                                                        align='center'
                                                        className={!alLine.HomeSpreadSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            selectLine(league, alGame, alLine, PLAY_LINE.HOMESPREAD);
                                                          }}
                                                          className='spreadText spreadColor'
                                                          dangerouslySetInnerHTML={{ __html: alLine.hsprdh }}
                                                        ></div>
                                                      </TableCell>
                                                      <TableCell
                                                        align='center'
                                                        className={!alLine.HomeMlSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            selectLine(league, alGame, alLine, PLAY_LINE.HOMEMONEYLINE);
                                                          }}
                                                          className='spreadText spreadColor'
                                                          dangerouslySetInnerHTML={{ __html: alLine.hoddsh }}
                                                        ></div>
                                                      </TableCell>
                                                      <TableCell
                                                        className={!alLine.HomeTotalSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                        align='center'
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            selectLine(league, alGame, alLine, PLAY_LINE.HOMETOTAL);
                                                          }}
                                                          className='spreadText spreadColor'
                                                          dangerouslySetInnerHTML={{ __html: alLine.unh }}
                                                        ></div>
                                                      </TableCell>
                                                    </TableRow>

                                                    {alGame.idspt != 'NHL' && alLine.vspoddst != '' && !isDesktop() && (
                                                      <TableRow className={classes.bb0}>
                                                        <TableCell colSpan={3}>
                                                          <span className={classes.mobTeam} style={{ color: '#fff', fontWeight: 'bold' }}>
                                                            DRAW
                                                          </span>
                                                        </TableCell>
                                                      </TableRow>
                                                    )}

                                                    {alGame.idspt != 'NHL' && alLine.vspoddst != '' && (
                                                      <TableRow className={isDesktop() ? '' : classes.bb0}>
                                                        {isDesktop() && (
                                                          <TableCell align='center' className='bg'>
                                                            {Number(alGame.hnum) + 1}
                                                          </TableCell>
                                                        )}
                                                        {isDesktop() && <TableCell align='center'>DRAW</TableCell>}
                                                        <TableCell className={'bg spreadRow'} align='center'>
                                                          <div className='spreadText spreadColor'></div>
                                                        </TableCell>
                                                        <TableCell
                                                          align='center'
                                                          className={!alLine.DrawSelected ? 'bg spreadRow' : 'bg spreadRow betActive'}
                                                        >
                                                          <div
                                                            className='spreadText spreadColor'
                                                            onClick={() => {
                                                              selectLine(league, alGame, alLine, PLAY_LINE.DRAW);
                                                            }}
                                                            dangerouslySetInnerHTML={{ __html: alLine.vsph }}
                                                          ></div>
                                                        </TableCell>
                                                        <TableCell align='center' className={'bg spreadRow'}>
                                                          <div className='spreadText spreadColor'></div>
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                  </React.Fragment>
                                                </>
                                              );
                                            })}
                                          </React.Fragment>
                                        );
                                      })}
                                    </>
                                  )}
                                  {game.ShowProp && (
                                    <TableRow>
                                      <TableCell colSpan={42}>
                                        <iframe src={game.Props} width='100%' height='400px'></iframe>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                                {/* im use ! = show always */}
                              </>
                            );
                          })}
                        </Table>
                      </TableContainer>
                    )}
                </div>
              );
            })}
          </>
        ) : (
          <Typography variant='h6' style={{ textAlign: 'center', marginTop: '1rem' }}>
            Please select a Sport.
            <hr />
          </Typography>
        )}
        {/* {isTvOn && lines.length <= 0 && (
          <Draggable handle='#draggable-video' cancel={'[class*="MuiDialogContent-root"]'}>
            <div className={classes.videoPlayer} aria-labelledby='draggable-video'>
              {v && (
                <div id='draggable-video'>
                  <Player url={nUrl} />
                </div>
              )}
              <FormControl fullWidth className={classes.channelSelect}>
                <Select fullWidth value={channel} onChange={e => handleChannel((e.target.value as unknown) as number)}>
                  <MenuItem value={0}>
                    <em>Select Channel</em>
                  </MenuItem>
                  <MenuItem value={7}>
                    <img src={`${process.env.PUBLIC_URL}/images/6878BE515D5544A2B7D8BDAF467A1181.png`} width='50' height='50' />
                    &nbsp;&nbsp;ESPN Network
                  </MenuItem>
                  <MenuItem value={1}>
                    <img src={`${process.env.PUBLIC_URL}/images/E265811C346143BA86DFE7773E658EB4.png`} width='50' height='50' />
                    &nbsp;&nbsp;NBA TV
                  </MenuItem>
                  <MenuItem value={6}>
                    <img src={`${process.env.PUBLIC_URL}/images/1BDC65FF4FB54713939660237B0E7AEB.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;NBCSN California
                  </MenuItem>
                  <MenuItem value={2}>
                    <img src={`${process.env.PUBLIC_URL}/images/CD39FBD56A9E4D69A12B64E56732356A.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;NHL Network
                  </MenuItem>
                  <MenuItem value={3}>
                    <img src={`${process.env.PUBLIC_URL}/images/510C3F675E174A27A7F49F7B881D9532.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;115814
                  </MenuItem>
                  <MenuItem value={4}>
                    <img src={`${process.env.PUBLIC_URL}/images/16F8BC869B5349BD9A79C902CBDCDF04.gif`} width='50' height='50' />
                    &nbsp;&nbsp;ESPN 2
                  </MenuItem>
                  <MenuItem value={5}>
                    <img src={`${process.env.PUBLIC_URL}/images/6878BE515D5544A2B7D8BDAF467A1181.png`} width='50' height='50' />
                    &nbsp;&nbsp;ESPN
                  </MenuItem>
                  <MenuItem value={8}>
                    <img src={`${process.env.PUBLIC_URL}/images/0F8E31CF030A4406B97A1DF3F30497B4.png`} width='50' height='50' />
                    &nbsp;&nbsp;TVG
                  </MenuItem>
                  <MenuItem value={10}>
                    <img src={`${process.env.PUBLIC_URL}/images/2122F140FCB04622B93FE1AADD355982.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;ROOT Sports
                  </MenuItem>
                  <MenuItem value={9}>
                    <img src={`${process.env.PUBLIC_URL}/images/605C994ABBD94A5BBC16EF059A08CCB1.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;TVG2
                  </MenuItem>
                  <MenuItem value={11}>
                    <img src={`${process.env.PUBLIC_URL}/images/D2987B922DA842ABBB5769C4E2D466E8.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;Longhorn Network
                  </MenuItem>
                  <MenuItem value={12}>
                    <img src={`${process.env.PUBLIC_URL}/images/5EFB5F2F8B7F4DB99842AF474208F9A3.png`} width='50' height='50' />
                    &nbsp;&nbsp;ESPN U
                  </MenuItem>
                  <MenuItem value={13}>
                    <img src={`${process.env.PUBLIC_URL}/images/AE9F6DDF52AF4E9FA8FA1C8688D35352.png`} width='50' height='50' />
                    &nbsp;&nbsp;Stadium 3
                  </MenuItem>
                  <MenuItem value={14}>
                    <img src={`${process.env.PUBLIC_URL}/images/85E76B2A17E44A99A487119E11072191.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;YES Network
                  </MenuItem>
                  <MenuItem value={15}>
                    <img src={`${process.env.PUBLIC_URL}/images/39AD4C8AC42241E0937A496B5AFA627C.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;Big Ten Network
                  </MenuItem>
                  <MenuItem value={16}>
                    <img src={`${process.env.PUBLIC_URL}/images/3E264C11E45B4BFDA4E3383E92B4C70E.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;VSiN
                  </MenuItem>
                  <MenuItem value={17}>
                    <img src={`${process.env.PUBLIC_URL}/images/60A485DA63B945698101615A9C9FF357.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;PAC 12 Network
                  </MenuItem>
                  <MenuItem value={18}>
                    <img src={`${process.env.PUBLIC_URL}/images/01A85EDB216046CE82279AFF83711658.jpg`} width='50' height='50' />
                    &nbsp;&nbsp;NESN
                  </MenuItem>
                  <MenuItem value={19}>
                    <img src={`${process.env.PUBLIC_URL}/images/98FF47AA01164C44A7FFBF363E567A67_1.png`} width='50' height='50' />
                    &nbsp;&nbsp;MASN 2
                  </MenuItem>
                  <MenuItem value={20}>
                    <img src={`${process.env.PUBLIC_URL}/images/30593E9FE6BE4D08BEA6BC67AC587F48.png`} width='50' height='50' />
                    &nbsp;&nbsp;MSG
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Draggable>
        )} */}
      </div>
    </div>
  );
};
