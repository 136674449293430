import { createSelector } from 'reselect';
import { guideSelectionReducer, GuideSelectionSliceType } from './guide.reducer';

export const guideSelectionSliceSelector = (state: any): any => state[guideSelectionReducer.sliceName];

export const isLoadingSliceSelector = createSelector(
  guideSelectionSliceSelector,
  (guideSelectionSlice: GuideSelectionSliceType) => guideSelectionSlice.isLoading,
);

export const guidesSelector = createSelector(
  guideSelectionSliceSelector,
  (guideSelectionSlice: GuideSelectionSliceType) => guideSelectionSlice.guides,
);

export const categoriesSelector = createSelector(
  guideSelectionSliceSelector,
  (guideSelectionSlice: GuideSelectionSliceType) => guideSelectionSlice.categories,
);
