import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const GET_MIDDLE_SECTION = `GET_MIDDLE_SECTION${suffix}`;

export const getMiddleSectionAction = createAction<any>(GET_MIDDLE_SECTION);

const GET_MIDDLE_SECTION_SUCCESS = `GET_MIDDLE_SECTION_SUCCESS${suffix}`;
const getMiddleSectionSuccessAction = createAction<any[]>(GET_MIDDLE_SECTION_SUCCESS);

const GET_MIDDLE_SECTION_ERROR = `GET_MIDDLE_SECTION_ERROR${suffix}`;
const getMiddleSectionErrorAction = createAction<string>(GET_MIDDLE_SECTION_ERROR);

function* getMiddleSectionWatcher(): SagaIterator {
  yield takeEvery(GET_MIDDLE_SECTION, getMiddleSectionWorker);
}
mergeSaga(getMiddleSectionWatcher);

function* getMiddleSectionWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getMiddleSectionApi, action.payload);
    yield put(getMiddleSectionSuccessAction(result));
  } catch (e) {
    yield put(getMiddleSectionErrorAction(e));
  }
}

const getMiddleSectionApi = (data: any): Request => {
  return vipApi(`/wager/getSchedulesByUsernameAndLeagueIds`, 'post', data);
};

const reduceHandlers = {
  [GET_MIDDLE_SECTION]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_MIDDLE_SECTION_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    lines: slice.lines.concat(action.payload),
  }),
  [GET_MIDDLE_SECTION_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
