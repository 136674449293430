import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'guideSelectionSlice';

export interface GuideSelectionSliceType {
  isLoading: boolean;
  isSaving: boolean;
  guides: any[];
  categories: any[];
}

export const initialState: GuideSelectionSliceType = {
  isLoading: false,
  isSaving: false,
  guides: [],
  categories: [],
};

export const guideSelectionReducer = createAndMergeSliceReducer(sliceName, initialState);
