import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const BET_RESULTS = `BET_RESULTS${suffix}`;

export const betResultsAction = createAction<boolean>(BET_RESULTS);

const BET_RESULTS_SUCCESS = `BET_RESULTS_SUCCESS${suffix}`;
const betResultsSuccessAction = createAction<boolean>(BET_RESULTS_SUCCESS);

const BET_RESULTS_ERROR = `BET_RESULTS_ERROR${suffix}`;
const betResultsErrorAction = createAction<string>(BET_RESULTS_ERROR);

function* betResultsWatcher(): SagaIterator {
  yield takeEvery(BET_RESULTS, betResultsWorker);
}
mergeSaga(betResultsWatcher);

function* betResultsWorker(action: any): SagaIterator {
  try {
    yield put(betResultsSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(betResultsErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [BET_RESULTS]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [BET_RESULTS_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    betResults: action.payload,
  }),
  [BET_RESULTS_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
