import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const CHANGE_CASINO_GAMES = `CHANGE_CASINO_GAMES${suffix}`;

export const changeCasinoGamesAction = createAction<any>(CHANGE_CASINO_GAMES);

const CHANGE_CASINO_GAMES_SUCCESS = `CHANGE_CASINO_GAMES_SUCCESS${suffix}`;
const changeCasinoGamesSuccessAction = createAction<any[]>(CHANGE_CASINO_GAMES_SUCCESS);

const CHANGE_CASINO_GAMES_ERROR = `CHANGE_CASINO_GAMES_ERROR${suffix}`;
const changeCasinoGamesErrorAction = createAction<string>(CHANGE_CASINO_GAMES_ERROR);

function* changeCasinoGamesWatcher(): SagaIterator {
  yield takeEvery(CHANGE_CASINO_GAMES, changeCasinoGamesWorker);
}
mergeSaga(changeCasinoGamesWatcher);

function* changeCasinoGamesWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(changeCasinoGamesApi, action.payload);
    yield put(changeCasinoGamesSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(changeCasinoGamesErrorAction(e));
  }
}

const changeCasinoGamesApi = (x: any): Request => {
  return vipApi(`/ptpi/categoriesWithGames`, 'post', {
    device_id: x.deviceId,
    categories: x.category,
    providers: x.providerId,
  });
};

const reduceHandlers = {
  [CHANGE_CASINO_GAMES]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CHANGE_CASINO_GAMES_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    casinoGames: action.payload.data[0].games,
  }),
  [CHANGE_CASINO_GAMES_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
