import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const GET_TEASERS = `GET_TEASERS${suffix}`;

export const getTeasersAction = createAction(GET_TEASERS);

const GET_TEASERS_SUCCESS = `GET_TEASERS_SUCCESS${suffix}`;
const getTeasersSuccessAction = createAction<any[]>(GET_TEASERS_SUCCESS);

const GET_TEASERS_ERROR = `GET_TEASERS_ERROR${suffix}`;
const getTeasersErrorAction = createAction<string>(GET_TEASERS_ERROR);

function* getTeasersWatcher(): SagaIterator {
  yield takeEvery(GET_TEASERS, getTeasersWorker);
}
mergeSaga(getTeasersWatcher);

function* getTeasersWorker(): SagaIterator {
  try {
    const result: any = yield call(getTeasersApi);
    yield put(getTeasersSuccessAction(result));
  } catch (e) {
    yield put(getTeasersErrorAction(e));
  }
}

const getTeasersApi = (): Request => {
  return vipApi(`/wager/Teasers`, 'post', {});
};

const reduceHandlers = {
  [GET_TEASERS]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_TEASERS_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    teasers: action.payload,
  }),
  [GET_TEASERS_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
