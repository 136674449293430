import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl, InputLabel } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import SendIcon from '@material-ui/icons/Send';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { WAGER_TYPE as WT, GetWagerTypeDescription, BET_STEP, WAGER_TYPE } from '../../enum';
import { DGS_BET_TYPE_LOGIC as DBTL } from '../../enum/betslip/dgs-bet-type-logic';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { VipTicketIcon } from '../icons/ticket-icon';
import { PendingBetPage } from './pending-bet';
import { RISK_WIN } from '../../enum/betslip/risk-win';
import CancelIcon from '@material-ui/icons/Cancel';

const drawerWidth = 240;
const drawerRightWidth = 500;

// let _WagerTypeList = [WT.STRAIGHT, WT.PARLAY];
let _WagerTypeList = [WT.STRAIGHT];
_WagerTypeList = [..._WagerTypeList, ...[WT.PARLAY]];

let _PlusWagerTypeList = [WT.TEASER];
_PlusWagerTypeList = [..._PlusWagerTypeList, ...[WT.ROUNDROBIN]];
// _PlusWagerTypeList = [..._PlusWagerTypeList, ...[WT.ROUNDROBIN, WT.IFWIN]];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 'auto',
    borderRight: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: '#000',
    [theme.breakpoints.up('md')]: {
      minHeight: '48px',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  serachTextField: {
    paddingLeft: '10px',
    '&>.MuiInput-underline': {
      '&::before, &::after': {
        borderBottom: 0,
      },
    },
  },
  betLiveNow: {
    display: 'flex',
    backgroundImage: 'linear-gradient(to right, #c8a964, #bc8818, #c8a964)',
    borderRadius: '7px',
    padding: '10px 5px 10px 10px',
    justifyContent: 'space-between',
  },
  nestedOpenList: {
    paddingLeft: theme.spacing(4),
  },
  disabledListItem: {
    color: '#999',
  },
  todayGame: {
    width: '100%',
    backgroundColor: '#000',
    '& span': {
      fontSize: '12px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '14px',
    },
  },
  middleSection: {
    width: 'calc(100% - 740px)',
  },
  middleHeader: {
    height: '48px',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px',
  },
  gameBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    height: '3rem',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '2.5rem',
      marginRight: '1.2rem',
    },
    '& p': {
      fontSize: '25px',
      fontWeight: 800,
    },
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      padding: '.9rem 5rem',
      marginRight: '1rem',
      backgroundColor: '#fff',
      color: '#000',
      clipPath: 'polygon(50px 0%, 0% 100%, 100% 50px, calc(100% - 50px) 100%, 100% 0%)',
    },
  },
  tab: {
    minWidth: 180,
    color: '#707070',
  },
  appBg: {
    backgroundColor: '#2d2d2d',
  },
  betTable: {
    '& th, td': {
      padding: '5px 16px',
    },
    '& th.bg, td.bg': {
      backgroundColor: '#2d2d2d',
    },
  },
  carHeader: {
    // textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '48px',
    verticalAlign: 'middle',
    background: '#000',
  },
  carHeaderItem: {
    flex: '1',
    height: '48px',
  },
  carHeaderLeft: {
    background: '#c8a964',
    borderTopRightRadius: '10px',
  },
  carHeaderLeftBetslip: {
    textAlign: 'initial',
    lineHeight: '45px',
  },
  carHeaderRight: {
    textAlign: 'center',
    lineHeight: '45px',
  },
  drawerRight: {
    width: drawerRightWidth,
    height: '48px',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  drawerRightPaper: {
    width: drawerRightWidth,
    top: 'auto',
    zIndex: 1099,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  drawerRightHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  rightTopTab: {
    textAlign: 'center',
    backgroundColor: '#000',
    '& button': {
      width: '50%',
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: '10px',
      },
    },
  },
  innerRightTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
    paddingRight: '10px',
    paddingLeft: '10px',
    backgroundColor: theme.palette.primary.main,
    '& span': {
      padding: '3px 15px',
      borderRadius: '5px',
      backgroundColor: '#fff',
      color: '#000',
      marginRight: '5px',
      cursor: 'pointer',
      '&.selected': {
        backgroundColor: '#000',
        color: theme.palette.primary.main,
      },
    },
  },
  selectBetType: {
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#000',
    padding: '0px 15px',
    height: '27px',
    '& svg.MuiSelect-icon': {
      color: '#000',
    },
  },
  rightRiskSelectionGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#000',
    '& .MuiFilledInput-underline': {
      borderRadius: '5px',
      '&::after, &::before': {
        borderBottom: 0,
      },
      '& input': {
        padding: '5px 10px',
      },
    },
  },
  nuggetText: {
    fontSize: '14px',
  },
  nuggetCloseIcon: {
    padding: '5px',
    cursor: 'pointer',
    background: '#E7593B',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  nuggetInput: {
    '& input, & .MuiSelect-filled.MuiSelect-filled': {
      padding: '24px 12px 4px',
    },
  },
  center: {
    textAlign: 'center',
  },
  betPart: {
    paddingLeft: '20px',
    paddingRight: '20px',
    overflow: 'auto',
    height: 'calc(100vh - 300px)',
  },
  betPartTvOn: {
    height: 'calc(50vh - 150px)',
  },
  emptySelectionContainer: {},
  boxNugget: {},
  betContainer: {
    justifyContent: 'space-between',
    backgroundColor: '#000',
    margin: '10px',
    marginBottom: '300px',
    padding: '15px 20px',
    borderRadius: '10px',
    overflowY: 'auto',
    maxHeight: '45%',
    ['@media only screen and (max-height: 600px)']: {
      maxHeight: '20%',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: '67%',
    },
  },
  wagerContainer: {},
  wagerTypeDescription: {},
  detailsContainer: {},
  detailLine: {
    margin: '7px 0px',
    borderBottom: 'solid 1px #000',
  },
  detailDescription: {},
  detailBuyPoint: {},
  detailBetContainer: {},
  detailBetItem: {},
  wagerRoundRobin: {},
  wagerBuyPointTeaser: {},
  wagerBetContainer: {},
  wagerFreePlayContainer: {},
  postContainer: {},
  postSuccessMsg: {
    margin: '15px 40px 15px 40px;',
  },
  postWagerContainer: {},
  postRiskWinContainer: {
    margin: '15px 40px 15px 40px;',
  },
  postTicketMsj: {
    color: '#FFF !important',
    // color: '#4caf50 !important',
    borderTop: '2px solid',
  },
  postRiskWinDetail: {},
  postRiskWinRisk: {},
  postRiskWinWin: {},
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  playerLines: {
    position: 'absolute',
    marginLeft: '10px',
    marginTop: '-42px',
    fontSize: '18px',
    color: '#c8a964',
    fontWeight: 900,
  },
  iconHeader: {},
  marginCenter: {
    margin: 'auto',
  },
  betAmounTypeForm: {
    marginTop: '15px',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface RightNavProps {
  wagerType: WT;
  setWagerType: (pWagerType: WT) => void;
  removeBetSlip: (fb: any, index: number) => void;
  calculateWin: (data: any, value: number) => void;
  calculateRisk: (data: any, value: number) => void;
  riskAllSelection: (value: number) => void;
  removePlayerLines: () => void;
  setBuyPoints: (data: any, value: number) => void;
  setIsBonus: (a: string) => void;
  userBalance: any;
  open: boolean;
  teaser: any;
  teasers: any;
  selectTeaser: (a: any) => void;
  step: BET_STEP;
  playerLines: any[];
  betsResult: any;
  placeBet: () => void;
  confirmBet: () => void;
  isLoading: boolean;
  DgsBetTypeLogic: (pWagerType: WAGER_TYPE) => DBTL;
  selectRoundRobin: (data: any, pRr: any) => void;
  isTvOn: boolean;
  url: string;
  isTvSetUp: boolean;
  selectBuyPointsTeaser: (data: any, pRr: any) => void;
  riskall: any;
  getPendingBetsAction: () => void;
  pendingBets: any;
  pendingBetsLoading: boolean;
  selectBetTypeAmount: (data: any) => void;
  setOpen: (x: boolean) => void;
  UseLinesOtherBet: () => void;
  closeAll: () => void;
}

export const RightNav = ({
  wagerType,
  setWagerType,
  removeBetSlip,
  calculateWin,
  calculateRisk,
  riskAllSelection,
  removePlayerLines,
  setBuyPoints,
  setIsBonus,
  userBalance,
  open,
  teaser,
  teasers,
  selectTeaser,
  step,
  playerLines,
  betsResult,
  placeBet,
  confirmBet,
  isLoading,
  // DgsBetTypeLogic,
  selectRoundRobin,
  selectBuyPointsTeaser,
  riskall,
  pendingBets,
  pendingBetsLoading,
  getPendingBetsAction,
  selectBetTypeAmount,
  setOpen,
  UseLinesOtherBet,
  closeAll,
}: RightNavProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [alertFreePlay, setAlertFreePlay] = React.useState<boolean>(false);
  const ShowAlertFreePlay = () => {
    setAlertFreePlay(true);
  };
  const HiddenAlertFreePlay = () => {
    setAlertFreePlay(false);
  };

  useEffect(() => {
    if (value != 0) setValue(0);
  }, [playerLines]);

  function drawarOpenDefault(): boolean {
    return window.innerWidth >= 1280;
  }

  function PostRiskWinSummary(props: any) {
    const { risk, win } = props;
    return (
      <Grid container direction='row' className={classes.postRiskWinContainer}>
        <Grid item xs={6} className={`${classes.postRiskWinDetail} ${classes.postRiskWinRisk}`}>
          <Typography className={classes.center} color='primary'>
            Risk
            <br />${risk}
          </Typography>
        </Grid>
        <Grid item xs={6} className={`${classes.postRiskWinDetail}  ${classes.postRiskWinWin}`}>
          <Typography className={classes.center} color='primary'>
            Win
            <br />${win}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawerRight}
        variant='persistent'
        anchor='right'
        open={open}
        classes={{
          paper: classes.drawerRightPaper,
        }}
      >
        <Grid container className={classes.carHeader}>
          <Grid item xs={6} className={`${classes.carHeaderItem} ${classes.carHeaderLeft}`}>
            <Grid container onClick={() => setValue(0)}>
              <Grid item xs={4}>
                {drawarOpenDefault() ? (
                  <>
                    <VipTicketIcon
                      rgb={'rgb(0,0,0)'}
                      style={{
                        width: '35px',
                        height: '45',
                        marginLeft: '5px',
                      }}
                    />
                    <div className={classes.playerLines}>({playerLines.length ?? 0})</div>
                  </>
                ) : (
                  <CancelIcon
                    style={{ margin: '10px' }}
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={8} className={classes.carHeaderLeftBetslip}>
                <div>BET SLIP</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            className={`${classes.carHeaderItem} ${classes.carHeaderRight}`}
            onClick={() => {
              setValue(1);
              getPendingBetsAction();
            }}
          >
            PENDING BETS
          </Grid>
        </Grid>
        {/* <Tabs value={0} className={classes.rightTopTab} indicatorColor='primary'>
          <Tab label='BET SLIP' />
          <Tab
            label='PENDING BETS'
            onClick={() => {
              setValue(1);
              getPendingBetsAction();
            }}
          />
        </Tabs> */}
        <TabPanel value={value} index={0}>
          <div className={classes.innerRightTabs}>
            <div>
              {_WagerTypeList &&
                _WagerTypeList.map((pWagerType: WT, index: number) => {
                  return (
                    <span
                      key={pWagerType + index}
                      className={wagerType == pWagerType ? 'selected' : ''}
                      onClick={() => {
                        setWagerType(pWagerType);
                      }}
                    >
                      {GetWagerTypeDescription(pWagerType)}
                    </span>
                  );
                })}
            </div>
            <Select className={classes.selectBetType} value='0'>
              <MenuItem value='0'>MORE</MenuItem>
              {_PlusWagerTypeList &&
                _PlusWagerTypeList.map((pWagerType: WT, index: number) => {
                  return (
                    <MenuItem
                      key={pWagerType + index + 'a'}
                      value={pWagerType}
                      onClick={() => {
                        setWagerType(pWagerType);
                        //Need change this for effect
                        if (!_WagerTypeList.find(x => x === pWagerType)) _WagerTypeList = [..._WagerTypeList, ...[pWagerType]];
                      }}
                    >
                      {GetWagerTypeDescription(pWagerType)}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          {/* Changes */}
          {/* Loading bar */}
          {isLoading && (
            <Grid container>
              <Grid item xs={12}>
                <LinearProgress color='primary' style={{ height: '7px' }} />
              </Grid>
            </Grid>
          )}
          {/* Risk All Pending */}
          {[WT.STRAIGHT, WT.IFWIN].indexOf(wagerType) != -1 && step == BET_STEP.COMPILED && playerLines.length > 1 && (
            <Grid container className={classes.rightRiskSelectionGrid}>
              <Grid item xs={7}>
                <Typography>Risk All Selection</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder='$0.00'
                  variant='filled'
                  inputProps={{ inputMode: 'numeric' }}
                  value={riskall.amount != null ? Number(riskall.amount) : ''}
                  onChange={e => riskAllSelection({ ...riskall, ...{ amount: ((Number(e.target.value) as unknown) as number) ?? 0 } })}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  size='small'
                  onClick={() => {
                    riskAllSelection({ ...riskall, ...{ amount: riskall?.amount ?? 0 } });
                  }}
                >
                  <SendIcon />
                </Button>
              </Grid>
            </Grid>
          )}

          {wagerType === WT.TEASER && (
            <Grid container>
              <FormControl variant='filled' fullWidth className={classes.formControl} style={{ margin: 0 }}>
                {/* <InputLabel>Teaser Type</InputLabel> */}
                <Select className={classes.nuggetInput} value={teaser} fullWidth onChange={e => selectTeaser(e.target.value as any)}>
                  <MenuItem value={undefined}>
                    <em>Select Teaser Type</em>
                  </MenuItem>
                  {teasers.data &&
                    teasers.data.map((teaser: any, j: number) => {
                      return (
                        <MenuItem value={teaser} key={j}>
                          <span dangerouslySetInnerHTML={{ __html: teaser.Name }} />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Box component='div' className={classes.betContainer}>
            {/* Here Bets Start, NOT Compiled */}
            {step === BET_STEP.INIT && playerLines.length > 0 && (
              <Grid container className={classes.detailsContainer}>
                {playerLines.map((item: any, index: number) => {
                  return (
                    <Grid container direction='row' className={classes.detailLine} key={item.Game.idgm + item.Game.mgdt + index}>
                      <Grid item xs={11}>
                        <Typography className={classes.nuggetText} color='primary'>
                          {item.Team}&nbsp;
                          <span dangerouslySetInnerHTML={{ __html: item.Selection }}></span>
                        </Typography>
                      </Grid>
                      <Grid item xs={1} style={{ zIndex: 2, display: 'flex' }}>
                        <CloseIcon
                          className={classes.nuggetCloseIcon}
                          onClick={() => {
                            removeBetSlip(item, index);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            {step === BET_STEP.INIT && playerLines.length == 0 && (
              <Grid container className={classes.emptySelectionContainer}>
                <Grid item xs={12}>
                  <Typography className={classes.nuggetText} color='primary'>
                    Select any line to continue <span style={{ fontSize: '24px' }}>&#128512;</span>
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* Here Bets COMPILE */}
            {step === BET_STEP.COMPILED && betsResult.compile && betsResult.compile.ErrorCode == '0' && (
              <>
                <Grid container className={classes.wagerContainer}>
                  <Grid item xs={12} className={classes.wagerTypeDescription}>
                    {betsResult.compile.wager[0].WagerTypeDesc}
                  </Grid>

                  {betsResult.compile.wager.map((wager: any, index: number) => {
                    return (
                      <Grid container className={classes.detailsContainer} key={'DC' + wager.IDWT + index}>
                        {wager.detail.map((detail: any, dIndex: number) => (
                          <>
                            <Grid container direction='row' className={classes.detailLine} key={'DL' + dIndex}>
                              {/* LineDescription */}
                              <Grid
                                item
                                className={classes.detailDescription}
                                xs={detail.buypoints && detail.buypoints.length > 0 ? 7 : 11}
                              >
                                <Typography className={classes.nuggetText} color='primary'>
                                  <span dangerouslySetInnerHTML={{ __html: detail.Description }}></span>
                                </Typography>
                              </Grid>

                              {/* detailBuyPoint */}
                              {detail.buypoints && detail.buypoints.length > 0 && (
                                <Grid item xs={4} className={classes.detailBuyPoint}>
                                  <FormControl variant='filled' fullWidth className={classes.formControl} style={{ margin: 0 }}>
                                    <InputLabel>Points</InputLabel>
                                    <Select
                                      className={classes.nuggetInput}
                                      value={detail.BuyPoints}
                                      fullWidth
                                      onChange={e => setBuyPoints({ wager, detail }, e.target.value as any)}
                                    >
                                      <MenuItem value={undefined}>
                                        <em>Buy No Points</em>
                                      </MenuItem>
                                      {detail.buypoints.map((bp: any, bpIndex: number) => {
                                        return (
                                          <MenuItem value={bp} key={bpIndex}>
                                            <span dangerouslySetInnerHTML={{ __html: `${bp.BuyPointsDesc} ${bp.LineDesc}` }} />
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={1} style={{ zIndex: 2, display: 'flex' }}>
                                <CloseIcon
                                  className={classes.nuggetCloseIcon}
                                  onClick={() => {
                                    removeBetSlip(wager, index);
                                  }}
                                />
                              </Grid>
                            </Grid>

                            {[WT.STRAIGHT, WT.IFWIN].indexOf(wagerType) != -1 && (
                              <Grid
                                container
                                direction='row'
                                spacing={2}
                                style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '5px' }}
                                className={classes.detailBetContainer}
                                key={'DBET' + dIndex}
                              >
                                <Grid item xs={6} style={{ display: 'flex' }} className={classes.detailBetItem}>
                                  <TextField
                                    className={classes.nuggetInput}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant='filled'
                                    label='Risk'
                                    placeholder='$0.00'
                                    value={wager.Risk == 0 ? '' : wager.Risk}
                                    onChange={e => calculateWin({ wager, detail }, (e.target.value as unknown) as number)}
                                  />
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex' }} className={classes.detailBetItem}>
                                  <TextField
                                    className={classes.nuggetInput}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant='filled'
                                    label='Win'
                                    placeholder='$0.00'
                                    value={wager.Win == 0 ? '' : wager.Win}
                                    onChange={e => calculateRisk({ wager, detail }, (e.target.value as unknown) as number)}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                        ))}

                        {WT.ROUNDROBIN == wagerType && betsResult.roundRobinCombinations && betsResult.roundRobinCombinations.length > 0 && (
                          <Grid item xs={12} className={classes.wagerRoundRobin}>
                            <FormControl variant='filled' fullWidth className={classes.formControl} style={{ margin: 0 }}>
                              <InputLabel>Round Robin Selection </InputLabel>
                              <Select
                                className={classes.nuggetInput}
                                value={wager.RoundRobinCombination}
                                fullWidth
                                onChange={e => selectRoundRobin({ wager }, e.target.value as any)}
                              >
                                {betsResult.roundRobinCombinations.map((rr: any, rrIndex: number) => {
                                  return (
                                    <MenuItem value={rr} key={'rr' + rrIndex}>
                                      <span dangerouslySetInnerHTML={{ __html: rr.Description }} />
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                        {WT.TEASER == wagerType && (wager.TeaserCanBuyHalf != '0' || wager.TeaserCanBuyOne != '0') && (
                          <Grid item xs={12} className={classes.wagerBuyPointTeaser}>
                            <FormControl variant='filled' fullWidth className={classes.formControl} style={{ margin: 0 }}>
                              <InputLabel>Buy Points </InputLabel>
                              <Select
                                className={classes.nuggetInput}
                                value={wager.TeaserPointsPurchased}
                                fullWidth
                                onChange={e => selectBuyPointsTeaser({ wager }, e.target.value as any)}
                              >
                                <MenuItem value={''}>No Buy Points</MenuItem>
                                {wager.TeaserCanBuyHalf != '0' && (
                                  <MenuItem value={'0.5'}>
                                    Buy Half Point &nbsp;
                                    {/* <span dangerouslySetInnerHTML={{ __html: wager.TeaserCanBuyHalf }} /> */}
                                  </MenuItem>
                                )}

                                {wager.TeaserCanBuyOne != '0' && (
                                  <MenuItem value={'1'}>
                                    Buy One Point &nbsp;
                                    {/* <span dangerouslySetInnerHTML={{ __html: wager.TeaserCanBuyOne }} /> */}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                        {[WT.PARLAY, WT.TEASER, WT.ROUNDROBIN].indexOf(wagerType) != -1 && (
                          <Grid
                            container
                            direction='row'
                            spacing={1}
                            style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px' }}
                            className={`${classes.wagerBetContainer}`}
                            key={'WBET' + index}
                          >
                            <Grid item xs={12} md={6} style={{ textAlign: 'center' }} className={`${classes.marginCenter}`}>
                              <TextField
                                className={`${classes.nuggetInput}`}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant='filled'
                                label='Wager Amount'
                                placeholder='$0.00'
                                value={wager.Risk == 0 ? '' : wager.Risk}
                                onChange={e => calculateWin({ wager }, (e.target.value as unknown) as number)}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {[WT.TEASER].indexOf(wagerType) != -1 && (
                          <FormControl component='fieldset' className={`${classes.marginCenter} ${classes.betAmounTypeForm}`}>
                            {/* <FormLabel component="legend">labelPlacement</FormLabel> */}
                            <RadioGroup
                              row
                              aria-label='position'
                              name='position'
                              value={wager.RiskWin}
                              onChange={e => selectBetTypeAmount((e.target.value as unknown) as string)}
                            >
                              <FormControlLabel
                                value={RISK_WIN.RISK}
                                control={<Radio color='primary' />}
                                label='Risk'
                                labelPlacement='start'
                              />
                              <FormControlLabel
                                value={RISK_WIN.WIN}
                                control={<Radio color='primary' />}
                                label='Win'
                                labelPlacement='start'
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Grid>
                    );
                  })}

                  <Grid
                    item
                    xs={12}
                    style={{ backgroundColor: '#222', margin: '15px', borderRadius: '10px', padding: '20px' }}
                    className={classes.wagerFreePlayContainer}
                  >
                    <FormControlLabel
                      onMouseEnter={ShowAlertFreePlay}
                      onMouseLeave={HiddenAlertFreePlay}
                      aria-haspopup='true'
                      control={
                        <Checkbox
                          checked={betsResult.compile.UseFreePlayAvailable == 'True'}
                          name='bonus'
                          color='primary'
                          onChange={() => {
                            betsResult.compile.UseFreePlayAvailable == 'False' ? setIsBonus('True') : setIsBonus('False');
                          }}
                          disabled={!(step == BET_STEP.COMPILED)}
                        />
                      }
                      label={`Use My Bonus Balance $${userBalance.FreePlayAmount}`}
                    />
                    <Collapse in={alertFreePlay}>
                      <Alert severity='warning' style={{ width: '100%' }}>
                        You can only use this option if your total bet amount is smaller or equal to your total bonus balance
                      </Alert>
                    </Collapse>
                  </Grid>
                </Grid>
              </>
            )}

            {/* Here Bets to confirm */}
            {(step === BET_STEP.CONFIRM || step === BET_STEP.ACEPT_CHANGES) && betsResult.confirm && betsResult.confirm.ErrorCode == '0' && (
              <>
                <Grid container className={classes.wagerContainer}>
                  <Grid item xs={12} className={classes.wagerTypeDescription}>
                    {betsResult.confirm.wager[0].WagerTypeDesc}
                  </Grid>

                  {betsResult.confirm.wager.map((wager: any, index: number) => {
                    return (
                      <Grid container className={classes.detailsContainer} key={'DC' + wager.IDWT + index}>
                        {wager.detail.map((detail: any, dIndex: number) => (
                          <>
                            <Grid container direction='row' className={classes.detailLine} key={'DL' + dIndex}>
                              {betsResult.confirm.LinesChanged == 1 && detail.PreviousLineDescription != '' && (
                                <>
                                  <Grid item xs={5}>
                                    <Typography className={classes.nuggetText} color='primary'>
                                      Line changed
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography className={classes.nuggetText} color='primary' style={{ color: '#E32749' }}>
                                      <span dangerouslySetInnerHTML={{ __html: detail.PreviousLineDescription }}></span>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <ArrowRightAltIcon />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography className={classes.nuggetText} color='primary' style={{ color: '#2AB40C' }}>
                                      <span dangerouslySetInnerHTML={{ __html: detail.LineDescription }}></span>
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                              {/* LineDescription */}
                              <Grid item className={classes.detailDescription} xs={11}>
                                <Typography className={classes.nuggetText} color='primary'>
                                  <span dangerouslySetInnerHTML={{ __html: detail.Description }}></span>
                                </Typography>
                              </Grid>
                              <Grid item xs={1} style={{ zIndex: 2, display: 'flex' }}>
                                <CloseIcon
                                  className={classes.nuggetCloseIcon}
                                  onClick={() => {
                                    removeBetSlip(wager, index);
                                  }}
                                />
                              </Grid>
                            </Grid>

                            {[WT.STRAIGHT, WT.IFWIN].indexOf(wagerType) != -1 && (
                              <Grid
                                container
                                direction='row'
                                spacing={2}
                                style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '5px' }}
                                className={classes.detailBetContainer}
                                key={'DBET' + dIndex}
                              >
                                <Grid item xs={6} style={{ display: 'flex' }} className={classes.detailBetItem}>
                                  <TextField
                                    className={classes.nuggetInput}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant='filled'
                                    label='Risk'
                                    placeholder='$0.00'
                                    value={wager.Risk == 0 ? '' : wager.Risk}
                                    onChange={e => calculateWin({ wager, detail }, (e.target.value as unknown) as number)}
                                  />
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex' }} className={classes.detailBetItem}>
                                  <TextField
                                    className={classes.nuggetInput}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant='filled'
                                    label='Win'
                                    placeholder='$0.00'
                                    value={wager.Win == 0 ? '' : wager.Win}
                                    onChange={e => calculateRisk({ wager, detail }, (e.target.value as unknown) as number)}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                        ))}

                        {[WT.PARLAY, WT.TEASER, WT.ROUNDROBIN].indexOf(wagerType) != -1 && (
                          <Grid
                            container
                            direction='row'
                            spacing={1}
                            style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '10px' }}
                            className={classes.wagerBetContainer}
                            key={'WBET' + index}
                          >
                            <Grid item xs={6} style={{ display: 'flex' }}>
                              <TextField
                                className={classes.nuggetInput}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant='filled'
                                label='Risk'
                                placeholder='$0.00'
                                value={wager.Risk}
                                onChange={e => calculateWin({ wager }, (e.target.value as unknown) as number)}
                              />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex' }}>
                              <TextField
                                className={classes.nuggetInput}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant='filled'
                                label='Win'
                                placeholder='$0.00'
                                value={wager.Win}
                                onChange={e => calculateRisk({ wager }, (e.target.value as unknown) as number)}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}

                  <Grid
                    item
                    xs={12}
                    style={{ backgroundColor: '#222', margin: '15px', borderRadius: '10px', padding: '20px' }}
                    className={classes.wagerFreePlayContainer}
                  >
                    <FormControlLabel
                      onMouseEnter={ShowAlertFreePlay}
                      onMouseLeave={HiddenAlertFreePlay}
                      aria-haspopup='true'
                      control={
                        <Checkbox
                          checked={betsResult.confirm.UseFreePlayAvailable == 'True'}
                          name='bonus'
                          color='primary'
                          onChange={() => {
                            betsResult.confirm.UseFreePlayAvailable == 'False' ? setIsBonus('True') : setIsBonus('False');
                          }}
                          disabled={true}
                        />
                      }
                      label={`Use My Bonus Balance $${userBalance.FreePlayAmount}`}
                    />
                    <Collapse in={alertFreePlay}>
                      <Alert severity='warning' style={{ width: '100%' }}>
                        You can only use this option if your total bet amount is smaller or equal to your total bonus balance
                      </Alert>
                    </Collapse>
                  </Grid>
                </Grid>
              </>
            )}

            {step === BET_STEP.POSTED && betsResult.post && betsResult.post.ErrorCode == '0' && (
              <>
                <Grid container className={classes.postContainer}>
                  <Grid item xs={12} className={classes.postSuccessMsg}>
                    <Alert variant='filled' severity='success'>
                      The bet was accepted!
                    </Alert>
                  </Grid>

                  {betsResult.post.wager.map((wager: any, wIndex: number) => {
                    return (
                      <Grid container direction='row' className={classes.postWagerContainer} key={'PsW' + wIndex}>
                        {([WT.STRAIGHT, WT.IFWIN].indexOf(wagerType) != -1 ||
                          ([WT.PARLAY, WT.TEASER, WT.ROUNDROBIN].indexOf(wagerType) != -1 && wIndex == 0)) && (
                          <Grid item xs={12} className={classes.postTicketMsj}>
                            <Typography className={classes.nuggetText}>
                              <strong>{wager.WagerTypeDesc}</strong>
                              &nbsp;&nbsp;&nbsp;Ticket #: {wager.TicketNumber}
                            </Typography>
                          </Grid>
                        )}
                        {wager.detail.map((detail: any) => (
                          <>
                            <Grid item xs={12}>
                              <Typography className={classes.nuggetText} color='primary'>
                                <span dangerouslySetInnerHTML={{ __html: detail.Description }}></span>
                              </Typography>
                            </Grid>

                            {[WT.STRAIGHT, WT.IFWIN].indexOf(wagerType) != -1 && <PostRiskWinSummary risk={wager.Risk} win={wager.Win} />}
                          </>
                        ))}

                        {[WT.PARLAY, WT.TEASER, WT.ROUNDROBIN].indexOf(wagerType) != -1 && (
                          <PostRiskWinSummary risk={wager.Risk} win={wager.Win} />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}

            {/* Generics Erros */}
            {betsResult && betsResult.errors && (
              <Grid container>
                {betsResult.errors.map((error: any, errorIndex: number) => {
                  return (
                    <Alert severity='error' key={'error' + errorIndex} style={{ width: '100%' }}>
                      <strong>
                        {' '}
                        <span dangerouslySetInnerHTML={{ __html: error.Msg }}></span>{' '}
                      </strong>
                    </Alert>
                  );
                })}
              </Grid>
            )}

            {/* BUTTONS STEPS*/}
            {((playerLines && playerLines.length > 0) || step == BET_STEP.POSTED) && (
              <Grid container direction='row'>
                <Grid container spacing={2} style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px' }}>
                  {step === BET_STEP.COMPILED && (
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          confirmBet();
                        }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  )}

                  {step === BET_STEP.ACEPT_CHANGES && (
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          placeBet();
                        }}
                      >
                        Accept Changes
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        removePlayerLines();
                        closeAll();
                      }}
                    >
                      Clear and Close
                    </Button>
                  </Grid>

                  {step === BET_STEP.CONFIRM && (
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          placeBet();
                        }}
                      >
                        Place Bet
                      </Button>
                    </Grid>
                  )}

                  {step === BET_STEP.POSTED && (
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          UseLinesOtherBet();
                        }}
                      >
                        Use in Another Bet
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* Loading bar */}
          {pendingBetsLoading && (
            <Grid container>
              <Grid item xs={12}>
                <LinearProgress color='primary' style={{ height: '7px' }} />
              </Grid>
            </Grid>
          )}
          <PendingBetPage pendingBets={pendingBets} />
        </TabPanel>
      </Drawer>
    </div>
  );
};
