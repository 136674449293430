import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const CREATE_BET = `CREATE_BET${suffix}`;

export const createBetAction = createAction<any>(CREATE_BET);

const CREATE_BET_SUCCESS = `CREATE_BET_SUCCESS${suffix}`;
const createBetSuccessAction = createAction<any[]>(CREATE_BET_SUCCESS);

const CREATE_BET_ERROR = `CREATE_BET_ERROR${suffix}`;
const createBetErrorAction = createAction<string>(CREATE_BET_ERROR);

function* createBetWatcher(): SagaIterator {
  yield takeEvery(CREATE_BET, createBetWorker);
}
mergeSaga(createBetWatcher);

function* createBetWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(createBetApi, action.payload);
    if (result && result.data && result.data.wager) {
      if (!Array.isArray(result.data.wager)) result.data.wager = [result.data.wager];
      for (let index = 0; index < result.data.wager.length; index++) {
        const element = result.data.wager[index];
        if (!Array.isArray(element.detail)) element.detail = [element.detail];
      }
    }
    yield put(createBetSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.errors.confirm, 'error');
    yield put(createBetErrorAction(e));
  }
}

const createBetApi = (data: any): Request => {
  return vipApi(`/wager/betslip/createBet`, 'post', data);
};

const reduceHandlers = {
  [CREATE_BET]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CREATE_BET_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    createBetResponses: action.payload,
  }),
  [CREATE_BET_ERROR]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    createBetResponses: action.payload,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
