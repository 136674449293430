import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Container, Grid, makeStyles, Paper, Typography, TextField, Button } from '@material-ui/core';
import { AccountLinks } from '../../shared/account/account-links.component';
import { Loading } from '../../shared/loading/loading.component';
import { getAppSettings } from '../../utils/app-settings.util';
import SignUpsTable from './components/signups-table.component';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
  paper: {
    padding: 15,
  },
  url: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    wordBreak: 'break-all',
  },
});

export interface ExternalProps {
  agentData: any;
  isLoading: any;
  user: any;
}

export interface ExternalActionProps {
  getAgentStatsAction: () => void;
  getUserInfoAction: () => void;
}

export const AgentDashboardPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getAgentStatsAction, getUserInfoAction, agentData, isLoading, user } = props;

  useEffect(() => {
    getAgentStatsAction();
    getUserInfoAction();
  }, []);

  const url = agentData != null ? `${getAppSettings().baseUrl}#/signup/${agentData.affiliateInviteCode}` : null;

  return (
    <div className={clsx(classes.root)}>
      <Container>
        <Loading loading={isLoading} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6'>Agent Dashboard</Typography>
          </Grid>
          {!isLoading && agentData && (
            <>
              <Grid item xs={12} sm={6} zeroMinWidth>
                <Paper className={classes.paper}>
                  <Typography variant='h6'>
                    Hello, {user.username} ({agentData.agentName})!
                    <br />
                  </Typography>
                  <hr />
                  <Typography>
                    {' '}
                    Welcome to your general dashboard. You will be able to view your current general statistics, and keep track of your
                    progress as an agent! <br />
                    <br />
                    <Typography variant='body1'>Your invite URL:</Typography>
                    <Typography color='primary' className={classes.url} variant='body1'>
                      {url}
                    </Typography>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Typography variant='h6'>Sign Up Data</Typography>
                  <hr />
                  <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                    <Grid item xs={6}>
                      <Typography>Sign Ups:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{agentData.signupsCount}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Sign Ups Deposited:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {agentData.signupsDeposited}
                        <br /> <br />
                        {agentData.signupsDepPercent}% of your sign ups have deposited.
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant='h6'>Wagering Data</Typography>
                  <hr />
                  <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                    <Grid item xs={6}>
                      <Typography>
                        Active Players:
                        <br />
                        <small>Last Week:</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {agentData.activeThisWeek} (
                        <span style={agentData.activeDifference < 0 ? { color: 'red' } : { color: 'green' }}>
                          {agentData.activeDifference}
                        </span>
                        )
                        <br />
                        <small>{agentData.activeLastWeek}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        Win/Loss:
                        <br />
                        <small>Last Week:</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        <span style={agentData.winLossThisWeek < 0 ? { color: 'red' } : { color: 'green' }}>
                          {'$' + agentData.winLossThisWeek.toFixed(2)}
                        </span>
                        <br />
                        <small style={agentData.winLossLastWeek < 0 ? { color: 'red' } : { color: 'green' }}>
                          {'$' + agentData.winLossLastWeek.toFixed(2)}
                        </small>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <form target='_blank' noValidate name='loginForm' method='post' action='https://www.periodreport.com/default.aspx'>
                        <TextField name='Account' type='hidden' value={agentData.agentName.toUpperCase()} />
                        <TextField name='Password' type='hidden' value={agentData.agentName.toUpperCase().replace('VIP', '')} />
                        <Button variant='contained' color='primary' type='submit'>
                          View Detailed Stats
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <AccountLinks routeName='/agent' />
          </Grid>
          {!isLoading && agentData && <SignUpsTable signups={agentData.signups} />}
          {!agentData && (
            <div style={{ textAlign: 'center' }}>
              <Typography variant='h6' style={{ display: 'block', margin: '0px auto' }}>
                Agent sign up is coming soon, check back later!
              </Typography>
            </div>
          )}
        </Grid>
      </Container>
    </div>
  );
};
