import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_FINGERPRINT = `SET_TRANSACTION_FINGERPRINT${suffix}`;

export const setTransactionFingerprintAction = createAction<any>(SET_TRANSACTION_FINGERPRINT);

const reduceHandlers = {
  [SET_TRANSACTION_FINGERPRINT]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    fingerprint: action.payload,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
