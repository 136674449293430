import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_NAME = `SET_TRANSACTION_NAME${suffix}`;

export const setTransactionNameAction = createAction<number>(SET_TRANSACTION_NAME);

const reduceHandlers = {
  [SET_TRANSACTION_NAME]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    name: action.payload,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
