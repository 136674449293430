import { createAction } from 'redux-actions';
import { UserSliceType, userReducer } from '../user.reducer';

const suffix = `/${userReducer.sliceName}/app`;

const SET_NEED_CASHIER_FORWARD = `SET_NEED_CASHIER_FORWARD${suffix}`;

export const setNeedCashierFowardAction = createAction<boolean>(SET_NEED_CASHIER_FORWARD);

const reduceHandlers = {
  [SET_NEED_CASHIER_FORWARD]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    needCashierForward: action.payload,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
