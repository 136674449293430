import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Container } from '@material-ui/core';
import { Loading } from '../../shared/loading/loading.component';
import { BonusHistory } from './component/bonus-history.component';
import { BalanceDetail } from '../../shared/account/balance-detail.component';
import { AccountLinks } from '../../shared/account/account-links.component';

const useStyles: any = makeStyles({
  root: {
    paddingTop: '24px',
    paddingBottom: '24px',
    width: '100%',
  },
  dot300: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #c8a964 100%, lightgrey 0%),
                     linear-gradient(120deg, #fff 36%, #fff 30%),
                     linear-gradient(300deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(210deg, #fff 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  dot280: {
    border: '20px solid #404040',
    margin: '10px',
    borderRadius: '50%',
    width: '230px',
    height: '230px',
  },
  colorMediumText: {
    fontSize: '18px',
    color: '#c8a964',
  },
  smallText: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#666',
  },
  innerCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  midGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  moneyText: {
    color: '#c8a964',
    fontSize: '28px',
  },
  upperText: {
    color: '#777',
  },
  depositBtn: {
    color: '#fff',
    backgroundColor: '#011f63',
  },
  btnGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    paddingBottom: '50px',
  },
  tabPill: {
    padding: '10px auto',
    border: '1px solid #c8a964',
  },
});

export interface ExternalActionProps {
  getBonusHistoryAction: () => void;
}

export interface ExternalProps {
  bonusHistory: any;
  isLoading: boolean;
  userBalance: any;
  user: any;
}

export const BonusHistoryPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getBonusHistoryAction, bonusHistory, isLoading, userBalance, user } = props;

  useEffect(() => {
    getBonusHistoryAction();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <BalanceDetail userBalance={userBalance} user={user} routeName='bonus' />
      <div style={{ backgroundColor: '#000', paddingTop: '20px', paddingBottom: '40px' }}>
        <Container>
          <AccountLinks routeName='bonus' />

          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <BonusHistory bonusHistory={bonusHistory} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
