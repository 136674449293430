import React from 'react';
import { Button, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import { RoutesDictionary, RoutesEnum } from '../../../models/routes';
import { getHistory } from '../../../utils/history.util';
import { useParams } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';

export interface ExternalActionProps {
  resetPasswordAction: (data: any) => void;
}

const useStyles = makeStyles({
  shrinkLogo: {
    maxWidth: 100,
    height: 'auto',
    margin: '0px auto',
    display: 'block',
  },
  moveForgetPasswordToRight: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingBottom: 20,
  },
  adjustEmailMargin: {
    marginTop: 30,
    marginBottom: 7,
  },
  centerForgetPassword: {
    textAlign: 'center',
    marginBottom: 20,
  },
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.png` + ')',
  },
  styleForgetPasswordParagraph: {
    textAlign: 'center',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: 450,
    backgroundColor: '#050505',
  },
  form: {
    padding: 24,
  },
  hidden: {
    display: 'none',
  },
});

const { path } = RoutesDictionary[RoutesEnum.LoginPage];

export interface ExternalProps {
  isLoading: boolean;
  resetPasswordSuccess: boolean;
}

export function ResetPasswordPage(props: ExternalActionProps & ExternalProps): React.ReactElement<ExternalActionProps> {
  const { resetPasswordAction, resetPasswordSuccess } = props;
  const { code } = useParams();
  const [isValid, setIsValid] = React.useState(true);

  const resetData = {
    password_reset_token: code,
    password: '',
    password_confirmation: '',
  };

  const [selected, setSelected] = React.useState(resetData);
  const classes = useStyles();
  const validateAndReq = () => {
    setIsValid(true);
    if (selected.password === selected.password_confirmation) {
      resetPasswordAction(selected);
    } else {
      setIsValid(false);
    }
  };

  if (resetPasswordSuccess) {
    getHistory().push(path);
  }

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={3}>
          <div className={classes.form}>
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
            <Typography className={classes.centerForgetPassword} variant='h5'>
              Reset Your Password
            </Typography>
            <form noValidate>
              <TextField
                id='password'
                variant='outlined'
                type='password'
                required
                fullWidth
                label='Password'
                value={selected.password}
                onChange={event => {
                  setSelected({
                    ...selected,
                    password: event.target.value,
                  });
                }}
                autoFocus
              />
              <TextField
                id='password_confirmation'
                className={classes.adjustEmailMargin}
                variant='outlined'
                type='password'
                required
                fullWidth
                label='Confirm Password'
                value={selected.password_confirmation}
                onChange={event => {
                  setSelected({
                    ...selected,
                    password_confirmation: event.target.value,
                  });
                }}
              />
              <InputLabel style={{ color: 'red' }} className={isValid ? classes.hidden : ''}>
                Password and Confirm password did not match.
              </InputLabel>
              <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '30px' }}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    validateAndReq();
                  }}
                >
                  Reset Password
                </Button>
              </div>
              <Button
                fullWidth
                color='primary'
                onClick={() => {
                  getHistory().push(path);
                }}
              >
                Cancel
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}
