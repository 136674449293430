import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
// import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const REMOVE_MIDDLE_SECTION = `REMOVE_MIDDLE_SECTION${suffix}`;

export const removeMiddleSectionAction = createAction<any>(REMOVE_MIDDLE_SECTION);

const REMOVE_MIDDLE_SECTION_SUCCESS = `REMOVE_MIDDLE_SECTION_SUCCESS${suffix}`;
const removeMiddleSectionSuccessAction = createAction<any>(REMOVE_MIDDLE_SECTION_SUCCESS);

const REMOVE_MIDDLE_SECTION_ERROR = `REMOVE_MIDDLE_SECTION_ERROR${suffix}`;
const removeMiddleSectionErrorAction = createAction<string>(REMOVE_MIDDLE_SECTION_ERROR);

function* removeMiddleSectionWatcher(): SagaIterator {
  yield takeEvery(REMOVE_MIDDLE_SECTION, removeMiddleSectionWorker);
}
mergeSaga(removeMiddleSectionWatcher);

function* removeMiddleSectionWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(removeMiddleSectionApi, action.payload);
    yield put(removeMiddleSectionSuccessAction(result));
  } catch (e) {
    yield put(removeMiddleSectionErrorAction('Some Error Occure.'));
  }
}

const removeMiddleSectionApi = (data: any): Request => {
  return data;
};

const reduceHandlers = {
  [REMOVE_MIDDLE_SECTION]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [REMOVE_MIDDLE_SECTION_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    lines: slice.lines.filter((item: any) => {
      return item.IdLeague != action.payload;
    }),
  }),
  [REMOVE_MIDDLE_SECTION_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
