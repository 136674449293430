import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const GET_HORSES_URL = `GET_HORSES_URL${suffix}`;

export const getHorsesUrlAction = createAction<any>(GET_HORSES_URL);

const GET_HORSES_URL_SUCCESS = `GET_HORSES_URL_SUCCESS${suffix}`;
const getHorsesUrlSuccessAction = createAction<any[]>(GET_HORSES_URL_SUCCESS);

const GET_HORSES_URL_ERROR = `GET_HORSES_URL_ERROR${suffix}`;
const getHorsesUrlErrorAction = createAction<string>(GET_HORSES_URL_ERROR);

function* getHorsesUrlWatcher(): SagaIterator {
  yield takeEvery(GET_HORSES_URL, getHorsesUrlWorker);
}
mergeSaga(getHorsesUrlWatcher);

function* getHorsesUrlWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getHorsesUrlApi, action.payload);
    yield put(getHorsesUrlSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getHorsesUrlErrorAction(e));
  }
}

const getHorsesUrlApi = (x: any): Request => {
  return vipApi(`/ptpi/initProvider`, 'post', {
    device_id: 1,
    site_id: 2,
    provider_id: x.provider_id,
  });
};

const reduceHandlers = {
  [GET_HORSES_URL]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_HORSES_URL_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    horsesUrl: action.payload.lauch_url,
  }),
  [GET_HORSES_URL_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
