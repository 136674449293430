import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Card, CardContent, Typography } from '@material-ui/core';
import { PaypalBtn } from './paypalBtn';
import { getAppSettings } from '../../../../utils/app-settings.util';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  content: {
    flex: '2 0 auto',
  },
  cover: {
    width: 200,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  spacer: {
    width: 20,
  },
  infoText: {
    textAlign: 'left',
  },
});

export interface ExternalProps {
  transactionRequest: any;
  bankReference: any;
}

export interface ExternalActionProps {
  getBankReferenceAction: (x: any) => void;
}

export const ManualPaymentCard = (props: ExternalProps & ExternalActionProps) => {
  const { transactionRequest, bankReference, getBankReferenceAction } = props;

  const [copySuccess, setCopySuccess] = useState(false);

  const classes = useStyles();

  let paymentUrl = '';
  if (getAppSettings().baseUrl == 'https://vipclub.lv/' || getAppSettings().baseUrl == '//vipclub.lv/') {
    paymentUrl =
      'https://cisso.services/test.php?playerNumber=' +
      transactionRequest.player_number +
      '&cashierId=' +
      transactionRequest.id +
      '&methodId=' +
      transactionRequest.method +
      '&price=' +
      Math.round(transactionRequest.totalToSend);
  } else {
    paymentUrl =
      'https://cisso.services/testing-file.php?playerNumber=' +
      transactionRequest.player_number +
      '&cashierId=' +
      transactionRequest.id +
      '&methodId=' +
      transactionRequest.method +
      '&price=' +
      Math.round(transactionRequest.totalToSend);
  }

  useEffect(() => {
    if (bankReference == null) {
      getBankReferenceAction({ method: transactionRequest.method, name: transactionRequest.bank });
    }

    if (transactionRequest.method == '7' || transactionRequest.method == '8') {
      window.open(paymentUrl, 'mywindow', 'menubar=1,resizable=1,width=450,height=600');
    }
  }, []);

  return (
    <Card>
      <div className={classes.details}>
        <CardContent>
          {transactionRequest.method == '6' && (
            <>
              <Typography component='h6' variant='h6'>
                Click the paypal button below to begin and complete payment.
              </Typography>
              <br />
              <PaypalBtn transactionRequest={transactionRequest} />
            </>
          )}

          {(transactionRequest.method == '7' || transactionRequest.method == '8') && (
            <>
              <Typography component='h6' variant='h6'>
                Please complete payment in the new window.
              </Typography>
              <Typography>Note: You must have pop ups enabled.</Typography>
              <br />
            </>
          )}

          {transactionRequest.method != '6' && transactionRequest.method != '7' && transactionRequest.method != '8' && (
            <>
              <Typography component='h6' variant='h6'>
                Please send ${transactionRequest.totalToSend} to {bankReference}
              </Typography>
              <br />
              <div className={classes.buttons}>
                <div className={classes.spacer} />
                <Button
                  variant='contained'
                  onClick={() => {
                    navigator.clipboard.writeText(bankReference);
                    setCopySuccess(true);
                    setTimeout(() => setCopySuccess(false), 2000);
                  }}
                >
                  {copySuccess ? <Typography variant='subtitle2'>Copied!</Typography> : <Typography>Copy Account to Send</Typography>}
                </Button>
              </div>
              <br />
              <Alert severity='info' className={classes.infoText}>
                <AlertTitle>Need Help?</AlertTitle>
                To complete your payment, please send the funds to the account listed above. <br />
              </Alert>
            </>
          )}
        </CardContent>
      </div>
    </Card>
  );
};
