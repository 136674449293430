import { BET_STEP, WAGER_TYPE } from '../../enum';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'gameSelectionSlice';

export interface GameSelectionSliceType {
  isLoading: boolean;
  isSaving: boolean;
  games: any[];
  lines: any;
  betResponses: any;
  previewBetResponses: any;
  createBetResponses: any;
  pendingBets: any;
  betPlaced: any;
  teasers: any;
  tvOnOffResponses: boolean;
  tvUrl: string;
  isSetUp: boolean;
  playerLines: any[];
  betResults: any;
  league: any;
  wagerType: WAGER_TYPE;
  stepWager: BET_STEP;
  teaser: any;
  rightOpen: boolean;
}

export const initialState: GameSelectionSliceType = {
  isLoading: false,
  isSaving: false,
  games: [],
  lines: [],
  betResponses: [],
  previewBetResponses: [],
  createBetResponses: [],
  pendingBets: {},
  betPlaced: false,
  teasers: {},
  tvOnOffResponses: false,
  tvUrl: '',
  isSetUp: false,
  playerLines: [],
  betResults: {},
  league: null,
  wagerType: WAGER_TYPE.STRAIGHT,
  stepWager: BET_STEP.INIT,
  teaser: null,
  rightOpen: false,
};

export const gameSelectionReducer = createAndMergeSliceReducer(sliceName, initialState);
