import { IWagerLine } from '../../models/wager-lines';
// import { getAppSettings } from '../../utils';
// import { getAppSetting } from '../../utils/app-settings.util';
import { GenericResponsePost } from '../../utils/http/http';
import Axios from 'axios';

export class WagerService {
  private static instance: WagerService;

  public static getInstance(): WagerService {
    if (!WagerService.instance) {
      WagerService.instance = new WagerService();
    }
    return WagerService.instance;
  }

  private _url = null;
  private _headers: any;
  // private getUrl = async (tryNum = 0) => {
  //   new Promise((resolve, reject) => {
  //     console.log('getUrl 2 ' + tryNum);
  //     console.log(this._url);
  //     if (this._url) {
  //       console.log('getUrl 3 ' + tryNum);
  //       resolve(this._url);
  //     } else {
  //       return Axios.get('app.settings.json')
  //         .then(response => {
  //           console.log('getUrl 4');
  //           console.log(response);
  //           this._url = response?.data?.apiUrl ?? null;
  //           return this._url;
  //         })
  //         .catch();
  //     }
  //   });
  // };

  constructor() {
    // I had to repeat code here. Sorry, I have to find a better solution.
    Axios.get('app.settings.json')
      .then(response => {
        this._url = response?.data?.apiUrl ?? null;
        return response.data;
      })
      .catch();
    this._headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
  }

  async getSchedulesByUsernameAndLeagueIds(pRequest: any) {
    return await GenericResponsePost<IWagerLine[]>(
      {
        path: this._url + '/wager/getSchedulesByUsernameAndLeagueIds',
        headers: this._headers,
      },
      pRequest,
    );
  }
}

export const WagerServiceClient = WagerService.getInstance();
