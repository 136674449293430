import { connect } from 'react-redux';
import { PromotionsPage, ExternalProps, ExternalActionProps } from './promotions.component';
import { promotionsSelector, isLoadingSliceSelector } from './../../api/promotion/promotion.selector';
import { getPromotionAction } from './../../api/promotion/actions/get-promotion.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    promotions: promotionsSelector(state),
    isLoading: isLoadingSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getPromotionAction,
};

export const PromotionsPageContainer = connect(mapStateToProps, mapDispatchToProps)(PromotionsPage);
