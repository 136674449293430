import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const WAGER_TYPE = `WAGER_TYPE${suffix}`;

export const wagerTypeAction = createAction<boolean>(WAGER_TYPE);

const WAGER_TYPE_SUCCESS = `WAGER_TYPE_SUCCESS${suffix}`;
const wagerTypeSuccessAction = createAction<boolean>(WAGER_TYPE_SUCCESS);

const WAGER_TYPE_ERROR = `WAGER_TYPE_ERROR${suffix}`;
const wagerTypeErrorAction = createAction<string>(WAGER_TYPE_ERROR);

function* wagerTypeWatcher(): SagaIterator {
  yield takeEvery(WAGER_TYPE, wagerTypeWorker);
}
mergeSaga(wagerTypeWatcher);

function* wagerTypeWorker(action: any): SagaIterator {
  try {
    yield put(wagerTypeSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(wagerTypeErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [WAGER_TYPE]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [WAGER_TYPE_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    wagerType: action.payload,
  }),
  [WAGER_TYPE_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
