import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { guideSelectionReducer, GuideSelectionSliceType } from './../guide.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${guideSelectionReducer.sliceName}/app`;

const GET_GUIDE = `GET_GUIDE${suffix}`;

export const getGuideAction = createAction(GET_GUIDE);

const GET_GUIDE_SUCCESS = `GET_GUIDE_SUCCESS${suffix}`;
const getGuideSuccessAction = createAction<any[]>(GET_GUIDE_SUCCESS);

const GET_GUIDE_ERROR = `GET_GUIDE_ERROR${suffix}`;
const getGuideErrorAction = createAction<string>(GET_GUIDE_ERROR);

function* getGuideWatcher(): SagaIterator {
  yield takeEvery(GET_GUIDE, getGuideWorker);
}
mergeSaga(getGuideWatcher);

function* getGuideWorker(): SagaIterator {
  try {
    const result: any = yield call(getGuideApi);
    console.log(result.data);
    yield put(getGuideSuccessAction(result.data));
  } catch (e) {
    yield put(getGuideErrorAction('Some error occure.'));
  }
}

const getGuideApi = (): Request => {
  return vipApi(`/guides/GetAllPlayerGuides`, 'get', {});
};

const reduceHandlers = {
  [GET_GUIDE]: (slice: GuideSelectionSliceType): GuideSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_GUIDE_SUCCESS]: (slice: GuideSelectionSliceType, action: any): GuideSelectionSliceType => ({
    ...slice,
    isLoading: false,
    guides: action.payload,
  }),
  [GET_GUIDE_ERROR]: (slice: GuideSelectionSliceType): GuideSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

guideSelectionReducer.addActionReducerMap(reduceHandlers);
