import { connect } from 'react-redux';
import { isLoggedInSelector, loggedInUserSelector, userBalanceSelector, tourStepsSelector } from '../../api/user/user.selector';
import { AppRoot, AppRootProps, ExternalActionProps } from './app-root.component';
import { getUserBalanceAction } from './../../api/user/actions/get-balance.action';
import { tvOnOffAction } from './../../api/game-selection/actions/tv-on-off.action';
import { setTvUrlAction } from './../../api/game-selection/actions/set-tv-url.action';
import { rightOpenSelector, tvOnOffResponsesSelector, tvUrlSelector } from './../../api/game-selection/game-selection.selector';
import { pathSelector } from '../nav/router.selectors';
import { userInfoAction } from './../../api/user/actions/user-info-action';
import { getTourStepsAction } from './../../api/user/actions/get-tour-steps.action';

function mapStateToProps(state: any): AppRootProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
    user: loggedInUserSelector(state),
    userBalance: userBalanceSelector(state),
    tvOnOffResponses: tvOnOffResponsesSelector(state),
    tvUrl: tvUrlSelector(state),
    path: pathSelector(state),
    tourSteps: tourStepsSelector(state),
    rightOpen: rightOpenSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getUserBalanceAction,
  tvOnOffAction,
  setTvUrlAction,
  userInfoAction,
  getTourStepsAction,
};

export const AppRootContainer = connect(mapStateToProps, mapDispatchToProps)(AppRoot);
