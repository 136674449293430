import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography, Grid, Container } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles: any = makeStyles({
  root: {
    marginBottom: '72px',
    width: '100%',
  },
  divider: {
    height: '1px',
    backgroundColor: '#dadada',
    marginBottom: '2px',
  },
  descriptionText: {
    lineHeight: '165%',
  },
});

export interface ExternalProps {
  pendingBets: any;
}

export const PendingBetPage = (props: ExternalProps) => {
  const classes = useStyles();
  const { pendingBets } = props;

  const getSort = (data: any) => {
    const sortedData = data.sort((a: any, b: any) => {
      return parseInt(b.TicketNumber) - parseInt(a.TicketNumber);
    });
    return sortedData;
  };

  return (
    <div className={clsx(classes.root)}>
      <div style={{ backgroundColor: '#000', paddingTop: '20px', paddingBottom: '40px' }}>
        <Container>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}>
              <Typography variant='h5' color='primary' align='center'>
                Pending Bets
              </Typography>
              <TableContainer component={Paper} style={{ marginTop: '15px', marginBottom: '75px' }}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#888' }}>
                      <TableCell>#</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Risk/Win</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingBets.data &&
                      getSort(pendingBets.data).map((item: any, i: number) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>#{++i}</TableCell>
                            <TableCell align='center' className={classes.descriptionText}>
                              Ticket Number: {item.TicketNumber}
                              <br />
                              {item.PlacedDate}&nbsp;{item.PlacedTime}
                              <br />
                              <div className={classes.divider}></div>
                              <strong>{item.HeaderDesc}</strong>
                              <br />
                              <br />
                              {Array.isArray(item.detail) ? (
                                <>
                                  {item.detail.map((detail: any, d: number) => {
                                    return (
                                      <React.Fragment key={d}>
                                        <div>
                                          <span dangerouslySetInnerHTML={{ __html: detail.DetailDesc }}></span>
                                          <br />
                                          <strong>
                                            &nbsp;&nbsp;{detail.GameDate}&nbsp;{detail.GameTime}
                                          </strong>
                                        </div>
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <div>
                                    <span dangerouslySetInnerHTML={{ __html: item.detail[0].DetailDesc }}></span>
                                    <strong>
                                      &nbsp;&nbsp;{item.detail.GameDate}&nbsp;{item.detail.GameTime}
                                    </strong>
                                  </div>
                                </>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.RiskAmount}/{item.WinAmount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
