import { connect } from 'react-redux';
import { setSmsCodeNullAction } from '../../../api/cashier/actions/set-sms-code-null.action';
import { transactionRequestSelector } from '../../../api/cashier/cashier.selector';
import { CompletePage, ExternalProps, ExternalActionProps } from './complete-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    transactionRequest: transactionRequestSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  setSmsCodeNullAction,
};

export const CompletePagePageContainer = connect(mapStateToProps, mapDispatchToProps)(CompletePage);
