/**
 * @export
 * Parameter defined by DGS
 * For Confirm wager
 */
export enum RISK_WIN {
  WAGER = '0',
  WIN = '1',
  RISK = '2',
}
