import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_TRANSACTION_ACCOUNT = `SET_TRANSACTION_ACCOUNT${suffix}`;

export const setTransactionAccountAction = createAction<number>(SET_TRANSACTION_ACCOUNT);

const reduceHandlers = {
  [SET_TRANSACTION_ACCOUNT]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    account: action.payload,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
