import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const GET_BONUS_HISTORY = `GET_BONUS_HISTORY${suffix}`;

export const getBonusHistoryAction = createAction(GET_BONUS_HISTORY);

const GET_BONUS_HISTORY_SUCCESS = `GET_BONUS_HISTORY_SUCCESS${suffix}`;
const getBonusHistorySuccessAction = createAction<any[]>(GET_BONUS_HISTORY_SUCCESS);

const GET_BONUS_HISTORY_ERROR = `GET_BONUS_HISTORY_ERROR${suffix}`;
const getBonusHistoryErrorAction = createAction<string>(GET_BONUS_HISTORY_ERROR);

function* getBonusHistoryWatcher(): SagaIterator {
  yield takeEvery(GET_BONUS_HISTORY, getBonusHistoryWorker);
}
mergeSaga(getBonusHistoryWatcher);

function* getBonusHistoryWorker(): SagaIterator {
  try {
    const result: any = yield call(getBonusHistoryApi);
    yield put(getBonusHistorySuccessAction(result.data));
  } catch (e) {
    yield put(getBonusHistoryErrorAction(e));
  }
}

const getBonusHistoryApi = (): Request => {
  return vipApi(`/bonuses`, 'post', {});
};

const reduceHandlers = {
  [GET_BONUS_HISTORY]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_BONUS_HISTORY_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => ({
    ...slice,
    isLoading: false,
    bonusHistory: action.payload,
  }),
  [GET_BONUS_HISTORY_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
