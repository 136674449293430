import { connect } from 'react-redux';
import { getBankReferenceAction } from '../../../api/cashier/actions/get-bank-reference.action';
import { getTransactionStatusAction } from '../../../api/cashier/actions/get-transaction-status.action';
import { setTransactionNameAction } from '../../../api/cashier/actions/set-name.action';
import { setSmsCodeNullAction } from '../../../api/cashier/actions/set-sms-code-null.action';
import { setTransactionAccountAction } from '../../../api/cashier/actions/set-transaction-account.action';
import { setTransactionAmountAction } from '../../../api/cashier/actions/set-transaction-amount.action';
import { setBankReferenceNullAction } from '../../../api/cashier/actions/set-transaction-bank-reference-null.action';
import { setTransactionBonusAction } from '../../../api/cashier/actions/set-transaction-bonus.action';
import { setTransactionMethodAction } from '../../../api/cashier/actions/set-transaction-method.action';
import { setTransactionTypeAction } from '../../../api/cashier/actions/set-transaction-type.action';
import {
  bankReferenceSelector,
  methodSelector,
  transactionRequestSelector,
  transactionStatusSelector,
} from '../../../api/cashier/cashier.selector';
import { RequestReceived, ExternalProps, ExternalActionProps } from './request-received-page.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    transactionRequest: transactionRequestSelector(state),
    bankReference: bankReferenceSelector(state),
    method: methodSelector(state),
    transactionStatus: transactionStatusSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getTransactionStatusAction,
  getBankReferenceAction,
  // Clearing TX States actions
  setTransactionBonusAction,
  setTransactionNameAction,
  setTransactionAccountAction,
  setTransactionAmountAction,
  setTransactionMethodAction,
  setTransactionTypeAction,
  setSmsCodeNullAction,
  setBankReferenceNullAction,
};

export const RequestReceivedPageContainer = connect(mapStateToProps, mapDispatchToProps)(RequestReceived);
