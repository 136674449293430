import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${userReducer.sliceName}/app`;

const IMPERSONATE_PLAYER = `IMPERSONATE_PLAYER${suffix}`;
export const impersonatePlayerAction = createAction(IMPERSONATE_PLAYER);

const IMPERSONATE_PLAYER_ERROR = `IMPERSONATE_PLAYER_ERROR${suffix}`;
const impersonatePlayerErrorAction = createAction<any>(IMPERSONATE_PLAYER_ERROR);

const IMPERSONATE_PLAYER_SUCCESS = `IMPERSONATE_PLAYER_SUCCESS${suffix}`;
const impersonatePlayerSuccessAction = createAction<any>(IMPERSONATE_PLAYER_SUCCESS);

function* impersonatePlayerWatcher(): SagaIterator {
  yield takeEvery(IMPERSONATE_PLAYER, impersonatePlayerWorker);
}
mergeSaga(impersonatePlayerWatcher);

function* impersonatePlayerWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(impersonatePlayerSuccessAction(result));
    localStorage.setItem('user', JSON.stringify(result.data));
    window.location.reload();
  } catch (e) {
    yield put(impersonatePlayerErrorAction(e));
  }
}

const getApiPlayer = (token: string): Request => {
  localStorage.setItem('token', token);
  return vipApi(`/wager/GetImpersonateData`, 'post', {});
};

const reduceHandlers = {
  [IMPERSONATE_PLAYER]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [IMPERSONATE_PLAYER_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      loggedInUser: action.payload.data,
    };
  },
  [IMPERSONATE_PLAYER_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
