import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

export interface DialogTitleProps {
  onClose: () => void;
  data: any;
  open: boolean;
}

export default function TermsConditionDialoge(props: DialogTitleProps) {
  const { open, onClose, data } = props;

  return (
    <div>
      <Dialog fullWidth maxWidth='md' onClose={() => onClose()} open={open}>
        <DialogContent dividers>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>{data && data.code}</Typography>
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{data && data.description}</Typography>
          <Typography>{data && data.terms}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => onClose()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
