import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import $ from 'jquery';
import { getAppSettings } from '../../../../utils/app-settings.util';

export interface ExternalProps {
  transactionRequest: any;
}

export const PaypalBtn = (props: ExternalProps) => {
  const { transactionRequest } = props;
  return (
    <PayPalButton
      amount={transactionRequest.totalToSend}
      onSuccess={(details: any) => {
        $.post(
          getAppSettings().apiUrl + '/pcashier/paypal-transaction-complete',
          {
            transactionId: transactionRequest.id,
            orderId: details.purchase_units[0].payments.captures[0].id,
            amount: transactionRequest.totalToSend,
            playerNumber: transactionRequest.player_number,
          },
          function () {
            alert('Transaction completed');
          },
        );
      }}
      options={
        getAppSettings().baseUrl == 'https://vipclub.lv/'
          ? {
              clientId: 'Adkj8qH16zoiBEMquTc5wE7QojGfegKzfT5Qz4wJG9OcMEgM3xE30mHFmOi9VS_1wvPfjLuIlFwyWCkZ', // Live
            }
          : {
              clientId: 'AS0CRyNUsrauhGcNGYW8nfa2yt-uSd-1pV04q_8rA1Rokgx8g3_We0q70fuehDWFBKre3PLUgGtGR4ls', // Sandbox
            }
      }
    />
  );
};
