import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Container, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { getHistory } from '../../utils/history.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  dot300: {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #c8a964 100%, lightgrey 0%),
                     linear-gradient(120deg, #fff 36%, #fff 30%),
                     linear-gradient(300deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(210deg, #fff 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  dotleft: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #c8a964 100%, lightgrey 0%),
                     linear-gradient(120deg, #fff 36%, #fff 30%),
                     linear-gradient(300deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(210deg, #fff 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  dot280: {
    border: '20px solid #404040',
    margin: '10px',
    borderRadius: '50%',
    width: '230px',
    height: '230px',
  },
  dot200: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    border: '25px solid transparent',
    backgroundSize: '100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(#303030, #303030), 
                     linear-gradient(30deg, #fff 100%, lightgrey 0%),
                     linear-gradient(120deg, #c8a964 36%, #c8a964 30%),
                     linear-gradient(300deg, #fff 36%, #fff 30%),
                     linear-gradient(210deg, #c8a964 100%, lightgrey 0%)`,
    backgroundPosition: 'center center, left top, right top, left bottom, right bottom',
    backgroundOrigin: 'content-box, border-box, border-box, border-box, border-box',
    backgroundClip: 'content-box, border-box, border-box, border-box, border-box',
  },
  innerCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  innerCircleRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '30px',
  },
  midGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  smallGrid: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  moneyText: {
    color: '#c8a964',
    fontSize: '28px',
  },
  upperText: {
    color: '#777',
  },
  colorMediumText: {
    fontSize: '18px',
    color: '#c8a964',
  },
  smallText: {
    fontSize: '12px',
    marginTop: '10px',
    color: '#666',
  },
  btnGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    paddingBottom: '50px',
  },
  btn: {
    margin: 5,
  },
}));

interface DetailProps {
  userBalance: any;
  user: any;
  routeName: string;
}

export const BalanceDetail = ({ userBalance, user, routeName }: DetailProps) => {
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState({
    username: '',
  });

  useEffect(() => {
    const { username } = user;
    username && setUserInfo({ username });
  }, [user]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container>
        <Grid container alignItems='center' justify='center'>
          <Grid item xs={4} className={`${classes.smallGrid} tour_profile-cashBalance`}>
            <div className={classes.dotleft}>
              <div className={classes.innerCircle}>
                <Typography align='center' className={classes.innerCircleRight}>
                  Cash Balance
                </Typography>
                <Typography align='center' className={classes.moneyText}>
                  {userBalance.RealAvailBalance} USD
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className={`${classes.midGrid} tour_profile-userName`}>
            <div className={classes.dot300}>
              <div className={classes.dot280}>
                <div className={classes.innerCircle}>
                  <img src='/logo.png' style={{ width: '70px' }} />
                  {/* <Typography>{userInfo.first_name + ' ' + userInfo.last_name}</Typography> */}
                  <Typography>{userInfo.username}</Typography>
                  <Typography className={classes.smallText}>Total Balance</Typography>
                  <Typography className={classes.colorMediumText}>
                    {userBalance.CurrentBalance}
                    USD
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className={`${classes.smallGrid} tour_profile-freeplay`}>
            <div className={classes.dot200}>
              <div className={classes.innerCircle}>
                <Typography align='center' className={classes.innerCircleRight}>
                  Freeplay Balance
                </Typography>
                <Typography align='center' className={classes.moneyText}>
                  {userBalance.FreePlayAmount} USD
                </Typography>
              </div>
            </div>
          </Grid>

          {routeName !== 'profile' && (
            <Grid item xs={12} className={classes.btnGrid}>
              <Button variant='contained' size='large' color='secondary' onClick={() => getHistory().push('/cashier')}>
                Cashier
              </Button>
            </Grid>
          )}

          {routeName == 'profile' && (
            <Grid item xs={12} className={`${classes.btnGrid} tour_profile-btns`}>
              <Button
                variant='contained'
                size='large'
                color='secondary'
                onClick={() => getHistory().push('/cashier')}
                startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}
                className={classes.btn}
              >
                Deposit
              </Button>
              <br /> <br />
              <Button variant='outlined' size='large' onClick={() => getHistory().push('/cashier')} className={classes.btn}>
                Withdraw
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};
