import { connect } from 'react-redux';
import { openCasinoGameAction } from '../../api/tpi/actions/open-game.action';
import { isLoadingSliceSelector, gameUrlSliceSelector } from '../../api/tpi/tpi.selector';
import { pathSelector } from '../../shared/nav/router.selectors';
import { LobbyProvider, ExternalProps, ExternalActionProps } from './lobby-provider.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    gameUrl: gameUrlSliceSelector(state),
    path: pathSelector(state),
  };
}
const mapDispatchToProps: ExternalActionProps = {
  openCasinoGameAction,
};

export const LobbyProviderContainer = connect(mapStateToProps, mapDispatchToProps)(LobbyProvider);
