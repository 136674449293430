import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Button, IconButton, Theme, Link, Divider, Hidden } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserIconPopover } from '../user-icon-popover/user-icon-popover.component';
import { RoutesDictionary, RoutesEnum } from '../../models/routes';
import { getHistory } from '../../utils/history.util';
import { VipLiveTv } from '../icons/live-icon';
import CancelIcon from '@material-ui/icons/Cancel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import { FormControl, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';
import { Player } from '../right-nav/player';
import Draggable from 'react-draggable';
// import AdDialog from './components/ad-dialog.componenent';
import Tour from './components/tour.component';

declare global {
  interface Window {
    LO: any;
  }
}

const openLOLiveChat = () => {
  if (window.LO != null) {
    if (window.LO.messenger != undefined && window.LO.messenger != null) {
      window.LO.messenger.open();
      console.log('opened LO Chat');
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
    color: 'white',
    '& hr': {
      margin: theme.spacing(0, 2),
    },
  },
  toolbarRightItems: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 64,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
    },
  },
  shrinkLogo: {
    maxHeight: '60px',
    maxWidth: '60px',
    // marginLeft: 'rem',
    color: theme.palette.secondary.main,
  },
  fixedVideoPlayer: {
    position: 'fixed',
    width: '200px',
    right: '20px',
    bottom: '70px',
    zIndex: 1240,
    [theme.breakpoints.up('sm')]: {
      width: '320px',
    },
  },
  channelSelect: {
    backgroundColor: '#000',
    position: 'relative',
    zIndex: 1240,
    '& .MuiSelect-select.MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px',
    },
  },
  tvClose: {
    position: 'relative',
    width: '20px',
    float: 'right',
    top: '10px',
    zIndex: 10000,
    right: '10px',
  },
}));

// const routes = [
//   RoutesDictionary[RoutesEnum.Cashier],
//   RoutesDictionary[RoutesEnum.Sportsbook],
//   RoutesDictionary[RoutesEnum.LiveSports],
//   RoutesDictionary[RoutesEnum.Casino],
// ];

export interface AppBarProps {
  user: any;
  userBalance: any;
  tvOnOffResponses: boolean;
  tvOnOffAction: (a: boolean) => void;
  setTvUrlAction: (ch: string) => void;
  tvUrl: string;
  isTvOn: boolean;
  getTourStepsAction: () => void;
  tourSteps: any;
}

export const AppBar = ({ user, userBalance, tvOnOffResponses, tvOnOffAction, isTvOn, getTourStepsAction, tourSteps }: AppBarProps) => {
  const classes = useStyles();
  const [channel, setChannel] = React.useState<number>(0);
  const [nUrl, setNUrl] = React.useState<string>('');
  const [v, setV] = React.useState<boolean>(true);

  const handleChannel = (value: number) => {
    setV(false);
    const randN = new Date().getTime();
    let newUrl = '';
    switch (value) {
      case 1:
        newUrl = `https://vipmail.sbs/espn1/prog_index.m3u8?i=${randN}`;
        break;
      case 2:
        newUrl = `https://vipmail.sbs/nbatv/prog_index.m3u8?i=${randN}`;
        break;
      case 3:
        newUrl = `https://vipmail.sbs/nbcsport1/prog_index.m3u8?i=${randN}`;
        break;
      case 4:
        newUrl = `https://vipmail.sbs/nhl1/prog_index.m3u8?i=${randN}`;
        break;
      case 5:
        newUrl = `https://vipmail.sbs/sportsnet1/prog_index.m3u8?i=${randN}`;
        break;
      case 6:
        newUrl = `https://vipmail.sbs/espn2/prog_index.m3u8?i=${randN}`;
        break;
      case 7:
        newUrl = `https://vipmail.sbs/espn/prog_index.m3u8?i=${randN}`;
        break;
      case 8:
        newUrl = `https://vipmail.sbs/tvg/prog_index.m3u8?i=${randN}`;
        break;
      case 9:
        newUrl = `https://vipmail.sbs/root/prog_index.m3u8?i=${randN}`;
        break;
      case 10:
        newUrl = `https://vipmail.sbs/tvg2/prog_index.m3u8?i=${randN}`;
        break;
      case 11:
        newUrl = `https://vipmail.press/1629254439/prog_index.m3u8?i=${randN}`;
        break;
      case 12:
        newUrl = `https://vipmail.press/1629321695/prog_index.m3u8?i=${randN}`;
        break;
      case 13:
        newUrl = `https://vipmail.press/1629325119/prog_index.m3u8?i=${randN}`;
        break;
      case 14:
        newUrl = `https://vipmail.press/1629325832/prog_index.m3u8?i=${randN}`;
        break;
      case 15:
        newUrl = `https://vipmail.press/1629326329/prog_index.m3u8?i=${randN}`;
        break;
      case 16:
        newUrl = `https://vipmail.press/1629328009/prog_index.m3u8?i=${randN}`;
        break;
      case 17:
        newUrl = `https://vipmail.press/1629329039/prog_index.m3u8?i=${randN}`;
        break;
      case 18:
        newUrl = `https://vipmail.press/1629330448/prog_index.m3u8?i=${randN}`;
        break;
      case 19:
        newUrl = `https://vipmail.press/1629331134/prog_index.m3u8?i=${randN}`;
        break;
      case 20:
        newUrl = `https://vipmail.press/1629331942/prog_index.m3u8?i=${randN}`;
        break;
      default:
        alert('Invalid Option');
    }
    setChannel(value);
    setNUrl(newUrl);
    setTimeout(() => setV(true), 500);
  };

  return (
    <MuiAppBar position='fixed' color='transparent' elevation={3}>
      <Tour getTourStepsAction={getTourStepsAction} tourSteps={tourSteps} />
      <Toolbar classes={{ root: classes.toolbar }}>
        <Hidden xsDown>
          <Link href='#/sportsbook' className='tour-logoCnt'>
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
          </Link>
        </Hidden>

        <div className={classes.toolbarRightItems}>
          {window.innerWidth >= 1024 && (
            <Button
              variant='contained'
              color='primary'
              style={{ marginRight: '50px' }}
              className='tour_header-watchBtn'
              onClick={() => {
                tvOnOffAction(!tvOnOffResponses);
              }}
            >
              {tvOnOffResponses ? 'Close Live TV' : 'Watch Live TV'}
            </Button>
          )}

          <Hidden only={['xs']}>
            <Typography className='tour_header-username' style={{ lineHeight: '1rem', textAlign: 'right' }}>
              ${userBalance.RealAvailBalance}
              <br />
              <small style={{ fontSize: '.8rem' }}>{user.username}</small>
            </Typography>
            <UserIconPopover user={user} userBalance={userBalance} />
          </Hidden>

          <Hidden xsDown>
            <Divider orientation='vertical' flexItem />

            <Button
              variant='contained'
              color='secondary'
              className='tour_header-cashier'
              startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}
              onClick={() => getHistory().push(RoutesDictionary[RoutesEnum.Cashier].path)}
            >
              <Typography variant='button'>Cashier</Typography>
            </Button>
          </Hidden>

          <Hidden xsDown>
            <Divider orientation='vertical' flexItem />

            <IconButton
              color='inherit'
              className='tour_header-chat'
              onClick={() => {
                openLOLiveChat();
                // getHistory().push('/live-chat');
              }}
            >
              <FontAwesomeIcon icon={['fad', 'comments']} />
            </IconButton>
          </Hidden>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            {/* <Divider orientation='vertical' flexItem /> */}

            <Button
              variant='contained'
              color='secondary'
              startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}
              onClick={() => getHistory().push(RoutesDictionary[RoutesEnum.Cashier].path)}
              size='small'
            >
              <Typography variant='button'>Cashier</Typography>
            </Button>
          </Hidden>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Divider orientation='vertical' flexItem className={'dividerTop'} />
            <IconButton onClick={() => tvOnOffAction(!tvOnOffResponses)}>
              <VipLiveTv style={{ width: '2rem', height: '2rem', position: 'absolute' }} />
            </IconButton>
          </Hidden>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Divider orientation='vertical' flexItem className={'dividerTop'} />
            <Typography style={{ lineHeight: '1rem', textAlign: 'right' }}>
              ${userBalance.RealAvailBalance}
              <br />
              <small style={{ fontSize: '.8rem' }}>{user.username}</small>
            </Typography>
            <UserIconPopover user={user} userBalance={userBalance} />
          </Hidden>
        </div>
      </Toolbar>
      {isTvOn && (
        <Draggable handle='#draggable-fixed-video' cancel={'[class*="MuiDialogContent-root"]'}>
          <div className={classes.fixedVideoPlayer} aria-labelledby='draggable-fixed-video'>
            <div className={classes.tvClose}>
              <CancelIcon onClick={() => tvOnOffAction(!tvOnOffResponses)} />
            </div>
            {v && (
              <div id='draggable-fixed-video'>
                <Player url={nUrl} />
              </div>
            )}
            <FormControl fullWidth className={classes.channelSelect}>
              <Select fullWidth value={channel} onChange={e => handleChannel((e.target.value as unknown) as number)}>
                <MenuItem value={0}>
                  <em>Select Channel</em>
                </MenuItem>
                <MenuItem value={7}>
                  <img src={`${process.env.PUBLIC_URL}/images/6878BE515D5544A2B7D8BDAF467A1181.png`} width='50' height='50' />
                  &nbsp;&nbsp;ESPN Network
                </MenuItem>
                <MenuItem value={1}>
                  <img src={`${process.env.PUBLIC_URL}/images/E265811C346143BA86DFE7773E658EB4.png`} width='50' height='50' />
                  &nbsp;&nbsp;NBA TV
                </MenuItem>
                <MenuItem value={6}>
                  <img src={`${process.env.PUBLIC_URL}/images/1BDC65FF4FB54713939660237B0E7AEB.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;NBCSN California
                </MenuItem>
                <MenuItem value={4}>
                  <img src={`${process.env.PUBLIC_URL}/images/0F8E31CF030A4406B97A1DF3F30497B4.png`} width='50' height='50' />
                  &nbsp;&nbsp;TVG Network
                </MenuItem>
                <MenuItem value={3}>
                  <img src={`${process.env.PUBLIC_URL}/images/510C3F675E174A27A7F49F7B881D9532.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;CBS Sports Network
                </MenuItem>
                <MenuItem value={4}>
                  <img src={`${process.env.PUBLIC_URL}/images/16F8BC869B5349BD9A79C902CBDCDF04.gif`} width='50' height='50' />
                  &nbsp;&nbsp;ESPN 2
                </MenuItem>
                <MenuItem value={5}>
                  <img src={`${process.env.PUBLIC_URL}/images/6878BE515D5544A2B7D8BDAF467A1181.png`} width='50' height='50' />
                  &nbsp;&nbsp;ESPN
                </MenuItem>
                <MenuItem value={8}>
                  <img src={`${process.env.PUBLIC_URL}/images/510C3F675E174A27A7F49F7B881D9532.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;Spectrum SportsNet
                </MenuItem>
                <MenuItem value={9}>
                  <img src={`${process.env.PUBLIC_URL}/images/510C3F675E174A27A7F49F7B881D9532.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;FOX Sports
                </MenuItem>
                <MenuItem value={10}>
                  <img src={`${process.env.PUBLIC_URL}/images/510C3F675E174A27A7F49F7B881D9532.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;NFL Red Zone
                </MenuItem>
                <MenuItem value={11}>
                  <img src={`${process.env.PUBLIC_URL}/images/510C3F675E174A27A7F49F7B881D9532.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;NBC Sports Network
                </MenuItem>
                <MenuItem value={12}>
                  <img src={`${process.env.PUBLIC_URL}/images/5EFB5F2F8B7F4DB99842AF474208F9A3.png`} width='50' height='50' />
                  &nbsp;&nbsp;ESPN U
                </MenuItem>
                <MenuItem value={13}>
                  <img src={`${process.env.PUBLIC_URL}/images/AE9F6DDF52AF4E9FA8FA1C8688D35352.png`} width='50' height='50' />
                  &nbsp;&nbsp;Stadium 3
                </MenuItem>
                <MenuItem value={14}>
                  <img src={`${process.env.PUBLIC_URL}/images/85E76B2A17E44A99A487119E11072191.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;YES Network
                </MenuItem>
                <MenuItem value={15}>
                  <img src={`${process.env.PUBLIC_URL}/images/39AD4C8AC42241E0937A496B5AFA627C.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;Big Ten Network
                </MenuItem>
                <MenuItem value={16}>
                  <img src={`${process.env.PUBLIC_URL}/images/3E264C11E45B4BFDA4E3383E92B4C70E.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;VSiN
                </MenuItem>
                <MenuItem value={17}>
                  <img src={`${process.env.PUBLIC_URL}/images/60A485DA63B945698101615A9C9FF357.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;PAC 12 Network
                </MenuItem>
                <MenuItem value={18}>
                  <img src={`${process.env.PUBLIC_URL}/images/01A85EDB216046CE82279AFF83711658.jpg`} width='50' height='50' />
                  &nbsp;&nbsp;NESN
                </MenuItem>
                <MenuItem value={19}>
                  <img src={`${process.env.PUBLIC_URL}/images/98FF47AA01164C44A7FFBF363E567A67_1.png`} width='50' height='50' />
                  &nbsp;&nbsp;MASN 2
                </MenuItem>
                <MenuItem value={20}>
                  <img src={`${process.env.PUBLIC_URL}/images/30593E9FE6BE4D08BEA6BC67AC587F48.png`} width='50' height='50' />
                  &nbsp;&nbsp;MSG
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Draggable>
      )}

      {/* <AdDialog /> */}
    </MuiAppBar>
  );
};
