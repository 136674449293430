import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles: any = makeStyles({
  root: {
    marginTop: '40px',
  },
  paper: {
    padding: '20px',
  },
});

export interface ExternalProps {
  rows: any;
  open: boolean;
  onClose: () => void;
}

function NewlineText(text: string) {
  const newText = text.split('\n').map((str: any) => <p key={str}>{str}</p>);

  return newText;
}

export const WagerTable = (props: ExternalProps) => {
  const classes = useStyles();
  const { rows, open, onClose } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='lg' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <DialogContentText>Wager Table</DialogContentText>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Ticket ID</TableCell>
                  <TableCell>Wager Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Free Play</TableCell>
                  <TableCell>Risk</TableCell>
                  <TableCell>To Win</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Contribution %</TableCell>
                  <TableCell>Contribution Amount</TableCell>
                  <TableCell>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map(
                    (row: {
                      ticket: string;
                      type: string;
                      description: string;
                      risk: number;
                      freePlay: any;
                      toWin: number;
                      result: string;
                      cPercent: number;
                      contribution: number;
                      reason: string;
                    }) => (
                      <TableRow key={row.ticket}>
                        <TableCell component='th' scope='row'>
                          {row.ticket}
                        </TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{NewlineText(row.description)}</TableCell>
                        <TableCell>{row.freePlay.toString()}</TableCell>
                        <TableCell>{row.risk}</TableCell>
                        <TableCell>{row.toWin}</TableCell>
                        <TableCell>{row.result}</TableCell>
                        <TableCell>{row.cPercent}</TableCell>
                        <TableCell>{row.contribution}</TableCell>
                        <TableCell>{row.reason}</TableCell>
                      </TableRow>
                    ),
                  )}
                {rows.length == 0 && (
                  <TableRow key='notFound'>
                    <TableCell colSpan={8} align='center'>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
