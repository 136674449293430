import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { tpiSliceReducer, tpiSliceType } from '../tpi.reducer';

const suffix = `/${tpiSliceReducer.sliceName}/app`;

const GET_LIVE_WAGERING_URL = `GET_LIVE_WAGERING_URL${suffix}`;

export const getLiveWageringUrlAction = createAction<any>(GET_LIVE_WAGERING_URL);

const GET_LIVE_WAGERING_URL_SUCCESS = `GET_LIVE_WAGERING_URL_SUCCESS${suffix}`;
const getLiveWageringUrlSuccessAction = createAction<any[]>(GET_LIVE_WAGERING_URL_SUCCESS);

const GET_LIVE_WAGERING_URL_ERROR = `GET_LIVE_WAGERING_URL_ERROR${suffix}`;
const getLiveWageringUrlErrorAction = createAction<string>(GET_LIVE_WAGERING_URL_ERROR);

function* getLiveWageringUrlWatcher(): SagaIterator {
  yield takeEvery(GET_LIVE_WAGERING_URL, getLiveWageringUrlWorker);
}
mergeSaga(getLiveWageringUrlWatcher);

function* getLiveWageringUrlWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getLiveWageringUrlApi, action.payload);
    yield put(getLiveWageringUrlSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getLiveWageringUrlErrorAction(e));
  }
}

const getLiveWageringUrlApi = (x: any): Request => {
  return vipApi(`/ptpi/initProvider`, 'post', {
    device_id: 1,
    site_id: 2,
    provider_id: x.provider_id,
  });
};

const reduceHandlers = {
  [GET_LIVE_WAGERING_URL]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_LIVE_WAGERING_URL_SUCCESS]: (slice: tpiSliceType, action: any): tpiSliceType => ({
    ...slice,
    isLoading: false,
    liveWageringUrl: action.payload.lauch_url,
  }),
  [GET_LIVE_WAGERING_URL_ERROR]: (slice: tpiSliceType): tpiSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

tpiSliceReducer.addActionReducerMap(reduceHandlers);
