import { connect } from 'react-redux';
import { getEsportsWageringUrlAction } from '../../api/tpi/actions/get-esports-wagering-url.action';
import { eSportsWageringUrlSliceSelector, isLoadingSliceSelector } from '../../api/tpi/tpi.selector';
import { EsportsPage, ExternalActionProps, ExternalProps } from './esports.component';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    eSportsUrl: eSportsWageringUrlSliceSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getEsportsWageringUrlAction,
};

export const ESportsPageContainer = connect(mapStateToProps, mapDispatchToProps)(EsportsPage);
