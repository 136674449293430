import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function RAFHowTo() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginTop: '1rem' }}>
      <Button variant='outlined' color='primary' onClick={handleClickOpen} className='tour_raf-howWork'>
        How does R-A-F work?
      </Button>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title' maxWidth='md'>
        <DialogTitle id='responsive-dialog-title'>{'How does RAF work?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <hr />
            <Grid container spacing={2}>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={['fad', 'share-square']} style={{ fontSize: '2rem', margin: '1rem' }} />
                <Typography variant='h6'>Share your link.</Typography>
                <Typography variant='subtitle1'>Your friend will be able to sign up via that link only.</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={['fad', 'envelope-open']} style={{ fontSize: '2rem', margin: '1rem' }} />
                <Typography variant='h6'>Sign Up</Typography>
                <Typography variant='subtitle1'>Your friend creates a VIPClub account.</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={['fad', 'money-bill-wave-alt']} style={{ fontSize: '2rem', margin: '1rem' }} />

                <Typography variant='h6'>Redeem your rewards!</Typography>
                <Typography variant='subtitle1'>
                  Your friend will need to sign up, deposit, and place a wager before your referral will be completed.
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleClose} color='primary' autoFocus>
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
