import { connect } from 'react-redux';
import { insertTransactionAction } from '../../../api/cashier/actions/insert-transaction.action';
import { setTransactionNameAction } from '../../../api/cashier/actions/set-name.action';
import { checkCbioAmountAction } from '../../../api/cashier/actions/check-cbio-amount.action';
import {
  accountSelector,
  amountSelector,
  bonusSelector,
  fingerprintSelector,
  ipSelector,
  methodSelector,
  nameSelector,
  typeSelector,
  cbioAllowedSelector,
  isLoadingSliceSelector,
  smsCodeCheckSelector,
} from '../../../api/cashier/cashier.selector';
import { loggedInUserSelector } from '../../../api/user/user.selector';
import { ConfirmTransactionPage, ExternalActionProps, ExternalProps } from './confirm-transaction-page.component';
import { sendSmsCodeAction } from '../../../api/cashier/actions/send-sms-code.action';
import { checkSmsCodeAction } from '../../../api/cashier/actions/check-sms-code.action';
// import { setSmsCodeNullAction } from '../../../api/cashier/actions/set-sms-code-null.action';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    account: accountSelector(state),
    amount: amountSelector(state),
    type: typeSelector(state),
    user: loggedInUserSelector(state),
    bonus: bonusSelector(state),
    method: methodSelector(state),
    ipAddress: ipSelector(state),
    name: nameSelector(state),
    fingerprint: fingerprintSelector(state),
    cbioAllowed: cbioAllowedSelector(state),
    smsCodeCheck: smsCodeCheckSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  setTransactionNameAction,
  insertTransactionAction,
  checkCbioAmountAction,
  sendSmsCodeAction,
  checkSmsCodeAction,
  // setSmsCodeNullAction,
};

export const ConfirmTransactionPageContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmTransactionPage);
