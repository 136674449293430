import { connect } from 'react-redux';
import { signupAction } from '../../../api/user/actions/signup-action';
import { inviteAction } from '../../../api/user/actions/invite-action';
import { SignupPage, ExternalProps, ExternalActionProps } from './signup-page.component';
import {
  isLoadingSelector,
  errorSelector,
  inviteSelector,
  signupSelector,
  inviteCodeInvalidSelector,
  isLoggedInSelector,
  signupUsernameSelector,
} from '../../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSelector(state),
    error: errorSelector(state),
    inviteSuccess: inviteSelector(state),
    signupSuccess: signupSelector(state),
    codeInvalid: inviteCodeInvalidSelector(state),
    loggedIn: isLoggedInSelector(state),
    signupUsername: signupUsernameSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  signupAction,
  inviteAction,
};

export const SignupPageContainer = connect(mapStateToProps, mapDispatchToProps)(SignupPage);
