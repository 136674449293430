import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { Loading } from '../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    width: '100%',
    border: 'none',
    height: '85%',
    overflow: 'hidden',
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute',
  },
});

export interface ExternalProps {
  isLoading: any;
  liveWageringUrl: any;
}

export interface ExternalActionProps {
  getLiveWageringUrlAction: (x: any) => void;
}

export const LiveSportsPage = (props: ExternalActionProps & ExternalProps) => {
  const { liveWageringUrl, getLiveWageringUrlAction, isLoading } = props;
  const classes = useStyles();

  useEffect(() => {
    getLiveWageringUrlAction({ provider_id: 2 });
  }, []);

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      {!isLoading && <iframe className={clsx(classes.root)} src={liveWageringUrl} height='100%'></iframe>}
    </>
  );
};
