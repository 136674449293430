import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getUserInfoAction } from './get-user-info.action';

const suffix = `/${userReducer.sliceName}/app`;

const UPDATE_PROFILE = `UPDATE_PROFILE${suffix}`;
export const updateProfileAction = createAction<string>(UPDATE_PROFILE);

const UPDATE_PROFILE_ERROR = `UPDATE_PROFILE_ERROR${suffix}`;
const updateProfileErrorAction = createAction<string>(UPDATE_PROFILE_ERROR);
const UPDATE_PROFILE_SUCCESS = `UPDATE_PROFILE_SUCCESS${suffix}`;
const updateProfileSuccessAction = createAction<string>(UPDATE_PROFILE_SUCCESS);

function* updateProfileWatcher(): SagaIterator {
  yield takeEvery(UPDATE_PROFILE, updateProfileWorker);
}
mergeSaga(updateProfileWatcher);

function* updateProfileWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(updateProfileSuccessAction(result));
    yield put(getUserInfoAction());
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(updateProfileErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (x: any): Request => {
  return vipApi(`/UpdateUserInfo`, 'post', {
    first_name: x.first_name,
    last_name: x.last_name,
    phone: x.phone,
    email: x.email,
    dob: x.dob,
    zip: x.zip,
  });
};

const reduceHandlers = {
  [UPDATE_PROFILE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [UPDATE_PROFILE_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      loggedInUser: action.payload.data,
    };
  },
  [UPDATE_PROFILE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
