import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 380,
    maxWidth: 380,
  },
});

export interface ExternalProps {
  rows: any;
}

export const ContTable = (props: ExternalProps) => {
  const classes = useStyles();
  const { rows } = props;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Wager Type</TableCell>
            <TableCell>Contribution %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 &&
            rows.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell component='th' scope='row'>
                  {row.type}
                  <br />
                  <small>{row.category}</small>
                </TableCell>
                <TableCell>{row.percentage}</TableCell>
              </TableRow>
            ))}
          {rows.length == 0 && (
            <TableRow key='notFound'>
              <TableCell colSpan={8} align='center'>
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
