import React, { useState } from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BonusDetail } from './bonus-detail.component';
import { WagerTable } from './wager-table.component';
import { ContTable } from './cont-table.component';
const useStyles: any = makeStyles({
  root: {
    marginTop: '40px',
  },
});

export interface ExternalProps {
  bonusHistory: any;
}

export const BonusHistory = (props: ExternalProps) => {
  const classes = useStyles();
  const { bonusHistory } = props;
  const [wagerData, setWagerData] = useState([]);
  const [contData, setContData] = useState([]);
  const [wagerTableOpen, setWagerTableOpen] = useState(false);
  const [contTableOpen, setContTableOpen] = useState(false);

  return (
    <React.Fragment>
      {Array.isArray(bonusHistory) &&
        bonusHistory.map((item: any, index: any) => {
          return (
            <Accordion key={item.id} defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography className={`${classes.heading} tour_bonusHistory-description`}>
                  {`[${item.bonus.code}] ${item.bonus.description}`}
                </Typography>
                {item.active == 1 && (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Chip label='Active ' color='primary' />
                  </>
                )}
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#121212', padding: 0 }}>
                <BonusDetail
                  bonusHistory={item}
                  setWagerData={setWagerData}
                  setContData={setContData}
                  setWagerTableOpen={setWagerTableOpen}
                  setContTableOpen={setContTableOpen}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      <WagerTable
        rows={wagerData}
        open={wagerTableOpen}
        onClose={() => {
          setWagerData([]);
          setWagerTableOpen(false);
        }}
      />

      <ContTable
        rows={contData}
        open={contTableOpen}
        onClose={() => {
          setContData([]);
          setContTableOpen(false);
        }}
      />
    </React.Fragment>
  );
};
