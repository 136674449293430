import React, { useEffect } from 'react';
import { Button, Paper, TextField, Link, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import { RoutesDictionary, RoutesEnum } from '../../../models/routes';
import { ILogin } from '../../../models/login';
// import 'clientjs';
import Grid from '@material-ui/core/Grid';
// import Alert from '@material-ui/lab/Alert';
// import AlertTitle from '@material-ui/lab/AlertTitle';
// // "this library was built to include directly into the browser, not imported."
// // @ts-ignore
// const client = new ClientJS();

export interface ExternalProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  error?: string;
  query?: any;
}
export interface ExternalActionProps {
  loginAction: (login: ILogin) => void;
  impersonatePlayerAction: (token: string) => void;
}

export interface InternalProps {
  classes: any;
}

function LoginPageComp(
  props: InternalProps & ExternalProps & ExternalActionProps,
): React.ReactElement<InternalProps & ExternalProps & ExternalActionProps> {
  const { classes, isLoggedIn, query, loginAction, impersonatePlayerAction, isLoading } = props;
  const [login, setLogin] = React.useState<ILogin>({
    username: '',
    password: '',
    ip: '',
    fingerprint: '',
  });

  const setUsername = (username: string) => {
    setLogin({ ...login, username });
  };

  const setPassword = (password: string) => {
    setLogin({ ...login, password });
  };

  const setLoginIp = (ip: string) => {
    setLogin({ ...login, ip });
  };

  useEffect(() => {
    if (query && query.imp) {
      impersonatePlayerAction(query.imp);
    }
  }, [query]);

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => {
        return response.json();
      })
      .then(res => {
        setLoginIp(res.ip);
        // console.log(res.ip);
      })
      .catch(err => console.error('Problem fetching my IP', err));
  }, []);

  // const startChat = (w: any, d: any, u: any) => {
  //   const s = d.createElement('script');
  //   s.setAttribute('id', 'liveChat');
  //   s.async = true;
  //   s.src = u + '?' + Date.now() / 60000;
  //   const h = d.getElementsByTagName('script')[0];
  //   h.parentNode.insertBefore(s, h);
  // };

  // useEffect(() => {
  //   startChat(window, document, 'https://ymt.pdub.lv/upload/crm/site_button/loader_1_wc8kwi.js');
  // }, []);

  if (isLoggedIn) {
    // const d: any = document.getElementById('liveChat');
    // d?.parentNode.removeChild(d);
    // const d: any = document.getElementsByClassName('b24-widget-button-inner-container')[0];
    // d?.parentNode.removeChild(d);
    // getUserBalanceAction();
    return <Redirect to={'/sportsbook'} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={3}>
          <div className={classes.form}>
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />

            {/* <Alert severity='warning'>
              <AlertTitle>Attention</AlertTitle>
              We will be down for maintenance from 6 AM to 2PM PST. Thank you for understanding
              <br /> <br />- VIP Team
            </Alert> */}

            <form
              noValidate
              onSubmit={event => {
                event.preventDefault();
                loginAction(login);
              }}
            >
              <TextField
                id='username'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label='Email/Username'
                value={login.username}
                onChange={event => setUsername(event.target.value)}
                autoFocus
                className={classes.inputText}
              />
              <TextField
                id='password'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label='Password'
                type='password'
                value={login.password}
                onChange={event => setPassword(event.target.value)}
                className={classes.inputText}
              />

              <Grid container justify='space-between'>
                <Link className={classes.moveForgotUsernameToLeft} href='https://www.pphreport.com/'>
                  Admin Login
                </Link>
                <Link className={classes.moveForgetPasswordToRight} href={`#${RoutesDictionary[RoutesEnum.ForgetPassword].path}`}>
                  Forgot Password
                </Link>
              </Grid>
              <div className={classes.bottomText}>
                <Button
                  color='default'
                  size='large'
                  variant='contained'
                  onClick={() => {
                    window.open(process.env.PUBLIC_URL + '/#/signup/Mysportspick', '_self');
                  }}
                  style={{ marginRight: '1rem' }}
                >
                  JOIN NOW
                </Button>
                <Button variant='contained' color='primary' size='large' type='submit' disabled={isLoading}>
                  <Typography variant='button'>{isLoading ? 'logging In...' : 'LogIn'}</Typography>
                </Button>
              </div>
              <div className={classes.bottomText}>
                <Typography variant='subtitle2'>
                  VIPClub <span dangerouslySetInnerHTML={{ __html: '&copy;' }} /> 2020-2021 - v3.0
                </Typography>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}

const styles: any = createStyles({
  shrinkLogo: {
    maxWidth: 100,
    height: 'auto',
    margin: '0px auto',
    display: 'block',
  },
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.png` + ')',
    backgroundSize: '1920px 1080px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
    backgroundPosition: 'top center',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: '450px',
    backgroundColor: '#050505',
  },
  form: {
    padding: 24,
  },
  moveForgetPasswordToRight: {
    paddingTop: 10,
    fontSize: '18px',
  },
  moveForgotUsernameToLeft: {
    paddingTop: 10,
    color: 'white',
    fontSize: '18px',
  },
  bottomText: {
    marginTop: 30,
    textAlign: 'center',
  },
});

export const LoginPage = withStyles(styles)(LoginPageComp);
