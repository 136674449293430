import { createAction } from 'redux-actions';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SET_SMS_CODE_NULL = `SET_SMS_CODE_NULL${suffix}`;

export const setSmsCodeNullAction = createAction(SET_SMS_CODE_NULL);

const reduceHandlers = {
  [SET_SMS_CODE_NULL]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    smsCodeCheck: null,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
