import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { ISignup } from '../../../models/users';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { showToast } from '../../../utils/toast/show-errors';
// import { CashierApiResponse } from '../../../models/cashier-response';

declare global {
  interface Window {
    LO: any;
  }
}

const logSignUpEventInLuckyOrange = (signUpData: any) => {
  if (window.LO != null) {
    if (window.LO.events) {
      window.LO.events.track('Signed Up', signUpData);
      console.log('[LO Event]: Signed Up');
    }
  }
};

const suffix = `/${userReducer.sliceName}/app`;

const SIGNUP = `SIGNUP${suffix}`;
export const signupAction = createAction<ISignup>(SIGNUP);

const SIGNUP_ERROR = `SIGNUP_ERROR${suffix}`;
const signupErrorAction = createAction<string>(SIGNUP_ERROR);
const SIGNUP_SUCCESS = `SIGNUP_SUCCESS${suffix}`;
const signupSuccessAction = createAction<string>(SIGNUP_SUCCESS);

function* signupWatcher(): SagaIterator {
  yield takeEvery(SIGNUP, signupWorker);
}
mergeSaga(signupWatcher);

function* signupWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(signupSuccessAction(result));

    if (result) {
      logSignUpEventInLuckyOrange(action.payload);
    }
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(signupErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (signup: ISignup): Request => {
  if (localStorage.getItem('fpjs') != null) {
    signup.fingerprint = localStorage.getItem('fpjs');
  }
  return vipApi(`/signup`, 'post', signup);
};

const reduceHandlers = {
  [SIGNUP]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    signupSuccess: false,
  }),
  [SIGNUP_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      signupSuccess: true,
      signupUsername: action.payload.username,
    };
  },
  [SIGNUP_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    error: action.payload,
    isLoading: false,
    signupSuccess: false,
    signupUsername: null,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
