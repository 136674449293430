import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Loading } from '../../../shared/loading/loading.component';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { getAppSettings } from '../../../utils/app-settings.util';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CancelIcon from '@material-ui/icons/Cancel';
import { isAndroid, isIOS, isWinPhone } from 'react-device-detect';
import { IRight } from '../../../models/rights';
import { getUserBalanceAction } from '../../../api/user/actions/get-balance.action';

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      paddingTop: '1rem',
      // backgroundColor: 'black',
      [theme.breakpoints.up('md')]: {
        overflowY: 'auto',
        height: '100%',
        // overflow-y: auto; height:150px;
      },
      overflowY: 'auto',
      height: '328px',
      // overflow-y: auto; height:150px;
    },
    card: {
      display: 'inline-block',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      width: '200px',
      marginTop: '0px',
      marginRight: '0px',
    },
    card_hover: {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
    container: {
      padding: '2px 16px',
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
    },
    paper: {
      position: 'absolute',
      width: '90%',
      height: '90%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0),
    },
    iframe: {
      width: '100%',
      height: '100%',
      border: 'none',
    },
    gamePaper: {
      maxWidth: '200px',
      textAlign: 'center',
      backgroundColor: '#212121',
      margin: '5px',
    },
    playbtn: {
      marginTop: '1rem',
      marginBottom: '.5rem',
    },
    closeBtn: {
      position: 'absolute',
      top: '2%',
      right: '2%',
    },
    titleGame: {
      fontSize: '14px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
);

export interface ExternalProps {
  isLoading: any;
  games: any;
  gameUrl: any;
  useFreePlay: any;
  provider: IRight;
}

export interface ExternalActionProps {
  getCasinoGamesAction: (x: any) => void;
  openCasinoGameAction: (x: any) => void;
  getUserBalanceAction: () => void;
}

export const TpiCasino = (props: ExternalProps & ExternalActionProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { isLoading, games, gameUrl, useFreePlay, getCasinoGamesAction, openCasinoGameAction, provider } = props;
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;

  useEffect(() => {
    getCasinoGamesAction({
      deviceId: 1,
      category: '',
      providerId: '' + provider.id,
    });
  }, [provider]);

  const handleClose = () => {
    setOpen(false);
    getUserBalanceAction();
  };

  const openGame = async (e: any) => {
    let device_id = 1;
    if (isMobile) {
      if (isAndroid) {
        device_id = 3;
      }
      if (isIOS) {
        device_id = 2;
      }
      if (isWinPhone) {
        device_id = 4;
      }
    }
    openCasinoGameAction({
      device_id: device_id,
      provider_id: e.provider_id,
      game_id: e.game_id,
      is_freeplay: useFreePlay,
    });
    if (!isMobile) {
      setOpen(true);
    }
  };

  const [modalStyle] = React.useState(getModalStyle);
  const body = (
    <>
      <IconButton color='primary' component='span' onClick={() => handleClose()} className={clsx(classes.closeBtn)} size='medium'>
        <CancelIcon />
      </IconButton>

      <div
        style={modalStyle}
        className={clsx(classes.paper)}
        dangerouslySetInnerHTML={{ __html: `<iframe style='width: 100%;height: 100%;' frameBorder='none' src='${gameUrl}' />` }}
      />
    </>
  );
  function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  let url = getAppSettings().apiUrl;
  url = url.slice(0, -4);

  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      {provider && (
        <>
          <Grid container className='tour_casino-grid'>
            {games.map((item: any) => (
              <Grid item xs={4} sm={2} key={item.game_id}>
                <Paper variant='outlined' className={clsx(classes.gamePaper)}>
                  <img
                    src={`${url + item.game_image}`}
                    alt='Avatar'
                    onError={(e: any) => {
                      e.target.onerror = null;
                      e.target.src = 'images/errors/game_default.png';
                    }}
                    className={clsx(classes.img)}
                  />
                  <Typography className={clsx(classes.titleGame)}>{item.game_description}</Typography>
                  <Typography variant='subtitle1'>{item.category_description}</Typography>

                  <Button onClick={() => openGame(item)} variant='contained' color='default' className={clsx(classes.playbtn)}>
                    <PlayArrowIcon /> Play
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            disableBackdropClick
          >
            {body}
          </Modal>
        </>
      )}
    </div>
  );
};
