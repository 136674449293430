import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { UserSliceType, userReducer } from '../user.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { getRafHistoryAction } from './get-raf-history.action';

const suffix = `/${userReducer.sliceName}/app`;

const REDEEM_RAF_BONUS = `REDEEM_RAF_BONUS${suffix}`;

export const redeemRafBonusAction = createAction(REDEEM_RAF_BONUS);

const REDEEM_RAF_BONUS_SUCCESS = `REDEEM_RAF_BONUS_SUCCESS${suffix}`;
const redeemRafBonusSuccessAction = createAction<any[]>(REDEEM_RAF_BONUS_SUCCESS);

const REDEEM_RAF_BONUS_ERROR = `REDEEM_RAF_BONUS_ERROR${suffix}`;
const redeemRafBonusErrorAction = createAction<string>(REDEEM_RAF_BONUS_ERROR);

function* redeemRafBonusWatcher(): SagaIterator {
  yield takeEvery(REDEEM_RAF_BONUS, redeemRafBonusWorker);
}
mergeSaga(redeemRafBonusWatcher);

function* redeemRafBonusWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(redeemRafBonusApi, action.payload);
    yield put(redeemRafBonusSuccessAction(result.data));
    yield call(showToast, result.message, 'success');

    yield put(getRafHistoryAction(action.payload.username));
  } catch (e) {
    yield call(showToast, e.payload.message, 'error');
    yield put(redeemRafBonusErrorAction(e));
  }
}

const redeemRafBonusApi = (x: any): Request => {
  return vipApi(`/raf/redeem`, 'post', {
    username: x.username,
    userId: x.referralId,
    amount: x.depositAmount,
  });
};

const reduceHandlers = {
  [REDEEM_RAF_BONUS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [REDEEM_RAF_BONUS_SUCCESS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [REDEEM_RAF_BONUS_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
