import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const RIGHT_OPEN = `RIGHT_OPEN${suffix}`;

export const rightOpenAction = createAction<boolean>(RIGHT_OPEN);

const RIGHT_OPEN_SUCCESS = `RIGHT_OPEN_SUCCESS${suffix}`;
const rightOpenSuccessAction = createAction<boolean>(RIGHT_OPEN_SUCCESS);

const RIGHT_OPEN_ERROR = `RIGHT_OPEN_ERROR${suffix}`;
const rightOpenErrorAction = createAction<string>(RIGHT_OPEN_ERROR);

function* rightOpenWatcher(): SagaIterator {
  yield takeEvery(RIGHT_OPEN, rightOpenWorker);
}
mergeSaga(rightOpenWatcher);

function* rightOpenWorker(action: any): SagaIterator {
  try {
    yield put(rightOpenSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(rightOpenErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [RIGHT_OPEN]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [RIGHT_OPEN_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    rightOpen: action.payload,
  }),
  [RIGHT_OPEN_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
