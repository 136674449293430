import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GeneralRules } from './components/general-rules';
import { SportsRules } from './components/sports-rules';
import { getHistory } from '../../utils/history.util';
import { Loading } from '../../shared/loading/loading.component';

const useStyles: any = makeStyles({
  root: {
    padding: '1rem',
    backgroundColor: '#121212',
  },
  container: {
    maxWidth: '1200px',
    display: 'block',
    margin: '0px auto',
  },
  rulesHeader: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  ul: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  li: {
    margin: '.5rem',
    padding: 0,
    fontSize: '.9rem',
    color: '#707070',
    cursor: 'pointer',
  },
  liSelected: {
    margin: '.5rem',
    padding: 0,
    fontSize: '.9rem',
    color: 'white',
    cursor: 'pointer',
  },
  hAd: {
    width: '100%',
    height: '100px',
  },
  vAd: {
    width: '100%',
    height: '500px',
  },
  img: {
    borderRadius: '5px',
    width: '100%',
  },
});

export interface ExternalActionProps {
  getRuleAction: (x: number) => void;
  getCategoryAction: () => void;
}

export interface ExternalProps {
  rules: any;
  isLoading: boolean;
  categories: any;
}

export const RulesPage = (props: ExternalActionProps & ExternalProps) => {
  const classes = useStyles();
  const { getRuleAction, rules, isLoading, categories, getCategoryAction } = props;
  const [selectedRules, setSelectedRules] = useState(0);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    getCategoryAction();
  }, []);

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      getRuleAction(categories[0].id);
      setSelectedRules(categories[0].id);
    }
  }, [categories]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile: boolean = width <= 768;
  return (
    <div className={clsx(classes.root)}>
      <Loading loading={isLoading} />
      <div className={clsx(classes.container)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2}>
            <Typography variant='h6' color='primary' className={clsx(classes.rulesHeader)}>
              Rules
            </Typography>
            <ul className={clsx(classes.ul)}>
              {Array.isArray(categories) &&
                categories.map((item: any) => {
                  return (
                    <li
                      key={item.id}
                      className={selectedRules == item.id ? clsx(classes.liSelected) : clsx(classes.li)}
                      onClick={() => {
                        getRuleAction(item.id);
                        setSelectedRules(item.id);
                      }}
                    >
                      <FontAwesomeIcon icon={['fad', 'crown']} /> {item.name}
                    </li>
                  );
                })}
            </ul>
          </Grid>

          {isMobile && (
            <Grid item xs={12}>
              <img
                src={`${process.env.PUBLIC_URL}/images/market/hzadimg.png`}
                className={clsx(classes.img)}
                onClick={() => {
                  getHistory().push('/refer-a-friend');
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={8}>
            <Paper>
              {Array.isArray(categories) &&
                categories.map((item: any) => {
                  return (
                    selectedRules == item.id &&
                    (Array.isArray(rules) && rules.length > 1 ? (
                      <SportsRules rules={rules} category={item} />
                    ) : (
                      <GeneralRules rules={rules} category={item} />
                    ))
                  );
                })}
            </Paper>
          </Grid>

          {!isMobile && (
            <Grid item xs={12} sm={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/market/verticalrafad.png`}
                className={clsx(classes.img)}
                onClick={() => {
                  getHistory().push('/refer-a-friend');
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
