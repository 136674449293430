import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import StarIcon from '@material-ui/icons/Star';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SpotlightBg from '../components/images/login-bg.png';
// import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Container, Grid, FormLabel, Typography, Card, Dialog, Button } from '@material-ui/core';
import { getHistory } from '../../../../utils/history.util';
// import { TransactionHistory } from './TransactionHistory';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
// import AdDialog from '../../../../shared/app-bar/components/ad-dialog.componenent';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 5,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
  },
  title2: {
    // fontWeight: theme.typography.fontWeightBold,
    color: 'white', //theme.palette.secondary.main,
    textAlign: 'left',
    marginLeft: '.5rem',
    marginTop: '.5rem',
    fontSize: '1.3rem',
  },
  card: {
    width: '100%',
    padding: '20px 0px',
    margin: '0px 0px',
    textAlign: 'center',
  },
  depositCard: {
    width: '100%',
    padding: '20px 0px',
    margin: '18px 0px',
    textAlign: 'center',
    backgroundImage: `url(${SpotlightBg})`,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  withdrawalCard: {
    width: '100%',
    padding: '20px 0px',
    margin: '18px 0px',
    textAlign: 'center',
    backgroundImage: `url(${SpotlightBg})`,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  cardDisabled: {
    filter: 'blur(2px)',
  },
  icon: theme.typography.h6,
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 12,
  },
  bonusSection: {
    width: '100%',
    marginTop: '10px',
    padding: '0px 0px',
    textAlign: 'left',
    // borderRadius: "0px",
    backgroundImage: `url(${SpotlightBg})`,
    backgroundSize: 'cover',
  },
  bonusAmountTitles: {
    fontSize: '.60rem',
    textAlign: 'center',
  },
  bonusAmounts: {
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#2d2d2d',
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  remainingRolloverWhite: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'grey',
  },
  remainingRolloverRed: {
    fontWeight: theme.typography.fontWeightBold,
    color: '#f00',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  guideBtn: {
    borderRadius: '.30rem',
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#181818',
  },
}));

export interface ExternalProps {
  playerNumber: string;
  rollover: any;
  isLoading: boolean;
  userBalances: any;
  user: any;
  feeFreeTimes: any;
}

export interface ExternalActionProps {
  setTransactionTypeAction: (type: number) => void;
  handleOpen: (data: any) => void;
  handleContOpen: (data: any) => void;
  setTransactionBonusAction: (data: any) => void;
  setBankReferenceNullAction: (x: any) => void;
}

export const FormTransactionType = (props: ExternalActionProps & ExternalProps) => {
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const classes = useStyles();
  const {
    user,
    playerNumber,
    rollover,
    isLoading,
    userBalances,
    feeFreeTimes,
    setTransactionTypeAction,
    handleOpen,
    handleContOpen,
    setTransactionBonusAction,
    setBankReferenceNullAction,
  } = props;

  const isOnBonus = rollover != null;

  // const url = `${getAppSettings().baseUrl}resources/instantpayvid.mp4`;

  console.log(feeFreeTimes);

  return (
    <React.Fragment>
      <Container className={classes.container} maxWidth='sm'>
        {!isLoading && userBalances.RealAvailBalance < 10 && (
          <Alert severity='warning' style={{ marginTop: '.5rem' }}>
            <AlertTitle>
              <Typography variant='body1'>Low Balance!</Typography>
            </AlertTitle>
            <Typography variant='body2'>
              Looks like you have a low balance! You must deposit additional funds before you are able to place wagers.
            </Typography>
          </Alert>
        )}
        {!isLoading && (
          <>
            <Grid>
              <Card
                classes={{
                  root: clsx(classes.bonusSection),
                }}
              >
                <Grid item xs={12} style={{ padding: '5px 10px' }}>
                  <FormLabel component='legend'>
                    <Typography variant='h4' classes={{ root: classes.title2 }}>
                      {playerNumber}
                      {rollover != null && (
                        <>
                          <br />
                          <Typography color='textSecondary'>Bonus Status</Typography>
                        </>
                      )}
                    </Typography>
                  </FormLabel>

                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      style={{
                        paddingTop: '2rem',
                        paddingBottom: '.5rem',
                        textAlign: 'left',
                        verticalAlign: 'text-bottom',
                      }}
                    >
                      {rollover != null && Object.keys(rollover).length !== 0 ? (
                        <>
                          {rollover.bonus.category == 'riskfreebet' ? (
                            <>
                              <Typography className={classes.remainingRolloverRed} gutterBottom>
                                Risk Free Wager Enabled
                              </Typography>
                              <small>
                                Place your wager if it loses you will be credited back the full risk amount up to $1000. You must wager the
                                deposit or bonus money prior to cashing out!
                              </small>
                            </>
                          ) : (
                            <>
                              <Typography variant='h4' className={classes.remainingRolloverRed}>
                                ${rollover.rollover_data.remaining_rollover}
                              </Typography>
                              <Typography color='textSecondary' gutterBottom>
                                Remaining Rollover
                              </Typography>
                              {rollover.bonus.description.includes('Risk Free') && (
                                <small>Upon claiming a bonus, you must play amount equal to deposit + bonus money to cash out.</small>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography variant='h4' className={classes.remainingRolloverWhite}>
                            $0.00
                          </Typography>
                          <Typography color='textSecondary' gutterBottom>
                            Remaining Rollover
                          </Typography>
                        </>
                      )}
                    </Grid>

                    {!isLoading && rollover == null ? (
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: '0rem',
                          paddingBottom: '.5rem',
                          textAlign: 'center',
                        }}
                      >
                        <StarIcon
                          color='primary'
                          style={{
                            fontSize: '1.5rem',
                            marginRight: '5px',
                          }}
                        />
                        <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }} gutterBottom>
                          Bonuses are available!
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={6}
                        style={{
                          paddingTop: '3.4rem',
                          paddingBottom: '.5rem',
                          textAlign: 'right',
                        }}
                      >
                        <Typography variant='subtitle2' gutterBottom>
                          Claimed Date:
                          <br />
                          {rollover.start_date}
                        </Typography>
                      </Grid>
                    )}

                    {!isLoading && rollover != null && (
                      <Grid item xs={12} style={{ width: '100%' }}>
                        <Button
                          variant='contained'
                          color='primary'
                          style={{
                            marginTop: '.5rem',
                            float: 'right',
                            marginLeft: '.5rem',
                          }}
                          onClick={handleOpen}
                        >
                          View Wagers
                        </Button>
                        <Button variant='outlined' color='default' style={{ marginTop: '.5rem', float: 'right' }} onClick={handleContOpen}>
                          Contribution Table
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4} className={classes.bonusAmounts}>
                    <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                      Starting Rollover
                    </Typography>
                    {rollover != undefined && rollover.rollover_data != undefined ? (
                      <>
                        {rollover.bonus.category == 'riskfreebet' ? (
                          <Typography variant='h6'>$0.00</Typography>
                        ) : (
                          <Typography variant='h6'>${rollover.rollover_data.starting_rollover}</Typography>
                        )}
                      </>
                    ) : (
                      <Typography variant='h6'>$0</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} className={classes.bonusAmounts}>
                    <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                      Free Play Winnings
                    </Typography>
                    {rollover != undefined && rollover.rollover_data != undefined ? (
                      <Typography variant='h6'>${rollover.rollover_data.free_play_winnings}</Typography>
                    ) : (
                      <Typography variant='h6'>$0</Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} className={classes.bonusAmounts}>
                    <Typography variant='subtitle1' color='textSecondary' className={classes.bonusAmountTitles}>
                      Amount Wagered
                    </Typography>
                    {rollover != undefined && rollover.rollover_data != undefined ? (
                      <Typography variant='h6'>${rollover.rollover_data.amount_wagered}</Typography>
                    ) : (
                      <Typography variant='h6'>$0</Typography>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card
                  onClick={() => {
                    setTransactionBonusAction(null);
                    setTransactionTypeAction(1);
                    setBankReferenceNullAction(null);
                    getHistory().push('/cashier/method');
                  }}
                  classes={{ root: classes.depositCard }}
                >
                  <MonetizationOnIcon style={{ color: 'green', fontSize: '2.5rem' }} />

                  <Typography variant='h6'>Deposit</Typography>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  onClick={() => {
                    /**
                     * Restrict credit players?
                     * - Users that DONT have an invite code?
                     * or?
                     * - Users that DONT have VIP in the beginning
                     */
                    if (user.phone_verified_at == null || user.email_verified_at == null) {
                      alert(
                        'Sorry, in order to withdraw funds, you must verify your phone AND email in your profile settings. Taking you there now...',
                      );
                      getHistory().push('/profile');
                    } else if (isOnBonus) {
                      alert(
                        'Sorry but you cannot withdraw while on a rollover or with a free play balance. Please read the Promotion Rules for more information.',
                      );
                    } else {
                      setTransactionBonusAction(null);
                      setTransactionTypeAction(2);
                      getHistory().push('/cashier/method');
                    }
                  }}
                  classes={{
                    root: clsx(classes.withdrawalCard, {
                      [classes.cardDisabled]: isOnBonus,
                    }),
                  }}
                >
                  <AccountBalanceIcon style={{ color: 'grey', fontSize: '2.5rem' }} />
                  <Typography variant='h6'>Withdrawal</Typography>
                </Card>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '-2rem', marginBottom: '1rem' }}>
                <Alert severity='success' style={{ marginTop: '.5rem', textAlign: 'center' }}>
                  <AlertTitle>
                    <Typography variant='body1'>Free BITCOIN Withdrawal Periods</Typography>
                  </AlertTitle>
                  {feeFreeTimes &&
                    feeFreeTimes.map((day: any) => {
                      return (
                        <Typography variant='body2' key={day.id} style={{ fontWeight: 'bold' }} gutterBottom>
                          {day.day} - {day.start_time} to {day.end_time} PST
                        </Typography>
                      );
                    })}
                  <br />

                  <Typography variant='body2'>Withdrawals outside of these hours will be processed at default fees.</Typography>
                  <br />
                  <Typography variant='caption'>These periods are subject to change at Managements discretion.</Typography>
                </Alert>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '-2rem' }}>
                <Alert severity='info' style={{ marginTop: '.5rem' }}>
                  <AlertTitle>
                    <Typography variant='body1'>(Instant) Pay Withdrawals Only available to US based clients</Typography>
                  </AlertTitle>
                  <Typography variant='body2'>(Instant) Pay withdrawals available for verified users!</Typography>
                  {/* <video
                    controls
                    style={{ width: '100%', height: 'auto', marginTop: '1rem', maxHeight: '350px', maxWidth: '480px' }}
                    autoPlay
                    muted
                  >
                    <source src={`${process.env.PUBLIC_URL}/images/instantpayvid.mp4`} type='video/mp4' />
                  </video> */}
                  <iframe
                    width='300'
                    height='315'
                    src='https://www.youtube.com/embed/tunXmNtSr38'
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    // allowfullscreen
                    style={{ width: '100%', marginTop: '1rem', maxWidth: '280px', display: 'block', margin: '0px auto' }}
                  ></iframe>
                </Alert>
              </Grid>
            </Grid>

            <Dialog
              onClose={() => {
                setShowTransactionHistory(false);
              }}
              aria-labelledby='transactionHistory'
              open={showTransactionHistory}
              fullWidth={true}
              maxWidth='sm'
            >
              {/* <TransactionHistory
                playerNumber={playerNumber}
                onClose={() => {
                  setShowTransactionHistory(false);
                }}
              /> */}
            </Dialog>
          </>
        )}
      </Container>
      {/* <AdDialog /> */}
    </React.Fragment>
  );
};
