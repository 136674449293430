import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';

export interface ExternalProps {
  rows: any;
  bonus: any;
}

export default function ConfirmTable(props: ExternalProps) {
  const { rows, bonus } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableBody>
          {rows
            .filter((row: any) => {
              if (bonus == null) {
                return row.dataType != ' Bonus';
              } else {
                return true;
              }
            })
            .map((row: any, index: any) => (
              <TableRow key={index}>
                <TableCell component='th' scope='row'>
                  {row.dataType}
                </TableCell>
                <TableCell align='right'>{row.dataAmount}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
