import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_PAYMENT_METHODS = `GET_PAYMENT_METHODS${suffix}`;

export const getPaymentMethodsAction = createAction<any>(GET_PAYMENT_METHODS);

const GET_PAYMENT_METHODS_SUCCESS = `GET_PAYMENT_METHODS_SUCCESS${suffix}`;
const getPaymentMethodsSuccessAction = createAction<any[]>(GET_PAYMENT_METHODS_SUCCESS);

const GET_PAYMENT_METHODS_ERROR = `GET_PAYMENT_METHODS_ERROR${suffix}`;
const getPaymentMethodsErrorAction = createAction<string>(GET_PAYMENT_METHODS_ERROR);

function* getPaymentMethodsWatcher(): SagaIterator {
  yield takeEvery(GET_PAYMENT_METHODS, getPaymentMethodsWorker);
}
mergeSaga(getPaymentMethodsWatcher);

function* getPaymentMethodsWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getPaymentMethodsApi, action.payload);
    yield put(getPaymentMethodsSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getPaymentMethodsErrorAction(e));
  }
}

const getPaymentMethodsApi = (x: any): Request => {
  return vipApi(`/pcashier/methods?player_number=${x.playerNumber}&site=1&type=${x.type}`, 'get', {});
};

const reduceHandlers = {
  [GET_PAYMENT_METHODS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PAYMENT_METHODS_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    paymentMethods: action.payload.data,
  }),
  [GET_PAYMENT_METHODS_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
