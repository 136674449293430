import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles: any = makeStyles({
  root: {
    marginTop: '40px',
  },
  paper: {
    padding: '20px',
  },
});

export interface ExternalProps {
  rows: any;
  open: boolean;
  onClose: () => void;
}

export const ContTable = (props: ExternalProps) => {
  const classes = useStyles();
  const { rows, open, onClose } = props;

  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth='lg' open={open} onClose={onClose} aria-labelledby='max-width-dialog-title'>
        <DialogContent>
          <DialogContentText>Contribution Table</DialogContentText>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Wager Type</TableCell>
                  <TableCell>Contribution %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map((row: { id: string; category: string; type: string; percentage: string }) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        {row.category}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.percentage}%</TableCell>
                    </TableRow>
                  ))}
                {rows.length == 0 && (
                  <TableRow key='notFound'>
                    <TableCell colSpan={8} align='center'>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
