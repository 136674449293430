import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { vipApi } from '../../../utils/vip-api';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const GET_PENDING_BETS = `GET_PENDING_BETS${suffix}`;

export const getPendingBetsAction = createAction(GET_PENDING_BETS);

const GET_PENDING_BETS_SUCCESS = `GET_PENDING_BETS_SUCCESS${suffix}`;
const getPendingBetsSuccessAction = createAction<any[]>(GET_PENDING_BETS_SUCCESS);

const GET_PENDING_BETS_ERROR = `GET_PENDING_BETS_ERROR${suffix}`;
const getPendingBetsErrorAction = createAction<string>(GET_PENDING_BETS_ERROR);

function* getPendingBetsWatcher(): SagaIterator {
  yield takeEvery(GET_PENDING_BETS, getPendingBetsWorker);
}
mergeSaga(getPendingBetsWatcher);

function* getPendingBetsWorker(): SagaIterator {
  try {
    const result: any = yield call(getPendingBetsApi);
    yield put(getPendingBetsSuccessAction(result));
  } catch (e) {
    yield put(getPendingBetsErrorAction(e));
  }
}

const getPendingBetsApi = (): Request => {
  return vipApi(`/wager/OpenBets`, 'post', {});
};

const reduceHandlers = {
  [GET_PENDING_BETS]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_PENDING_BETS_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
    pendingBets: action.payload,
  }),
  [GET_PENDING_BETS_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
