import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { gameSelectionReducer, GameSelectionSliceType } from '../game-selection.reducer';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${gameSelectionReducer.sliceName}/app`;

const PLAYER_LINES = `PLAYER_LINES${suffix}`;

export const playerLinesAction = createAction<boolean>(PLAYER_LINES);

const PLAYER_LINES_SUCCESS = `PLAYER_LINES_SUCCESS${suffix}`;
const playerLinesSuccessAction = createAction<boolean>(PLAYER_LINES_SUCCESS);

const PLAYER_LINES_ERROR = `PLAYER_LINES_ERROR${suffix}`;
const playerLinesErrorAction = createAction<string>(PLAYER_LINES_ERROR);

function* playerLinesWatcher(): SagaIterator {
  yield takeEvery(PLAYER_LINES, playerLinesWorker);
}
mergeSaga(playerLinesWatcher);

function* playerLinesWorker(action: any): SagaIterator {
  try {
    yield put(playerLinesSuccessAction(action.payload));
  } catch (e) {
    yield call(showToast, 'Some error occure', 'error');
    yield put(playerLinesErrorAction('Some error occure'));
  }
}

const reduceHandlers = {
  [PLAYER_LINES]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
  [PLAYER_LINES_SUCCESS]: (slice: GameSelectionSliceType, action: any): GameSelectionSliceType => ({
    ...slice,
    playerLines: action.payload,
  }),
  [PLAYER_LINES_ERROR]: (slice: GameSelectionSliceType): GameSelectionSliceType => ({
    ...slice,
  }),
};

gameSelectionReducer.addActionReducerMap(reduceHandlers);
