import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const CHECK_SMS_CODE = `CHECK_SMS_CODE${suffix}`;

export const checkSmsCodeAction = createAction<any>(CHECK_SMS_CODE);

const CHECK_SMS_CODE_SUCCESS = `CHECK_SMS_CODE_SUCCESS${suffix}`;
const checkSmsCodeSuccessAction = createAction<any[]>(CHECK_SMS_CODE_SUCCESS);

const CHECK_SMS_CODE_ERROR = `CHECK_SMS_CODE_ERROR${suffix}`;
const checkSmsCodeErrorAction = createAction<string>(CHECK_SMS_CODE_ERROR);

function* checkSmsCodeWatcher(): SagaIterator {
  yield takeEvery(CHECK_SMS_CODE, checkSmsCodeWorker);
}
mergeSaga(checkSmsCodeWatcher);

function* checkSmsCodeWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(checkSmsCodeApi, action.payload);
    yield put(checkSmsCodeSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(checkSmsCodeErrorAction(e));
  }
}

const checkSmsCodeApi = (x: any): Request => {
  return vipApi(`/pcashier/checkCode`, 'post', {
    username: x.username,
    code: x.code,
  });
};

const reduceHandlers = {
  [CHECK_SMS_CODE]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [CHECK_SMS_CODE_SUCCESS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    smsCodeCheck: true,
  }),
  [CHECK_SMS_CODE_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    smsCodeCheck: null,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
