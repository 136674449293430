import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const SEND_SMS_CODE = `SEND_SMS_CODE${suffix}`;

export const sendSmsCodeAction = createAction<any>(SEND_SMS_CODE);

const SEND_SMS_CODE_SUCCESS = `SEND_SMS_CODE_SUCCESS${suffix}`;
const sendSmsCodeSuccessAction = createAction(SEND_SMS_CODE_SUCCESS);

const SEND_SMS_CODE_ERROR = `SEND_SMS_CODE_ERROR${suffix}`;
const sendSmsCodeErrorAction = createAction<any>(SEND_SMS_CODE_ERROR);

function* sendSmsCodeWatcher(): SagaIterator {
  yield takeEvery(SEND_SMS_CODE, sendSmsCodeWorker);
}
mergeSaga(sendSmsCodeWatcher);

function* sendSmsCodeWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(sendSmsCodeApi, action.payload);
    yield put(sendSmsCodeSuccessAction());
    yield call(showToast, result.message, 'success');
  } catch (e) {
    if (e.response.data.message == undefined || e.response.data == undefined) {
      e.response.data.message = 'Failed sending verification code. Please try again in a few seconds.';
    }
    yield call(showToast, e.response.data.message, 'error');
    yield put(sendSmsCodeErrorAction(e));
  }
}

const sendSmsCodeApi = (username: any): Request => {
  return vipApi(`/pcashier/sendSms`, 'post', {
    username: username,
  });
};

const reduceHandlers = {
  [SEND_SMS_CODE]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [SEND_SMS_CODE_SUCCESS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [SEND_SMS_CODE_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
