import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { ruleSelectionReducer, RuleSelectionSliceType } from './../rule.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${ruleSelectionReducer.sliceName}/app`;

const GET_RULE = `GET_RULE${suffix}`;

export const getRuleAction = createAction<number>(GET_RULE);

const GET_RULE_SUCCESS = `GET_RULE_SUCCESS${suffix}`;
const getRuleSuccessAction = createAction<any[]>(GET_RULE_SUCCESS);

const GET_RULE_ERROR = `GET_RULE_ERROR${suffix}`;
const getRuleErrorAction = createAction<string>(GET_RULE_ERROR);

function* getRuleWatcher(): SagaIterator {
  yield takeEvery(GET_RULE, getRuleWorker);
}
mergeSaga(getRuleWatcher);

function* getRuleWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getRuleApi, action.payload);
    yield put(getRuleSuccessAction(result.data));
  } catch (e) {
    yield put(getRuleErrorAction('Some error occure.'));
  }
}

const getRuleApi = (id: number): Request => {
  return vipApi(`/rules/GetRulesByCategory/${id}`, 'get', {});
};

const reduceHandlers = {
  [GET_RULE]: (slice: RuleSelectionSliceType): RuleSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_RULE_SUCCESS]: (slice: RuleSelectionSliceType, action: any): RuleSelectionSliceType => ({
    ...slice,
    isLoading: false,
    rules: action.payload,
  }),
  [GET_RULE_ERROR]: (slice: RuleSelectionSliceType): RuleSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

ruleSelectionReducer.addActionReducerMap(reduceHandlers);
