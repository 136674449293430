import React, { useEffect, useState, useRef } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  FormLabel,
  Chip,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Collapse,
  TextField,
} from '@material-ui/core';
import { getHistory } from '../../../utils/history.util';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Loading } from '../../../shared/loading/loading.component';
import { BonusContDialog } from './components/BonusContDialog.component';
import { BonusContTable } from './components/BonusContTable.component';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    padding: 15,
    marginBottom: '3rem',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  alertBody: {
    marginLeft: -22,
    width: '100%',
    textAlign: 'center',
  },
  card: {
    marginBottom: 10,
  },
  alreadyOnBonusCard: {
    textAlign: 'center',
    padding: 15,
    marginBottom: 10,
  },
  loader: {
    padding: 15,
  },
  bonusDetails: {
    display: 'grid',
    textAlign: 'center',
    marginTop: 10,
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  message: {
    width: '100%',
  },
}));

export interface ExternalProps {
  user: any;
  type: any;
  isLoading: any;
  rollover: any;
  availableBonuses: any;
  method: any;
  bonus: any;
}

export interface ExternalActionProps {
  setTransactionBonusAction: (bonus: any) => void;
  getBonusesAction: (x: any) => void;
  getBonusByCodeAction: (code: any) => void;
}

export const BonusSelectPage = (props: ExternalProps & ExternalActionProps) => {
  const {
    user,
    isLoading,
    type,
    rollover,
    availableBonuses,
    method,
    bonus,
    setTransactionBonusAction,
    getBonusesAction,
    getBonusByCodeAction,
  } = props;

  const [showDetails, setShowDetails] = useState(0);
  const mountedRef = useRef(true);
  const classes = useStyles();
  const [promoCode, setPromoCode] = useState('');
  const [contOpen, setContOpen] = useState(false);
  const [contData, setContData] = useState([]);

  useEffect(() => {
    // Object.keys(availableBonuses).length < 1
    if (method == undefined || method == null) {
      getHistory().push('/cashier');
    } else {
      getBonusesAction({
        player_number: user.username,
        methodId: method.id,
      });
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  let isOnBonus = null;
  if (rollover != undefined) {
    isOnBonus = true;
  } else {
    isOnBonus = false;
  }

  if (type == 1 && bonus != null) {
    getHistory().push('/cashier/info');
  }

  return (
    <>
      <Container className={classes.container} maxWidth='sm'>
        <Loading loading={isLoading} />

        <FormLabel component='legend'>
          <Typography variant='h6' classes={{ root: classes.title }}>
            Select a {type == 1 ? 'Deposit' : 'Withdrawal'} Bonus
          </Typography>
        </FormLabel>
        <hr />
        <Alert severity='info'>
          <Typography variant='body2'>If you would like to deposit without a bonus, you can skip by pressing the button below.</Typography>
        </Alert>
        <br />

        {!isLoading ? (
          <Grid item xs={12}>
            <BonusContDialog
              open={contOpen}
              onClose={() => {
                setContData([]);
                setContOpen(false);
              }}
              data={<BonusContTable rows={contData && contData != null ? contData : []} />}
            />

            {isOnBonus && (
              <Alert severity='warning' classes={{ root: classes.alreadyOnBonusCard }}>
                <Typography variant='body2'>
                  It looks like you are already on a bonus. If you claim ANOTHER bonus, your remaining rollover will be COMBINED with the
                  new rollover.
                </Typography>
              </Alert>
            )}

            <Alert
              severity='success'
              variant='outlined'
              classes={{ message: classes.message }}
              style={{ textAlign: 'center', marginBottom: '1rem', minWidth: '100%' }}
              icon={false}
            >
              <AlertTitle> Use special promo code below!</AlertTitle>
              <div style={{ minWidth: '100%' }}>
                <Grid container style={{ marginTop: '.5rem', marginBottom: '1rem', textAlign: 'center', width: '100%' }}>
                  <Grid item xs={12} style={{ display: 'block', margin: '0px auto', padding: 10 }}>
                    <TextField
                      variant='outlined'
                      label='Promo Code'
                      size='small'
                      value={promoCode}
                      onChange={event => {
                        setPromoCode(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <Button
                      variant='contained'
                      style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                      size='large'
                      color='secondary'
                      onClick={() => {
                        getBonusByCodeAction({ code: promoCode });
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Alert>

            {availableBonuses.length > 0 ? (
              availableBonuses.map((bonus: any) => (
                <Card
                  key={bonus.id}
                  className={classes.card}
                  onClick={() => {
                    if (bonus.id == showDetails) {
                      setShowDetails(0);
                    } else {
                      setShowDetails(bonus.id);
                    }
                    // setTransactionBonusAction(bonus);
                  }}
                >
                  <CardContent>
                    <div style={{ margin: '0px auto', display: 'block', textAlign: 'center' }}>
                      <Chip label={bonus.type} color={bonus.type == 'Free Play' ? 'primary' : 'secondary'} />

                      <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        dangerouslySetInnerHTML={{ __html: bonus.description }}
                        gutterBottom
                      ></Typography>

                      {bonus.details && bonus.details != null && bonus.details != 'null' && (
                        <Typography
                          variant='subtitle1'
                          // color='textSecondary'
                          dangerouslySetInnerHTML={{ __html: bonus.details }}
                          style={{ color: 'orange' }}
                        ></Typography>
                      )}

                      <Grid container style={{ paddingTop: '1rem', paddingBottom: '1rem' }} spacing={2}>
                        <Grid item xs={6}>
                          <Button
                            variant='outlined'
                            style={{ marginLeft: '.5rem', marginRight: '.5rem' }}
                            color='default'
                            onClick={() => {
                              setContData(bonus.contribution_table);
                              setContOpen(true);
                            }}
                          >
                            Contribution
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => {
                              setTransactionBonusAction(bonus);
                              getHistory().push('/cashier/info');
                            }}
                          >
                            <Typography>Claim Bonus</Typography>
                          </Button>
                        </Grid>
                      </Grid>

                      <div className={classes.bonusDetails}>
                        <Typography variant='caption'>Bonus Details:</Typography>
                        <Typography variant='caption'>
                          Deposit: Min ${bonus.min_deposit} - Max ${bonus.max_deposit}
                        </Typography>
                        {bonus.match_multiplier != 0 && bonus.category != 'riskfreebet' && (
                          <Typography variant='caption'>
                            {bonus.match_multiplier * 100}% Match | {Math.floor(bonus.rollover_multiplier)}x Rollover
                          </Typography>
                        )}
                      </div>
                    </div>

                    <br></br>

                    <div className={classes.center}>{bonus.id == showDetails ? <ArrowDropUpIcon /> : <ArrowDropDownCircleIcon />}</div>
                  </CardContent>
                  <Collapse in={bonus.id === showDetails}>
                    <Alert severity='warning' classes={{ message: classes.alertBody }}>
                      {bonus.match_multiplier == 0 ? (
                        <>
                          <AlertTitle>
                            <Typography variant='body1'>Bonus Terms</Typography>
                          </AlertTitle>
                          <Typography variant='body2' gutterBottom dangerouslySetInnerHTML={{ __html: bonus.terms }}></Typography>
                          <Typography variant='caption'>*See Bonus Rules for complete terms and Conditions</Typography>
                        </>
                      ) : (
                        <>
                          <AlertTitle>
                            <Typography variant='body1'>Wagering Requirement Before Withdrawals</Typography>
                          </AlertTitle>
                          <Typography variant='body2'>For a Sports Bonus with {bonus.rollover_multiplier * 1}x Rollover:</Typography>
                          <Typography variant='body2'>
                            $100 Deposit + ${bonus.match_multiplier * 100} Bonus = ${bonus.match_multiplier * 100 + 100}
                          </Typography>
                          <Typography variant='body2'>
                            ${bonus.match_multiplier * 100 + 100} x {Math.floor(bonus.rollover_multiplier)}x Rollover Multiplier = $
                            {bonus.rollover_multiplier * (bonus.match_multiplier * 100 + 100)}
                          </Typography>
                          <Typography variant='body2'>
                            This means you must wager a total of ${bonus.rollover_multiplier * (bonus.match_multiplier * 100 + 100)} to be
                            eligible for a Withdrawal.
                          </Typography>
                          <Typography variant='caption'>*See Bonus Rules for complete terms and Conditions</Typography>
                        </>
                      )}
                    </Alert>
                  </Collapse>
                </Card>
              ))
            ) : (
              <Typography variant='h6'>There are no available bonuses.</Typography>
            )}
          </Grid>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color='secondary' />
            <Typography variant='subtitle2'>Loading Bonuses...</Typography>
          </div>
        )}

        <span className={classes.button}>
          <Button
            variant='contained'
            color='default'
            onClick={() => {
              setTransactionBonusAction(null);
              getHistory().goBack();
            }}
          >
            <Typography>Back</Typography>
          </Button>

          <Button
            variant='outlined'
            color='default'
            onClick={() => {
              setTransactionBonusAction(null);
              getHistory().push('/cashier/info');
            }}
          >
            <Typography>Skip</Typography>
          </Button>
        </span>
      </Container>
    </>
  );
};
