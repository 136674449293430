import { connect } from 'react-redux';
import { changeCasinoGamesAction } from '../../api/tpi/actions/change-casino-games.action';
import { getCasinoGamesAction } from '../../api/tpi/actions/get-casino-games.action';
import { openCasinoGameAction } from '../../api/tpi/actions/open-game.action';
import {
  casinoCategoriesSliceSelector,
  casinoGamesSliceSelector,
  gameUrlSliceSelector,
  isLoadingSliceSelector,
} from '../../api/tpi/tpi.selector';
import { getUserBalanceAction } from '../../api/user/actions/get-balance.action';
import { userBalanceSelector } from '../../api/user/user.selector';
import { CasinoPage, ExternalProps, ExternalActionProps } from './casino.component';
import { loggedInUserSelector } from './../../api/user/user.selector';

function mapStateToProps(state: any): ExternalProps {
  return {
    isLoading: isLoadingSliceSelector(state),
    categories: casinoCategoriesSliceSelector(state),
    games: casinoGamesSliceSelector(state),
    gameUrl: gameUrlSliceSelector(state),
    userBalances: userBalanceSelector(state),
    user: loggedInUserSelector(state),
  };
}

const mapDispatchToProps: ExternalActionProps = {
  getCasinoGamesAction,
  changeCasinoGamesAction,
  openCasinoGameAction,
  getUserBalanceAction,
};

export const CasinoPageContainer = connect(mapStateToProps, mapDispatchToProps)(CasinoPage);
