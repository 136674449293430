import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { cashierTransactionReducer, CashierTransactionSliceType } from '../cashier.reducer';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${cashierTransactionReducer.sliceName}/app`;

const GET_ONLINE_HOURS = `GET_ONLINE_HOURS${suffix}`;

export const getOnlineHoursAction = createAction<any>(GET_ONLINE_HOURS);

const GET_ONLINE_HOURS_SUCCESS = `GET_ONLINE_HOURS_SUCCESS${suffix}`;
const getOnlineHoursSuccessAction = createAction<any[]>(GET_ONLINE_HOURS_SUCCESS);

const GET_ONLINE_HOURS_ERROR = `GET_ONLINE_HOURS_ERROR${suffix}`;
const getOnlineHoursErrorAction = createAction<string>(GET_ONLINE_HOURS_ERROR);

function* getOnlineHoursWatcher(): SagaIterator {
  yield takeEvery(GET_ONLINE_HOURS, getOnlineHoursWorker);
}
mergeSaga(getOnlineHoursWatcher);

function* getOnlineHoursWorker(action: any): SagaIterator {
  try {
    const result: any = yield call(getOnlineHoursApi, action.payload);
    yield put(getOnlineHoursSuccessAction(result));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(getOnlineHoursErrorAction(e));
  }
}

const getOnlineHoursApi = (type: any): Request => {
  return vipApi(`/pcashier/GetOnlineHours`, 'post', {
    type: type,
  });
};

const reduceHandlers = {
  [GET_ONLINE_HOURS]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_ONLINE_HOURS_SUCCESS]: (slice: CashierTransactionSliceType, action: any): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
    onlineHours: action.payload.data,
  }),
  [GET_ONLINE_HOURS_ERROR]: (slice: CashierTransactionSliceType): CashierTransactionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

cashierTransactionReducer.addActionReducerMap(reduceHandlers);
