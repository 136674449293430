import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { guideSelectionReducer, GuideSelectionSliceType } from './../guide.reducer';
import { vipApi } from '../../../utils/vip-api';
// import { IGetAll } from '../../../models/get-all';

const suffix = `/${guideSelectionReducer.sliceName}/app`;

const GET_CATEGORY = `GET_CATEGORY${suffix}`;

export const getCategoryAction = createAction(GET_CATEGORY);

const GET_CATEGORY_SUCCESS = `GET_CATEGORY_SUCCESS${suffix}`;
const getCategorySuccessAction = createAction<any[]>(GET_CATEGORY_SUCCESS);

const GET_CATEGORY_ERROR = `GET_CATEGORY_ERROR${suffix}`;
const getCategoryErrorAction = createAction<string>(GET_CATEGORY_ERROR);

function* getCategoryWatcher(): SagaIterator {
  yield takeEvery(GET_CATEGORY, getCategoryWorker);
}
mergeSaga(getCategoryWatcher);

function* getCategoryWorker(): SagaIterator {
  try {
    const result: any = yield call(getCategoryApi);
    console.log(result.data);
    yield put(getCategorySuccessAction(result.data));
  } catch (e) {
    yield put(getCategoryErrorAction('Some error occure.'));
  }
}

const getCategoryApi = (): Request => {
  return vipApi(`/guides/GetAllGuideCategories`, 'get', {});
};

const reduceHandlers = {
  [GET_CATEGORY]: (slice: GuideSelectionSliceType): GuideSelectionSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [GET_CATEGORY_SUCCESS]: (slice: GuideSelectionSliceType, action: any): GuideSelectionSliceType => ({
    ...slice,
    isLoading: false,
    categories: action.payload,
  }),
  [GET_CATEGORY_ERROR]: (slice: GuideSelectionSliceType): GuideSelectionSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

guideSelectionReducer.addActionReducerMap(reduceHandlers);
