import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { showToast } from '../../../utils/toast/show-errors';

const suffix = `/${userReducer.sliceName}/app`;

const SEND_OTP = `SEND_OTP${suffix}`;
export const sendOtpAction = createAction<string>(SEND_OTP);

const SEND_OTP_ERROR = `SEND_OTP_ERROR${suffix}`;
const sendOtpErrorAction = createAction<string>(SEND_OTP_ERROR);
const SEND_OTP_SUCCESS = `SEND_OTP_SUCCESS${suffix}`;
const sendOtpSuccessAction = createAction<string>(SEND_OTP_SUCCESS);

function* sendOtpWatcher(): SagaIterator {
  yield takeEvery(SEND_OTP, sendOtpWorker);
}
mergeSaga(sendOtpWatcher);

function* sendOtpWorker(action: any): SagaIterator {
  try {
    yield call(getApiPlayer, action.payload);
    yield put(sendOtpSuccessAction(action.payload.username));
  } catch (e) {
    yield call(showToast, e.response.data.message, 'error');
    yield put(sendOtpErrorAction(e.response.data.message));
  }
}

const getApiPlayer = (username: any): Request => {
  return vipApi(`/SendSMS`, 'post', { username });
};

const reduceHandlers = {
  [SEND_OTP]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    sendOtpSuccess: false,
  }),
  [SEND_OTP_SUCCESS]: (slice: UserSliceType): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      sendOtpSuccess: true,
    };
  },
  [SEND_OTP_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    error: action.payload,
    sendOtpSuccess: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
